import React, { useContext, useEffect, useState } from "react";
import Switch from "../../components/Buttons/Switch";
import { LangContext } from '../../context/lang-context';
import useFetch from '../../hooks/fetchMSSQL-hook';
import { NotificationManager } from 'react-notifications';
import useHttp from '../../hooks/use-http';

const PaymentSystems = () => {
    const { fetchMssqlApi } = useFetch()
    const { TRL_Pack } = useContext(LangContext)
    const { sendRequest } = useHttp();

    const [PaymentType, setPaymentType] = useState(null)
    const [UISettings, setUISettings] = useState(null)
    const [payments, setPayments] = useState({})

    const getPaymentType = () => {
        fetchMssqlApi('payment-types', {}, paymentType => {
            if (PaymentType == null)
                setPaymentType(paymentType)
        })
    }

    const getSettings = () => {
        fetchMssqlApi('settings', {}, data => {
            data.forEach(setting => setting.key === "cancelPayments" && setPayments(JSON.parse(setting.value)))
            data.forEach(setting => {
                if (setting.key === "cancelPayments") {
                    setPayments(JSON.parse(setting.value))
                }
            })
        })
    }

    const getUISettings = () => {
        fetchMssqlApi('ui-settings', {}, UISettings => {
            setUISettings(UISettings[0].CoinsCreditEnabled)
        })
    }

    useEffect(() => {
        getPaymentType();
        getUISettings();
        getSettings();
    }, [])

    const changeHandler = e => {
        const data = e.target;
        PaymentType[data.id].Enabled = Number(data.checked)
    }

    const onSubmit = e => {
        e.preventDefault();
        const { credit, cancelPayments, cooldownPayments } = e.target.elements
        const data = {
            paymentType: PaymentType,
            credit: Number(credit.checked),
            cancelPayments: Boolean(cancelPayments.checked),
            cooldownPayments: cooldownPayments.value
        }

        let countPaymentTypeEnabled = 0;
        data.paymentType.map(payment => {
            if (payment.Enabled)
                countPaymentTypeEnabled++
        })

        if (countPaymentTypeEnabled < 1)
            return NotificationManager.error("Please select at least one payment type", null, 4000);

        sendRequest({ url: 'payment-systems', method: 'PUT', data: data }, response => {
            if (response.status === 201) {
                getPaymentType()
                getUISettings()
                getSettings()
            }
        })
    }

    const getPaymentUI = () => {
        return (
            <div>
                <form onSubmit={onSubmit}>
                    <div className="row">
                        <div className="col-sm">
                            <div className="form-group">
                                <div className="row">
                                    <div className="col-sm">
                                        <label
                                            className="form-label">{TRL_Pack.advancedConfig.paymentSystemName}</label>
                                    </div>
                                    <div className="col-sm">
                                        <label className="form-label">{TRL_Pack.advancedConfig.enabled}</label>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                {PaymentType != null ? (PaymentType.map((item, index) => (
                                    <div className="form-group" key={index}>
                                        <div className="row">
                                            <div className="col-sm">
                                                <input type="text" className="form-control" readOnly={true}
                                                    defaultValue={TRL_Pack.advancedConfig.paymentTypes[item.Name]}></input>
                                            </div>
                                            <div className="col-sm">
                                                <Switch id={index} onChange={changeHandler}
                                                    defaultChecked={Boolean(item.Enabled)} />
                                            </div>
                                        </div>
                                    </div>
                                ))) : null}
                            </div>
                        </div>
                        <div className="col-sm">

                            {PaymentType != null && PaymentType[1].Enabled == 1 ? (
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label
                                            className="form-label">{TRL_Pack.advancedConfig.getCreditBeforeChooseProduct}</label>
                                        <div style={{ top: 2, left: "1.1em" }} className="custom-control custom-switch">
                                            <input type="checkbox" style={{ width: 15, transform: "scale(1.4)" }}
                                                defaultChecked={UISettings}
                                                className="form-control custom-control-input"
                                                id="credit"></input>
                                            <label className="form-label custom-control-label"
                                                style={{ width: 15, transform: "scale(1.4)" }}
                                                htmlFor="credit"></label>
                                        </div>
                                    </div>
                                </div>)
                                : (PaymentType != null ? (
                                    <div className="col-sm">
                                        <div className="form-group">
                                            <label
                                                className="form-label">{TRL_Pack.advancedConfig.getCreditBeforeChooseProduct}</label>
                                            <div style={{ top: 2, left: "1.1em" }}
                                                className="custom-control custom-switch">
                                                <input type="checkbox" style={{ width: 15, transform: "scale(1.4)" }}
                                                    defaultChecked={false} disabled={true}
                                                    className="form-control custom-control-input"
                                                    id="credit"></input>
                                                <label className="form-label custom-control-label"
                                                    style={{ width: 15, transform: "scale(1.4)" }}
                                                    htmlFor="credit"></label>
                                            </div>
                                        </div>
                                    </div>
                                ) : null)}
                            {payments && (
                                <>
                                    <div className="col-sm">
                                        <div className="form-group">
                                            <label
                                                className="form-label">{TRL_Pack.advancedConfig.cancelTransactions}</label>
                                            <div style={{ top: 2, left: "1.1em" }}
                                                className="custom-control custom-switch">
                                                <input type="checkbox" style={{ width: 15, transform: "scale(1.4)" }}
                                                    className="form-control custom-control-input"
                                                    id="cancelPayments"
                                                    defaultChecked={payments.cancelPayments}></input>
                                                <label className="form-label custom-control-label"
                                                    style={{ width: 15, transform: "scale(1.4)" }}
                                                    htmlFor="cancelPayments"></label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm">
                                        <div className="form-group">
                                            <label htmlFor="cooldownPayments" className="form-label">
                                                {TRL_Pack.advancedConfig.secondCancelTransactions}
                                            </label>
                                            <input
                                                type="number"
                                                min={0}
                                                max={100}
                                                step={1}
                                                defaultValue={payments.cooldownPayments}
                                                className="form-control"
                                                id="cooldownPayments"
                                            ></input>
                                        </div>
                                    </div>
                                </>)}
                        </div>
                    </div>
                    <div style={{ marginTop: 25 }}>
                        <input className="btn btn-success mx-auto" type="submit"
                            value={TRL_Pack.advancedConfig.saveChangdes} />
                    </div>
                </form>
            </div>
        )
    }

    return (
        <>
            <div className="row">
                <div className="col-12 mb-2">
                    <h5>{TRL_Pack.advancedConfig.paymentSystem}</h5>
                    {getPaymentUI()}
                </div>
            </div>
        </>
    )
}


export default PaymentSystems;