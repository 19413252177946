import React, { useState, createContext, useEffect } from 'react'
import PropTypes from 'prop-types';

export const LoaderContext = createContext({
  loader: false,
  showLoader: () => {},
  incrementRequests: () => {},
  decrementRequests: () => {}
})

const LoaderContextProvider = ({ children }) => {
  const [activeRequests, setActiveRequests] = useState(0);

  const incrementRequests = () => setActiveRequests(prev => prev + 1)
  const decrementRequests = () => setActiveRequests(prev => prev - 1)
  
  
  const showLoader = (isLoading) => {
    if (isLoading)
      setActiveRequests(prev => prev + 1);
    else if (!isLoading)
      activeRequests >= 0 && setActiveRequests(prev => prev - 1);
  }

  !activeRequests === 0 ? document.body.style.overflow = "hidden" : document.body.style.overflow = "visible"

  return (
    <LoaderContext.Provider
      value={{
        loader: activeRequests > 0,
        showLoader,
        incrementRequests,
        decrementRequests
      }}
    >
      {children}
    </LoaderContext.Provider>
  )
}

LoaderContextProvider.propTypes = {
  children: PropTypes.object
};

export default LoaderContextProvider;