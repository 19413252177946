import React from 'react'

export default function MachineTasksNavBar({ type, setType, machineType }) {
    return (
        <div>
            <ul className="nav nav-tabs machine-tabs mb-3">
                {machineType.map((el, i) => (
                    <li className="nav-item" key={i}>

                        <button
                            key={i}
                            className={`nav-link btn ${type === i && 'active'}`}
                            onClick={() => type !== i && setType(i)}
                            tabIndex={i}
                        >
                            {i === 0 ? 'Ustawienia (master)' : 'Ustawienia (Slave #' + i + ')'}
                        </button>
                    </li>

                ))}

                <li className="nav-item">
                    <button
                        className={`nav-link btn ${type === 3 && 'active'}`}
                        onClick={() => type !== 3 && setType(prev => 3)}
                        tabIndex="0"
                    >
                        Historia
                    </button>
                </li>
            </ul>
        </div>
    )
}
