import React, {useEffect, useContext, useState} from 'react'
import fetchHook from '../../hooks/fetchMSSQL-hook'
import { NavigationContext } from '../../context/navigation-context'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import NoResults from '../../components/NoResults/NoResults'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import link from '../../assets/images/back_to_list_icon.svg'
import { NotificationManager } from 'react-notifications'


export default function CashRegister() {
    const [defaultCashRegister, setDefaultCashRegister] = useState([]),
    [cashRegister, setCashRegister] = useState([]),
    [machinesList, setMachinesList] = useState([]),
    [cashRegisterIN, setCashRegisterIN] = useState([]),
    [changed, setChanged] = useState(false),
    [availableToSave, setAvailableToSave] = useState(true),
    [terminalList, setTerminalList] = useState(null),
    [cashRegistersList, setCashRegistersList] = useState(null),
    { setHeaderData } = useContext(NavigationContext),
    { fetchMssqlApi } = fetchHook(),
    params = useParams(),
    history = useHistory()
    const getCashRegister = (id = params.id) => {
        id !== "0" && fetchMssqlApi(`cashRegister/${id}`, {}, res => setDefaultCashRegister(res))
    }
    const getCashRegistersList = () => {
        fetchMssqlApi(`cashRegistersList`, {}, res => setCashRegistersList(res))
    }


    const getterminalList = () => {
        fetchMssqlApi(`terminal-list`, {method: "POST", hideNotification: true}, res => 
        setTerminalList(
            res
            .filter(i => i.Name.replace(/[^a-zA-Z]/g, '').toLowerCase() === 'pax' )
            .map(i => ({...i, SerialNo: i.Name.replace(/[^0-9]/g, '')}))
        )

        )
    }
    const getMachinesList = () => {
        fetchMssqlApi(`machines-list-without-cash-registers`, {}, res => setMachinesList(res))
    }


    const handleChangeMachine = (machineName) => {
        const machine = machinesList && machinesList.find(machine => machine.Name === machineName)
        if (!machine) {
            setCashRegister(prev => ({...prev, MachineName: machineName}))
        } else {
            setCashRegister(prev => ({...prev, MachineName: machineName, MachineId: machine.MachineId}))
        }
    }
    const handleChangeState = (evt) => {
        const {name, value} = evt.target
        setChanged(true)

        switch(name) {
            case "IsTest": {

                const v = value === "true"
                setCashRegister(prev => ({...prev, IsTest: v}))
                break;
            }
            case "TSN":
            case "NrEwid":
            case "NrUnik":
            case "TypeOfWork":
                setCashRegister(prev => ({...prev, [name]: value}))
                break;
            case "MachineName":
                handleChangeMachine(value)
                break; 
        }
    }
    const handleFocus = (evt) => {
        const {name} = evt.target

        switch(name) {
            case "TSN":
            case "NrEwid":
            case "NrUnik":
                setCashRegister(prev => ({...prev, [name]: ""}))
                break;
            case "MachineName":
                setCashRegister(prev => ({...prev, [name]: "", MachineId: null}))
                break; 
        }
    }

    const checkProvidedValuesIsUnique = () => {
        let val = true
        cashRegistersList.recordset.forEach(i => {
            if (i.TSN === cashRegister.TSN || i.NrUnik === cashRegister.NrUnik) {
                if(i.TSN == cashRegister.TSN) {
                    NotificationManager.error("Ten numer fabryczny używany przez inną kasę, wybierz inny")
                    setCashRegister(prev => ({...prev, TSN: ""}))
                    val = false
                }
                if(i.NrUnik == cashRegister.NrUnik) {
                    NotificationManager.error("Ten numer unikatowy używany przez inną kasę, wybierz inny")
                    setCashRegister(prev => ({...prev, NrUnik: ""}))
                    val = false
                }
            } 
        })
        return val
    }
    const handleSubmit = () => {
        const nrUnikTest = RegExp(/WBL+\d{10}$/)

        if (!nrUnikTest.test(cashRegister.NrUnik) || !(terminalList && terminalList.map(i => i.SerialNo).includes(cashRegister.TSN)) ) {
            NotificationManager.error('Provide correct value')
            if (!nrUnikTest.test(cashRegister.NrUnik)) setCashRegister(prev => ({...prev, NrUnik: ""}))
            if (!(terminalList && terminalList.map(i => i.SerialNo).includes(cashRegister.TSN)) ) setCashRegister(prev => ({...prev, TSN: ""}))
            return 
        }



        if (checkProvidedValuesIsUnique()) {
            fetchMssqlApi(`cashRegister`, {method: "POST", data: cashRegister}, res => {
                if (res.Id) {
                    history.replace(res.Id)
                    getCashRegister(res.Id)
                } else {
                    NotificationManager.error("Cash register doesn`t save")
                }
            })
            .catch(e => {
                console.log(e)
            })
        }
    }
    useEffect(() => {
        setHeaderData({text: "Kasa fiskalna"})
        getCashRegister()
        getMachinesList()
        getterminalList()
        getCashRegistersList()
    }, [])
    useEffect(() => console.log(terminalList), [terminalList])
    useEffect(() => {
        setCashRegister(defaultCashRegister)
    }, [defaultCashRegister])
    useEffect(() => {
        const data = machinesList.find(i => cashRegister.machineName === i.Name)
        if (!data) return
        setCashRegister(prev => ({...prev, MachineId: data.MachineId}))
    }, [cashRegister.MachineId])
    useEffect(() => {
        (cashRegister.TypeOfWork === "null") ? setAvailableToSave(false) : setAvailableToSave(true) 

        // console.log(cashRegister.TSN)
        RegExp(/WBL+\d{10}$/).test(cashRegister.NrUnik) 
        && terminalList && terminalList.map(i => i.SerialNo).includes(cashRegister.TSN)
        // && RegExp(/\d{4}\/\d{9}$/g).test(cashRegister.NrEwid)
        ? setAvailableToSave(true)
        : setAvailableToSave(false)

        // Object.keys(cashRegister).map(key => {
        //     switch (key) {
        //         case "NrUnik": 
        //         cashRegister.NrUnik
        //     }
        // })
    }, [cashRegister])
    /**
        @TSN
        @MachineName
        @NrEwid
        @NrUnik
     */
    return (
        <div>
            <Link className='ml-5' to='/wkfs/cashregisters'>
                <label htmlFor='back' className="btn btn-link ml-1 text-decoration-none ml-2 mb-3">
                    <img id='back' src={link}></img>
                </label>
            </Link>
            <div className='container-fluid'>

                <div className='row col-12 justify-content-around'>
                    <div className='col-5 mr-3' name="Information">
                        <div className='card  ' >
                            <div className='card-header'>
                                Nowa kasa fiskalna
                            </div>
                            <div className='card-body'>
                                <div className='mb-3'>
                                    <label>Numer fabryczny</label>
                                    <input
                                        className={`form-control ${!cashRegister.TSN && `invalid-input`}`}
                                        value={cashRegister.TSN || ""}
                                        onChange={handleChangeState}
                                        onFocus={handleFocus}
                                        list='terminalList'
                                        disabled={params.id !== "0"}
                                        name='TSN'
                                    />
                                    <datalist id='terminalList'>
                                        {terminalList && terminalList.map((terminal, idx) => (
                                            <option key={idx} value={terminal.SerialNo}/>
                                        ))}
                                    </datalist>
                                </div>
                                {/* <div className='mb-3'>
                                    <label>Numer ewidencyjny</label>
                                    <input
                                        className={`form-control ${(!cashRegister.NrEwid || !RegExp(/\d{4}\/\d{9}$/g).test(cashRegister.NrEwid)) && `invalid-input`}`}
                                        value={cashRegister.NrEwid || ""}
                                        onChange={handleChangeState}
                                        onFocus={handleFocus}
                                        disabled={params.id !== "0"}
                                        name='NrEwid'
                                    />
                                </div> */}
                                <div className='mb-3'>
                                    <label>Numer unikatowy</label>
                                    <input
                                        className={`form-control ${(!cashRegister.NrUnik || !RegExp(/WBL+\d{10}$/).test(cashRegister.NrUnik)) && `invalid-input`}`}
                                        value={cashRegister.NrUnik || ""}
                                        onChange={handleChangeState}
                                        onFocus={handleFocus}
                                        disabled={params.id !== "0"}
                                        name='NrUnik'
                                    />
                                </div>
                                <div className='mb-3'>
                                    <label>Przypisany automat</label>
                                    <input
                                        className={`form-control`}
                                        value={cashRegister.MachineName || ""}
                                        onChange={handleChangeState}
                                        onFocus={handleFocus}
                                        name='MachineName'
                                        list='machiensList'
                                        disabled={params.id !== "0"}
                                    />
                                    <datalist id='machiensList'>
                                        {machinesList && machinesList.map((machine, idx) => (
                                            <option key={idx} value={machine.Name}/>
                                        ))}
                                    </datalist>
                                </div>
                                <div className='w-100 d-flex align-items-center'>
                                    <label htmlFor="IsTest" className='mb-0 mr-2'>
                                        Kasa testowa
                                    </label>
                                        <input
                                            type='checkbox'
                                            value={cashRegister.IsTest}
                                            name="IsTest"
                                            id="IsTest"
                                            onChange={handleChangeState}
                                        />
                                </div>
                                {/* <div className='mb-3'>
                                    <label>Tryb pracy</label>
                                    <select
                                        className={`form-control`}
                                        value={cashRegister.TypeOfWork || ""}
                                        onChange={handleChangeState}
                                        onFocus={handleFocus}
                                        name='TypeOfWork'
                                        disabled="true"
                                    >
                                        <option value={"null"}>Wybierz</option>
                                        <option value={"test"} >Test</option>
                                        <option value={"production"} >Produkcja</option>
                                    </select>
                                    
                                </div> */}
                            </div>
                        </div>
                    </div>
                    {/* <div className='col-6 ' name="Operations">
                        <div className='card '>
                            <div className='card-header'>
                                Stan bieżący
                            </div>
                            <div className='card-body'>
                                {!cashRegisterIN.length ? (
                                    <NoResults/>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                    </div> */}
                </div>
                <div className={`position-fixed container`} style={{bottom: "0px", paddingTop: "50px"}}>

                        <div className="card-footer text-center fixed-bottom" style={{backgroundColor:"#cbcbcb"}}>
                            <button disabled={!changed || !availableToSave} className={`btn btn-primary w-20  btn-sm`} onClick={handleSubmit}>
                                Zapisz
                            </button>
                        </div>
                </div>
            </div>
        </div>
    )
}
