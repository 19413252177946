import React, { useContext, useEffect, useState } from 'react'
import fetchHook from '../../hooks/fetchMSSQL-hook'
import { NavigationContext } from '../../context/navigation-context'
import langContext, { LangContext } from '../../context/lang-context'
import formHook from '../../hooks/form-hook'
import NoResults from '../../components/NoResults/NoResults'
import WarehouseForm from '../../components/Modals/WarehouseForm'
import { NotificationManager } from 'react-notifications'
import Pagination from '../../components/Pagination/Pagination'
import FilterEx from '../../components/Filter/FilterEx'


export default function Warehouses() {
    const [warehouses, setWarehouses] = useState([])
    const {fetchMssqlApi} = fetchHook()
    const { setHeaderData } = useContext(NavigationContext)
    const {form, openForm, closeForm} = formHook()
    const {TRL_Pack} = useContext(LangContext)



    const getWarehouses = () => {
        fetchMssqlApi('warehouses', {}, res => {
            setWarehouses(res)
        })
    }


//filter...
const defaultFilter = {
    showIndexes: false,
    name: "warehouses",
    page: 1,
    disableIndexes: true,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 25, 50],
    visible: false,
    //sortByColumns: true,
    sortByColumns: false,
    //sortBy: '3 | asc | text',
    activeTags: [],
    columns: [
    {
        id: 3,
        name: "Aktywny",
        sortable: true,
        selectable: true,
        disabled: false,
        type: "threeStateBoolByActivity",
        value: "true",
        selectbar: "true"
    },
    ]
}
const [filter, setFilter] = useState(() => {
    if (localStorage.getItem('warehousesFilter')) {
    return JSON.parse(localStorage.getItem('warehousesFilter'));
    }
    return defaultFilter;
})
const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
const resetFilter = () => {
    setFilter(defaultFilter);

}
const reportFilter = row =>
filter.columns
    .filter(col => col.selectbar)
    .every(col => {
    const x = String(row[Object.keys(row)[col.id - 1]])
        .toLowerCase() === col.selectbar.toLowerCase()
    return x
    }
)
const search = () => {
    setFilter(prev => ({
        ...prev,
        columns: prev.columns.map(col => {
            col.selectbar = col.value
            return col
        })
    }))
}
const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))
//===================

    const getCheckboxFromBool = (bool) => (
        <div className='d-flex justify-content-center align-items-center'>
            <input
                type='checkbox'
                checked={bool}
                onChange={() => {}}
            />
        </div>
    ) 

    const handleSubmit = evt => {
        evt.preventDefault();
        const {name, isActive} = evt.target.elements
        const isAvaiable = warehouses.filter(warehouse => warehouse.WarehouseId !== form).map(warehouse => warehouse.Name).indexOf(name.value) === -1
        if (!isAvaiable) {
            NotificationManager.error("Taka nazwa już istnieje")
            name.value = ""
            return
        }
        const payload = {
            Name: name.value,
            IsActive: isActive && (isActive.value === true) ||true
        }
        const wh = warehouses.find(wh => wh.WarehouseId === form)
        if (form !== "new" && String(wh.IsActive) !== isActive.value) {
            fetchMssqlApi(`warehouse/${form}`, {method: "PUT", data: {Name: wh.Name, IsActive: !wh.IsActive}}, () => {
                getWarehouses()
                closeForm()
                return
            })
        }
        else if (form === "new") {
            fetchMssqlApi('warehouse', {method: "POST", data: payload}, () => {
                getWarehouses()
                closeForm()
            })
        } else {
            fetchMssqlApi(`warehouse/${form}`, {method: "PUT", data: payload}, () => {
                getWarehouses()
                closeForm()
            })
        }
    }


    useEffect(() => {
        setHeaderData({text: "Magazyny"})
        getWarehouses()
    }, [])
    return (
        <div>
            <Pagination 
                totalItems={warehouses.length}
                page={filter.page}
                handleSwitchPage={handleSwitchPage}
                rowsPerPage={filter.rowsPerPage}
                toggleFilter={toggleFilter}
                filterVisibility={filter.visible}
                resetFilter={resetFilter}
            />
            {filter.visible && (

            <FilterEx
                filter={filter}
                setFilter={setFilter}
                columns={filter.columns}
                // data={}
                resetPage={resetPage}
                resetFilter={resetFilter}
                search={search}
                toggleFilter={toggleFilter}
                />
            )}
            {warehouses.length ? (
                <>
                <div>
                <button
                    className="d-block btn btn-link text-decoration-none ml-auto my-2 mr-1"
                    onClick={openForm()}
                >
                    <i className="fas fa-plus mr-2" />
                    {TRL_Pack.locations.addProductButton}
                </button>
            </div>
                <table className='table table-striped border' style={{width: "100%"}}>
                    <thead>
                        <tr >
                            <th>Magazyn</th>
                            <th style={{textAlign: "center"}}>Aktywny</th>
                        </tr>
                    </thead>
                    <tbody>
                        {warehouses.filter(reportFilter).slice(
                            (filter.page - 1) * filter.rowsPerPage,
                            filter.page * filter.rowsPerPage
                        ).map((warehouse, idx) => (
                            <tr key={idx}>
                                <td>
                                    {warehouse.Name}
                                </td>
                                <td>
                                    {getCheckboxFromBool(warehouse.IsActive)}
                                </td>
                                <td className='d-flex justify-content-end mb-2 mt-2 border-0'>
                                    <button className="btn btn-outline-primary btn-sm "
                                            onClick={openForm(warehouse.WarehouseId)}
                                            >
                                        Edytuj
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </>
            ) : (
                <NoResults buttonText={TRL_Pack.locations.addProduct} onClick={openForm()} />
            )} 

            {form && (
                <WarehouseForm
                    handleClose={closeForm}
                    data={warehouses.find(warehouse => warehouse.WarehouseId === form)}
                    handleSubmit={handleSubmit}/>
            )}
        </div>
    )
}
