import React, { useState } from 'react'
import CloneMachine from './CloneMachine'
import CreateMachine from './CreateMachine'

export default function NewMachine({setParam}) {
    const [type, setType] = useState(0)
    


    return (
        <>
            <div>
                <ul className="nav nav-tabs machine-tabs mb-3">
                    <li className="nav-item">
                        <button
                            className={`nav-link btn ${type === 0 && 'active'}`}
                            onClick={() => type !== 0 && setType(0)}
                            tabIndex="0"
                        >
                            Klonuj
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                        className={`nav-link btn ${type === 1 && 'active'}`}
                        onClick={() => type !== 1 && setType(1)}
                        tabIndex="0"
                        >
                            Utwórz nowy
                        </button>
                    </li>
                </ul>
            </div>        
            {type === 0 && <CloneMachine setParam={setParam}/>} 
            {type === 1 && <CreateMachine setParam={setParam}/>}
        </>
    )
}
