import React, { useEffect, useState, useMemo, useContext } from 'react'
import GroupForm from '../../../components/Modals/GroupForm'
import useFetch from '../../../hooks/fetchMSSQL-hook'
import useForm from '../../../hooks/form-hook'
import { NavigationContext } from '../../../context/navigation-context'
import NoResults from '../../../components/NoResults/NoResults'

export default function Groups() {
    const [searchedGroup, setSearchedGroup] = useState("")
    const [groups, setGroups] = useState([])
    const { fetchMssqlApi } = useFetch()
    const { form, openForm, closeForm } = useForm()
    const {setHeaderData} = useContext(NavigationContext)

    const filterGroups = searchedText => groups.length && [...groups].filter(group => (`${group.Name}`.toLowerCase()).includes(searchedText.toLowerCase()))
    const filteredGroups = useMemo(() => filterGroups(searchedGroup), [searchedGroup, groups])
    const getGroups = () => {
        fetchMssqlApi('products/groups', {}, group => setGroups(group))
    }
    const getHTMLCheckbox = value => (
        <div className='d-flex align-items-center justify-content-center'>
        <input type="checkbox" checked={value} onChange={() => {}}/>
        </div>
    )
    const submitGroup = evt => {
        evt.preventDefault()
        const {name, isActive, isSnack, isCoffee} = evt.target.elements 
        // console.log(name.value, type.value, isActive.value)
        
        const groupNames = new Set()
        if (form !== "new") {
        const data = groups.find(group => +group.ProductGroupId === +form)
        groups && groups.forEach(group =>+group.ProductGroupId !== +form && groupNames.add(group.Name.replaceAll(" ", "").toLowerCase()))
        if(groupNames.has(String(name.value).replaceAll(" ", "").toLowerCase())) {
            alert("Taka grupa już istnieje")
            name.value = ""
            return
        }
        const payload = {
            // ProductGroupId: form,
            IsActive: isActive.value,
            IsCoffee: isCoffee.checked,
            IsSnack: isSnack.checked,
            Name: name.value
        }
        const activeStatusToggle = {
            IsCoffee: data.IsCoffee,
            IsSnack: data.IsSnack,
            Name: data.Name
        }
        if (String(data.IsActive) !== isActive.value) {
            fetchMssqlApi(`product/groups/${form}`, {method: "PUT", data: {...activeStatusToggle, IsActive: isActive.value}}, () => getGroups())
        } else {
            fetchMssqlApi(`product/groups/${form}`, {method: "PUT", data: payload}, () => getGroups())
        }
        } else {
        groups && groups.forEach(group => groupNames.add(group.Name.replaceAll(" ", "").toLowerCase()))
        if(groupNames.has(String(name.value).replaceAll(" ", "").toLowerCase())) {
        alert("Taka grupa już istnieje")
        name.value = ""
        return
        }
        const payload = {
            IsCoffee: isCoffee.checked,
            IsSnack: isSnack.checked,
            Name: name.value
        }
        fetchMssqlApi(`product/group`, {method: "POST", data: payload}, () => getGroups())
        }
        closeForm()
    }
    useEffect(() => {
    setHeaderData({text: "Grupy"})
    getGroups()
    }, [])
    return (
        <>
        <div>
            <div className="row mb-3">
            <div className="d-flex offset-sm-1 offset-md-2 offset-lg-3 col col-sm-10 col-md-8 col-lg-6">

                {/* <div className="input-group">
                <input
                    onChange={evt => setSearchedGroup(evt.target.value)}
                    type="search"
                    className="form-control rounded-left border-right-0"
                    autoComplete="off"
                />
                </div> */}
            </div>
            </div>
            { groups.length ? (
                <>
                    <div>
                    <button
                        className="d-block btn btn-link text-decoration-none ml-auto my-2 mr-1"
                        onClick={openForm()}
                    >
                        <i className="fas fa-plus mr-2" />
                    </button>
                    </div>

                        <table className='table table-striped border overflow-hidden'>
                        <thead>
                            <tr>
                            <th>Nazwa</th>
                            <th>Ilość powiązanych produktów</th>
                            <th className='text-center'>Automaty snack</th>
                            <th className='text-center'>Automaty kawowe</th>
                            <th className='text-center'>Aktywna</th>
                            </tr>
                        </thead>
                        <tbody>
                            {groups.sort((a, b) => {
                                if (a.ConnectedProduct > b.ConnectedProduct) {
                                    return -1;
                                }
                                if (a.ConnectedProduct < b.ConnectedProduct) {
                                    return 1;
                                }
                                return 0;
                            }).map((group, idx) => (
                            <tr key={idx}>
                                <td>
                                {group.Name}
                                </td>
                                <td>
                                    {group.ConnectedProduct}
                                </td>
                                <td>
                                {getHTMLCheckbox(group.IsSnack)}
                                </td>
                                <td>
                                {getHTMLCheckbox(group.IsCoffee)}
                                </td>
                                <td>
                                {getHTMLCheckbox(group.IsActive)}
                                </td>
                                <td className='d-flex justify-content-end'>
                                <button className="btn btn-outline-primary btn-sm "
                                onClick={openForm(group.ProductGroupId)}>
                                    Edytuj
                                </button>
                                </td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                </>
                ) : (<NoResults onClick={openForm()} buttonText='Dodaj grupę'/>)
            }
        </div>
            {form && (
                <GroupForm
                    handleClose={closeForm}
                    handleSubmit={submitGroup}
                    headerText={form !== "new" ? "Edytuj grupę" : "Dodaj grupę"}
                    data={form !== "new" ? groups.find(group => group.ProductGroupId === form) : null }
                />
            )}
        </>
    
    )
}
