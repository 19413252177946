import React from 'react'
import moment from 'moment'
import getZEinfo from './ZEInfo'
import { NotificationManager } from 'react-notifications'
import ReportBtnForArr from '../../../../components/Buttons/ReportsBtn/ReportBtnForArr'
import getFiscalDoc from '../utils/getFiscalDoc'

export default function ZE  (ZE, idx, state, setState) {

    return (
        <tr key={idx}>
            <td>
                <div className='d-flex align-items-center mt-2 mb-2'>
                    <h5 className='mb-0'>
                        <span className={`badge badge-dark `}>
                            {ZE.FiscalDocType}
                        </span>
                    </h5>
                </div>
            </td>
            <td>
                <div><b>{ZE.NrUnik}</b></div>
                <div><b>{ZE.NrEwid}</b></div>
            </td>
            <td>
                {moment(ZE.dataCzas).format("YYYY-MM-DD HH:mm:ss")}
            </td>
            {/* <td colSpan={2} className='text-center'> */}
            <td colSpan={2} >
                    
                    {getZEinfo(ZE)}
            </td>
            <td>
                <ReportBtnForArr
                        state={state}
                        setState={setState}
                        value={{Report: 1, Id: ZE.FiscalDocId}}
                        btnColor='primary'
                    clickFnc={() => {getFiscalDoc(ZE, setState)}}
                    />
                <button
                    onClick={() => {
                        try {
                            window.navigator.clipboard.writeText(`https://portal.retail24.pl/wkf/params?nrunik=${ZE.NrUnik}&fiscaldoc=${ZE.Hash}&client=${localStorage.getItem('clientId')}`)
                            NotificationManager.success("Link copied to clipboard")
                        } catch (e) {
                            NotificationManager.error(e)
                        }
                    }}
                    className={`d-inline m-1 btn btn-info float-right fas fa-xs fa-copy`}
                >
                </button>
            </td>
        </tr>
    )
} 
