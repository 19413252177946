import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import ReportBtnForArr from '../../../../components/Buttons/ReportsBtn/ReportBtnForArr'
import getFiscalDoc from '../utils/getFiscalDoc'

export default function RD (RD, idx, state, setState) {
    return (
        <tr key={idx}>
            <td>
                <div className='d-flex align-items-center'>
                    <Link 
                        className= {"btn text-nowrap btn-sm btn-info"}
                    > 
                        {RD.FiscalDocType}
                    </Link>
                </div>
            </td>
            <td>
                <div><b>{RD.NrUnik}</b></div>
                <div><b>{RD.NrEwid}</b></div>
            </td>
            <td>
                <div>
                    Wygenerowany {moment(RD.zakRap).format('YYYY-MM-DD HH:mm:ss')}
                </div>
                <div>
                    Od {moment(RD.rozpSprzed).format('YYYY-MM-DD HH:mm:ss')}
                </div>
                <div>
                    Do {moment(RD.zakSprzed).format('YYYY-MM-DD HH:mm:ss')}
                </div>
            </td>
            <td>
                <div className='d-flex align-items-center justify-content-between flex-wrap pt-2 pb-2'>
                    <div>
                        <div> <span className='badge badge-primary'>Łączna kwota brutto: <b>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(RD.sumaBrutto) || "0,00zł"}</b></span></div>
                        <div> <span className='badge badge-success'>Sprzedaż opodatkowana: <b>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(RD.sumNetto) || "0,00zł"}</b></span></div>
                        <div> <span className='badge badge-danger'>Łączna kwota opodatkowania: <b>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(RD.sumaPod) || "0,00zł"}</b></span></div>
                        <h6><span className='badge badge-warning'>Kwota anulowanych paragonów: <b>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(RD.wartParAnul) || "0,00zł"}</b></span></h6>
                    </div>
                </div>
            </td>
            <td>
                <div>
                    <span className='badge badge-info'>Waluta: {RD.waluta}</span>
                </div>
                <div>
                    <span className='badge badge-success'>Liczba paragonów: {RD.liczbaPar}</span>
                </div>
                <div>
                    <span className='badge badge-warning'>Anulowane paragony: {RD.liczbaParAnul}</span>
                </div>
            </td>   
            <td>
                {/* <Link
                    target={"_blank"}
                    to={`/wkf/params?nrunik=${RD.NrUnik}&fiscaldoc=${RD.Hash}&client=${localStorage.getItem('clientId')}`}
                    className={`d-inline m-1 btn btn-primary float-right fas fa-xs fa-download`}
                >
                </Link> */}
                <ReportBtnForArr
                    state={state}
                    setState={setState}
                    value={{Report: 1, Id: RD.FiscalDocId}}
                    btnColor='primary'
                    clickFnc={() => getFiscalDoc(RD, setState)}
                />
                <button
                    onClick={() => {
                        try {
                            window.navigator.clipboard.writeText(`https://portal.retail24.pl/wkf/params?nrunik=${RD.NrUnik}&fiscaldoc=${RD.Hash}&client=${localStorage.getItem('clientId')}`)
                            NotificationManager.success("Link copied to clipboard")
                        } catch (e) {
                            NotificationManager.error(e)
                        }
                    }}
                    className={`d-inline m-1 btn btn-info float-right fas fa-xs fa-copy`}
                >
                </button>
            </td>
        </tr>
    )
} 