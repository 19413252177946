
import React, { useContext, useEffect, useState } from 'react'
import fetchHook from '../../../hooks/fetchMSSQL-hook'
import { NavigationContext } from '../../../context/navigation-context'
import langContext, { LangContext } from '../../../context/lang-context'
import formHook from '../../../hooks/form-hook'
import NoResults from '../../../components/NoResults/NoResults'
import { NotificationManager } from 'react-notifications'
import Pagination from '../../../components/Pagination/Pagination'
import FilterEx from '../../../components/Filter/FilterEx'
import MachineTypeForm from '../../../components/Modals/MachineTypeForm'


export default function MachineTypes() {
    const [machineTypes, setMachineTypes] = useState([])
    const [manufacturers, setManufacturers] = useState([])
    const { fetchMssqlApi } = fetchHook()
    const { setHeaderData } = useContext(NavigationContext)
    const { form, openForm, closeForm } = formHook()
    const { TRL_Pack } = useContext(LangContext)

    const getMachineTypes = () => {
        fetchMssqlApi('machine-types-manufacturers', {}, res => {
            setMachineTypes(res)
        })
    }
    const getManufacturers = () => {
        fetchMssqlApi('manufacturers-list', {}, res => {
            setManufacturers(res)
        })
    }

    const defaultFilter = {
        showIndexes: false,
        name: "machineTypes",
        page: 1,
        disableIndexes: true,
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 25, 50],
        visible: false,
        //sortByColumns: true,
        sortByColumns: false,
        //sortBy: '3 | asc | text',
        activeTags: [],
        columns: [
            {
                id: 2,
                name: "Aktywny",
                sortable: true,
                selectable: true,
                disabled: false,
                type: "threeStateBoolByActivity",
                value: "true",
                selectbar: "true"
            },
        ]
    }
    const [filter, setFilter] = useState(() => {
        if (localStorage.getItem('machineTypesFilter')) {
            return JSON.parse(localStorage.getItem('machineTypesFilter'));
        }
        return defaultFilter;
    })
    const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
    const resetFilter = () => {
        setFilter(defaultFilter);

    }
    const reportFilter = row =>
        filter.columns
            .filter(col => col.selectbar)
            .every(col => {
                const x = String(row[Object.keys(row)[col.id - 1]])
                    .toLowerCase() === col.selectbar.toLowerCase()
                return x
            }
            )
    const search = () => {
        setFilter(prev => ({
            ...prev,
            columns: prev.columns.map(col => {
                col.selectbar = col.value
                return col
            })
        }))
    }
    const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
    const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))
    //===================

    const getCheckboxFromBool = (bool) => (
        <div className='d-flex justify-content-center align-items-center'>
            <input
                type='checkbox'
                checked={bool}
                onChange={() => { }}
            />
        </div>
    )

    const handleSubmit = (evt, setData) => {
        evt.preventDefault();
        const { name, manufacturer, isActive } = evt.target.elements
        const machineManufacturer = manufacturers.find(man => man.Name === manufacturer.value)
        const isAvaiable = machineTypes.filter(machineType => machineType.MachineTypeId !== form).map(machineType => machineType.Name).indexOf(name.value) === -1
        const mt = machineTypes.find(mt => mt.MachineTypeId === form)
        if (form !== "new" && (String(mt.IsActive) !== isActive.value)) {
            fetchMssqlApi(`machine-type/${form}`, { method: "PATCH", data: { ManufacturerId: +mt.ManufacturerId || null, Name: mt.Name, IsActive: String(!mt.IsActive) } }, () => {
                getMachineTypes()
                closeForm()
            })
            return
        }
        if (!isAvaiable) {
            NotificationManager.error("Taka nazwa już istnieje")
            setData(prev => ({ ...prev, Name: "" }))
            return
        }
        if (manufacturer.value && !machineManufacturer) {
            NotificationManager.error("Wybierz poprawnego producenta")
            setData(prev => ({ ...prev, ManufacturerName: "" }))
            return
        }
        const payload = {
            Name: name.value,
            ManufacturerId: machineManufacturer ? machineManufacturer.ManufacturerId : null,
        }
        if (form === "new") {
            fetchMssqlApi('machine-type', { method: "POST", data: { ...payload, IsActive: true } }, () => {
                getMachineTypes()
                closeForm()
            })
        } else {
            fetchMssqlApi(`machine-type/${form}`, { method: "PATCH", data: { ...payload, IsActive: String(mt.IsActive) } }, () => {
                getMachineTypes()
                closeForm()
            })
        }
    }


    useEffect(() => {
        setHeaderData({ text: "Typy automatów" })
        getMachineTypes()
        getManufacturers()
    }, [])
    return (
        <div>
            <Pagination
                totalItems={machineTypes.length}
                page={filter.page}
                handleSwitchPage={handleSwitchPage}
                rowsPerPage={filter.rowsPerPage}
                toggleFilter={toggleFilter}
                filterVisibility={filter.visible}
                resetFilter={resetFilter}
            />
            {filter.visible && (

                <FilterEx
                    filter={filter}
                    setFilter={setFilter}
                    columns={filter.columns}
                    // data={}
                    resetPage={resetPage}
                    resetFilter={resetFilter}
                    search={search}
                    toggleFilter={toggleFilter}
                />
            )}
            {machineTypes.length ? (
                <>
                    <div>
                        <button
                            className="d-block btn btn-link text-decoration-none ml-auto my-2 mr-1"
                            onClick={openForm()}
                        >
                            <i className="fas fa-plus mr-2" />
                            {TRL_Pack.locations.addProductButton}
                        </button>
                    </div>
                    <table className='table table-striped border' style={{ width: "100%" }}>
                        <thead>
                            <tr >
                                <th>Typ</th>
                                <th>Producent</th>
                                <th style={{ textAlign: "center" }}>Aktywny</th>
                            </tr>
                        </thead>
                        <tbody>
                            {machineTypes.filter(reportFilter).slice(
                                (filter.page - 1) * filter.rowsPerPage,
                                filter.page * filter.rowsPerPage
                            ).map((machineType, idx) => (
                                <tr key={idx}>
                                    <td>
                                        {machineType.Name}
                                    </td>
                                    <td>{machineType.ManufacturerName}</td>
                                    <td>
                                        {getCheckboxFromBool(machineType.IsActive)}
                                    </td>
                                    <td className='d-flex justify-content-end mb-2 mt-2 border-0'>
                                        <button className="btn btn-outline-primary btn-sm "
                                            onClick={openForm(machineType.MachineTypeId)}
                                        >
                                            Edytuj
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <NoResults buttonText={TRL_Pack.locations.addProduct} onClick={openForm()} />
            )}
            {
                form &&
                <MachineTypeForm
                    machineTypeData={machineTypes.find(machineType => machineType.MachineTypeId === form)}
                    handleClose={closeForm}
                    handleSubmit={handleSubmit}
                    manufacturers={manufacturers}
                />
            }
        </div>
    )
}
