import React, { createContext } from 'react';
import PropTypes from 'prop-types';

import { NotificationManager } from 'react-notifications';

export const NotificationContext = createContext({
  notification: () => { },
  ErrorNotification: () => { },
  SuccessNofication: () => { }
});

const NotificationContextProvider = ({ children }) => {
  const notification = (type, value) => {
    const notificationTimeout = 4000;

    switch (type) {
      case 'success':
        NotificationManager.success(value, null, notificationTimeout);
        break;
      case 'error':
        NotificationManager.error(value, null, notificationTimeout);
        break;
      default:
        NotificationManager.info(value, null, notificationTimeout);
    }
  }

  // TO DO: remove ErrorNotification & SuccessNotification functions
  const ErrorNotification = ({ message }) => NotificationManager.error(message, null, 4000)
  const SuccessNofication = (message = 'Success') => NotificationManager.success(message, null, 4000)

  return (
    <NotificationContext.Provider value={{ notification, ErrorNotification, SuccessNofication }}>
      {children}
    </NotificationContext.Provider>
  )
};

NotificationContextProvider.propTypes = {
  children: PropTypes.object
};

export default NotificationContextProvider;