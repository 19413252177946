import { NotificationManager } from "react-notifications"
import axios from 'axios'
import { API_URL } from "../config/config"
import { useContext, useEffect, useState } from "react"
import authHook from "../hooks/auth-hook"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { LoaderContext } from "../context/loader-context"




export default () => {
    const [isInProgress, setIsInProgress] = useState(false)
    const {logout} = authHook()
    const history = useHistory()
    const {showloader} = useContext(LoaderContext)

    function checkExaparedIn() {
        if (!isInProgress) {     
            // manipulateTimeouts.showTimeot()      
            
            setIsInProgress(true)
            // if (!localStorage.getItem("expirationDate")) {
            //     logout()
            //     // window.location.reload()
            // }
            const exparedIn = new Date(localStorage.getItem("expirationDate"))
            const now = new Date()
            const remainingTime = (exparedIn - now) / 60000 
            if (remainingTime < 0 && !showloader) {
                // logout()
                return 
            }
            if (remainingTime < 4) {
                axios.get(`${API_URL}/api/auth/retakeToken`,{
                    method: "GET",
                })
                .then(() => {
                    const newExpirationDate = new Date(new Date().getTime() + 1000 * 60 * 60)
                    localStorage.setItem('expirationDate', newExpirationDate.toISOString())
                    setIsInProgress(false)
                })
                .catch(e => {
                    console.log(e)
                    setIsInProgress(false)
                })
                return
            }
            return
        }
    }
    return {checkExaparedIn, isInProgress}
}