import React, { useState, useEffect, useContext } from 'react'
import { NavigationContext } from '../../context/navigation-context'
import { LangContext } from '../../context/lang-context'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

import Users from './Users'
import Role from './Role'
import Permissions from '../ClientView/Permissions'

const UserView = () => {
  const { setHeaderData } = useContext(NavigationContext)
  const { TRL_Pack: { shelves } } = useContext(LangContext)
  const { TRL_Pack } = useContext(LangContext)
  const history = useHistory()

  const [currentMachineSection, setCurrentMachineSection] = useState(0)
  const changeSection = id => () => setCurrentMachineSection(id)

  useEffect(() => {
    setHeaderData({ text: currentMachineSection === 1 
      && sessionStorage.getItem("DB_TYPE") === "mysql" 
        ? shelves.configHeader 
        : "Użytkownicy"
      || currentMachineSection === 2 && "Uprawnienia R24 Dashboard"
      || currentMachineSection === 3 && "Kolejność Menu"})
  }, [])
  // useEffect(() => {
  //   if (currentMachineSection === 2) history.replace('/users/permissions')
  //   if (currentMachineSection === 0) history.replace('/users')
  // }, [currentMachineSection])
  return (
    <div className='m-20'>
      <ul className="nav nav-tabs machine-tabs mb-3">
        <li className="nav-item">
          <button
            className={`nav-link btn ${currentMachineSection === 0 && 'active'}`}
            onClick={changeSection(0)}
            tabIndex="0"
          >
            {TRL_Pack.userView.users}
          </button>
        </li>
        {sessionStorage.getItem("DB_TYPE") !== "mysql" && (
          <>
          <li className="nav-item">
            <button
              className={`nav-link btn ${currentMachineSection === 1 && 'active'}`}
              onClick={changeSection(1)}
              tabIndex="0"
            >
              {TRL_Pack.userView.role}
            </button>
          </li>
          <li className="nav-item">
            <button
              className={`nav-link btn ${currentMachineSection === 2 && 'active'}`}
              onClick={changeSection(2)}
              tabIndex="0"
            >
              {TRL_Pack.userView.permissions}
            </button>
          </li>
          {/* <li className="nav-item">
            <button
              className={`nav-link btn ${currentMachineSection === 3 && 'active'}`}
              onClick={changeSection(3)}
              tabIndex="0"
            >
              Kolejność Menu
            </button>
          </li> */}
          </>
        )}

      </ul>
      {currentMachineSection === 0 && <Users />}
      {currentMachineSection === 1 && <Role />}
      {currentMachineSection === 2 && sessionStorage.getItem("DB_TYPE") !== "mysql" && <Permissions />}
    </div>
  )
}

export default UserView;