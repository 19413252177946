import React, { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import useFetch from '../../hooks/fetchMSSQL-hook';
import { NavigationContext } from '../../context/navigation-context';
import moment from 'moment';

export default function PZ() {
    const { fetchMssqlApi } = useFetch()
    const { setHeaderData } = useContext(NavigationContext)
    const { id } = useParams()
    const [PZ, setPZ] = useState()
    const [PZItems, setPZItems] = useState()
    const getDate = date => {
        // console.log(date)
        if(!date ) return null
        if (date.includes(" ")) {
        date = date.split(" ")
        return date[0]
        } else if (date.includes("T")) {
        date = date.split("T")
        return date[0]
        }
        return null
    }
    const getPZ = () => {
        fetchMssqlApi(`pz/${id}`, {}, pz => setPZ(() => (
            {...pz, IssuedOn: getDate(pz.IssuedOn), PaymentDate: getDate(pz.PaymentDate)})))
    }
    const getPZItems = () => {
        fetchMssqlApi(`fz/${id}`, {}, items => setPZItems(items))
    }
    useEffect(() => {
        setHeaderData({
            text: "Pryjęcie zewnętrzne"
        });
        getPZ()
        getPZItems()

        
    }, [])
    return (
        <>
        <Link to={'/pzs'}>
        <button className="btn btn-link ml-1 text-decoration-none mb-3">
            <i className="fas fa-arrow-left mr-2" />
            Wróć
        </button>
        </Link>
        {PZ && (

        <>
        <div className='card mb-3'>
        <div className='card-header'>
            <div className='h5'>
            {PZ.WarehouseOperationFullName || ""}
            </div>
            <table className="table table-striped">
            <tbody>
            <tr className='justify-content-center'>
                <td>Data faktury</td>
                <td>Kontrahent</td>
                <td>Magazyn</td>
                <td></td>
            </tr>
            <tr>


                <td>
                <input
                disabled={true}
                className={`mt-2 form-control form-control-sm ${!PZ.IssuedOn && `invalid-input`}`}
                type='date'
                value={PZ.IssuedOn}
                />
                </td>
                <td>
                <input
                disabled={true}
                value={PZ.ContractorName}
                placeholder='Wybierz...'
                className={`mt-2 form-control ${!PZ.ContractorName && `invalid-input`} form-control-sm`}
                min={1}
                list='contractors'
                />
                </td>
                
                
                <td>
                <input
                disabled={true}
                value={PZ.WarehouseFullName}
                datalist='warehouses'
                placeholder='Wybierz...'
                className=  {`mt-2 form-control ${!PZ.WarehouseFullName && `invalid-input`} form-control-sm`}
                min={1}
                list='warehouses'
                />
                </td>
            </tr>
            <tr className='justify-content-center'>
                <td>Zapłacona</td>
                <td>Data płatności</td>
                <td>Komentarz / dodatkowe informacje</td>
                <td></td>
            </tr>
            <tr className='justify-content-center'>

            <td className='mb-2'>
                <div className='d-flex justify-content-center align-items-center'>

                <input
                disabled={true}
                    type='checkbox'
                    className='form-check-input'
                    style={{width: "30px", height: "30px"}}
                    checked={PZ.IsPaid}
                />
                </div>
            </td>
            <td>
            <input
            disabled={true}
                type='date'
                className={`mt-2 ${!PZ.PaymentDate && `invalid-input`} form-control form-control-sm`}
                value={PZ.PaymentDate}
                />
            </td>
            <td>
                <input
                disabled={true}
                className='mt-2 form-control form-control-sm'
                type='text'
                value={PZ.Comment}
                />
            </td>
            </tr>
            {/* <tr className='justify-content-center'>
                <td>Wartość netto</td>
                <td>Wartość VAT</td>
                <td>Wartość brutto</td>
                <td>Stworzona</td>
            </tr>
            <tr className='justify-content-center'>
                <td>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(PZ.TotalNetto) || "0,00zł"}</td>
                <td>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(PZ.TotalVAT) || "0,00zł"}</td>
                <td>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(PZ.TotalBrutto) || "0,00zł"}</td>
                <td>{PZ.Created && `${moment(PZ.Created).format("YYYY.MM.DD")} ${PZ.Created.split(" ").at(-1)}`}</td>
            </tr> */}
            </tbody>
            </table>
        </div>
        </div>
        <div className='card' style={{marginBottom: "40px"}}>
        
        <table className="table table-striped ">
            <thead>
            <tr>
                <th>Produkt</th>
                <th>Ilość</th>
                {/* <th>Cena jedn. netto</th>
                <th>Wartość netto</th>
                <th>Wartość VAT</th>
                <th>Stawka VAT</th>
                <th>Wartość brutto</th> */}
            </tr>
            </thead>
            <tbody>
            {PZItems && PZItems.map((item, idx) => 
                !item.deleted && 
                (
                <tr key={idx}>
                    <td>
                    <input
                    disabled={true}
                        className={`form-control ${!item.Name && `invalid-input` } form-control-sm mx-auto mx-lg-0`}
                        value={item.Name}
                        list='products'
                    />
                    </td>
                    <td>
                    <input
                    disabled={true}
                    type='text'
                    className={`form-control ${(!item.Quantity || item.Quantity == "0") && `invalid-input` } form-control-sm mx-auto mx-lg-0`}
                    value={item.Quantity}
                    />
                    </td>
                    {/* <td>
                    <input
                    disabled={true}
                    type='number'
                    className={`form-control ${(!item.Price || item.Price == "0") && `invalid-input` } form-control-sm mx-auto mx-lg-0`}
                    value={item.Price}
                    />
                    </td> */}
                    {/* <td>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(item.PriceNetto|| "0,00zł")}</td>
                    <td>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(item.VAT || "0,00zł")}</td>
                    <td>{item.Value}%</td>
                    <td>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(item.PriceBrutto || "0,00zł")}</td> */}
                    
                </tr>
                ))}
            
            </tbody>
        </table>
        
        </div>
            </>
        )}
    </>
    )
}
