import React, { useEffect, useContext } from "react";
import useFetch from "../../../hooks/fetchMSSQL-hook";
import { useState } from "react";
import { NotificationManager } from "react-notifications";
import { LangContext } from "../../../context/lang-context";
import useHttp from "../../../hooks/use-http";

const MachineConfiguration = () => {
  const { fetchMssqlApi } = useFetch();
  const { sendRequest } = useHttp();
  const [machine, setMachine] = useState(null);
  const { TRL_Pack } = useContext(LangContext);

  const [settings, setSettings] = useState(null);
  const [machineType, setMachineType] = useState("-");
  const [machineSlaveType, setMachineSlaveType] = useState("-");
  const [machineSecondSlaveType, setMachineSecondSlaveType] = useState("-");

  const getMachine = () => {
    fetchMssqlApi("machines", {}, machines => {
      const machine = machines[0];

      setMachine(machine);
      setMachineType(machine.Type.split("_")[0]);

      if (machine.Type.split("_")[1] !== undefined)
        setMachineSlaveType(machine.Type.split("_")[1]);
      if (machine.Type.split("_")[2] !== undefined)
        setMachineSecondSlaveType(machine.Type.split("_")[2]);
    });
  };

  const getSettings = () => {
    fetchMssqlApi("settings", {}, (settings) => {
      setSettings(settings);
    });
  };

  useEffect(() => {
    getSettings();
    getMachine();
  }, []);


  const handleClick = (e) => {
    e.persist();

    switch (e.target.id) {
      case "machineType":
        setMachineType(e.target.value);
        setMachineSlaveType("-");
        setMachineSecondSlaveType("-");
        break;
      case "machineSlaveType":
        setMachineSlaveType(e.target.value);
        setMachineSecondSlaveType("-");
        break;
      case "machineSecondSlaveType":
        setMachineSecondSlaveType(e.target.value);
        break;
      default:
        NotificationManager.error(
          TRL_Pack.advancedConfig.functionNotAvaiable,
          null,
          4000
        );
    }
  };

  const getColorOfButtonElasticNumeration = (placeholder) => {
    let color;

    switch (placeholder) {
      case "M":
        color = "red";
        break;
      case "S1":
        color = "green";
        break;
      case "S2":
        color = "blue";
    }
    return color;
  }

  const ButtonElasticNumeration = (e) => (

    <input
      style={{ fontSize: "0.9rem", color: getColorOfButtonElasticNumeration(e.placeholder) }}
      type="number"
      className="form-control"
      defaultValue={e.defaultValue}
      min={0}
      max={900}
      placeholder={e.placeholder}
      id={e.id}
    ></input>
  )
  const findSettingByKey = (key) => {
    return settings[settings.findIndex(p => p.key == key)].value
  }

  const getElasticNumeration = (key) => {
    const elasticJson = findSettingByKey("elasticNumeration")
    const parsedJson = JSON.parse(elasticJson);

    return parsedJson[key]
  }

  const ElasticNumeration = () => (
    getElasticNumeration(),
    <div className="form-group">
      <label htmlFor="remoteIP" className="form-label">
        {TRL_Pack.advancedConfig.elasticNumeration} [<span style={{ color: 'red', fontWeight: 'bold' }}>Master</span>, <span style={{ color: 'green', fontWeight: 'bold' }}>Slave #1</span>, <span style={{ color: 'blue', fontWeight: 'bold' }}>Slave #2</span>]
      </label>

      <div className="row">
        <div className="col-sm">
          <ButtonElasticNumeration placeholder={"M"} id={"masterFrom"} defaultValue={getElasticNumeration("masterFrom")} />
        </div>
        <Arrow />
        <div className="col-sm">
          <ButtonElasticNumeration placeholder={"M"} id={"masterTo"} defaultValue={getElasticNumeration("masterTo")} />
        </div>
      </div>
      {machineSlaveType !== '-' ? (
        <div>
          <div style={{ paddingTop: 10 }}></div>

          <div className="row">
            <div className="col-sm">
              <ButtonElasticNumeration placeholder={"S1"} id={"slaveFirstFrom"} defaultValue={getElasticNumeration("slaveFirstFrom")} />
            </div>
            <Arrow />
            <div className="col-sm">
              <ButtonElasticNumeration placeholder={"S1"} id={"slaveFirstTo"} defaultValue={getElasticNumeration("slaveFirstTo")} />
            </div>
          </div>
        </div>

      ) : null}

      {machineSecondSlaveType !== '-' ? (
        <div>
          <div style={{ paddingTop: 10 }}></div>
          <div className="row">
            <div className="col-sm">
              <ButtonElasticNumeration placeholder={"S2"} id={"slaveSecondFrom"} defaultValue={getElasticNumeration("slaveSecondFrom")} />
            </div>
            <Arrow />

            <div className="col-sm">
              <ButtonElasticNumeration placeholder={"S2"} id={"slaveSecondTo"} defaultValue={getElasticNumeration("slaveSecondTo")} />
            </div>
          </div>
        </div>
      ) : null}
    </div >
  )

  const Arrow = () => (
    <div className="row">
      <div className="col-3 p-0 m-1">&rarr;</div>
    </div>
  )

  const ConfiguartionUI = () => (
    machine != null && settings != null ? (
      <div>
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <div className="row">
                  <div className="col-sm">
                    <label className="col-xs-3 control-label">
                      {TRL_Pack.advancedConfig.machineType}
                    </label>
                    <div className="col-xs-2">
                      <select
                        className="form-control"
                        id="machineType"
                        value={machineType}
                        onChange={handleClick}>
                        <option>V10</option>
                        <option>V11</option>
                        <option>V12</option>
                        <option>LOCKER</option>
                        <option>NECTA</option>
                      </select>
                    </div>
                  </div>
                  {(machineType === "V10") |
                    (machineType === "V11") |
                    (machineType === "LOCKER") ? (
                    <div>
                      <div className="col-sm">
                        <label className="col-xs-3 control-label">
                          {TRL_Pack.advancedConfig.slaveType}
                        </label>
                        <div className="col-xs-2">
                          <select
                            className="form-control"
                            id="machineSlaveType"
                            value={machineSlaveType}
                            onChange={handleClick}
                          >
                            <option>-</option>
                            <option>V10</option>
                            <option>V11</option>
                            <option>LOCKER</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  {(machineSlaveType === "V10") |
                    (machineSlaveType === "V11") |
                    (machineSlaveType === "LOCKER") ? (
                    <div>  
                      <div className="col-sm">
                        <label className="col-xs-3 control-label">
                          {TRL_Pack.advancedConfig.secondSlaveType}
                        </label>
                        <div className="col-xs-2">
                          <select
                            className="form-control"
                            value={machineSecondSlaveType}
                            id="machineSecondSlaveType"
                            onChange={handleClick}
                          >
                            <option>-</option>
                            <option>V10</option>
                            <option>V11</option>
                            <option>LOCKER</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-sm">
              <ElasticNumeration />
            </div>
          </div>

          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label htmlFor="trxCheckThreshold" className="form-label">
                  {TRL_Pack.advancedConfig.trxCheckThreshold}
                </label>
                <input
                  type="number"
                  className="form-control"
                  defaultValue={machine.TrxCheckThreshold}
                  id="trxCheckThreshold"
                ></input>
              </div>
            </div>
            <div className="col-sm">
              <div className="form-group">
                <label htmlFor="trxCountThreshold" className="form-label">
                  {TRL_Pack.advancedConfig.trxCountThreshold}
                </label>
                <input
                  type="number"
                  className="form-control"
                  defaultValue={machine.TrxCountThreshold}
                  id="trxCountThreshold"
                ></input>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label htmlFor="softwareVersion" className="form-label">
                  {TRL_Pack.advancedConfig.softwareVersion}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={true}
                  defaultValue={
                    machine.SoftwareVersion.slice(0, 3) +
                    machine.SoftwareVersion.slice(40, 47) +
                    machine.SoftwareVersion.slice(84, 94) +
                    machine.SoftwareVersion.slice(131, 138) +
                    machine.SoftwareVersion.slice(175, 182) +
                    machine.SoftwareVersion.slice(219, 226) +
                    machine.SoftwareVersion.slice(263, 270)
                  }
                  id="softwareVersion"
                ></input>
              </div>
            </div>
            <div className="col-sm">
              <div className="form-group">
                <label htmlFor="clientId" className="form-label">
                  {TRL_Pack.advancedConfig.clientId}
                </label>
                <input
                  type="text"
                  maxLength={3}
                  className="form-control"
                  defaultValue={machine.ClientId}
                  id="clientId"
                ></input>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm">
              <div className="form-group">
                <label htmlFor="remoteIP" className="form-label">
                  {TRL_Pack.advancedConfig.remoteIP}
                </label>
                <input
                  type="text"
                  className="form-control"
                  disabled={true}
                  defaultValue={machine.RemoteIP}
                  id="remoteIP"
                ></input>
              </div>
            </div>
            <div className="col-sm">
            </div>
          </div>

          <div style={{ marginTop: 15 }}>
            <input
              className="btn btn-success mx-auto"
              type="submit"
              value={TRL_Pack.advancedConfig.saveChangdes}
            />
          </div>
        </form>
      </div>
    ) : null
  );

  const onSubmit = (e) => {
    e.preventDefault();
    const {
      trxCheckThreshold,
      trxCountThreshold,
      clientId,
      masterFrom,
      masterTo,
      slaveFirstFrom,
      slaveFirstTo,
      slaveSecondFrom,
      slaveSecondTo,
    } = e.target.elements;

    let type = machineType;
    if (machineSlaveType !== "-") type += "_" + machineSlaveType;
    if (machineSecondSlaveType !== "-") type += "_" + machineSecondSlaveType;
    const elasticNumeration = {
      masterFrom: Number(masterFrom.value),
      masterTo: Number(masterTo.value),
    };

    if (machineSlaveType !== "-") {
      Object.assign(elasticNumeration, {
        slaveFirstFrom: Number(slaveFirstFrom.value),
        slaveFirstTo: Number(slaveFirstTo.value)
      })
    }

    if (machineSecondSlaveType !== "-") {
      Object.assign(elasticNumeration, {
        slaveSecondFrom: Number(slaveSecondFrom.value),
        slaveSecondTo: Number(slaveSecondTo.value)
      })
    }


    const data = {
      machineType: type,
      trxCheckThreshold: trxCheckThreshold.value,
      trxCountThreshold: trxCountThreshold.value,
      clientId: clientId.value,
      elasticNumeration: elasticNumeration,
    };

    setSettings(settings.map(setting => {
      if (setting.key === "elasticNumeration") setting.value = JSON.stringify(elasticNumeration)
      return setting
    }))

    if (
      data.trxCheckThreshold < 0 ||
      data.trxCountThreshold < 0 ||
      data.trxCheckThreshold > 2147483647 ||
      data.trxCountThreshold > 2147483647
    )
      return NotificationManager.error(
        TRL_Pack.errors.invalidInput,
        null,
        4000
      );

    sendRequest(
      { url: "advanced-settings", method: "PUT", data: data },
      (response) => {
        if (response.status === 200) {
          getSettings();
          getMachine();
        }
      }
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col-12 mb-2">
          <ConfiguartionUI />
        </div>
      </div>
    </div>
  );
};

export default MachineConfiguration;