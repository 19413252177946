import React from 'react';

const Code = ({ id, paymentCode, ean, productName, status, deleteCodeHandler }) => {
    return (<tr>
        <td>{paymentCode}</td>
        <td>{ean}</td>
        <td>{productName}</td>
        <td>{status}</td>
        <td>
            <button className="btn btn-link" onClick={() => deleteCodeHandler(id)}>
                <i className="fas fa-trash text-danger" />
            </button>
        </td>
    </tr>);
};

export default Code;