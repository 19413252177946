import React, { useContext } from 'react'
import { useEffect, useState } from 'react'
import axios from 'axios'
import {Spinner} from 'react-bootstrap'
import { API_URL } from '../../../config/config'
import { NotificationManager } from 'react-notifications'
import parseURLQuery from '../../../util/parseURLQuery'
import logo from '../../../assets/newLogo.png'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { NavigationContext } from '../../../context/navigation-context'

export default function FiscalDocGetting() {
    
    const defaultQuerySettings = {process: 1, timeout: 1, lastStatus: 0, readyToFile: false},
    [querySettings, setQuerySettings] = useState(defaultQuerySettings),
    [text, setText] = useState(`Trwa pobieranie paragonu. <br/> Proszę czekać...`),
    [showButtons, setShowButtons] = useState(false),
    [initialPathname, setInitialPathname]=useState(document.location.pathname),
    paramObj = parseURLQuery(document.URL),
    {setShowSideMenu} = useContext(NavigationContext),
    [isDownloaded, setIsDownloaded] = useState(false),
    [okCounter, setOkCounter] = useState(0)

    // let isMobile 
    // try {
    //     isMobile = window.navigator.userAgentData.mobile
    // } catch (e) {
    //     // isMobile = true
    //     console.log(e)
    // }


    const getPdf = (data = querySettings) => {
        // window.alert(isMobile)
        
        setShowButtons(false)
        // console.log(paramObj)
        setShowSideMenu(false)
        axios({
            method: "POST",
            url: `${API_URL}/api/wkf/${paramObj.fiscaldoc}/${paramObj.client}/${paramObj.nrunik}`,
                responseType:'json',
            data: {
                ...querySettings, 
                client: paramObj.client,
                nrUnik: paramObj.nrunik
            }
        })
        .then( answ => {
            setText(answ.data.message)
            delete answ.data.message

            if (answ.status === 206) {
                // if (okCounter === 2) {
                    // window.location.reload()
                    setQuerySettings(prev => ({...prev, readyToFile: true}))
                    return true
                // } else {
                    // setOkCounter(prev => prev++)
                // }
            } 
            else if (answ.status === 201) 
            {
                window.location.reload()
            }
            else if (answ.status < 300 && answ.status !== 206) {
                setQuerySettings({...answ.data, timeout: ++data.timeout})
            }
            if (data.timeout > 18) {
                setShowButtons(true)
                setText("Czas pobierania mineł <br/> Proszę spróbować ponownie")
                return null
            } 
            // if (window.location.pathname !== initialPathname) return
            setTimeout(() => {

                getPdf(querySettings)
            }, 5000);

        })
        .then(isReady => {
            if (isReady) {
                axios({
                    method: "post",
                    url: `${API_URL}/api/wkf/${paramObj.fiscaldoc}/${paramObj.client}/${paramObj.nrunik}`,
                    responseType: 'arraybuffer',
                    headers:{
                        "Content-Type":"application/pdf",
                    },
                    data: {
                        ...querySettings,
                        client: paramObj.client,
                        nrUnik: paramObj.nrunik,
                        readyToFile: true
                    }
                })
                // .then(res => {
                //     // const blobObj = new Blob([atob(res.data)], {type: "application/pdf"})
                //     // window.close()
                //     // let window1 =
                //     // window1.addEventListener('close', () => {
                //     //     console.log("worl")
                //     //     window.URL.revokeObjectURL(fileURL)
                //     // })

                //     const d = new Blob([res.data], {type: 'application/pdf'})
                //     const fileURL = window.URL.createObjectURL(d)
                //     const wnd = window.open(fileURL, "_self")
                //     wnd.window.navigator.msSaveOrOpenBlob(d); 
                //     console.log(window)
                //     setShowButtons(null)
                //     // window.history.back()
                // })
                // .then(res => {
                //     const d = new Blob([res.data], {type: 'application/pdf'})
                //     const fileURL = window.URL.createObjectURL(d)
                //     let isMobile
                //     try {
                //         isMobile = window.navigator.userAgentData.mobile
                //     } catch (e) {
                //         console.log(e)
                //     }
                //     if (isMobile) {
                //         window.open(fileURL, "_self")
                //     } else {
                //         setIsDownloaded(true)
                //         const iframe = document.createElement('embed');
                //         iframe.src = fileURL;
                //         iframe.width = "100%";
                //         iframe.height = window.innerHeight - document.querySelector("figure").getBoundingClientRect().height - 70
                //         iframe.style.borderRadius = "10px"
                //         // Replace `elementId` with the actual ID of your target element
                //         document.querySelector("section").appendChild(iframe);
                        
                //         // Set callback for cleanup
                //         // iframe.onload = () => {
                //             // URL.revokeObjectURL(fileURL);
                //         // };
                //     }
                //     // if (window.navigator.userage)
                //     setShowButtons(null)
                // })
                // .then(res => {
                //     const d = new Blob([res.data], {type: 'application/pdf'})
                //     const fileURL = window.URL.createObjectURL(d)
                //     if (isMobile) {
                //         window.open(fileURL, "_self")
                //     } else {
                //         setIsDownloaded(true)
                //         const iframe = document.createElement('iframe');
                //         iframe.src = fileURL
                //         iframe.width = "100%"
                //         iframe.style.borderRadius = "10px"
                //         iframe.height = window.innerHeight - document.querySelector("figure").getBoundingClientRect().height - 70

                //         document.querySelector("section").appendChild(iframe);

                //     }
                //     setShowButtons(null)

                // })  
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${paramObj.fiscaldoc}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                    setShowButtons(null)
                })  
                .catch(e => {
                    setText(e.response.data.message || "Nie udało się pobrać dokumentu1")
                    setShowButtons(true)
                })
            }
        })
        .catch(e => {
            setText(e.response.data.message || "Nie udało się pobrać dokumentu")
            setShowButtons(true)
        })
    }
    useEffect(() => {
        getPdf()
        // setHeaderData({text: paramObj.type})
    }, [])
    useEffect(() => {
        showButtons === true && setQuerySettings(defaultQuerySettings)
        showButtons === null && setText("")
    }, [showButtons])


    return (
        <div>
        <section className='w-100 h-100 overflow-hidden' >
            {/* <div className='h-100'> */}
                <figure className='d-flex w-100 justify-content-center' style={{marginTop: "16px", marginBottom: "16px"}}>
                    <div style={{maxWidth: !isDownloaded ? "25vh" : "12vh"}}>
                        <img
                            src={logo}
                            loading="lazy"
                            onError={evt => (evt.target.src = "")}
                            alt={"Retail24 logo"}
                            width="100%"
                            height="100%"
                        />
                    </div>
                </figure>
                <div className='text-center'>
                    <h4><div className='content' dangerouslySetInnerHTML={{__html: text}}></div></h4>
                </div>
            {
                
                showButtons === false && (
                    <div className='text-center h-100'>
                            <Spinner
                                animation='grow'
                                size='xl'
                                role='status'
                                aria-hidden="true"
                                variant="primary"
                                className='b-0 pr-0 pl-0 '
                                style={{height: "15vh", width: "15vh", marginTop: "10%"}}
                                />
                    </div>
                ) 
                
                || showButtons === true && (
                    <div className='w-100 text-center' style={{marginTop: "10%", bottom: 0}}>
                        <div>
                            <div>
                                <button
                                    onClick={() => getPdf()}
                                    className='btn btn-lg btn-primary'
                                >
                                    Spróbuj ponownie
                                </button>
                            </div>
                            <div>
                                <button
                                    onClick={() => {
                                        navigator.clipboard.writeText(document.URL)
                                        NotificationManager.success("Link skopiowany")
                                    }}
                                    className='btn btn-lg btn-secondary mt-4'
                                >
                                    Skopiuj link
                                </button>
                            </div>
                        </div>
                    </div>
                )
                || showButtons === null &&  (
                    <div className='text-center' style={{marginTop: "10%", bottom: 0}}>
                        <div>
                            <h3>
                                <span className='badge badge-success'>
                                    Dokument został wysłany
                                </span>
                            </h3>
                        </div>
                    </div>
                )
            }
            {/* </div> */}
        </section>
        </div>

    )
}
    