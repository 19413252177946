import React from "react"


// TODO: dodac translacje
export default function getPaymentType(paymentTypeId) {
    switch (paymentTypeId) {
        case 0:
            return (<div><span className="badge badge-secondary"><b>Typ płatności: Gotówka,</b> gotówkowa</span></div>)
        case 1:
            return (<div><span className="badge badge-info"><b>Typ płatności: Karta,</b> bezgotówkowa</span></div>)
        case 3:
            return (<div><span className="badge badge-primary"><b>Typ płatności: BLIK,</b> bezgotówkowa</span></div>)
        default:
            return <div className='content' dangerouslySetInnerHTML={{__html: paymentTypeId}}></div>
    }
}