import React, {createContext, useContext, useEffect, useReducer, useState} from 'react'
import PropTypes from 'prop-types';

import Sidebar from '../components/Navigation/Sidebar'
import Header from '../components/Navigation/Header'
import {LoaderContext} from '../context/loader-context'
import {ErrorContext} from './error-context'
import ErrorWrapper from '../components/ErrorWrapper/ErrorWrapper'
import Loader from '../components/Loader/Loader'
import {LangContext} from '../context/lang-context'
import authHook from '../hooks/auth-hook';

import {API_URL} from "../config/config"

import axios from "axios"
import {useHistory} from 'react-router-dom/cjs/react-router-dom.min';


export const NavigationContext = createContext()

const NavigationContextProvider = ({children}) => {
    const {logout} = authHook()
    const {TRL_Pack} = useContext(LangContext)
    const {fatalError} = useContext(ErrorContext)
    const {loader} = useContext(LoaderContext)
    const sidebarWidth = localStorage.getItem('sidebarWidth')
    const [sectionModal] = useState(null)
    const [permission, setPermission] = useState([])
    const [customerName, setCustomerName] = useState("")
    const [isAllowed, setIsAllowed] = useState(false)
    const history = useHistory()

    const [width, setWidth] = useState(sidebarWidth)
    const toggleSidebarWidth = () =>
        setWidth(prev => {
            if (prev === 'md') {
                localStorage.setItem('sidebarWidth', 'sm')
                return 'sm'
            } else {
                localStorage.setItem('sidebarWidth', 'md')
                return 'md'
            }
        })

    const [headerData, setHeaderData] = useState({})
    let navlinks = []

    const repRegExp = new RegExp(/VD_REPORT_\d{0,3}\d/g)
    const permissionReducer = (state, action) => {
        switch (action.type) {
            case "setPermission": {
                return {...state, permissions: state.permissions = action.payload}
            }
            // case "setPermissionFunc": {
            //   return {permissions: state.permissions = getPermissions()}
            // }
            case "setReports": {
                const reps = []
                action.payload.map(i => {
                    if (/VD_REPORT_\d{0,3}\d/g.test(i.Name)) {
                        // console.log(i.Name)
                        reps.push(i.Name)
                    }
                })
                // console.log("reps", reps)
                return {...state, enableReports: reps}
            }
            case "setRoutes": {
                return {routes: state.routes = action.payload}
            }
            case "getRoutes": {
                return {routes: state.routes = navlinks}
            }
            case "dispatchIsLoad": {
                return {...state, isLoad: true}
            }
            case "setRoute": {
                //console.log(state.routes)
                return {...state, routes: [...state.routes, action.payload]}
            }
            case "setIsAllowed": {
                setIsAllowed(action.payload)
                return {...state, isAllowed: action.payload}
            }
        }
    }
    const [state, dispatch] = useReducer(permissionReducer, {
        permissions: [],
        routes: [],
        enableReports: [],
        isAllowed: false,
        isLoad: false
    })

    if (sessionStorage.getItem('DB_TYPE') === "mysql") // MACHINE Routes
    {
        permission.forEach((permission) => {
            switch (permission.Name) {
                case "VD_PRODUCTS":
                    navlinks.push({text: TRL_Pack.navbar.products, path: '/products', icon: 'fas fa-cookie-bite '})
                    break;
                case "VD_CATEGORIES":
                    navlinks.push({text: TRL_Pack.navbar.categories, path: '/categories', icon: 'fas fa-th-large'})
                    break;
                case "VD_MACHINE_CONFIG":
                    navlinks.push({text: TRL_Pack.navbar.supply, path: '/supply', icon: 'fas fa-cart-plus'})
                    break;
                case "VD_MACHINE_RECHARGE":
                    navlinks.push({text: TRL_Pack.shelves.configHeader, path: '/config', icon: 'fas fa-cog'})
                    break;
                case "VD_EVENTS":
                    navlinks.push({text: TRL_Pack.events.events, path: '/events', icon: 'fas fa-calendar'})
                    break;
                case "VD_USERS":
                    navlinks.push({text: TRL_Pack.navbar.users, path: '/users', icon: 'fas fa-users'})
                    break;
                // case "VD_PERSONALISE":
                //   navlinks.push({ text: TRL_Pack.navbar.personalise, path: '/personalise', icon: 'fas fa-users' })
                //   break;
                default:
                    break;
            }
        })

    } else if (localStorage.getItem('clientId') === 'console')
        permission.forEach((permission) => {
                switch (permission.Name) {
                    case "VD_MONITORING":
                        navlinks.push({text: TRL_Pack.navigation.monitoring, path: '/monitoring', icon: 'fa fa-desktop'})
                        break;
                    case "VD_MACHINES":
                        navlinks.push({text: TRL_Pack.navigation.machines, path: '/machines', icon: 'fas fa-tablet-alt'})
                        break;
                    case "VD_TERMINALS":
                        navlinks.push({text: TRL_Pack.navigation.terminals, path: '/terminals', icon: 'fas fa-credit-card'})
                        break;
                    case "VD_TRXS":
                        navlinks.push({text: TRL_Pack.navigation.trxs, path: '/trxs', icon: 'fas fa-credit-card'})
                        break;
                    case "VD_CUSTOMERS":
                        navlinks.push({text: TRL_Pack.navigation.clients, path: '/clients', icon: 'fas fa-users-cog'})
                        break;
                    case "VD_PRODUCTS":
                        navlinks.push({text: TRL_Pack.navbar.products, path: '/products', icon: 'fas fa-cookie-bite'})
                        break;
                    case "VD_CATEGORIES":
                        navlinks.push({text: TRL_Pack.navbar.categories, path: '/categories', icon: 'fas fa-th-large'})
                        break;
                    case "VD_SOFTWARE":
                        navlinks.push({text: TRL_Pack.navbar.software, path: '/software', icon: 'fas fa-microchip'})
                        break;
                    case "VD_PREPAID":
                        navlinks.push({text: "Karty prepaid", path: '/prepaids', icon: 'fas fa-address-card'})
                        break;
                    // case "VD_PERSONALISE":
                    //   navlinks.push({ text: TRL_Pack.navbar.personalise, path: "/personalise", icon: 'fas fa-cookie-bite' })
                    //   break;
                    default:
                        break;
                }
            }
        )
    else //SERVER Routes
    {
        permission.forEach((permission) => {
            switch (permission.Name) {
                case "VD_PRODUCTS":
                    navlinks.push({
                        text: TRL_Pack.navbar.products,
                        path: '/products'/*, group: 'configuration'*/,
                        icon: 'fas fa-cookie-bite '
                    })
                    break;
                case "VD_PAS":
                    navlinks.push({
                        text: TRL_Pack.navbar.pas,
                        path: '/pas'/*, group: 'machine'*/,
                        icon: 'fas fa-receipt'
                    })
                    break;
                case "VD_WIS":
                    navlinks.push({
                        text: TRL_Pack.navbar.wis,
                        path: '/wis'/*, group: 'machine'*/,
                        icon: 'fas fa-compass'
                    })
                    break;
                case "VD_KPS":
                    navlinks.push({text: TRL_Pack.navbar.kps, path: '/kps'/*, group: 'machine'*/, icon: 'fas fa-coins'})
                    break;
                case "VD_DOS":
                    navlinks.push({
                        text: TRL_Pack.navbar.dos,
                        path: '/dos'/*, group: 'machine'*/,
                        icon: 'fas fa-forward'
                    })
                    break;
                case "VD_ROS":
                    navlinks.push({
                        text: TRL_Pack.navbar.ros,
                        path: '/ros'/*, group: 'machine'*/,
                        icon: 'fas fa-backward'
                    })
                    break;
                case "VD_INMS":
                    navlinks.push({
                        text: TRL_Pack.navbar.inms,
                        path: '/inms'/*, group: 'machine'*/,
                        icon: 'fas fa-prescription-bottle'
                    })
                    break;
                case "VD_INS":
                    navlinks.push({
                        text: TRL_Pack.navbar.ins,
                        path: '/ins'/*, group: 'warehouse'*/,
                        icon: 'fas fa-pallet'
                    })
                    break;
                case "VD_WZS":
                    navlinks.push({
                        text: TRL_Pack.navbar.wzs,
                        path: '/wzs'/*, group: 'warehouse'*/,
                        icon: 'fas fa-arrow-alt-circle-right'
                    })
                    break;
                case "VD_PZS":
                    navlinks.push({
                        text: TRL_Pack.navbar.pzs,
                        path: '/pzs'/*, group: 'warehouse'*/,
                        icon: 'fas fa-arrow-alt-circle-left'
                    })
                    break;
                case "VD_STS":
                    navlinks.push({
                        text: TRL_Pack.navbar.sts,
                        path: '/sts'/*, group: 'warehouse'*/,
                        icon: 'fas fa-skull-crossbones'
                    })
                    break;
                case "VD_ZWS":
                    navlinks.push({
                        text: TRL_Pack.navbar.zws,
                        path: '/zws'/*, group: 'warehouse'*/,
                        icon: 'fas fa-chalkboard-teacher'
                    })
                    break;
                case "VD_FZS":
                    navlinks.push({
                        text: TRL_Pack.navbar.fzs,
                        path: '/fzs'/*, group: 'warehouse'*/,
                        icon: 'fas fa-cart-plus'
                    })
                    break;
                case "VD_KOS":
                    navlinks.push({
                        text: TRL_Pack.navbar.kos,
                        path: '/kos'/*, group: 'machine'*/,
                        icon: 'fas fa-eraser'
                    })
                    break;
                case "VD_CATEGORIES":
                    navlinks.push({
                        text: TRL_Pack.navbar.categories,
                        path: '/categories'/*, group: 'configuration'*/,
                        icon: 'fas fa-th-large'
                    })
                    break;
                case "VD_PRODUCT_GROUPS":
                    navlinks.push({text: "Grupy", path: '/groups', icon: 'fas fa-layer-group'})
                    break;
                case "VD_MACHINES":
                    navlinks.push({
                        text: TRL_Pack.navigation.machines,
                        path: '/machines'/*, group: 'machine'*/,
                        icon: 'fas fa-tablet'
                    })
                    break;
                case "VD_PRODUCT_CATALOG":
                    navlinks.push({
                        text: TRL_Pack.navigation.catalog,
                        path: '/catalog-products'/*, group: 'configuration'*/,
                        icon: 'fas fa-th-list'
                    })
                    break;
                case "VD_TAGS":
                    navlinks.push({
                        text: TRL_Pack.navigation.tags,
                        path: '/tags'/*, group: 'configuration'*/,
                        icon: 'fas fa-tags'
                    })
                    break;
                case "VD_REPORTS":
                    navlinks.push({
                        text: TRL_Pack.navigation.reports,
                        path: '/reports'/*, group: 'raport'*/,
                        icon: 'far fa-file-alt'
                    })
                    break;
                case "VD_REPORT_CONDITIONS":
                    navlinks.push({
                        text: TRL_Pack.summaries.summaries,
                        path: '/summaries'/*, group: 'raport'*/,
                        icon: 'fas fa-table'
                    })
                    break;
                case "VD_PAYMENT_CODES":
                    navlinks.push({
                        text: TRL_Pack.codes.codes,
                        path: '/codes'/*, group: 'configuration'*/,
                        icon: 'fas fa-qrcode'
                    })
                    break;
                case "VD_PERSONALISE":
                    navlinks.push({
                        text: TRL_Pack.navbar.personalise,
                        path: '/personalise'/*, group: 'configuration'*/,
                        icon: 'fas fa-sliders-h'
                    })
                    break;
                case "VD_LOCATIONS":
                    navlinks.push({
                        text: TRL_Pack.navbar.locations,
                        path: '/machinelocations'/*, group: 'configuration'*/,
                        icon: 'fas fa-map-marker-alt'
                    })
                    break;
                case "VD_USERS":
                    navlinks.push({
                        text: TRL_Pack.navbar.users,
                        path: '/users'/*, group: 'configuration'*/,
                        icon: 'fas fa-users'
                    })
                    break;
                case "VD_TERMINALS":
                    navlinks.push({text: TRL_Pack.navigation.terminals, path: '/terminals', icon: 'fas fa-credit-card'})
                    break;
                case "VD_TRXS":
                    navlinks.push({text: TRL_Pack.navigation.trxs, path: '/trxs', icon: 'fas fa-credit-card'})
                    break;
                case "VD_MONITORING":
                    navlinks.push({text: TRL_Pack.navigation.monitoring, path: '/monitoring', icon: 'fa fa-desktop'})
                    break;
                case "VD_LASTVI":
                    navlinks.push({text: "Ostatnie wizyty", path: '/lastvi', icon: 'fas fa-compass'})
                    break;
                case "VD_MM":
                    navlinks.push({text: "Przesunięcia magazynowe", path: '/mms', icon: 'fas fa-exchange-alt'})
                    break;
                case "VD_WAREHOUSES":
                    navlinks.push({text: "Magazyny", path: '/whs', icon: 'fas fa-warehouse'})
                    break;
                case "VD_MACHINE_TYPES":
                    navlinks.push({text: "Typy automatów", path: '/machinetypes', icon: "fas fa-poll-h"})
                    break;
                case "VD_BAGS":
                    navlinks.push({text: "Worki na monety", path: '/bags', icon: 'fa fa-money-bill'})
                    break;
                case "VD_CONTRACTORS":
                    navlinks.push({text: "Kontrahenci", path: '/contractors', icon: 'fas fa-solid fa-user-tie'})
                    break;
                case "VD_BASKETS":
                    navlinks.push({text: "Zestawy promocyjne", path: '/promotions', icon: 'fas fa-percent'})
                    break;
                case "VD_EVENTS":
                    navlinks.push({text: "Typy zdarzeń", path: '/events', icon: 'fas fa-exclamation-triangle'})
                    break;
                case "VD_PREPAID":
                    navlinks.push({text: "Karty prepaid", path: '/prepaids', icon: 'fas fa-address-card'})
                    break;
                case "VD_WKF":
                    navlinks.push({text: "Kasy fiskalne", path: '/wkfs', icon: 'fas fa-cash-register'})
                    break;
                case "VD_CR":
                    navlinks.push({text: "Powody korekt", path: '/corection-reasons', icon: 'fas fa-undo-alt'})
                    break;
                case "VD_ERRORS":
                  navlinks.push({ text: "Błędy", path: '/errors', icon: 'fas fa-solid fa-bug' })
                  break;
                default:
                    break;
            }

        })

    }
    if (sessionStorage.getItem('DB_TYPE') !== "mysql") {
        !isAllowed && navlinks.push({text: "Uzupełnienie danych", path: '/wkfs/configuration', icon: 'fas fa-user'})
    }

    navlinks.push({text: TRL_Pack.navigation.logout, path: '/logout', icon: 'fas fa-sign-out-alt'})

    useEffect(() => {
        sessionStorage.getItem('DB_TYPE') === "mysql" || localStorage.getItem("clientId") === "console"
            ? axios
                .get(`${API_URL}/api/permissions`, {
                    headers: {},
                }).then(({data}) => {
                    dispatch({type: "setPermission", payload: data})
                    setPermission(data)
                })
            : axios
                .get(`${API_URL}/api/customerIsConfigurated`, {
                    headers: {},
                }).then(res => {
                    const {data} = res
                    dispatch({type: "setIsAllowed", payload: data.IsConfigurated})
                    if (data.IsConfigurated) {
                        axios
                            .get(`${API_URL}/api/permissions`, {
                                headers: {},
                            }).then(({data}) => {
                            dispatch({type: "setPermission", payload: data})
                            setPermission(data)
                            return data
                        })
                            .then((data) => {
                                dispatch({type: "setReports", payload: data})
                            })
                            .then(() => {
                                dispatch({type: "dispatchIsLoad", payload: true})
                            })
                            .catch(err => {
                                if (res.response.status === 401) {
                                    logout()
                                      .then(() => {
                                        return window.location.reload()
                                    })
                                }
                                // console.log(err.response || err.response)
                                console.group(err.response)
                            })
                    } else {
                        history.replace('/wkfs/configuration')
                    }
                }).catch((err) => {

                    // console.log(err.response || err.response)
                    console.group(err.response)
                })

    }, [])
    useEffect(() => {
        if (sessionStorage.getItem('DB_TYPE') !== "mysql" && localStorage.getItem('clientId') !== "console") axios
            .get(`${API_URL}/api/customerinfo`, {
                headers: {},
            }).then((res) => {
                const {data} = res
                setCustomerName(data)
            })
            .catch(err => {
                console.log(err.response)
                if (err.response.status === 401) {
                    logout()
                    .then(() => {
                        return window.location.reload()
                    })
                }
                if (err.response.data.message === "jwt malformed") window.location.reload();

                // console.log(err.response || err.response)
                console.group(err.response)
            })
    }, [])
    useEffect(() => {
        if (sessionStorage.getItem('DB_TYPE') !== "mysql" && localStorage.getItem('clientId') !== "console") {

            axios.get(`${API_URL}/api/customerIsConfigurated`, {
                headers: {},
            }).then((res) => {
                const {data} = res
                if (data.IsConfigurated) {
                    axios
                        .get(`${API_URL}/api/permissions`, {
                            headers: {},
                        }).then(({data}) => {
                        // dispatch({type: "setPermission", payload: data.isAllowed})
                        setPermission(data)
                    }).catch(err => {
                        console.log(err)
                        if (res.response.status === 401) {
                            logout()
                              .then(() => {
                                return window.location.reload()
                            })
                        }
                        // console.log(err.response || err.response)
                        console.group(err.response)
                    })
                } else {
                    history.replace('/wkfs/configuration')
                }
            })
        } else {
            axios
                .get(`${API_URL}/api/permissions`, {
                    headers: {},
                }).then(({data}) => {
                dispatch({type: "setPermission", payload: data.isAllowed})
                setPermission(data)
            }).catch(err => {
                console.group(err.response)
            })
        }
    }, [isAllowed])
    const [showSideMenu, setShowSideMenu] = useState(true)
    return (
        <NavigationContext.Provider value={{state, navlinks, dispatch, setHeaderData, setShowSideMenu, customerName}}>
            <div className={showSideMenu ? 'd-block' : 'd-none'}>

                <Sidebar width={width} navlinks={navlinks}/>
            </div>

            <div className="w-100 d-flex flex-column">

                <Header
                    toggleSidebar={toggleSidebarWidth}
                    headerData={headerData}
                    navlinks={navlinks}
                    customerName={customerName}
                    headerClassName={showSideMenu ? "shadow d-flex flex-column justify-content-center p-0" : "d-none"}
                />
                {fatalError ? (
                    <ErrorWrapper/>
                ) : (
                    <div
                        className={`d-flex flex-column flex-grow-1 ${loader ? 'position-static' : ''
                        }`}
                    >
                        {sectionModal}
                        {loader && <Loader/>}
                        <div className="p-3 h-100 d-flex flex-column">{children}</div>
                    </div>
                )}
            </div>
        </NavigationContext.Provider>
    )
}

NavigationContextProvider.propTypes = {
    children: PropTypes.object
};

export default NavigationContextProvider;


