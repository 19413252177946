import React, { useEffect, useState } from 'react'
import fetchHook from '../../../../hooks/fetchMSSQL-hook'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { Config } from './MachineTaskConstants'

export default function TaskScreenHistory() {
  const { fetchMssqlApi } = fetchHook()
  const [tasks, setTasks] = useState(null)
  const { machineId } = useParams()

  const getTasks = () => {
    fetchMssqlApi(`machine-tasks/${machineId}`, { method: "GET" }, data => {
      setTasks(data)
    })
  }

  useEffect(() => {
    getTasks()
  }, [])

  const getStatus = (status) => {
    switch (status) {
      case 1:
        return <span className='badge badge-primary'>Nowe</span>
      case 2:
        return <span className='badge badge-info'>Wysłano do automatu</span>
      case 3:
        return <span className='badge badge-success'>Wykonano przez automat</span>
      case 4:
        return <span className='badge badge-danger'>Odrzucono</span>
      default:
        return <span className='badge badge-dark'>Nie rozpoznany status</span>

    }
  }
  const cancelTask = (taskId) => fetchMssqlApi(`machine-tasks/${taskId}`, { method: "PUT" }, () => getTasks())
  const commandsMap = new Map(Config && Config.map(i => ([i.taskType, i.name])))

  const getTask = (type, value, createDateTime, updateDateTime, status, response, machineTaskId) => (
    <tr>
      <td>{commandsMap.get(type) == null ? type : commandsMap.get(type) }</td>
      <td>{value}</td>
      <td>{createDateTime}</td>
      <td>{updateDateTime}</td>
      <td>{getStatus(status)}</td>
      <td>{response}</td>
      <td>
        <div>
          <button
            className='btn btn-sm btn-danger'
            disabled={status !== 1}
            onClick={() => cancelTask(machineTaskId)}
          >
            Usuń
          </button>
        </div>
      </td>
    </tr>
  )
  return (
    <>
      <div className='card w-100' style={{overflowY: "scroll"}}>
        <div className='card-header'>
          Historia
        </div>
        <div className='card-body'>
          <table className='table table-striped'>
            <thead>
              <tr>
                <th>Zadanie</th>
                <th>Wartość</th>
                <th>Stworzone</th>
                <th>Aktualizowane</th>
                <th>Status</th>
                <th>Odpowiedź</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {tasks && tasks.map(i =>
                getTask(i.Type + ` ${i.SlaveNo === 1 ? "" : `(Slave #${i.SlaveNo})`}`, i.Value, i.CreateDateTime, i.UpdateDateTime, i.Status, i.Response, i.MachineTaskId))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}
