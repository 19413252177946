import React, { useEffect, useState } from 'react'
import FormSkel from './FormSkel'

export default function RoleForm({handleClose, data, form, handleSubmit}) {

    const [roleData, setRoleData] = useState(form !== "new" ? data : {Name: ""}),
    [changed, setChanged] = useState(false)

    useEffect(() => {
        setChanged(true)
        data && roleData.Name === data.Name && setChanged(false)
    }, [roleData])
    // headerText, noFooter, classes, handleClose, children, style, disableSubmit, acceptForm, data, informationForm, deleteDisable
    return (
        <FormSkel headerText={form === "new" && "Nowa rola" || "Edytuj rolę"}
            disableSubmit={!roleData.Name || !changed}
            data={data}
            handleClose={handleClose}>
            <form id='modal-form'
                onSubmit={evt => handleSubmit(evt, roleData, setRoleData)}
                autoComplete='off'
                onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()}
            >   
                <label className='h6'>Nazwa</label>
                <div className='form-group'>
                    <input
                        onKeyDown={evt => evt.key === "enter" && evt.preventDefault()}
                        className={`form-control ${!roleData.Name && `invalid-input`}`}
                        value={roleData.Name || ""}
                        name='name'
                        onFocus={() => setRoleData(prev => ({...prev, Name: ""}))}
                        onChange={evt =>{setChanged(true); const value = evt.target.value; setRoleData(prev => ({...prev, Name:value}))} }/>
                </div>
            </form>
        </FormSkel>
    )
}
