

function setInformation (setFilter, data, filter, terminalsList, customers = localStorage.getItem("clientId")) {
    // const {value, name} = evt.target
    // const [clients, setClients] = useState([])
    // useEffect(() => { 
    //     let x = new Set()
    //     data.machines.map(machine => x.add(`${machine.CustomerName} (${machine.Abbreviation})`))
    //     // console.log(...x)
    //     const arr = Array.from(x)
    //     setClients(arr)
    // }, [data])
    // resetPage()
    const getAbbr = value => {
        let abbrArr = value?.split("(")
        let abbr = abbrArr[1]?.replace(")", "")
        if(abbr === "brak") return "brak"
        if(String(abbr).length !== 3) return null
        else return abbr
    }
    const getCustomerId = data => {
        // let arr = customers.Id
        // let data = null
        // arr.find(name => {
        //     if(!name.split(" ")) return null
        //     let fullNameWithId = name.split(" ")
        //     let fullName = Array.from(fullNameWithId.slice(1)).join(" ") 
        //     if(fullName === customer) data = fullNameWithId[0] 
        // })

        // if (data && typeof(+data) === "number") return +data
        // else return null
        // if(Array.isArray())
        if(localStorage.getItem("clientId") === "console") {
            let id = customers.find(customer => `${customer.Name} (${customer.Abbreviation})` === data)?.CustomerId
            return id || null
        }
        else {
            return customers[0]?.CustomerId
        }
    }   
    const getTermianal = (payload) =>{ let obj = terminalsList.find(terminal => terminal.Name === payload)?.TerminalId
    //console.log(obj)
return obj?.TerminalId}
    const getAutomatId = param => {
        if(localStorage.getItem("clientId") !== "console") {
            let id
            let serialno
            data.machines.map(machine => {if (machine.Name === param) serialno = machine.SerialNo})
            data.terminals.map(terminal => {if(terminal.MachineSerialNo === serialno) id= terminal.MachineId})
            if(serialno && !id && param) return alert("Maszyna nie ma terminala")
            return id || null
        }
        if (localStorage.getItem("clientId") === "console") {
            let id
            if(!param) return null 
            let serialNo = param.split("[")
            // if(!Array.isArray(serialNo)) return alert('something went wrong')
            serialNo = serialNo[1].replace("]", "")
            //console.log(serialNo)
            data.terminals.map(terminal => {if (terminal.MachineSerialNo === serialNo) {id = terminal.MachineId}})
            return id || null
        } 
        return null
    }
    filter.columns.map(col => {
        let name = col.name
        let value = col.value
        if(col.name === "Automat") {
            setFilter(prev => ({
                ...prev,
                columns: prev.columns.map(col => {
                    if(col.name === "Automat") {col.save = getAutomatId(col.value)
                }
                    return col
                })
            }))
        }
        if(col.name === "Terminal") {

            setFilter(prev => ({
                ...prev,
                columns: prev.columns.map(col => {
                    if(col.name === "Terminal") {col.save = terminalsList.find(terminal => terminal.Name === col.value)?.TerminalId}
                    // data.terminals.map(terminal => console.log(`${terminal.TerminalTypeName} ${terminal.SerialNo}`) )
                    return col
                })
            }))
        }
        if(col.name === "Klient") {
            setFilter(prev => ({
                ...prev,
                columns: prev.columns.map(col => {
                    if(col.name === "Klient") {col.save = getCustomerId(value)
                }
                    return col
                })
            }))
        }
    })

    
} 

export default setInformation