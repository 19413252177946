import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import MachineTaskNavigation from "./MachineTaskNavigation";
import MachineTaskHistory from "./MachineTaskHistory";
import MachineTaskView from "./MachineTaskView";

export default function MachineTask({ machineData }) {
  const { machineId } = useParams();

  const [menuFeeder, setMenuFeeder] = useState(0);
  const machineType = machineData.MachineTypeName.split(/[\s+]+/).filter((el) =>
    ["V10", "V11", "V12", "V13", "V14"].includes(el)
  );

  return (
    <div className="container-xl">
      {machineType.length != 0 ? (
        <>
          <MachineTaskNavigation
            type={menuFeeder}
            setType={setMenuFeeder}
            machineType={machineType}
          />
          <div className="d-flex w-100 pl-4 pr-4">
            {machineType.map(
              (el, i) =>
                menuFeeder === i && (
                  <MachineTaskView
                    key={i}
                    machineType={i+1}
                    machineId={machineId}
                  />
                )
            )}
            {menuFeeder === 3 && <MachineTaskHistory machineId={machineId} />}
          </div>
        </>
      ) : (
        <h5>BRAK DOSTĘPNYCH MASZYN DO STEROWANIA</h5>
      )}
    </div>
  );
}
