import React, {useState, useEffect, useContext} from 'react'
import fetchHook from '../../hooks/fetchMSSQL-hook'
import { NavigationContext } from '../../context/navigation-context'
import moment from 'moment'
import { Link, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import NoResults from '../../components/NoResults/NoResults'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function Promotions() {
    const [promotions, setPromotions] = useState([]),
    {setHeaderData} = useContext(NavigationContext),
    {fetchMssqlApi} = fetchHook(),
    params = useParams(),
    [promotionType, setPromotionType] = useState("baskets"),
    history = useHistory()
    const getPromotions = () => {
        promotionType === "baskets" && fetchMssqlApi('promotions-basket', {}, res => setPromotions(res))
        // promotionType === "baskets" && setPromotions(baskets)
    }

    useEffect(() => {
        setHeaderData({text: "Promocje"})
        getPromotions()
    }, [])
    useEffect((() => {
        history.replace(`/promotions/${promotionType}`)
    }), [promotionType])
    return (
        <div>
            <ul className='nav nav-tabs machine-tabs mb-3'>
                <li className="nav-item">
                    <button
                    onClick={() => setPromotionType("baskets")}
                    className={`nav-link btn active`}
                    tabIndex="0">
                        Zestawy
                    </button>
                </li>
                {/* <li className="nav-item">
                    <button
                        onClick={() => setPromotionType("adasdasd")}
                        className={`nav-link btn`}
                        tabIndex="0">
                    </button>
                </li> */}

            </ul>
            {promotions.length ? (
                
                <div className='card'>
                    <div className='card-header'>
                        <div className='d-flex justify-content-between'>
                            <div className='h5 mt-2 mb-1'>Zestawy promocyjne</div>
                            <Link to={`/promotion/basket/0`} className="btn text-nowrap btn-sm btn-outline-secondary mt-1 mb-1"><i className="fas fa-plus" /></Link>
                        </div>
                    </div>
                    <div className='card-body overflow-auto' style={{maxHeight: "60vh"}}>
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <td className='text-center'>Start</td>
                                    <td className='text-center'>Koniec</td>
                                    <td className='text-center'>Cena</td>
                                    <td className='text-center'>Ilość produktów w zestawie</td>
                                    <td className='text-center'>Stworzona</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {promotions.map((promotion, idx) => (
                                    <tr key={idx}>
                                        <td className='text-center'>{moment(promotion.StartDateTime).format('YYYY-MM-DD HH:MM')} </td>
                                        <td className='text-center'>{moment(promotion.EndDateTime).format('YYYY-MM-DD HH:MM')}</td>
                                        <td className='text-center'>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(promotion.Price)}</td>
                                        <td className='text-center'>
                                            {promotion.SubscribeProducts}

                                        </td>
                                        <td className='text-center'>{promotion.Created}</td>
                                        <td className='d-flex justify-content-end mb-2 border-0'>
                                            <Link to={`/promotion/basket/${promotion.BasketId}`} className="btn btn-outline-primary btn-sm mt-2 mr-2"
                                                    
                                                    // key={location.id+"btn"}
                                                    >
                                                Edytuj
                                            </Link>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            ) : (
                <div className='text-center'>

                    <NoResults/>
                    <Link className='mt-5' to="/promotion/basket/0">
                        Dodaj promocję
                    </Link>
                </div>
            )}
        </div>
    )  
}
