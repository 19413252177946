import React, { useState } from "react"


import useForm from '../../../hooks/form-hook'
import UsersForm from "./UsersForm"
import fetchMSSQLHook from "../../../hooks/fetchMSSQL-hook"
import { useEffect } from "react"
import { NotificationManager } from "react-notifications"


const UsersTable = ({filter, data, setData , children, IsActive, id, page, sortRows, roles, warehouses}) => {

    const { form, openForm, closeForm } = useForm()
    const {fetchMssqlApi} = fetchMSSQLHook()
    const [samePassword, setSamePasword] = useState({status: false, message: ""})
    useEffect(() => {
        setSamePasword({status: false, message: ""})
    }, [form])
    const handleSubmit = (evt, locations) => {
        evt.preventDefault()
        const {name, role, warehouse, email, newPassword, newPasswordRepeat, userId, isActive} = evt.target.elements
        const user = data.filter(user => user.UserId === userId.value)[0]
        const roleId = roles.filter(odjRole => odjRole.Name === role.value)[0]?.RoleId 
            ||  roles.filter(odjRole =>  odjRole.Name === user.Role)[0].RoleId
        const warehouseId = warehouses.filter(wh => wh.Name === warehouse.value)[0]?.WarehouseId
            || warehouses.filter(wh => wh.Name === user.WarehousName)[0].WarehouseId
        const IsActive = (isActive.value === "true")
        // console.log(name, password, role, warehouse, email, newPassword, newPasswordRepeat)
        // console.log(roleId);
        if(newPassword.value !== newPasswordRepeat.value) {
            newPassword.value = ""
            newPasswordRepeat.value = ""
            return setSamePasword({status: true, message: "Niepoprawno powtórzone hasło"})
        } 
        if (!name.value) {
            NotificationManager.error("Wpisz imię użytkownika")
            name.focus()
            return
        }
        let emailArr = []
        data.filter(data => data.Email !== user.Email).forEach(user => emailArr.push(user.Email) )
        if(emailArr.includes(email.value)) return setSamePasword({status: true, message: "Taki mail już istnieję"})

        if(String(newPassword.length) < 3) return setSamePasword({status: true, message: "Wpisz hasło, dlugość którego powyżej 4 symboly"})
        if(newPassword.value === newPasswordRepeat.value && samePassword.status) setSamePasword({status: false, message: ""}) 
        const locationsToAdding = new Array
        const locationsToDelete = new Array
        for (let location of locations) {
            if(location.IsDeleted) locationsToDelete.push(location.LocationId)
            if(location.IsNew) locationsToAdding.push(location.LocationId)
        }
        const payload = {
            userName: name.value,
            roleId: roleId,
            warehouseId: warehouseId,
            email: email.value,
            newPassword: newPassword.value,
            isActive: IsActive,
            locationsToAdding,
            locationsToDelete
        }
        fetchMssqlApi(`users/${userId.value}`, {method: "POST", data: payload}, res => {
            // console.log({name: role.value, roleId, roles})
            closeForm()
            setData()
        })

    }
    return (             
    <>                   
    <table className="table table-striped border" style={{width: "100%"}}>
    <thead>
        <tr >
            {filter.columns
                .filter(col => !col.hidden && !col.disabled)
                .map((col, idx) => (
                    <th key={idx} 
                    className={(col.name === "Aktywny") ? "text-center" : ""}
                    >{col.name}</th>
                ))}
        </tr>
    </thead>
    <tbody>
        {data.sort(sortRows)
            .slice(
                (page - 1) * filter.rowsPerPage,
                page * filter.rowsPerPage
            )
            .map((miniData, idx) => (
                <tr key={idx} className='align-text-center'>
                    {Object.keys(miniData)
                        .filter((col, col_idx) =>
                            filter.columns
                                .filter(col => !col.hidden && !col.disabled)
                                .map(col => col.id)
                                .includes(col_idx + 1)
                        )
                        .map((col, col_idx) => (
                            <td key={col_idx} className={(col !== "IsActive") ? "small" : "d-flex justify-content-center"}>
                            <div className="btn btn-link font-size-inherit text-reset text-decoration-none p-1 ">
                            {(typeof(miniData[col])  !== "boolean" )
                                ? miniData[col]
                                : <input type='checkbox'
                                    style={{width: "15px", height: "15px", marginTop: "9px"}}
                                    checked={miniData[col]}  onChange={() => {}}/>}
                            </div>
                            </td>
                        ))}
                        <td className='d-flex justify-content-end border-0 mb-2'>
                            <button className="btn btn-outline-primary btn-sm "
                                onClick={openForm(miniData[id])}>
                                Edytuj
                            </button>
                        </td>
                        {children}
                </tr>
            ))}
            
    </tbody>
</table>
    {form && (<UsersForm
        form={form}
        data={data}
        miniData={data.filter(data => data.UserId == form)[0]}
        handleClose={closeForm}
        handleSubmit={handleSubmit}
        warning={samePassword}
    />)}    
</>
)
}

export default UsersTable