import React, { useEffect, useState, useContext } from "react";
import fetchMSSQLHook from "../../hooks/fetchMSSQL-hook";
import Filter from "../../components/Filter/Filter";
import Pagination from "../../components/Pagination/Pagination";
import { LangContext } from "../../context/lang-context";
const ErrorTable = () => {
  const [errors, setErrors] = useState([]);
  const [errorsType, setErrorsType] = useState([]);
  const { fetchMssqlApi } = fetchMSSQLHook();
  const { TRL_Pack } = useContext(LangContext);

  const getErrors = () => {
    fetchMssqlApi("errors-type", {}, (data2) => {
      setErrorsType(data2);
      fetchMssqlApi("errors", {}, (data) => {
        const newData = data.map((error, index) => {
          const errorType = data2.find(
            (errorType) => errorType.ErrorCode === error.ErrorCode
          );
          return {
            ErrorId: error.ErrorId,
            ErrorCodeDesc:
              errorType && errorType.Description
                ? errorType.Description
                : "Unknown",
            ErrorCode: error.ErrorCode,
            MachineFeederNo: error.MachineFeederNo,
            CreateDateTime: error.CreateDateTime,
            IsCritical: errorType && errorType.IsErrorCritical ? "Tak" : "Nie",
          };
        });
        setErrors(newData);
      });
    });
  };

  useEffect(() => getErrors(), []);

  const getErrorTypeByErrorNumber = (errorNumber) => {
    return errorsType.find((errorType) => errorType.ErrorCode === errorNumber);
  };

  let defaultFilter = {
    showIndexes: true,
    page: 1,
    rowsPerPage: 25,
    rowsPerPageOptions: [10, 25, 50],
    visible: false,
    sortByColumns: true,
    sortBy: "3 | asc | text",
    activeTags: [],
    // disableIndexes: false,
    columns: [
      {
        id: 2,
        name: TRL_Pack.events.errors.describe,
        sortable: true,
        searchable: true,
        type: "text",
      },
      {
        id: 3,
        name: TRL_Pack.events.errors.number,
        sortable: true,
        searchable: true,
        type: "price",
      },
      {
        id: 4,
        name: TRL_Pack.events.errors.selectionNumber,
        sortable: true,
        searchable: true,
        type: "price",
      },

      {
        id: 5,
        name: TRL_Pack.events.errors.occured,
        sortable: true,
        date: true,
        searchable: true,
        type: "date",
      },
      {
        id: 6,
        name: TRL_Pack.events.errors.critical,
        sortable: true,
        searchable: true,
        type: "price",
      },
    ],
  };

  const [filter, setFilter] = useState(() => {
    if (localStorage.getItem("errorsFilter")) {
      return JSON.parse(localStorage.getItem("errorsFilter"));
    } else return defaultFilter;
  });

  const resetFilter = () => setFilter(defaultFilter);
  const toggleFilter = () =>
    setFilter((prev) => ({ ...prev, visible: !prev.visible }));

  const handleSwitchPage = (pageNo) => () =>
    setFilter((prev) => ({ ...prev, page: pageNo }));
  const resetPage = () => setFilter((prev) => ({ ...prev, page: 1 }));

  const reportFilter = (row) =>
    filter.columns
      .filter((col) => (col.searchbar ? col.searchbar : col.selectbar))
      .every((col) => {
        const x = String(row[Object.keys(row)[col.id - 1]])
          .toLowerCase()
          .includes(
            col.searchbar
              ? col.searchbar.toLowerCase()
              : col.selectbar.toLowerCase()
          );
        return x;
      });

  const sortRows = (a, b) => {
    const [id, order, type] = filter.sortBy.split(" | ");

    const col = Object.keys(errors[0])[Number(id) - 1];

    if (a[col] === b[col]) return 0;
    else if (a[col] === null) return 1;
    else if (b[col] === null) return -1;

    let valueA, valueB;
    if (type === "text" || type === "date") {
      valueA = a[col];
      valueB = b[col];
    } else if (type === "bool") {
      valueA = Number(a[col]);
      valueB = Number(b[col]);
    } else if (type === "price") {
      valueA = Number(a[col]);
      valueB = Number(b[col]);
    } else return 0;
    if (order === "asc") return valueA < valueB ? -1 : 1;
    else return valueA < valueB ? 1 : -1;
  };

  return (
    errors &&
    errorsType && (
      <div>
        <Pagination
          totalItems={errors.filter(reportFilter).sort(sortRows).length}
          page={filter.page}
          rowsPerPage={filter.rowsPerPage}
          handleSwitchPage={handleSwitchPage}
          filterVisibility={filter.visible}
          toggleFilter={toggleFilter}
          resetFilter={resetFilter}
        />
        {filter.visible && (
          <Filter
            {...{
              filter,
              setFilter,
              columns: filter.columns,
              data: errors,
              resetPage,
              resetFilter,
            }}
          />
        )}

        <table className="table table-striped border">
          <thead>
            <tr>
              {filter.showIndexes && (
                <th className="text-center px-4" style={{ width: "1%" }}>
                  #
                </th>
              )}
              {filter.columns.map(
                (col) =>
                  !col.disabled && (
                    <th
                      key={col.id}
                      className="text-center"
                      style={{ width: 100 }}
                    >
                      {col.name}
                    </th>
                  )
              )}
            </tr>
          </thead>
          <tbody>
            {errors
              .filter(reportFilter)
              .sort(sortRows)
              .slice(
                (filter.page - 1) * filter.rowsPerPage,
                filter.page * filter.rowsPerPage
              )
              .map((error, index) => {
                const errorType = getErrorTypeByErrorNumber(error.ErrorCode);
                return (
                  <tr key={index}>
                    {filter.showIndexes && (
                      <td className="font-weight-bold text-center">
                        {error.ErrorId}
                      </td>
                    )}
                    <td className="font-weight-bold text-center">
                      {error.ErrorCodeDesc}
                    </td>
                    <td className="font-weight-bold text-center">
                      {error.ErrorCode}
                    </td>
                    <td className="font-weight-bold text-center">
                      {error.MachineFeederNo}
                    </td>
                    <td className="font-weight-bold text-center">
                      {error.CreateDateTime.replace("T", " ").slice(0, 19)}
                    </td>
                    <td
                      className="font-weight-bold text-center"
                      style={{
                        backgroundColor:
                          errorType && errorType.IsErrorCritical && "crimson",
                      }}
                    >
                      {errorType && errorType.IsErrorCritical ? "Tak" : "Nie"}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    )
  );
};

export default ErrorTable;
