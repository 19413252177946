import React, { useState, useRef, useContext } from 'react'
import { Link } from 'react-router-dom'
import { LangContext } from '../../context/lang-context'

import onClickAway from '../../util/onClickAway'

import "./style.css"

// eslint-disable-next-line react/display-name
export default ({ toggleSidebar, navlinks, headerData: { text, subtext } = {} , customerName, headerClassName="shadow d-flex flex-column justify-content-center p-0"}) => {
  const navbarRef = useRef(null)
  const { TRL_Pack } = useContext(LangContext)

  const [mobileNavbar, setMobileNavbar] = useState(false)
  const openMobileNavbar = () => {
    setMobileNavbar(true)
    onClickAway(navbarRef, closeMobileNavbar)
  }
  const closeMobileNavbar = () => setMobileNavbar(false)

  let list = []

  // Machine
  let machineList = []
  const [collapseMachine, setCollapseMachine] = useState(false)

  // Warehouse
  let warehouseList = []
  const [collapseWarehouse, setCollapseWarehouse] = useState(false)

  // Raport
  let raportList = []
  const [collapseRaport, setCollapseRaport] = useState(false)

  // Konfiguracja
  let configurationList = []
  const [collapseConfiguration, setCollapseConfiguration] = useState(false)

  const handleClick = (type) => {
    switch (type) {
      case 'machine':
        setCollapseMachine(!collapseMachine)
        break
      case 'warehouse':
        setCollapseWarehouse(!collapseWarehouse)
        break
      case 'raport':
        setCollapseRaport(!collapseRaport)
        break
      case 'configuration':
        setCollapseConfiguration(!collapseConfiguration)
        break
      default:
        break
    }
  }

  const ListItem = ({ text, path }) => (
    <li className="nav-item">
      <Link
        to={path}
        onClick={closeMobileNavbar}
        className="nav-link py-2 text-muted btn btn-link font-weight-normal"
      >
        {text}
      </Link>
    </li>
  )

  const GroupItem = ({ text, type }) => (
    <li className="nav-item">
      <Link
        onClick={() => handleClick(type)}
        className="nav-link py-2 text-muted btn btn-link font-weight-normal"
      >
        {text}
      </Link>
    </li>
  )

  return (
    <header
      ref={navbarRef}
      className={headerClassName}
      style={{
        backgroundColor: 'rgba(0, 0, 0, 0.05)'
      }}
    >
      <div className="d-flex align-items-center justify-content-between w-100">
        <div className='d-flex'>

          <button
            className="mr-2 d-none d-lg-flex btn p-2"
            style={{ fontSize: '1.25rem' }}
            onClick={toggleSidebar}
          >
            <i className="fas fa-bars text-muted" />
          </button> 
          <p className="mb-0 font-weight-bolder">{text}</p>
          {subtext && (
            <span className="ml-2 font-weight-bolder align-self-end">{subtext}</span>
          )}
        </div>
        <div>
          <span className="ml-2 font-weight-bolder d-none d-lg-flex align-self-end mr-3">{customerName && customerName}</span>
        </div>
        <button
          className="ml-auto d-flex d-lg-none btn"
          onClick={mobileNavbar ? closeMobileNavbar : openMobileNavbar}
        >
          <i className="fas fa-bars text-muted" />
        </button>
      </div>
      {mobileNavbar && (
        <nav className="d-lg-none collapse navbar-collapse px-3 pb-2 show">
          <ul className="navbar-nav ml-auto">
            {sessionStorage.getItem('DB_TYPE') === 'mssql' && (
              <ListItem text={TRL_Pack.navbar.start} path={'/start'} icon={'fas fa-play-circle'}></ListItem>
            )}
            {navlinks.map((navlink, idx) => (
              navlink.group === 'machine' && (machineList.push(<ListItem key={idx} {...navlink} />)),
              navlink.group === 'warehouse' && (warehouseList.push(<ListItem key={idx} {...navlink} />)),
              navlink.group === 'raport' && (raportList.push(<ListItem key={idx} {...navlink} />)),
              navlink.group === 'configuration' && (configurationList.push(<ListItem key={idx} {...navlink} />)),
              navlink.group == undefined && (list.push(<ListItem key={idx} {...navlink} />)),
              null
            ))}

            {machineList.length > 0 && (<GroupItem text={TRL_Pack.navbar.machines} type={'machine'} collapsed={collapseMachine} icon={'fas fa-tablet-alt'} onClick={() => setCollapseMachine(!collapseMachine)} />)}
            {collapseMachine ? (machineList) : (null)}

            {warehouseList.length > 0 && (<GroupItem text={TRL_Pack.navbar.warehouse} type={'warehouse'} collapsed={collapseWarehouse} icon={'fas fa-tablet-alt'} onClick={() => setCollapseWarehouse(!collapseWarehouse)} />)}
            {collapseWarehouse ? (warehouseList) : (null)}

            {raportList.length > 0 && (<GroupItem text={TRL_Pack.navbar.raports} type={'raport'} collapsed={collapseRaport} icon={'fas fa-tablet-alt'} onClick={() => setCollapseRaport(!collapseRaport)} />)}
            {collapseRaport ? (raportList) : (null)}

            {configurationList.length > 0 && (<GroupItem text={TRL_Pack.navbar.config} type={'configuration'} collapsed={collapseConfiguration} icon={'fas fa-tablet-alt'} onClick={() => setCollapseConfiguration(!collapseConfiguration)} />)}
            {collapseConfiguration ? (configurationList) : (null)}
            {list}
          </ul>
        </nav>
      )}
    </header>
  )
}
