import React, { useEffect, useState, useContext } from 'react'
import useFetch from '../../hooks/fetchMSSQL-hook'
import { Link, Prompt, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import moment from 'moment'
import { NavigationContext } from '../../context/navigation-context'
import icon from '../../assets/images/back_to_list_icon.svg'


export default function PA() {
    const [PA, setPA] = useState({})
    const [items, setItems] = useState([])
    const [initialItems, setinitialItems] = useState([])
    const [changed, setChanged] = useState(false)
    const { setHeaderData } = useContext(NavigationContext)
    const {fetchMssqlApi} = useFetch()
    const param = useParams()
    const getPA = () => fetchMssqlApi(`pa-pas/${param.id}`, {}, res => setPA(res))
    const getItems = () => fetchMssqlApi(`pa-pas/items/${param.id}`, {}, res => setinitialItems(res))
    useEffect(() => {
        setHeaderData({text: "Sprzedaż detaliczna"})
        getPA()
        getItems()
    }, [])
    useEffect(() => {
        setItems(initialItems)
    }, [initialItems])
    const deleteItem = id => {
        setItems(prev => prev.map(item => item.WarehouseOperationItemId === id ? ({...item, deleted: true}) : item))

    }
    const getBruttoItem = ({Price, Quantity}) =>  (Number(Quantity) * Number(Price)).toFixed(2) || 0
    const totalBruttoSum = () => items && (items.map(item => !item.deleted && getBruttoItem(item))).reduce((a, b) => +a + +b, 0)
    const handleSubmit = () => {
        const payload = {
            Total: totalBruttoSum(),
            Items: items.filter(item => item.deleted).map(item => item.WarehouseOperationItemId),
            WarehouseOperationId: PA.WarehouseOperationId,
        }
        console.log(payload)
        fetchMssqlApi(`pa-pas/items/${PA.WarehouseOperationId}`, {method: "DELETE", data: payload}, res => {
            
            setChanged(false)
            setItems(prev => prev.filter(item => !item.deleted))
            getPA()
        }) 
    }
    return (
        PA && (
        <div>
            <Prompt when={changed} message="Istnieją niezapisane zmiany, czy na pewno chcesz kontynuować?"/>
            <div className='container-xl'>
            {/* <Link to='/pas'>
                <button onClick={() => window.history.back()} className="btn btn-link ml-1 text-decoration-none mb-3">
                    <i className="fas fa-arrow-left mr-2" />
                    Wróć
                </button>
            </Link> */}
            <Link to='/pas'>
                    <label to='back' className="btn btn-link ml-1 text-decoration-none mb-3">
                        <img id='back' src={icon}></img>
                        {/* Wróć */}
                    </label>
                </Link>
            <div className='card'>
                <div className='card-header'>
                    <div className='h5'>
                        {PA.PA}
                    </div>
                </div>
                {/* <div className='card-body'> */}

                    <table className="table table-striped">
                        <tbody>
                        <tr className='justify-content-center'>
                        {/* <td>Numer</td> */}
                        <td className='text-center'>Data wystawienia</td>
                        <td className='text-center'>Stworzony</td>
                        <td className='text-center'>Automat</td>
                        <td className='text-center'>Wartość brutto</td>
                        </tr>


                        <tr >
                        {/* <td style={{minHeight:"100px"}}>{PA.PA}</td> */}
                        <td className='text-center'>{moment(PA.IssuedOn).format("DD.MM.YYYY")}</td>
                        <td className='text-center'>{PA.Created && `${moment(PA.Created.split(" ")[0]).format("DD.MM.YYYY")} ${PA.Created.split(" ").at(-1)}`}</td>
                        <td className='text-center'>{PA.MachineFullName}</td>
                        <td className='text-center'>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(totalBruttoSum().toFixed(2)) || "0,00zł"}</td>
                        </tr>


                        <tr className='justify-content-center'>
                        <td className='text-center'>Dokument magazynowy</td>
                        <td colSpan={2} className='text-center'>Komentarz / dodatkowe informacje</td>
                        <td className='text-center'>Wizyta</td>
                        </tr>


                        <tr className='justify-content-center'>
                        <td>
                                <div className='text-center mt-1'>
                                    <Link
                                    to={`/wz/${PA.WZWarehouseOperationId}`}
                                        className="btn btn-outline-danger"
                                        // disabled
                                    >
                                        {PA.WZ}
                                    </Link>
                                </div>
                        </td>
                        <td colSpan={2} className='text-center'>
                        <textarea
                            value={PA.Comment}
                            cols={50}
                            rows={3}
                            style={{resize: "none"}}
                            disabled/>
                        </td>
                        <td>
                        <div className='text-center mt-1'>
                                    <button
                                        className="btn btn-outline-primary"
                                        // disabled
                                        onClick={() => {}}
                                    >
                                        {PA.WI}
                                    </button>
                                </div>
                        </td>
                        </tr>
                        {
                            PA.WarehouseInventoryId && (
                        <tr className='justify-content-center'>
                            <td className='text-center' colSpan={4}>Inwentaryzacja</td>
                        </tr>)}
                        {
                            PA.WarehouseInventoryId && (
                            <tr className='justify-content-center'>
                                <td colSpan={4}>
                                    <div className='text-center mt-1'>
                                        <Link
                                        to={`/in/${PA.WarehouseInventoryId}`}
                                            className="btn btn-outline-success"
                                            // disabled
                                        >
                                            {PA.WarehouseInventory}
                                        </Link>
                                    </div>
                                </td>
                            </tr>
                            )
                        }


                        </tbody>
                    </table>
                {/* </div> */}
            </div>

                <div className='card' style={{marginBottom: "40px", marginTop: "40px"}}>
                {items.length ?
                ( <table className="table table-striped ">
                    <thead>
                        <tr>
                            <th>Produkt</th>
                            <th>Ilość</th>
                            <th>Cena brutto</th>
                            <th>Sprężyna / nr wyboru</th>
                            <th>
                                {!PA.WarehouseInventoryId && (
                                    <div className="d-flex justify-content-end">
                                        <button className="btn btn-sm btn-outline-danger"
                                            onClick={() => {
                                                setChanged(true)
                                                setItems(prev => prev.map(item => ({...item, deleted: true})))
                                            }}
                                        >
                                            Usuń wszystkie
                                        </button>
                                    </div>
                                )}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {items.map((item, idx) => 
                        !item.deleted && 
                        (
                            <tr key={idx}>
                                <td>{item.Name}</td>
                                <td>{item.Quantity}</td>
                                <td>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(item.Price.toFixed(2)) || "0,00zł"}</td>
                                <td>{item.MachineFeederNo}</td>
                                <td>
                                {!PA.WarehouseInventoryId && (
                                    <div className='d-flex justify-content-end'>
                                        
                                    <button className="btn btn-link btn-sm" 
                                        onClick={() => {setChanged(true); deleteItem(item.WarehouseOperationItemId)}}
                                    >
                                    <i className="fas fa-trash" />                                
                                    </button>
                                    </div>
                                )}
                                </td>
                                {/* <td>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(item.) || "0,00zł"}</td> */}
                            </tr>
                        ))}
                        
                    </tbody>
                    </table>)
                    : 
                    <div className='card'>
                        <div className='d-flex justify-content-center align-items-center card-header'>
                            Paragon nie ma produktów
                        </div>
                    </div>
                }
                </div>
                
            </div>
            <div className='position-fixed container' style={{bottom: "0px"}}>

                    <div className="card-footer text-center fixed-bottom" style={{backgroundColor:"#cbcbcb"}}>
                        <button disabled={!changed} className={`btn btn-primary w-20  btn-sm`} onClick={handleSubmit}>
                            Zapisz
                        </button>
                    </div>
                </div>
        </div>

        )
    )
}
