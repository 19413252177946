import React, {useState, useEffect, useContext} from 'react'
import { NavigationContext } from '../../../context/navigation-context'
import PrepaidCards from './PrepaidCards'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import PrepaidGroup from './PrepaidGroups'
import PrepaidsTransactionns from './PrepaidTransactions'


export default function WorkerCards() {
    const { setHeaderData } = useContext(NavigationContext),
    history = useHistory(),
    params = useParams()

    const getType = () => {
        if (params.type) {
            switch(params.type) {
                case "cards": 
                    return 0
                case "groups": 
                    return 1;
                case "transactions":
                    return 2
            }
        } else return 0
    } 

    const [type, setType] = useState(getType())

    const changeType = (typeId) => {
        setType(typeId)
        switch(typeId) {
            case 0: 
                history.replace("/prepaids/cards")
                break;
            case 1: 
                history.replace('/prepaids/groups')
                break;
            case 2:
                history.replace('/prepaids/transactions')
                break;
        }
    }

    useEffect(() => {
        changeType(type)
        setHeaderData({text: "Karty prepaid"})
    }, [])
    return (
        <div>
            <div>
                <ul className="nav nav-tabs machine-tabs mb-3">
                    <li className="nav-item">
                    <button
                        className={`nav-link btn ${type === 0 && 'active'}`}
                        onClick={() => type !== 0 && changeType(0)}
                        tabIndex="0"
                    >
                        Karty
                    </button>
                    </li>
                    <li className="nav-item">
                        <button
                        className={`nav-link btn ${type === 1 && 'active'}`}
                        onClick={() => type !== 1 && changeType(1)}
                        tabIndex="0"
                        >
                            Grupy
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                        className={`nav-link btn ${type === 2 && 'active'}`}
                        onClick={() => type !== 2 && changeType(2)}
                        tabIndex="0"
                        >
                            Transakcje
                        </button>
                    </li>
                </ul>
            </div>
            {type === 0 && <PrepaidCards/>}
            {type === 1 && <PrepaidGroup/>}
            {type === 2 && <PrepaidsTransactionns/>}
        </div>
    )
}
