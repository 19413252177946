import React, { useState, useEffect, Fragment, useContext } from 'react'
import { LangContext } from '../../context/lang-context'
import useForm from '../../hooks/form-hook'
import FormSkel from '../Modals/FormSkel'
import useFetch from '../../hooks/fetchMSSQL-hook'
import GetId from './filterFunctions/queryGenerator'
import { useReducer } from 'react'
var moment = require('moment')

export default ({ filter, setFilter, columns, data, resetPage, resetFilter, search, toggleFilter, enableMachineTags,  }) => {
  const { TRL_Pack } = useContext(LangContext)


  
  const [tags, setTags] = useState([])
  const [machines, setMachines] = useState([])
  const [users, setUsers] = useState([])
  const { fetchMssqlApi } = useFetch()
  const [locations, setLocations] = useState([])
  const [machineTypes, setMachineTypes] = useState([])
  const [warehouses, setWarehouses] = useState([])
  const [roles, setRoles] = useState([])
  const [maintenances, setMaintenances] = useState([])
  const [vats, setVats] = useState([])
  const [groups, setGroups] = useState([])
  const [cashRegisters, setCashRegisters] = useState([])
  const [fiscalDocsType, setFiscalDocsType] = useState([])
  const [recipes, setRecipes] = useState([])

  useEffect(() => {
    switch(filter.name) {
      case "recipes":
        getRecipes()
        getVAT()
        break;
      default:
        getTags()
        getUsers()
        getMachines()
        getLocations()
        getMachinetypes()
        getWarehouses()
        getRoles()
        getMaintenances()
        getVAT()
        getGroups()
        if (filter?.name === ("cashRegistersReport")) {
          getCashRegisters()
          getFiscalDocsType()
        }
    }
  }, [])



  const machinesProps = Object.values(TRL_Pack.machines.properties).map(prop => prop.replaceAll(" ", "").replace("Name", "").toLowerCase())
  const namePropsMap = new Map()
  for (const prop in TRL_Pack.machines.properties) {
    const word = prop.charAt(0).toUpperCase() + prop.slice(1)
    namePropsMap.set( TRL_Pack.machines.properties[prop], word)
  }
  const getMaintenances = () => {fetchMssqlApi('/maintenance', {}, maintenance => setMaintenances(maintenance) )}

  const getMachines = () => {
    fetchMssqlApi('machines-list', {}, machines => setMachines(machines))
  }

  const getUsers = () => {
    fetchMssqlApi('users-list', {}, users => setUsers(users))
  }

  const getTags = () => {
    fetchMssqlApi('tags', {}, tags =>
      setTags(tags.machine.filter(tag => tag.options.length > 0))
    )
  }
  const getVAT = () => {
    fetchMssqlApi('vats', {}, vats => setVats(vats))
  }
  const getLocations = () => {
    fetchMssqlApi('locations', {}, locations => setLocations(locations))
  }
  const getMachinetypes = () => {
    fetchMssqlApi('/machine-types', {}, machineTypes => setMachineTypes(machineTypes))}
  const getWarehouses = () => {
    fetchMssqlApi('/warehouses-list', {}, warehouses => setWarehouses(warehouses))
  }
  const getRoles = () => {
    fetchMssqlApi('/roles', {}, roles => setRoles(roles))
  }
  const getGroups = () => {
    fetchMssqlApi('products/groups', {}, groups => setGroups(groups))
  }
  const getCashRegisters = () => {
    fetchMssqlApi('cashRegisters/null', {}, crs => setCashRegisters(crs)) 
  }
  const getFiscalDocsType = () => {
    fetchMssqlApi('cashRegisters/reports/types', {}, types => setFiscalDocsType(types)) 
  }

  const handleChangeCashRegisterReportType = (evt) => {
    const {name, value} = evt.target

    const type = fiscalDocsType.find(type => type.Name === value)
    if (type) {
      setFilter(prev => ({
        ...prev,
        columns: prev.columns.map(col => col.name === name 
          ? ({...col, value: type.FiscalDocTypeId, searchbar: value}) 
          : col
        )
      }))
    } else {
      setFilter(prev => ({
        ...prev,
        columns: prev.columns.map(col => col.name === name 
          ? ({...col, searchbar: value})
          : col
        )
      }))
    }
  } 
  const handleChangeCashRegister = (evt) => {
    const {name, value} = evt.target

    const type = cashRegisters.find(cr => cr.NrUnik === value)
    if (type) {
      setFilter(prev => ({
        ...prev,
        columns: prev.columns.map(col => col.name === name 
          ? ({...col, value: type.CashRegisterId, searchbar: value}) 
          : col
        )
      }))
    } else {
      setFilter(prev => ({
        ...prev,
        columns: prev.columns.map(col => col.name === name 
          ? ({...col, searchbar: value})
          : col
        )
      }))
    }
  }
  const handleChange = evt => {
    const { name, value, type, checked } = evt.target

    // resetPage()

    if (type === 'checkbox') setFilter(prev => ({ ...prev, [name]: checked }))

    else setFilter(prev => ({ ...prev, [name]: value }))
    if (resetPage) resetPage()
  }

  const handleChangeSelectbar = evt => {
    const { name, value } = evt.target
    
    resetPage()
    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.selectbar = value
        return col
      })
    }))
  }

  const handleChangeDateTimeRangeToday = evt => {
    const { name, value, text } = evt.target

    resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "datetimeRange") {
          col.dateTimeRangeFrom = moment().format('YYYY-MM-DDT00:00:00');
          col.dateTimeRangeTo = moment().format('YYYY-MM-DDT23:59:59');
        }
        return col
      })
    }))
  }
  const handleChangeDateTimeRangeLastWeek = evt => {
    const { name, value, text } = evt.target

    resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "datetimeRange") {
          col.dateTimeRangeFrom = moment().add(-7, 'd').format('YYYY-MM-DDT00:00:00');
          col.dateTimeRangeTo = moment().format('YYYY-MM-DDT23:59:59');
        }
        return col
      })
    }))
  }
  const handleChangeDateTimeRangePreviousWeek = evt => {

    resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "datetimeRange") {
          col.dateTimeRangeFrom = moment(moment().add(-1, 'w')).startOf('week').format('YYYY-MM-DDT00:00:00');
          col.dateTimeRangeTo = moment(moment().add(-1, 'w')).endOf('week').format('YYYY-MM-DDT23:59:59');
        }
        return col
      })
    }))
  }
  const handleChangeDateTimeRangeLastMonth = evt => {
    const { name, value, text } = evt.target

    resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "datetimeRange") {
          col.dateTimeRangeFrom = moment().add(-1, 'M').format('YYYY-MM-DDT00:00:00');
          col.dateTimeRangeTo = moment().format('YYYY-MM-DDT23:59:59');
        }
        return col
      })
    }))
  }
  const handleChangeDateTimeRangePreviousMonth = evt => {

    resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "datetimeRange") {
          col.dateTimeRangeFrom = moment(moment().add(-1, 'M')).startOf("month").format('YYYY-MM-DDT00:00:00');
          col.dateTimeRangeTo = moment(moment().add(-1, 'M')).endOf("month").format('YYYY-MM-DDT23:59:59');
        }
        return col
      })
    }))
  }

  const handleChangeDateRangeLastWeek = evt => {
    const { name, value, text } = evt.target

    resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "dateRange") {
          col.dateTimeRangeFrom = moment().add(-7, 'd').format('YYYY-MM-DD');
          col.dateTimeRangeTo = moment().format('YYYY-MM-DD');
        }
        return col
      })
    }))
  }
  const handleChangeDateRangeLastMonth = evt => {
    const { name, value, text } = evt.target

    resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "dateRange") {
          col.dateTimeRangeFrom = moment().add(-1, 'M').format('YYYY-MM-DD');
          col.dateTimeRangeTo = moment().format('YYYY-MM-DD');
        }
        return col
      })
    }))
  }

  const filterByDate = machine => {
    // const date = moment().format('YYYY-MM-DDT00:00:00').split('T')
    // const endDate = moment().format('YYYY-MM-DDT23:59:59').split('T')
    // const corDate = machine.VisitCreated.split(' ')
    // return (corDate[0] === endDate[0] && corDate[1] < endDate[1] && corDate[1] > date[1]) ? true : false
    // filter.columns.
    // filter(col => col.type === "datetimeRange").
    // every(col => {
    //   if(col.VisitCreated.split(" ")[0] === moment().format('YYYY-MM-DD') 
    //   && col.VisitCreated.split(" ")[1] < "23:59:59" 
    //   && col.VisitCreated.split(" ")[1] > "00:00:00") Object.keys(machine)[col.id - 1]
    // })

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "datetimeRange") {
          col.dateTimeRangeFrom = moment().format('YYYY-MM-DDT00:00:00');
          col.dateTimeRangeTo = moment().format('YYYY-MM-DDT23:59:59');
        }
        return col
      })
    }))

  }



  const handleChangeDateTimeRangeFrom = evt => {
    const { value } = evt.target
    
    resetPage()
    
    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "datetimeRange") {
          col.dateTimeRangeFrom = value;
        }
        return col
      })
    }))
  }

  const handleChangeDateTimeRangeTo = evt => {
    const { value } = evt.target

    resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "datetimeRange") {
          col.dateTimeRangeTo = value;
        }
        return col
      })
    }))
  }
  const handleChangeDateRangeFrom = evt => {
    const { value } = evt.target
    
    resetPage()
    
    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "dateRange") {
          col.dateTimeRangeFrom = value;
        }
        return col
      })
    }))
  }

  const getRecipes = () => {
    fetchMssqlApi("/recipes", {}, rec => setRecipes(rec))
  }
  const handleChangeDateRangeTo = evt => {
    const { value } = evt.target

    resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "dateRange") {
          col.dateTimeRangeTo = value;
        }
        return col
      })
    }))
  }
  const handleChangeDateRangeWhenever = evt => {
    const { name, value, text } = evt.target

    resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "dateRange") {
          col.dateTimeRangeFrom = moment().add(-20, 'y').format('YYYY-MM-DD');
          col.dateTimeRangeTo = moment().format('YYYY-MM-DD');
        }
        return col
      })
    }))
  }
  const handleChangeDateTimeRangeWhenever = evt => {
    const { name, value, text } = evt.target

    resetPage()

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.type === "datetimeRange") {
          col.dateTimeRangeFrom = moment().add(-20, 'y').format('YYYY-MM-DDT00:00:00');
          col.dateTimeRangeTo = moment().format('YYYY-MM-DDT23:59:59');
        }
        return col
      })
    }))
  }

  const handleChangeMachine = evt => {
    const { name, value} = evt.target
    let id = machines.filter(machine => machine.Name === value)[0]?.MachineId
    resetPage()
    if (name) {
      setFilter(prev => ({
        ...prev,
        columns: prev.columns.map( col => {
          if(col.name === name) {col.value = value
          col.MachineId = id}
          return col})
      }))
    }
  }
  const handleClickInput = evt => {
    const { name} = evt.target
    evt.target.value = ""
    if (name) {
      setFilter(prev => ({
        ...prev,
        columns: prev.columns.map( col => {
          if(col.name === name) {
            console.log(this)
            col.value = ""
          }
          return col
        })
      }))
    }
  }
  const handleChangeMachines = evt => {
    const { name, value} = evt.target
    resetPage()
    if (name) {
      setFilter(prev => ({
        ...prev,
        columns: prev.columns.map( col => {
          if(col.name === name) col.value = value
          return col})
      }))
    }}
  const handleChangeLocalisation = evt => {
    const { name, value } = evt.target
    resetPage()
    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.value = value;

        return col
      })
    }))
  }
  const handleChangeRecipe = evt => {
    const { name, value } = evt.target
    resetPage()
    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.value = value;

        return col
      })
    }))
  }

  const handleChangeCreateUser = evt => {
    const { name, value } = evt.target

    resetPage()

    let createUserId = null;

    if (value !== '' && users.find(obj => obj.Name === value) !== undefined)
      createUserId = users.find(obj => obj.Name === value).UserId;

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.CreateUserId = createUserId;

        return col
      })
    }))
  }
  const handleChangeInputs = evt => {
    const { name, value } = evt.target

    // resetPage()
    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) {col.searchbar = value};
        return col
      })
    }))
  }
  const handleChangeVatOrGroup = evt => {
    const { name, value } = evt.target

    resetPage()
    if (name === "Stawka VAT") {

      setFilter(prev => ({
        ...prev,
        columns: prev.columns.map(col => {
          if (col.name === name) {col.value = String(vats.find(vat => vat.Code === value.replace("%", ""))?.VATId) || null
        col.searchbar = value};
          return col
        })
      }))
    } else {
      setFilter(prev => ({
        ...prev,
        columns: prev.columns.map(col => {
          if (col.name === name) {col.value = groups.find(group => group.Name === value)?.ProductGroupId || null
        col.searchbar = value};
          return col
        })
      }))
    }
  }


  const handleFocusMachine = evt => {
    const { name, value } = evt.target

    resetPage()


    evt.target.value = '';

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) {col.value = null
        col.MachineId = null};

        return col
      })
    }))
  }
  const handleChangeTerminals = evt => {
    const {name, value} = evt.target
    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if(col.name === name) col.value = value
        return col
      })
    }))
  }
  const handleFocusMachines = evt => {
    const { name, value } = evt.target

    resetPage()


    evt.target.value = '';

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.value = null;

        return col
      })
    }))
  }
  const handleChangeMachinesFilter = (name ,param, value) => {
      //console.log(name, param, value)
      resetPage()
      setFilter(prev => ({
        ...prev,
        columns: prev.columns.map(col => {
          if (col.name === name) col[param] = value
          return col
        })
      }))
  }
  const handleChangeInput = (evt) => {
      const {name , value} = evt.target
      setFilter(prev => ({
        ...prev,
        columns: prev.columns.map(col => {
          if (col.name === name) col.value = value
          return col
        })
      }))
  }

  // const createPayload = () => {

  // }

  const handleFocusCreateUser = evt => {
    const { name, value } = evt.target

    resetPage()

    let machineId = null;
    evt.target.value = '';

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) col.CreateUserId = null;

        return col
      })
    }))
  }
  const handleFocusCleaner = evt => {
    const { name, value } = evt.target

    // resetPage()

    evt.target.value = '';

    setFilter(prev => ({
      ...prev,
      columns: prev.columns.map(col => {
        if (col.name === name) {col.value = null
        col.searchbar = null
        };

        return col
      })
    }))
  }

  const { form, openForm, closeForm } = useForm()

  const [activeLabel, setActiveLabel] = useState(null)

  const handleChangeLabel = labelIdx => () => {

    if (labelIdx === activeLabel) {
      setActiveLabel(null)
    } else if (tags[labelIdx].options.length > 0) {
      setActiveLabel(labelIdx)
    } else {
      toggleTag(tags[labelIdx].tagId)()
      setActiveLabel(null)
    }
  }

  const toggleTag = tagId => () => {

    resetPage()

    if (filter.activeTags.includes(tagId))
      setFilter(prev => ({
        ...prev,
        activeTags: prev.activeTags.filter(tag => tag !== tagId)
      }))
    else setFilter(prev => ({ ...prev, activeTags: prev.activeTags.concat(tagId) }))
  }

  return (
    <div className="mb-4 rounded border bg-fade p-3 user-select-none">
      <div className="row px-3 d-flex justify-content-around">
        {filter.columns
          .filter(col => !col.disabled && (col.selectable || col.searchable || col.sortable || col.type === 'datetimeRange'))
          .map((col, idx) => (
            <div
              className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
              key={idx}
            >
              {col.type === "threeStateBool" && (
                <><h6 className="text-center mb-0">{col.name}</h6>
                  <select
                    className="mt-2 form-control form-control-sm"
                    defaultValue={col.selectbar}
                    name={col.name}
                    onChange={handleChangeSelectbar}
                  >
                    < option value={"null"} > {TRL_Pack.filter.unknown} </ option>
                    < option value={"true"} > {TRL_Pack.filter.yes} </ option>
                    < option value={"false"} > {TRL_Pack.filter.no} </ option>
                  </select>
                </>
              )}
              {col.type === "threeStateBoolByActivity" && (
                <><h6 className="text-center mb-0">{col.name}</h6>
                  <select
                    className="mt-2 form-control form-control-sm"
                    defaultValue={col.selectbar}
                    name={col.name}
                    onChange={handleChangeLocalisation}
                  >
                    < option value={""} > {TRL_Pack.filter.unknown} </ option>
                    < option value={"true"} > {TRL_Pack.filter.yes} </ option>
                    < option value={"false"} > {TRL_Pack.filter.no} </ option>
                  </select>
                </>
              )}
              {col.type === "lookup" && filter?.name !== "machines" && (col.name === "Maszyna" || col.name === "Automat") && (
                <>
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleFocusMachine}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={col.value || col.selectbar}
                    list={col.name}
                    name={col.name}
                    onChange={handleChangeMachine}
                    autoComplete='off'
                    // value={col.value}
                  />
                  {(<datalist id={col.name}>
                    {machines.map((machine, idx) => (
                      <option value={machine.Name} key={idx}/>
                    ))}
                  </datalist>)
                  }
                </>
              )} 
              {col.type === "lookup" &&  col.name === "Nr wyboru" && (
                <>
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleClickInput}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={col.value || ""}
                    name={col.name}
                    onChange={handleChangeInput}
                    autoComplete='off'
                    // value={col.value}
                  />
                </>
              )} 
              {col.type === "lookup" && col.name === "Typ zmiany" && (
                <>
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleClickInput}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={col.selectbar || col.searchbar}
                    list="TLookup"
                    name={col.name}
                    onChange={handleChangeInput}
                    autoComplete='off'
                  />
                  {(<datalist id="TLookup">
                    {
                    [
                    "[AUTOMAT] Zmiana nr wyboru",
                    "[AUTOMAT] Zmiana ceny",
                    "[AUTOMAT] Nowy wybór",
                    "[AUTOMAT] Usunięcie wyboru",
                    "[AUTOMAT] Zmiana stanu",
                    "[AUTOMAT] Zmiana produktu",
                    "[AUTOMAT] Zmiana pojemności",
                    "[AUTOMAT] Wybór zablokowany / Wybór odblokowany",
                    "[VD] Zmiana nr wyboru",
                    "[VD] Zmiana ceny",
                    "[VD] Nowy wybór",
                    "[VD] Usunięcie wyboru",
                    "[VD] Zmiana stanu",
                    "[VD] Zmiana produktu",
                    "[VD] Zmiana pojemności",
                    "[TRX] Zmiana ceny",
                    "[TRX] Zmiana stanu",
                    "[VM] Zmiana nr wyboru",
                    "[VM] Zmiana ceny",
                    "[VM] Nowy wybór",
                    "[VM] Usunięcie wyboru",
                    "[VM] Zmiana stanu",
                    "[VM] Zmiana produktu",
                    "[VM] Zmiana pojemności",
                    "[VM] Pobranie danych stanu automatu",
                    "[VM] Zapisanie wizyty (start)",
                    "[VM] Zapisanie wizyty (koniec)",
                    "[VD] Pobranie danych do synchronizacji przez automat",
                  ].map((entry, idx) => (
                      <option value={entry} key={idx} 
                      // onSelect={() => console.log(...entry)                      }
                      />
                    )
                    )}
                  </datalist>)
                  }
                </>
              )}
              {col.type === "lookup" && col.name === "Żródło zmiany" && (
                <>
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleClickInput}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={col.selectbar || col.searchbar}
                    list="RLookup"
                    name={col.name}
                    onChange={handleChangeInput}
                    autoComplete='off'
                  />
                  {(<datalist id="RLookup">
                    {
                    [
                    "[AUTOMAT]",
                    "[VD]",
                    "[TRX]",
                    "[VM]",].map((entry, idx) => (
                      <option value={entry} key={idx}/>
                    )
                    )}
                  </datalist>)
                  }
                </>
              )}
              {col.type === "lookup" && filter?.name === "machines"  && (
                <>
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleFocusMachines}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={col.value || col.selectbar}
                    list={col.name}
                    name={col.name}
                    onChange={handleChangeMachines}
                    autoComplete='off'
                    // value={col.value}
                  />
                  {(<datalist  id={col.name}>
                    {(col.name === TRL_Pack.machines.properties.machineName && machines
                    || col.name === TRL_Pack.machines.properties.serialNo && machines
                    || col.name === TRL_Pack.machines.properties.location && [...locations].filter(location => location.IsActive)
                    || col.name === TRL_Pack.machines.properties.machineType && machineTypes
                    || col.name === TRL_Pack.machines.properties.maintenance && [...maintenances].filter(maintance => maintance.IsActive) )
                    .map((entry, idx) => (
                      col.name === TRL_Pack.machines.properties.serialNo ? <option value={entry.SerialNo} key={idx}/> 
                      :<option value={entry.Name} key={idx}/>
                    ))}
                  </datalist>)
                  }
                </>
              )}
              {col.type === "lookup" && filter?.name !== "machines" && col.name === TRL_Pack.machines.properties.serialNo  && (
                <>
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleFocusMachines}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={col.value || col.selectbar}
                    list={col.name}
                    name={col.name}
                    onChange={handleChangeMachines}
                    autoComplete='off'
                    // value={col.value}
                  />
                  {(<datalist  id={col.name}>
                    {
                    machines.map((entry, idx) => (
                      col.name === TRL_Pack.machines.properties.serialNo ? <option value={entry.SerialNo} key={idx}/> 
                      :<option value={entry.Name} key={idx}/>
                    ))}
                  </datalist>)
                  }
                </>
              )}
              {col.type === "lookup" && filter.name === "terminals" && (
                <>
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleFocusMachine}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={ col.value || ""}
                    list={col.name}
                    name={col.name}
                    onChange={handleChangeTerminals}
                    autoComplete='off'
                  />
                  {(<datalist id={col.name}>
                    {/* {(col.name === "Automat" && data.machines || col.name === "Terminal" && data.terminals).map((entry, idx) => (
                      (col.name === "Automat") &&
                      <option value={`${entry.SerialNo} (${entry.Name})`} key={idx} onSelect={() => console.log(...entry)}/> 
                      ||
                      (col.name === "Terminal") 
                      && <option  value={`${entry.SerialNo} ${entry.TerminalTypeName}  `} key={idx}/>
                    )
                    )} */}
                    {col.name === "Automat" && data.machines?.length && data.machines.map((entry, idx) => (
                        <option value={`${entry?.MachineName ? `${entry?.MachineName}` : ``} (${entry.MachineSerialNo})`} key={idx} 
                        // onSelect={() =>  console.log(...entry)}
                        /> 
                      ))
                    || 
                    col.name === "Terminal" && data.terminalsList?.length && data.terminalsList.map((entry, idx) => (
                        <option value={entry.Name} key={idx} 
                        // onSelect={() =>  console.log(...entry)}
                        /> 
                      ))
                    }
                  </datalist>)}
                </>
              )}
              {col.type === "lookup" && col.name === "Lokalizacja" && filter?.name === "locations" && (
                <>
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleFocusMachine}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={col.selectbar || col.searchbar}
                    list="LocationsLookup"
                    name={col.name}
                    onChange={handleChangeLocalisation}
                    autoComplete='off'
                  />
                  {(<datalist id="LocationsLookup">
                    {data.map((entry, idx) => (
                      <option value={entry.Name} key={idx} 
                      // onSelect={() => console.log(...entry)                      }
                      />
                    )
                    )}
                  </datalist>)
                  }
                </>
              )}
              {col.type === "lookup" && col.name === "Lokalizacja" && filter?.name !== "locations" && filter?.name !== "machines" && (
                <>
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleFocusMachine}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={col.selectbar || col.searchbar}
                    list="LocationsLookup"
                    name={col.name}
                    onChange={handleChangeLocalisation}
                    autoComplete='off'
                  />
                  {(<datalist id="LocationsLookup">
                    {locations && locations.map((entry, idx) => (
                      <option value={entry.Name} key={idx} 
                      // onSelect={() => console.log(...entry)                      }
                      />
                    )
                    )}
                  </datalist>)
                  }
                </>
              )}
              {col.type === "lookup" && col.name === "Serwisant" &&  (
                <>
                {/* {console.log(...users.filter(user => user.UserId === col.CreateUserId).Name)} */}
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleFocusCreateUser}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={ col.searchbar}
                    list="CreateUsersLookup"
                    name={col.name}
                    onChange={handleChangeCreateUser}
                    autoComplete='off'
                  />
                  {(<datalist id="CreateUsersLookup">
                    {users.map((entry, idx) => (
                      <option value={entry.Name} key={idx} />
                    )
                    )}
                  </datalist>)
                  }
                </>
              )}
              {
                col.type === "lookup" && filter.Name === "consoleMachines" && (
                  <>
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleFocusCleaner}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={ col.searchbar}
                    list={col.name}
                    name={col.name}
                    onChange={handleChangeInputs}
                    autoComplete='off'
                  />
                  {(<datalist id={col.name}>
                    {col.name === "Automat" && <datalist id={col.name}>
                        {data && data.map((machine, idx) => <option key={idx} value={`${machine.MachineSerialNo} (${machine.MachineName})`}/>)}
                      </datalist>}
                    {col.name === "Klient" && <datalist id={col.name}>
                        {data && data.map((machine, idx) => <option key={idx} value={machine.CustomerName}/>)}
                      </datalist>}
                  </datalist>)}
                  </>
                  
                )
              }
              {col.type === "lookup" && filter?.name === "products" && col.name !== "Stawka VAT" && col.name !== "Grupa" && (
                <>
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleFocusCleaner}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={ col.value}
                    list={col.name}
                    name={col.name}
                    onChange={handleChangeLocalisation}
                    autoComplete='off'
                  />
                  <datalist id={col.name}>
                    {col.name === "EAN" && data.map((product, idx) => (
                      <option value={product.EAN} key={idx}/>
                    ))} 
                    {col.name === "Nazwa" && data.map((product, idx) => (
                      <option value={product.Name} key={idx}/>
                    ))}
                  </datalist>
                </>
              )}
              {col.type === "lookup" && filter?.name === "recipes" && (col.name === "EAN" || col.name === "Nazwa") && (
                <>
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleFocusCleaner}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={ col.value}
                    list={col.name}
                    name={col.name}
                    onChange={handleChangeRecipe}
                    autoComplete='off'
                  />
                  <datalist id={col.name}>
                    {col.name === "EAN" && recipes.map((product, idx) => (
                      <option value={product.EAN} key={idx}/>
                    ))} 
                    {col.name === "Nazwa" && recipes.map((product, idx) => (
                      <option value={product.Name} key={idx}/>
                    ))}
                  </datalist>
                </>
              )}
              {col.type === 'lookup' && (col.name === "Stawka VAT" || col.name === "Grupa") && (
                <>
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleFocusCleaner}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={col.searchbar || ""}
                    list={col.name}
                    name={col.name}
                    onChange={handleChangeVatOrGroup}
                    autoComplete='off'
                  />
                  <datalist id={col.name}>
                  
                    {col.name === "Stawka VAT" && vats.map((vat, idx) => (
                      <option value={`${vat.Code}%`} key={idx}/>
                    ))}
                    {col.name === "Grupa" && groups.map((group, idx) => group.IsActive && group.ConnectedProduct && (
                      <option value={group.Name} key={idx}/>
                    ))}
                  </datalist>
                </>
              )}
              {col.type === "lookup" && filter.name === "users" &&  (
                <>
                {/* {console.log(...users.filter(user => user.UserId === col.CreateUserId).Name)} */}
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleFocusCleaner}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={ col.searchbar}
                    list={col.name}
                    name={col.name}
                    onChange={handleChangeInputs}
                    autoComplete='off'
                  />
                  {(<datalist id={col.name}>
                    {( col.name === "Nazwa" && users
                    ||col.name === "Magazyn" && warehouses || col.name === "Rola" && roles)
                    .map((entry, idx) => (
                      // (col.name !== "Imię") 
                      // ? 
                      <option value={entry.Name} key={idx}/>
                      // : <option value={`${entry.Name} (${entry.Email})`} key={idx}/>
                    ))} 
                    {/* {col.name === "Imię" && data.filter(user => user.IsActive === true)
                    .map((entry, idx) => (
                      <option value={entry.Name} key={idx}/>
                    ))} */}
                  </datalist>)
                  }
                </>
              )}
              {(filter?.name === "mms" || filter?.name === "warehouses") && col.name === "Magazyn" && (
                <>
                <h6 className="text-center mb-0">{col.name}</h6>
                <input
                  onFocus={handleFocusCleaner}
                  className="mt-2 form-control form-control-sm"
                  placeholder={TRL_Pack.searchbarPlaceholder}
                  defaultValue={ col.searchbar}
                  list={col.name}
                  name={col.name}
                  onChange={handleChangeInputs}
                  autoComplete='off'
                />
                  <datalist id={col.name}>
                    { warehouses
                    .map((entry, idx) => (
                      <option value={entry.Name} key={idx}/>
                    ))} 
                  </datalist>
                </>
              )} 
              {col.type === "lookup" && filter?.name === "fzs" && (
                <>
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleFocusCleaner}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={ col.searchbar}
                    list={col.name}
                    name={col.name}
                    onChange={handleChangeInputs}
                    autoComplete='off'
                  />
                  {(<datalist id={col.name}>
                    {( col.name === "Kontrahent" && data?.contractors
                    ||col.name === "Magazyn" && warehouses)
                    .map((entry, idx) => (
                      <option value={entry.Name} key={idx}/>
                    ))} 
                  </datalist>)}
                </>
              )}
              {col.name === "Nr unikatowy" && filter?.name === "cashRegistersReport" && (
                  <>
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleFocusCleaner}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={ col.searchbar}
                    value={col.searchbar}
                    list={col.name}
                    name={col.name}
                    onChange={handleChangeCashRegister}
                    autoComplete='off'
                  />
                  <datalist id={col.name}>
                    {cashRegisters.length && cashRegisters.map((cr, idx) => (
                      <option value={cr.NrUnik} key={idx}/>
                    ))}
                  </datalist>
                </>
              )}
              {col.name === "Typ dokumentu" && filter?.name === "cashRegistersReport" && (
                  <>
                  <h6 className="text-center mb-0">{col.name}</h6>
                  <input
                    onFocus={handleFocusCleaner}
                    className="mt-2 form-control form-control-sm"
                    placeholder={TRL_Pack.searchbarPlaceholder}
                    defaultValue={ col.searchbar}
                    list={col.name}
                    name={col.name}
                    onChange={handleChangeCashRegisterReportType}
                    autoComplete='off'
                  />
                  <datalist id={col.name}>
                    {fiscalDocsType.length && fiscalDocsType.map((type, idx) => (
                      <option
                        value={type.Name}
                        key={idx}
                      />
                    ))}
                  </datalist>
                </>
              )}
              {col.type === "datetimeRange" && (
                <>
                  <div className='d-flex flex-column justify-content-center'>
                    <h6 className="text-center mb-1">{col.name}</h6>
                    <div className='d-inline justify-content-center'>
                      Od <input type="datetime-local" style={{ border: "1px solid rgba(0, 0, 0, .1)", padding: ".375rem .75rem" }} className="rounded date" name={col.name + "_from"} onChange={handleChangeDateTimeRangeFrom} value={moment(col.dateTimeRangeFrom).format('YYYY-MM-DDTHH:mm:ss')}></input>
                    </div>
                    <div className='d-inline justify-content-center'>
                      Do <input type="datetime-local" style={{ border: "1px solid rgba(0, 0, 0, .1)", padding: ".375rem .75rem" }} className="rounded date" name={col.name + "_to"} onChange={handleChangeDateTimeRangeTo} value={moment(col.dateTimeRangeTo).format('YYYY-MM-DDTHH:mm:ss')} ></input>
                    </div>
                    <div className='d-inline justify-content-center'>
                      <button
                        className="m-1 btn btn-outline-primary btn-sm"
                        onClick={handleChangeDateTimeRangeToday}
                      >
                        {TRL_Pack.filter.dateRangeToday}
                      </button>
                      <button
                        className="m-1 btn btn-outline-primary btn-sm"
                        onClick={handleChangeDateTimeRangePreviousWeek}
                      >
                        {TRL_Pack.filter.dateRangePreviousWeek}
                      </button>
                      <button
                        className="m-1 btn btn-outline-primary btn-sm"
                        onClick={handleChangeDateTimeRangeLastWeek}
                      >
                        {TRL_Pack.filter.dateRangeLastWeek}
                      </button>
                      <button
                        className="m-1 btn btn-outline-primary btn-sm"
                        onClick={handleChangeDateTimeRangePreviousMonth}
                      >
                        {TRL_Pack.filter.dateRangePreviousMonth}
                      </button>
                      <button
                        className="m-1 btn btn-outline-primary btn-sm"
                        onClick={handleChangeDateTimeRangeLastMonth}
                      >
                        {TRL_Pack.filter.dateRangeLastMonth}
                      </button>
                      <button
                        className="m-1 btn btn-outline-primary btn-sm"
                        onClick={handleChangeDateTimeRangeWhenever}
                      >
                        {TRL_Pack.filter.dateRangeWhenever}
                      </button></div>
                  </div>
                </>
              )}

            {col.type === "dateRange" && (
              <>
              <div className='d-flex flex-column justify-content-center'>
                <h6 className="text-center mb-1">{col.name}</h6>
                <div className='continer'>
                  <div className='row'>
                    <div className='col-sm'>

                      <div >
                        Od <input type="date" style={{ border: "1px solid rgba(0, 0, 0, .1)", padding: ".375rem .75rem" }} className="rounded date-sm" name={col.name + "_from"} onChange={handleChangeDateRangeFrom} value={moment(col.dateTimeRangeFrom).format('YYYY-MM-DD')}></input>
                      </div>
                      <div >
                        Do <input type="date" style={{ border: "1px solid rgba(0, 0, 0, .1)", padding: ".375rem .75rem" }} className="rounded date-sm" name={col.name + "_to"} onChange={handleChangeDateRangeTo} value={moment(col.dateTimeRangeTo).format('YYYY-MM-DD')} ></input>
                      </div>
                    </div>
                    <div className='col-sm align-self-end'>
                      <div className='d-flex flex-wrap'>

                        {/* <button
                          className="m-1 btn btn-outline-primary btn-sm"
                          onClick={handleChangeDateTimeRangeToday}
                        >
                          {TRL_Pack.filter.dateRangeToday}
                        </button> */}
                        <button
                          className="m-1 btn btn-outline-primary btn-sm"
                          onClick={handleChangeDateRangeLastWeek}
                        >
                          {TRL_Pack.filter.dateRangeLastWeek}
                        </button>
                        <button
                          className="m-1 btn btn-outline-primary btn-sm"
                          onClick={handleChangeDateRangeLastMonth}
                        >
                          {TRL_Pack.filter.dateRangeLastMonth}
                        </button>
                        <button
                          className="m-1 btn btn-outline-primary btn-sm"
                          onClick={handleChangeDateRangeWhenever}
                        >
                          {TRL_Pack.filter.dateRangeWhenever}
                        </button>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </>
            )}
            </div>
          ))}
                {tags && enableMachineTags && (
                  <div className='d-flex flex-column justify-content-center' >
                  <h6 className="text-center mb-0">Tagi</h6>
                  <div className="">                                     
                    <div className="mb-n1 mt-1 d-flex flex-column justify-content-center flex-row">
                      {tags.map(tag =>
                        tag.options
                          .filter(opt => filter.activeTags.includes(opt.tagId))
                          .map(opt => (
                            <button className="btn btn-info badge badge-pill px-2 py-1 mx-2 mb-1">
                              {tag.label ? `${tag.label} - ${opt.name}` : opt.name}
                            </button>
                          ))
                      )}
                      <button
                        className="btn btn-info badge badge-pill px-2 py-1 mx-2 mb-1"
                        onClick={openForm()}
                      >
                        <i className="fas fa-bars" />
                      </button>
                    </div>
                    {form && (
                      <FormSkel
                        headerText="Tagi"
                        noFooter
                        handleClose={closeForm}
                        classes="d-flex p-0"
                      >
                        <div className="w-50 overflow-auto" style={{ maxHeight: 250 }}>
                          {tags.map((tag, idx) => (
                            <div
                              key={idx}
                              className={`font-weight-bolder list-group-item cursor-pointer ${idx === activeLabel ? 'active' : ''
                                }`}
                              onClick={handleChangeLabel(idx)}
                            >
                              {tag.others ? 'Inne' : tag.label}
                            </div>
                          ))}
                        </div>
                        {activeLabel !== null && (
                          <div className="w-50 overflow-auto" style={{ maxHeight: 250 }}>
                            {tags[activeLabel].options.map((opt, idx) => (
                              <div
                                key={idx}
                                className={`font-weight-bolder list-group-item cursor-pointer ${filter.activeTags.includes(opt.tagId)
                                  ? 'list-group-item-success'
                                  : ''
                                  }`}
                                onClick={toggleTag(opt.tagId)}
                              >
                                {opt.name}
                              </div>
                            ))}
                          </div>
                        )}
                      </FormSkel>
                    )}
                    </div>
                  </div>
                )}
      </div>
      {/* <hr className="my-2" /> */}
      {/* {tags && enableMachineTags && (
                  <>
                  <h6 className="text-center mb-0">Tagi</h6>
                  <div className="">                                     
                    <div className="mb-n1">
                      {tags.map(tag =>
                        tag.options
                          .filter(opt => filter.activeTags.includes(opt.tagId))
                          .map(opt => (
                            <button className="btn btn-info badge badge-pill px-2 py-1 mx-2 mb-1">
                              {tag.label ? `${tag.label} - ${opt.name}` : opt.name}
                            </button>
                          ))
                      )}
                      <button
                        className="btn btn-info badge badge-pill px-2 py-1 mx-2 mb-1"
                        onClick={openForm()}
                      >
                        <i className="fas fa-bars" />
                      </button>
                    </div>
                    {form && (
                      <FormSkel
                        headerText="Tagi"
                        noFooter
                        handleClose={closeForm}
                        classes="d-flex p-0"
                      >
                        <div className="w-50 overflow-auto" style={{ maxHeight: 250 }}>
                          {tags.map((tag, idx) => (
                            <div
                              key={idx}
                              className={`font-weight-bolder list-group-item cursor-pointer ${idx === activeLabel ? 'active' : ''
                                }`}
                              onClick={handleChangeLabel(idx)}
                            >
                              {tag.others ? 'Inne' : tag.label}
                            </div>
                          ))}
                        </div>
                        {activeLabel !== null && (
                          <div className="w-50 overflow-auto" style={{ maxHeight: 250 }}>
                            {tags[activeLabel].options.map((opt, idx) => (
                              <div
                                key={idx}
                                className={`font-weight-bolder list-group-item cursor-pointer ${filter.activeTags.includes(opt.tagId)
                                  ? 'list-group-item-success'
                                  : ''
                                  }`}
                                onClick={toggleTag(opt.tagId)}
                              >
                                {opt.name}
                              </div>
                            ))}
                          </div>
                        )}
                      </FormSkel>
                    )}
                    </div>
                  </>
                )} */}
<hr className="my-2" />
      <div className="row align-items-center justify-content-center">
        {filter.rowsPerPageOptions && (
          <div className="col-12 col-md-6 col-lg-4 mb-1 mt-1 d-flex align-items-center justify-content-center">
            <h6 className="mb-0">{TRL_Pack.filter.rowsPerPage}</h6>
            <select
              className="form-control form-control-sm ml-2"
              style={{ maxWidth: 100 }}
              name="rowsPerPage"
              value={filter.rowsPerPage}
              onChange={handleChange}
            >
              {filter.rowsPerPageOptions.map((num, idx) => (
                <option key={idx}>{num}</option>
              ))}
            </select>
          </div>
        )}
        {filter.sortByColumns && (
          <div className="col-12 col-md-6 col-lg-4 mb-1 mt-1 d-flex align-items-center justify-content-center ">
            <h6 className="mb-0">{TRL_Pack.filter.sortBy}</h6>
            <select
              className="form-control form-control-sm ml-2"
              style={{ maxWidth: 175 }}
              name="sortBy"
              defaultValue="none"
              // value={filter.sortBy}
              onChange={handleChange}
            >
              <option defaultValue value={""}>{TRL_Pack.products.sharedProductLackOption}</option>
              {columns
                .filter(col => col.sortable)
                .map((col, idx) => (
                  <Fragment key={idx}>
                    <option value={`${col.id} | asc | ${col.type}`}>
                      {col.name} &nbsp; &#8681;
                    </option>
                    <option value={`${col.id} | desc | ${col.type}`}>
                      {col.name} &nbsp; &#8679;
                    </option>
                  </Fragment>
                ))}
            </select>
          </div>
        )}
        <div>
        <button
          className="d-inline m-2 btn btn-primary float-right"
          onClick={toggleFilter}
        >
          {TRL_Pack.filter.closeFilter}
        </button>
        <button
          className="d-inline m-2 btn btn-primary float-right"
          onClick={resetFilter}
        >
          {TRL_Pack.filter.defaultFilter}
        </button>
        
        <button
          className="d-inline m-2 btn btn-primary float-right"
          onClick={search}
        >
          {TRL_Pack.filter.search}
        </button>
        </div>
      </div>      
    </div>
  )
}