import React, {useState, useEffect, useContext, useRef} from 'react'
import fetchHook from '../../../hooks/fetchMSSQL-hook'
import {text} from './confidencePolitics.json'
import formHook from '../../../hooks/form-hook'
import FormSkel from '../../../components/Modals/FormSkel'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { NavigationContext } from '../../../context/navigation-context'
import onClickAway from '../../../util/onClickAway'


export default function Configuration({isNew}) {

    const defaultObj = {
        Name: null
        , CodeUs: null
        , Street: null
        , Town: null
        , Local: null
        , PostDep: null
        , HomeNr: null
        , Index: null
        , US: null
    }
    const [defaultData, setDefaultData] = useState(defaultObj)
    const [data, setData] = useState({})
    const {fetchMssqlApi} = fetchHook()
    const [isInvalid, setIsInvalid] = useState(false)
    const [isAllowed, setIsAllowed] = useState(false)
    // const [showConfidence, setShowConfidence] = useState(false)
    // const [disabled, setDisabled] = useState(isNew)
    const [isChanged, setIsChanged] = useState(false)
    const [taxDepartments, setTaxDepartments] = useState(null)
    const {form, openForm, closeForm} = formHook()
    const history = useHistory()
    const {dispatch} = useContext(NavigationContext)
    const getData = () => {
        fetchMssqlApi("customerData", {}, res => {
            setDefaultData(res[0])
            setIsAllowed(res[1].IsAllowed || false)
            if (Object.values(res[0]).every(v => v) && !res[1].IsAllowed ) {
                setIsChanged(true)
            }
        })
    }
    const getTaxDepartments = () => {
        fetchMssqlApi("getTaxDepartments", {}, res => {
            setTaxDepartments(res)
        })
    }
    const regexpCodeUS = (str) => /^[0-9]{4}$/.test(str) 
    const regexpPostCode = (str) => /^\d{2}-\d{3}$/.test(str)
    // const regextPost = (str) => /^(.+)@(.+)\.(.+)$/.test(str)
    // const handleChangeStyle = ({target}, valid) => {
    //     if (!valid && !target.className.includes("form-control mt-4 invalid-input") ) {
    //         target.className += "form-control mt-4 invalid-input"
    //     }
    //     if (valid && target.className !== "form-control mt-4") {
    //         target.className += "form-control mt-4"
    //     }
    // }

    const handleChangeData = ({target}) => {
            !isChanged && setIsChanged(true)
            const {value} = target 
            const taxDepartnemt = taxDepartments && taxDepartments.find(v => v.Name === value)
            const CodeUs = taxDepartnemt ? taxDepartnemt.KodUs : null 
            switch (target.name) {
                case 'name':
                setData(prev => ({...prev, Name: value}))
                break;
                case 'codeUS':
                setData(prev => ({...prev, US: value, CodeUs}))
                break;
                case 'street':
                setData(prev => ({...prev, Street: value}))
                break;
                case 'town':
                setData(prev => ({...prev, Town: value}))
                break;
                case 'local':
                    setIsInvalid(target.value.length < 1 )
                    setData(prev => ({...prev, Local: value}))
                    break;
                case 'postDep':
                setData(prev => ({...prev, PostDep: value}))
                break;
                case 'homeNr':
                setData(prev => ({...prev, HomeNr: value}))
                break;
                case 'index':
                setData(prev => ({...prev, Index: value}))
                break;
            }
    }
    const validation = (name=null) => {
        // console.log("param name: ")
        const param = name ? {[name]: data[name]} : data 
        const vl =  !Object.keys(param).map((value) => {
            // console.log(`${idx}) ${value}: ${param[value]}`)
            switch (value) {
                case 'Name':
                    return param[value] && String(param[value]).length <= 256 
                case 'CodeUs':
                    return regexpCodeUS(param[value]) 
                case 'Street':
                    return param[value] && String(param[value]).length <= 70
                case 'US':
                    return param[value] && String(param[value]).length <= 70
                case 'Town':
                    return param[value] && String(param[value]).length <= 70
                case 'Local':
                    return param[value] && String(param[value]).length <= 15
                case 'PostDep':
                    return param[value] && String(param[value]).length <= 70 
                    // return regextPost(param[value])
                case 'HomeNr':
                    return param[value] && String(param[value]).length <= 15
                case 'Index':
                    return regexpPostCode(param[value])
                default: 
                    return true
            }
        }).every(e => e)
        setIsInvalid(vl)
        // .forEach((element, idx) => {
        //     if (!element) console.log("can`t find value of", Object.keys(data)[idx])
        //     console.log(idx, " : ", element)
        // })
        
    }
    const handleFocus = ({target}) => {
            !isChanged && setIsChanged(true)
            switch (target.name) {
                case 'name':
                    setData(prev => ({...prev, Name: ""}))
                    break;
                case 'codeUS':
                    setData(prev => ({...prev, US: "", CodeUs: null}))
                    break;
                case 'street':
                    setData(prev => ({...prev, Street: ""}))
                    break;
                case 'town':
                    setData(prev => ({...prev, Town: ""}))
                    break;
                case 'local':
                    setData(prev => ({...prev, Local: ""}))
                    break;
                case 'postDep':
                    setData(prev => ({...prev, PostDep: ""}))
                    break;
                case 'homeNr':
                    setData(prev => ({...prev, HomeNr: ""}))
                    break;
                case 'index':
                    setData(prev => ({...prev, Index: ""}))
                    break;
            }
    }

    const handleSubmit = () => {
        fetchMssqlApi(
            "updateCustomerInfo", 
            {
                method: "POST"
                , data: {
                    ...data
                    , PrivacyAcception: isAllowed
                } 
            },
            () => {
                // console.log(isNew)
                setDefaultData(data)
                setIsChanged(false)
                // setDisabled(true)
                // setShowConfidence(false)
                if (isNew)  {
                    // console.log(dispatch)
                    dispatch({type: "setIsAllowed", payload: true})
                    history.replace('/start')
                } 
                
            }
        )
    }

    const handleClickSubmit = (evt) => {
        form && evt.preventDefault()
        if (isNew) {
            if (!form) {
                openForm("acceptModal")()
                // setShowConfidence(true)
            } else {
                handleSubmit(true)
                closeForm()
            } 
        } else {
            handleSubmit(true)
        }
    }
    // document.querySelector("section") && document.querySelector("section").addEventListener('scroll', () => {
    //     console.log("scrollll")
    //     // const dialogWindow = document.querySelector("text")
    //     // if (!dialogWindow) return
    //     // if (document.querySelector("section").scrollHeight - document.querySelector("section").scrollTop - document.querySelector("section").getBoundingClientRect().height < 2) {
    //     //     setIsAllowed(true)
    //     // } 
    // })
    const ScrollBtn = () => (
        <button type="submit" className="btn btn-primary btn-sm" form="modal-form"
            onClick={evt => {
                evt.preventDefault()
                //console.log(document.querySelector("text").getBoundingClientRect().height)
                // console.log("height: ", this.height())
                document.querySelector("section").scrollBy(0,document.querySelector("section").getBoundingClientRect().height - 40 )
            }}
        >
            Przewiń
        </button>
    )
    useEffect(() => {
        getData()
        getTaxDepartments()
    }, [])

    useEffect(() => {
        setData(defaultData)
    }, [defaultData])

    useEffect(() => {
        Object.values(data).some(v => v) && validation()
    }, [data])



    const modalRef = useRef(null)
    useEffect(
        () => {
            setIsAllowed(false)
            onClickAway(modalRef, closeForm)
        },
        [form !== null]
    )
        return ( data &&
            <>
                <div className='container'>
                    <div className='card'>
                        <div className='card-header'>
                            Dane Podatnika
                        </div>
                        <div className='card-body mb-5'>
                            <label>
                                NIP
                            </label>
                            <input
                                disabled={true}
                                placeholder='Wpisz nazwę podatnika...'
                                className={`form-control mb-3 ${data.NIP < 1 && `invalid-input`}`}
                                value={data.NIP || ""}
                                onChange={handleChangeData}
                                required={true}
                            />
                            <label htmlFor="name">
                                Nazwa Podatnika
                            </label>
                            <input name='name'
                                id="name"
                                disabled={ !isNew}
                                onFocus={handleFocus}
                                placeholder='Wpisz nazwę podatnika...'
                                className={`form-control mb-3 ${(!data.Name || String(data.Name).length > 256) && `invalid-input`}`}
                                value={data.Name || ""}
                                onChange={handleChangeData}
                                required={true}
                            />
                            <label
                                htmlFor="codeUS"
                            >
                                Urząd Skarbowy
                            </label>
                            <input name='codeUS'
                                id="codeUS"
                                disabled={ !isNew }
                                onFocus={handleFocus}
                                placeholder='Wpisz nazwę urązdu skarbowego...'
                                className={`form-control mb-3 ${(!data.CodeUs || !data.US) && `invalid-input`}`}
                                value={data.US || ""}
                                onInvalid={evt => evt.target.className = "form-control mt-4 invalid-input"}
                                onChange={handleChangeData}
                                required={true}
                                list="codeUSList"
                            />
                            <datalist id="codeUSList">
                                {taxDepartments && taxDepartments.map((value, idx) => (
                                    <option key={idx} value={value.Name}></option>
                                ) )}
                            </datalist>
                            <label
                                htmlFor="street"
                            >
                                Ulica
                            </label>
                            <input name='street'
                                id="street"
                                onFocus={handleFocus}
                                placeholder='Wpisz ulicę...'
                                className={`form-control mb-3 ${(!data.Street || String(data.Street).length > 70) && `invalid-input`}`}
                                value={data.Street || ""}
                                onChange={handleChangeData}
                                required={true}
                            />
                            <div className='d-flex'>
                                <div className='w-50 mr-2'>
                                    <label
                                        htmlFor="homeNr"
                                    >
                                        Nr budynku
                                    </label>
                                    <input name='homeNr'
                                        id="homeNr"
                                        onFocus={handleFocus}
                                        placeholder='Wpisz numer budynku...'
                                        className={`form-control mb-3  ${(!data.HomeNr  || String(data.HomeNr).length > 15) && `invalid-input`}`}
                                        value={data.HomeNr || ""}
                                        onChange={handleChangeData}
                                        required={true}
                                    />
                                </div>
                                <div className='w-50'>
                                    <label
                                        htmlFor="local"
                                    >
                                        Nr lokalu
                                    </label>
                                    <input name='local'
                                        id="local"
                                        onFocus={handleFocus}
                                        placeholder='Wpisz numer lokalu...'
                                        className={`form-control mb-3 ${(!data.Local  || String(data.Local).length > 15) && `invalid-input`}`}
                                        value={data.Local || ""}
                                        onChange={handleChangeData}
                                        required={true}
                                    />
                                </div>
                            </div>

                            <label
                                htmlFor="index"
                            >
                                Kod pocztowy
                            </label>
                            <input name='index'
                                id="index"
                                onFocus={handleFocus}
                                placeholder='Wpisz kod pocztowy...'
                                className={`form-control mb-3 ${!regexpPostCode(data.Index) && `invalid-input`}`}
                                value={data.Index || ""}
                                onChange={handleChangeData}
                                required={true}
                            />
                            <label
                                htmlFor="town"
                            >
                                Miejscowość
                            </label>
                            <input name='town'
                                id="town"
                                onFocus={handleFocus}
                                placeholder='Wpisz miejscowość...'
                                className={`form-control mb-3 ${(!data.Town || String(data.Town).length > 70 )&& `invalid-input`}`}
                                value={data.Town || ""}
                                onChange={handleChangeData}
                                required={true}
                            />
                            <label
                                htmlFor="postDep"
                            >
                                Urząd pocztowy
                            </label>
                            <input name='postDep'
                                id="postDep"
                                onFocus={handleFocus}
                                placeholder='Wpisz pocztę...'
                                className={`form-control mb-3 ${(!data.PostDep || String(data.PostDep).length > 70) && `invalid-input`}`}
                                type='text'
                                value={data.PostDep || ""}
                                onChange={handleChangeData}
                                required={true}
                            />
                            {/* {
                                showConfidence && (
                                        <div className='mb-5 mb-5'> 
                                            <div>
                                                {text}
                                            </div>
                                            <div>
                                                <div className='d-flex align-items-center mt-3'>
                                                    <input
                                                        id="allow"
                                                        type='checkbox'
                                                        style={{width:"2vw", height: "2vh"}}
                                                        checked={isAllowed}
                                                        onChange={() => setIsAllowed(prev => !prev)}
                                                    />
                                                    <label htmlFor='allow' className='mb-0'>
                                                        <b>Zapoznałem i zgadzam się z regulaminem</b>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    // </div>
                                )
                            } */}
                        </div>
                    </div>
                </div>



                <div className='position-fixed container' style={{bottom: "0px"}}>
                    {/* {isNew ? ( */}

                        <div className="card-footer text-center fixed-bottom" style={{backgroundColor:"#cbcbcb"}}>
                            {/* {!showConfidence && 
                                (
                                        <button
                                            className='btn btn-sm btn-primary'
                                            onClick={() => handleClickSubmit()}
                                            disabled={!isInvalid || !isChanged}
                                        >  
                                            Zatwierdz
                                        </button>
                                )
                            }
                            {
                                showConfidence && (
                                    <div>
                                            <button
                                                className='btn btn-sm btn-secondary mr-3'
                                                onClick={() => {
                                                    setIsAllowed(false)
                                                    setShowConfidence(false)
                                                }}
                                            >  
                                                Zmień informacje
                                            </button>
                                            <button disabled={!isInvalid || !isAllowed || !isChanged} className={`btn btn-success w-20  btn-sm`} onClick={handleSubmit}>
                                                Zapisz
                                            </button>
                                    </div>
                                )
                            }
                        </div>
                    ) : (
                        <div className="card-footer text-center fixed-bottom" style={{backgroundColor:"#cbcbcb"}}>
                            <button
                                className='btn btn-sm btn-secondary mr-3'
                                disabled={isChanged}
                                onClick={() => {
                                    setData(defaultData)
                                    setIsChanged(false)
                                }}
                            >  
                                Anuluj
                            </button>
                            <button disabled={!isChanged || isInvalid} className={`btn btn-success w-20  btn-sm`} onClick={handleSubmit}>
                                Zapisz
                            </button>
                        )}  */}
                            {
                                isNew ? (
                                    <button
                                        className='btn btn-sm btn-primary'
                                        onClick={() => handleClickSubmit()}
                                        disabled={isInvalid || !isChanged}
                                    >  
                                        Zatwierdź
                                    </button>
                                ) : (
                                    <div>
                                        <button
                                            className='btn btn-sm btn-secondary mr-3'
                                            onClick={() => {
                                                setData(defaultData)
                                                setIsChanged(false)
                                            }}
                                            disabled={!isChanged}
                                        >  
                                            Anuluj
                                        </button>
                                        <button
                                            className='btn btn-sm btn-success'
                                            onClick={() => handleClickSubmit()}
                                            disabled={isInvalid || !isChanged}
                                        >  
                                            Zapisz
                                        </button>
                                    </div>
                                )
                            }
                        </div>
                </div>
                {
                    form && (
                        <div className="modal fade show d-block" tabIndex="-1">
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div ref={modalRef} className="modal-content border-0">
                                <div className="modal-header bg-light align-items-center justify-content-end">
                                    {/* <h6 className="modal-title">{}</h6> */}
                                    <button onClick={closeForm} className="btn text-secondary px-2 py-1">
                                    <i className="fas fa-times" />
                                    </button>
                                </div>
                                <section className={`modal-body`} onScroll={() =>{ 
                                        const dialogWindow = document.querySelector("text")
                                        if (!dialogWindow) return
                                        if (document.querySelector("section").scrollHeight - document.querySelector("section").scrollTop - document.querySelector("section").getBoundingClientRect().height < 2) {
                                            setIsAllowed(true)
                                        } 
                                    }}>
                                <div className='mb-5 mb-5'> 
                                <form onSubmit={handleClickSubmit} id="modal-form">
                                    <text dangerouslySetInnerHTML={{__html: text}}>
                                        
                                    </text>
                                    {/* <div>
                                        <div className='d-flex align-items-center mt-3'>
                                            <input
                                                id="allow"
                                                type='checkbox'
                                                style={{width:"2vw", height: "2vh"}}
                                                checked={isAllowed}
                                                onChange={() => setIsAllowed(prev => !prev)}
                                            />
                                            <label htmlFor='allow' className='mb-0'>
                                                <b>Zapoznałem i zgadzam się z regulaminem</b>
                                            </label>
                                        </div>
                                    </div> */}

                                </form>
                            </div>
                                </section>
                                <div className="modal-footer bg-light">
                                        <div className='d-flex w-100 justify-content-between'>
                                        <div>
                                            <ScrollBtn/>
                                        </div>
                                        <div>
                                            <button type="submit" disabled={!isAllowed}  className="btn btn-primary btn-sm" form="modal-form">
                                                Akceptuję
                                            </button>
                                        </div>
                                </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    )
                }
            </>
        )
}
