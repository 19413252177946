import React from 'react';
import { useState, useContext } from 'react';
import FormSkel from '../Modals/FormSkel'
import useHttp from '../../hooks/use-http';
import { LangContext } from '../../context/lang-context';

const User = ({ id, name, email, role, warehouse, showModalHandler, users, isActive }) => {

    const [showModal, setShowModal] = useState(false);
    const [showRestoreModal, setShowRestoreModal] = useState(false);

    const { sendRequest } = useHttp();
    const { TRL_Pack } = useContext(LangContext);

    const showModalDeleteHandler = () => {
        setShowModal(true);
    }

    const showModalRestoreHandler = () => {
        setShowRestoreModal(true);
    }


    const handleUserDelete = e => {
        e.preventDefault();
        const data = {
            ID: id,

        }
        sendRequest({ url: 'user/delete', method: 'POST', data }, response => {
            if (response.status === 201) {
                handleClose();
                users()
            }
        })
    }

    const handleUserRestore = e => {
        e.preventDefault();
        const data = {
            ID: id,
            Active: true

        }
        sendRequest({ url: 'users/change-active', method: 'POST', data }, response => {
            if (response.status === 201) {
                handleClose();
                users()
            }
        })
    }

    const handleClose = () => {
        setShowModal(false);
    }

    const handleRestoreClose = () => {
        setShowRestoreModal(false);
    }

    return (
        <>
            {sessionStorage.getItem('DB_TYPE') === "mssql" ? (
                <tr>
                    <td>{name}</td>
                    <td>{email}</td>
                    <td>{warehouse}</td>
                    <td>{role}</td>
                    <td>{isActive}</td>
                    <td>
                        <button onClick={() => showModalHandler(id)} className='btn btn-link'>
                            <i className='fas fa-lock'></i>
                        </button>
                    </td>
                    <td>
                        {isActive ? (
                            <button onClick={showModalDeleteHandler} className='btn btn-link'>
                                <i style={{ color: "red" }} className='fa fa-trash icon-large'></i>
                            </button>
                        ) : (
                            <button onClick={showModalRestoreHandler} className='btn btn-link'>
                                <i style={{ color: "red" }} className='fas fa-plus icon-large'></i>
                            </button>
                        )}
                    </td>
                    <td>
                        {showModal && < FormSkel
                            headerText={TRL_Pack.users.deleteUser}
                            handleClose={handleClose}
                            acceptForm="true"
                        >
                            <div>
                                <form id="modal-form" onSubmit={handleUserDelete} >
                                    <div >
                                        <label>{TRL_Pack.users.deleteUserConfirm}</label>
                                    </div>
                                </form>
                            </div>
                        </FormSkel >
                        }
                        {showRestoreModal && < FormSkel
                            headerText={TRL_Pack.users.restoreUser}
                            handleClose={handleRestoreClose}
                            acceptForm="true"
                        >
                            <div>
                                <form id="modal-form" onSubmit={handleUserRestore} >
                                    <div >
                                        <label>{TRL_Pack.users.restoreUserConfirm}</label>
                                    </div>
                                </form>
                            </div>
                        </FormSkel >
                        }      
                    </td>
                </tr>
            ) : (
                <tr>
                    <td>{name}</td>
                    <td>{email}</td>
                    {/*<td>{notifyEmail}</td>*/}
                    <td>{role}</td>
                    <td>
                        <button onClick={() => showModalHandler(id)} className='btn btn-link'>
                            <i className='fas fa-lock'></i>
                        </button>
                    </td>
                    <td>
                        <button onClick={showModalDeleteHandler} className='btn btn-link'>
                            <i style={{ color: "red" }} className='fa fa-trash icon-large'></i>
                        </button>
                    </td>
                    <td>
                        {showModal && < FormSkel
                            headerText={TRL_Pack.users.deleteUser}
                            handleClose={handleClose}
                            acceptForm="true"
                        >
                            <div>
                                <form id="modal-form" onSubmit={handleUserDelete} >
                                    <div >
                                        <label>{TRL_Pack.users.deleteUserConfirm}</label>
                                    </div>
                                </form>
                            </div>
                        </FormSkel >}
                    </td>
                </tr>
            )}
        </>
    );
};

export default User;