import React, {useState, useEffect, useContext} from 'react'
import fetchHook from '../../hooks/fetchMSSQL-hook';
import { NavigationContext } from '../../context/navigation-context';
import Pagination from '../../components/Pagination/Pagination';
import FilterEx from '../../components/Filter/FilterEx';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import formHook from '../../hooks/form-hook'
import EventForm from '../../components/Modals/EventForm';
import { NotificationManager } from 'react-notifications';


export default function Events() {
    const [events, setEvents] = useState([])
    const { fetchMssqlApi } = fetchHook()
    const { setHeaderData } = useContext(NavigationContext)
    const {form, openForm, closeForm} = formHook()


    const getCheckboxFromBool = (bool) => (
        <div className='d-flex justify-content-center align-items-center'>
            <input
                type='checkbox'
                checked={bool}
                onChange={() => {}}
            />
        </div>
    )
    //filter...
    const defaultFilter = {
        showIndexes: false,
        name: "events",
        page: 1,
        disableIndexes: true,
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 25, 50, 100],
        visible: false,
        //sortByColumns: true,
        sortByColumns: false,
        //sortBy: '3 | asc | text',
        activeTags: [],
        columns: [
        {
            id: 5,
            name: "Aktywny",
            sortable: true,
            selectable: true,
            disabled: false,
            type: "threeStateBoolByActivity",
            value: "true",
            selectbar: "true"
        },
        ]
    }
    const [filter, setFilter] = useState(() => {
        if (localStorage.getItem('locationRoutesFilter')) {
        return JSON.parse(localStorage.getItem('locationRoutesFilter'));
        }
        return defaultFilter;
    })
    const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
    const resetFilter = () => {
        setFilter(defaultFilter);

    }
    const reportFilter = row =>
    filter.columns
        .filter(col => col.selectbar)
        .every(col => {
        const x = String(row[Object.keys(row)[col.id - 1]])
            .toLowerCase() === col.selectbar.toLowerCase()
        return x
        }
    )
    const search = () => {
        setFilter(prev => ({
            ...prev,
            columns: prev.columns.map(col => {
                col.selectbar = col.value
                return col
            })
        }))
    }
    const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
    const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))
    //=====================
    const handleSubmit = (evt, data, setData) => {
        evt.preventDefault()
        const isAvaiable = events.filter(event => event.EventId !== form).map(event => event.Code).indexOf(data.Code.trim()) === -1
        const {isActive} = evt.target.elements
        const {
            Code,
            Name,
            Description,
            DefaultNotificationLevelId,
        } = data
        const payload = {
            Code,
            Name,
            Description,
            DefaultNotificationLevelId
        }
        const event = events.find(evt => evt.EventId === form)
        if (!isAvaiable) {
            setData(prev => ({...prev, Code: ""}))
            NotificationManager.error("Taki kod już istnieje")
            return
        } 
        if (form !== "new" && String(data.IsActive) !== isActive.value) {
            fetchMssqlApi(`event/${form}`, {method: "PUT", data: {...event, IsActive: !data.IsActive}}, () => {
                getEvents()
                closeForm()
                return
            })
        } else if (form === "new") {
            fetchMssqlApi('event', {method: "POST", data: payload}, () => {
                getEvents()
                closeForm()
            })
        } else {
            event ? fetchMssqlApi(`event/${form}`, {method: "PUT", data: {...payload, IsActive: event.IsActive}}, () => {
                getEvents()
                closeForm()
            })
            : NotificationManager.error("Something went wrong") 
        }

    }
    const getEvents = () => {
        fetchMssqlApi('events', {}, res => {
            setEvents(res)
        })
    }
    useEffect(() => {
        setHeaderData({text: "Zdarzenia"})
        getEvents()
    }, [])
    return (
        <div>
            <Pagination 
                totalItems={events.length}
                page={filter.page}
                handleSwitchPage={handleSwitchPage}
                rowsPerPage={filter.rowsPerPage}
                toggleFilter={toggleFilter}
                filterVisibility={filter.visible}
                />
            {filter.visible && (
                <FilterEx
                    filter={filter}
                    setFilter={setFilter}
                    columns={filter.columns}
                    resetPage={resetPage}
                    resetFilter={resetFilter}
                    toggleFilter={toggleFilter}
                    search={search}
                    />
            )}
            <div className='card'>
                <div className='card-header'>
                    <div>
                        
                            <div className='d-flex justify-content-between'>
                                <div className='h5 mt-2 mb-1'>Zdarzenia</div>
                                <div onClick={openForm()} className="btn text-nowrap btn-sm btn-outline-secondary mt-1 mb-1"><i className="fas fa-plus" /></div>
                            </div>
                    </div>
                </div>
            <div className='card-body overflow-auto' style={{ maxHeight: !filter.visible ? "68vh" : "40vh"}}>
                {events.length ? (
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th>Kod</th>
                                <th>Zdarzenie </th>
                                <th>Rodzaj powiadomienia </th>
                                <th>Opis </th>
                                <th> <div className='d-flex justify-content-center'>Aktywne</div></th>
                                <th> <div className='d-flex justify-content-center'>Edytowalne</div></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        {events
                        .filter(reportFilter)
                        .slice(
                            (filter.page - 1) * filter.rowsPerPage,
                            filter.page * filter.rowsPerPage
                        )
                        .map((event, idx) => (
                            <tr key={idx} className='mt-1 mb-1'>
                                <td> 
                                    {event.Code}
                                </td>
                                <td> 
                                    {event.Name}
                                </td>
                                <td> 
                                    {event.NotificationType}
                                </td>
                                <td> 
                                    {event.Description}
                                </td>
                                <td> 
                                    {getCheckboxFromBool(event.IsActive)}
                                </td>
                                <td> 
                                    {getCheckboxFromBool(event.IsEditable)}
                                </td>

                                <td>
                                    <button 
                                        onClick={openForm(event.EventId)} 
                                        className="btn btn-outline-primary btn-sm"
                                    >
                                        Edytuj
                                    </button>
                                </td>
                            </tr>
                        )) }
                        </tbody>
                    </table>) : <div className='h3 d-flex justify-content-center align-items-center'>Brak danych</div>}
                    </div>
                        {form && (
                            <EventForm handleClose={closeForm} handleSubmit={handleSubmit} eventData={events.find(event => event.EventId === form)}/>
                        )}
                    </div>
        </div>
    )
}
