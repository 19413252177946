import React, { useState, useEffect, useContext } from 'react';

// Context
import { NavigationContext } from '../../../context/navigation-context';
import { LangContext } from '../../../context/lang-context';
import { NotificationContext } from '../../../context/notification-context';

// Custom hooks
import useHttp from '../../../hooks/use-http';

// Components
import Table from '../../../components/Table';
import Modal from '../../../components/Modal';
import Code from '../../../components/Definitions/Code';
import NoResults from '../../../components/NoResults/NoResults'

const Codes = () => {
    const navigationCtx = useContext(NavigationContext);
    const notificationCtx = useContext(NotificationContext);
    const { TRL_Pack } = useContext(LangContext);

    const { sendRequest } = useHttp();

    const [codes, setCodes] = useState([]);
    const [products, setProducts] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [inputValues, setInputValues] = useState({
        ean: ''
    });
    const [suggestions, setSuggestions] = useState([]);

    // const [modifiedUserId, setModifiedUserId] = useState(null);

    useEffect(() => {
        navigationCtx.setHeaderData({ text: TRL_Pack.navbar.codes })
        sendRequest({ url: 'payment-codes' }, codes => setCodes(codes.data));
        sendRequest({ url: 'products-list' }, products => setProducts(products.data));
    }, []);

    const showModalHandler = () => {
        setShowModal(true);
    };

    const hideModalHandler = () => setShowModal(false);

    const getSuggestions = value => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;

        const suggestions = inputLength === 0 ? [] : products.filter(product =>
            product.Name.toLowerCase().slice(0, inputLength) === inputValue
        );

        setSuggestions(suggestions);
    };

    const changeHandler = e => {
        const { name, value } = e.target;

        getSuggestions(value);

        setInputValues(prevState => ({
            ...prevState,
            [name]: value
        }))
    };

    const submitHandler = e => {
        e.preventDefault();

        const selectedProduct = products.find(product => product.EAN === inputValues.ean);

        // Validation
        if (!inputValues.ean || !selectedProduct) {
            notificationCtx.notification('error', TRL_Pack.errors.invalidInput);
            return;
        }

        const data = {
            Ean: inputValues.ean
        };

        addCodeHandler(data);
    }

    const deleteCodeHandler = id => {
        const url = `payment-code/${id}`;

        sendRequest({
            url,
            method: 'DELETE',
        }, response => {
            if (response.status === 200) {
                const newCodes = codes.filter(code => code.PaymentCodeId !== id);
                setCodes(newCodes);
            }
        });
    }

    const addCodeHandler = data => {
        const url = 'payment-code';

        sendRequest({
            url,
            method: 'POST',
            data
        }, response => {
            if (response.status === 201) {
                hideModalHandler();
                const newRow = response.data.rows[0];

                const newCodes = [
                    newRow,
                    ...codes
                ];

                setCodes(newCodes);
            }
        });
    }

    return (
        <div className='container-xl'>
            {codes.length > 0 ? (<>
                <div>
                    <button
                        className="d-block btn btn-link text-decoration-none ml-auto my-2 mr-1"
                        onClick={showModalHandler}
                    >
                        <i className="fas fa-plus mr-2" />
                        {TRL_Pack.codes.add}
                    </button>
                </div>
                <Table>
                    <thead>
                        <tr>
                            <th>{TRL_Pack.codes.code}</th>
                            <th>{TRL_Pack.codes.ean}</th>
                            <th>{TRL_Pack.codes.productName}</th>
                            <th>{TRL_Pack.codes.status}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {codes.map(code => <Code
                            key={code.PaymentCodeId}
                            id={code.PaymentCodeId}
                            paymentCode={code.PaymentCode}
                            ean={code.EAN}
                            productName={code.Name}
                            status={code.Status}
                            deleteCodeHandler={deleteCodeHandler} />)}
                    </tbody>
                </Table>
            </>) : (
                <NoResults buttonText={TRL_Pack.codes.add} onClick={showModalHandler} />
            )}
            {showModal && <Modal
                hideModalHandler={hideModalHandler}
                header={'Test'}
            >
                <form onSubmit={submitHandler} id='modal-form'>
                    <div className='form-group'>
                        <label className='h6'>{TRL_Pack.codes.ean}</label>
                        <input
                            type='text'
                            name='ean'
                            className='form-control'
                            onChange={changeHandler}
                            value={inputValues.ean}
                            list='suggestions'
                            autoComplete='off'
                            placeholder={TRL_Pack.codes.eanPlaceholder}
                            required
                        />
                        <datalist id='suggestions'>
                            {suggestions.map(suggestion => <option key={suggestion.EAN} value={suggestion.EAN}>{suggestion.Name}</option>)}
                        </datalist>
                    </div>
                </form>
            </Modal>}
        </div>);
};

export default Codes;