import React, { useEffect, useState } from 'react'
import FormSkel from './FormSkel'
import fetchHook from '../../hooks/fetchMSSQL-hook'
import InfoToast from '../../util/InfoToast'

export default function RouteForm({traceData, handleClose, handleSubmit}) {
    const [initialData, setInitialData] = useState(traceData || {Name: "", RouteId: null})
    const [data, setData] = useState([])
    const [initialLocations, setInitialLocations] = useState([])
    const [locations, setlLocations] = useState([])
    const [locationsList, setlLocationsList] = useState([])
    const [chosedLocation, setChosedLocation] = useState(null)
    const [newLocation, setNewLocation] = useState("")
    const [changed, setChanged] = useState(false)
    const { fetchMssqlApi } = fetchHook()
    const random = () => { 
        const set = new Set(locations.map(item => +item.RouteLocationId))
        const a = Math.floor(Math.random()*10000)
        return set.has(a) ? random() : a
    }
    const infoText = "Funkcja Tras pozwala na ułożenie lokalizacji w kolejność, tym samym wyświetlanie w aplikacji mobilnej automatów w kolejności odwiedzania na danej trasie. Dodatkowo służy do filtrowania automatów przy logowaniu do systemu, w przypadku gdy mamy ponad 250 automatów aktywnych w systemie. Trasy NIE ograniczają dostępu do automatów, do tego służy funkcja dostępna w edycji użytkowników, tam można przypisać lokalizacje i tym samym ich automaty do danego użytkownika."
    const getLocations = () => {
        if (traceData) fetchMssqlApi(`routes-locations/${traceData.RouteId}`, {}, res => setInitialLocations(res))
        else setInitialLocations([])
    }
    const getLocationsList = () => {
        fetchMssqlApi('locations', {}, (locations) => setlLocationsList(locations))
    }
    const upLocation = (order) => {
        setChanged(true)
        let item = locations[+order - 1]
        let item2 = locations[+order -2]
        if(!item2) return
        let arr = [...locations]
        arr.splice(+order -2, 2, ...[{...item, Order: String(+order - 1)}, {...item2, Order: order}])
        setlLocations(arr)
    }
    const downLocation = (order) => {
        setChanged(true)
        let item = locations[+order - 1]
        let item2 = locations[+order]
        if(!item2) return
        let arr = [...locations]
        arr.splice(+order-1, 2, ...[{...item2, Order: order}, {...item, Order: String(+order + 1)}])
        setlLocations(arr)
    }
    const addNewLocation = () => {
        setChanged(true)
        const locationId = locationsList.find(location => location.Name === newLocation)
        if (!locationId) setNewLocation("")
        else {
            setlLocations(prev => [...prev,
                { LocationId: locationId.LocationId,
                    Name: newLocation,
                    RouteLocationId: String(random()),
                    Order: String(++locations.length),
                    RouteId: initialData.RouteId,
                }])
            setNewLocation("")
        }
    }
    const deleteLocation = (id) => {
        setChanged(true)
        const item = locations.find(location => location.RouteLocationId === id)
        if (!item) return
        setlLocations(prev => prev
            .map(location => location.Order > item.Order ? ({...location, Order: String(--location.Order)}) : location)
            .filter(location => location.RouteLocationId !== item.RouteLocationId))
    } 
    useEffect(() => {
        setData(initialData)
    }, [initialData])
    useEffect(() => {
        setlLocations(initialLocations)
    }, [initialLocations])
    useEffect(() => {
        getLocations()
        getLocationsList()
    },[])
    return (
        <FormSkel disableSubmit={!data.Name || !locations.length || !changed} headerText={data ? "Edytuj trasę" : "Nowa trasa"} data={data} handleClose={handleClose}>

        <form 
            onSubmit={evt => handleSubmit(evt, data, setData ,locations)} 
            id='modal-form' 
            autoComplete='off'
            onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()}>
            <label className='h6'>Nazwa trasy</label>
            <div className='form-group'>
                <input
                    onKeyDown={evt => evt.key === "enter" && evt.preventDefault()}
                    className={`form-control ${!data.Name && `invalid-input`}`}
                    value={data.Name || ""}
                    name='name'
                    onFocus={() => setData(prev => ({...prev, Name: ""}))}
                    onChange={evt =>{setChanged(true); const value = evt.target.value; setData(prev => ({...prev, Name:value}))} }/>
            </div>
        </form>
        <div className='pb-3 pt-3' >

            <div className='d-flex justify-content-between align-content-end mb-1'>
                <label className='h6 mb-0'>Przypisz lokalizację</label>
                <InfoToast text={infoText} delay={10000} btnSize={"sm"} leftDistance={"19%"}/>
            </div>
            <div className='d-flex mb-2'>
                <input
                    className='form-control'
                    value={newLocation}
                    onChange={evt => setNewLocation(evt.target.value)}
                    // placeholder='Nowa lokalizacja...'
                    onFocus={() => setNewLocation("")}
                    list='locations'
                />
                <datalist id='locations'>
                    {locationsList && locationsList.map((location, idx) =>(
                        <option value={location.Name} key={idx}/>
                    ))}
                </datalist>
                <button className="btn btn-outline-success" onClick={addNewLocation} disabled={!newLocation}> 
                    <i className=" fa fa-plus"/> 
                </button>
            </div>
            {/* <label className='h6'>Lokalizacje trasy</label> */}
            {locations && locations.length ? (
            <>
            <label className='h6 mt-3 mb-1'>Lokalizacje</label>
            <table className='table table-striped '>
                <tbody>
                    {locations && locations.map((location, idx) => (
                        <tr key={idx} 
                            onClick={() => {
                                chosedLocation === location.RouteLocationId 
                                    ? setChosedLocation(null) 
                                    : setChosedLocation(location.RouteLocationId)
                                }
                            } 
                            className={location.RouteLocationId === chosedLocation ? `bg-warning` : ``}>
                            <td>
                                {location.Order}
                            </td>
                            <td>
                                {location.Name}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="d-flex mt-3 mb-2 justify-content-between">
                <div>
                    <button className="btn btn-outline-danger mr-2"
                        disabled={!chosedLocation}
                        onClick={() => deleteLocation(chosedLocation)}> 
                        <i className=" fa fa-trash"/> 
                    </button>
                </div>
                <div className='d-flex mb-1 mt-1'>
                    <div className='mr-2'>
                        <button className="btn btn-outline-primary" 
                            disabled={!chosedLocation}
                            onClick={() => {const location = locations.find(location => location.RouteLocationId === chosedLocation); location && upLocation(location.Order)}}>
                            <i className="fas fa-sharp fa-regular fa-arrow-up"/> 
                        </button>
                    </div>
                        <button
                            className="btn btn-outline-primary" 
                            disabled={!chosedLocation}
                            onClick={() => {const location = locations.find(location => location.RouteLocationId === chosedLocation); location && downLocation(location.Order)}}
                        >
                            <i className="fas fa-sharp fa-regular fa-arrow-down"/>  
                        </button>
                </div>
            </div>
            </>

            ) : <></>}
        </div>
        </FormSkel>
    )
}

