import React from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import PA from './PA'
import PR from './PR'
import RD from './RD'

export default function CashRegisterOperation() {
    const params = useParams()
    



    switch (params.type) {
        case "pa":
            return <PA/>
        case "pr":
            return <PR/>
        case "rd":
            return <RD/>
    }
}
