import React, { useContext, useEffect, useState } from 'react'
import { NavigationContext } from '../../context/navigation-context'
import { Prompt, useParams, Link, useHistory} from 'react-router-dom/cjs/react-router-dom.min'
import useFetch from '../../hooks/fetchMSSQL-hook' 
import moment from 'moment'
import link from "../../assets/images/back_to_list_icon.svg"

export default function MM() {

    
    //=========================
    const [initialData, setInitialData] = useState({})
    const [initialItems, setInitialItems] = useState([]) 
    const [data, setData] = useState()
    const [items, setItems] = useState([])
    const [products, setProducts] = useState([])
    const [warehouses, setWarehouses] = useState([])
    const [changed, setChanged] = useState(false)
    const [avaiableToSave, setAvaiableToSave] = useState(false)
    const [editable, setEditable] = useState(true)
    
    const {id} = useParams()
    const history = useHistory()
    const { setHeaderData } = useContext(NavigationContext)
    const { fetchMssqlApi } = useFetch()
    //=========================

    //Generator functions
    const random = () => { 
        const set = new Set(items.map(item => +item.WarehouseOperationItemId))
        const a = Math.floor(Math.random()*10000)
        return set.has(a) ? random() : a}
    //=========================


    //static constants 
    const emptyMM = {
        Comment: "",
        CreateDateTime: moment().format('YYYY-MM-DD'),
        Created: null,
        IN: null,
        INId: null,
        IssuedOn: moment().format('YYYY-MM-DD'),
        RelatedDoc: "",
        ToWarehouseName: "",
        ToWarehouseOperationId: null,
        WarehouseName: "",
        WarehouseOperationFullName: null,
        WarehouseOperationId: null,
        WarehouseOperationTypeId: "20"
    }
    const newItem = {
        new: true,
        Name: "",
        Quantity: "",
        WarehouseOperationItemId: String(random())
    }
    //=========================
    // Requests...
    const getMM = (warehouseOperationId = id) => {
        if (warehouseOperationId !== "0") {
        fetchMssqlApi(`mm/${warehouseOperationId}`, {}, res => {
                setInitialData(res[0][0])
                setInitialItems(res[1])
        })} else {
                setInitialData(emptyMM)
        }
    }
    const getWarehouses = () => {
        fetchMssqlApi('warehouses-list', {}, warehouses => setWarehouses(warehouses))
    }
    const getProducts = () => {
        fetchMssqlApi('products-list', {}, products => setProducts(products))
    }
    //=========================



    //handle functions...
    const handleChangeWarehouse = (e) => {
        const evt = e.target
        console.log(evt)
        setChanged(true)
        const warehouse = warehouses.find(data => data.Name === evt.value)
        if (evt.name === "FromWarehouse") {

            if (!warehouse) {
            evt.className += " invalid-input"
                setData(prev => ({...prev, WarehouseName: evt.value})) 
            } else {
            evt.className = "mt-2 form-control form-control-sm"
                setData(prev => ({...prev, WarehouseName: evt.value, WarehouseId: warehouse.WarehouseId})) 
            }
        }
        if (evt.name === "ToWarehouse") {
            if (!warehouse) {
                evt.className += " invalid-input"
                setData(prev => ({...prev, ToWarehouseName: evt.value}))
            } else {
                evt.className = "mt-2 form-control form-control-sm"
                setData(prev => ({...prev, ToWarehouseName: evt.value, ToWarehouseId: warehouse.WarehouseId}))
            }
        }
    }

    const handleChangeItem = (id, evt) => {
        const name = evt.target.value
        setChanged(true)
        const product = products.find(pr => pr.Name === name)
        product ? evt.target.className = "form-control form-control-sm mx-auto mx-lg-0" : evt.target.className += " invalid-input"
        product
        ? setItems(prev => prev.map(item => {
        if(item.WarehouseOperationItemId === id) {return {
                ...item,
                Name: product.Name,
                ProductId: product.ProductId,
                Quantity: item.Quantity,
            }} else return item
        }))
        : setItems(prev => prev.map(item => item.WarehouseOperationItemId === id ? ({...item, Name: name}) : (item)))
    }

    const handleChangeQuantity = (id, quantity) => {
        setChanged(true)
        setItems(prev => prev.map(item => item.WarehouseOperationItemId === id ? ({
            ...item,
            Quantity: quantity.replace(/[^0-9]/, ""),
        }) : item))
    }

    const deleteItem = (id) => {
        const item = items.find(item => item.WarehouseOperationItemId === id)
        item.new ? setItems(prev => prev.filter(item => item.WarehouseOperationItemId !== id)) 
        : setItems(prev => prev.map(a => a.WarehouseOperationItemId === id ? ({...a, deleted: true}) : (a)))
    }    

    const handleSubmit = () => {
        const {
            WarehouseOperationId
            , WarehouseId
            , ToWarehouseId
            , Comment
            , IssuedOn
            , ToWarehouseOperationId
        } = data,
        Payload = id !== "0" ? {
            WarehouseOperationId
            , FromWarehouseId: WarehouseId
            , ToWarehouseId: ToWarehouseId
            , Comment
            , IssuedOn
            , ToWarehouseOperationId
            , WarehouseInventoryId: data.INId
        } : {
            IssuedOn
            , FromWarehouseId: WarehouseId
            , ToWarehouseId: ToWarehouseId
            , Comment
        },
        Items = items.filter(item => !(item.deleted && item.new)).map(item => {
            // console.log(item)
            return id === "0" ? {
                    ProductId: item.ProductId,
                    Quantity: parseFloat(item.Quantity),
                    WarehouseOperationId: MM.WarehouseOperationId
                } : {
                    deleted: item.deleted ? 1 : 0,
                    new: item.new ? 1 : 0,
                    WarehouseOperationItemId: item.WarehouseOperationItemId,
                    ProductId: item.ProductId,
                    Quantity: item.Quantity,
                    WarehouseOperationId: MM.WarehouseOperationId
                }
            })
        // console.log({Payload, MMItems}) 
        fetchMssqlApi(`mm/${id !== "0" ? id : `new`}`, {method: id !== "0" ? "PUT" : "POST", data: {Payload, Items}}, res => {

            // console.log(res.id[0].Id) 
            // fetchMssqlApi(`mm/${res}`, {}, res => {
            //     setInitialData(res[0][0])
            //     setInitialItems(res[1])
            // })
            setChanged(false)
            if (id !== "0") {
                return getMM()
            }
            else {
                history.replace(`${res.id[0].Id}`) 
                return getMM(res.id[0].Id)  
            } 
            // window.location.pathname = `mm/${res.id[0].Id}` 
            // <Redirect to={`mm/${res.id[0].Id}`}/>

        }) 
    }
    //=========================

    // useEfects...
    useEffect(() => {
        setHeaderData({text: "Przesunięcie magazynowe"})
        getMM()
        getWarehouses()
        getProducts()
    }, [])

    useEffect(() => {
        setData(initialData)
        initialData.WarehouseOperationTypeId === "19" || initialData.INId ? setEditable(false) : setEditable(true)
    }, [initialData])

    useEffect(() => {
        setData(prev => ({...prev, 
            WarehouseId: warehouses && warehouses.find(warehouse => warehouse.Name === prev.WarehouseName)?.WarehouseId || null,
            ToWarehouseId: warehouses && warehouses.find(warehouse => warehouse.Name === prev.ToWarehouseName)?.WarehouseId || null
        }))
    }, [initialData, warehouses])

    useEffect(() => {
        setItems(initialItems)
    }, [initialItems])

    useEffect(() => {
        items && items.every(item => 
                item.Name 
            &&  item.Quantity
            &&  item.WarehouseOperationItemId
        )
        && data
        && data.IssuedOn
        && data.WarehouseId
        && data.ToWarehouseId
        && !(data.WarehouseId === data.ToWarehouseId)
        && items.filter(item => !item.deleted).length
        ? setAvaiableToSave(true)
        : setAvaiableToSave(false)
    }, [data, items])
    //=========================


    return (
        <div>
            <Prompt when={changed} message="Istnieją niezapisane zmiany, czy na pewno chcesz kontynuować?"/>
            {/* <Link to="/mms">
                <button className="btn btn-link ml-1 text-decoration-none mb-3">
                    <i className="fas fa-arrow-left mr-2" />
                    Wróć
                </button>
            </Link> */}
            <Link to='/mms'>
                    <label to='back' className="btn btn-link ml-1 text-decoration-none mb-3">
                        <img id='back' src={link}></img>
                    </label>
            </Link>
            {data && (data.WarehouseOperationId ?  (
                <div className='card mb-3'>
                    <div className='card-header'>
                    <div className='h5'>
                        {data?.WarehouseOperationFullName && data.WarehouseOperationFullName || ""}
                    </div>
                        <table className="table table-striped">
                            <tbody>
                                <tr className='justify-content-center'>
                                    <td>Data wystawienia</td>
                                    <td>Magazyn żródłowy</td>
                                    <td>Magazyn docelowy</td>
                                    <td>Stworzona</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                            disabled={!editable}
                                            className={`mt-2 form-control form-control-sm ${!data.IssuedOn && `invalid-input`}`}
                                            type='date'
                                            value={moment(data.IssuedOn).format("YYYY-MM-DD")}
                                            onChange={evt => {setChanged(true); const value = evt.target.value ;setData(prev => ({...prev, IssuedOn: value || ""}))}}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            disabled={!editable}
                                            value={data.WarehouseName}
                                            datalist='warehouses'
                                            placeholder='Wybierz...'
                                            className=  {`mt-2 form-control ${!data.WarehouseName && `invalid-input`} form-control-sm`}
                                            min={1}
                                            list='warehouses'
                                            onFocus={() => {setChanged(true); setData(prev => ({...prev, WarehouseName: "", WarehouseId: null}))}}
                                            onChange={handleChangeWarehouse}
                                            name="FromWarehouse"
                                        />
                                        <datalist id='warehouses'>
                                        {warehouses && warehouses.map((warehouse, idx) => (
                                            <option value={warehouse.Name} key={idx}/>
                                        ))}
                                        </datalist>
                                    </td>
                                    
                                    
                                    <td>
                                        <input
                                            disabled={!editable}
                                            value={data.ToWarehouseName}
                                            datalist='warehouses'
                                            placeholder='Wybierz...'
                                            className=  {`mt-2 form-control ${!data.ToWarehouseName && `invalid-input`} form-control-sm`}
                                            min={1}
                                            list='warehouses'
                                            onFocus={() => {setChanged(true); setData(prev => ({...prev, ToWarehouseName: "", ToWarehouseId: null}))}}
                                            onChange={handleChangeWarehouse}
                                            name="ToWarehouse"
                                        />
                                        <datalist id='warehouses'>
                                        {warehouses && warehouses.map((warehouse, idx) => (
                                            <option value={warehouse.Name} key={idx}/>
                                        ))}
                                        </datalist>
                                    </td>
                                    <td>{data.Created && `${moment(data.Created).format("YYYY.MM.DD")} ${data.Created.split(" ").at(-1)}`}</td>
                                </tr>
                                <tr className='justify-content-center'>
                                    <td>Komentarz / dodatkowe informacje</td>
                                    <td>Powiązane dokumenty magazynowe</td>
                                    {data.IN ? <td>Zamknięty</td> : <td></td>}
                                    <td></td>
                                </tr>
                                <tr className='justify-content-center'>
                                    <td>
                                        <textarea
                                            disabled={!editable}
                                            className='mt-2 form-control form-control-sm'
                                            rows={2}
                                            value={data.Comment}
                                            onChange={evt => {setChanged(true);const value = evt.target.value ;setData(prev => ({...prev, Comment: value || ""}))}}
                                            // onFocus={() => {setChanged(true); setData(prev => ({...prev, Comment: ""}))}}
                                            />
                                    </td>
                                    <td>
                                        <div className='d-flex  align-items-center mt-1'>
                                                <button 
                                                onClick={() => {
                                                    history.replace(data.ToWarehouseOperationId)
                                                    getMM(data.ToWarehouseOperationId)
                                                }}
                                                // to={`/mm/${data.ToWarehouseOperationId}`} 
                                                className="btn text-nowrap btn-sm btn-outline-primary">
                                                    {data.RelatedDoc}
                                                </button>
                                        </div>
                                    </td>
                                    {data.IN && (
                                        <td>
                                            <div className='d-flex  align-items-center mt-1'>
                                                    <Link to={`/in/${data.INId}`} className="btn text-nowrap btn-sm btn-outline-success">{data.IN}</Link>
                                            </div>
                                        </td>
                                        )
                                    }
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            ) 
            /** @form for new records */
            : (
                <div className='card mb-3'>
                    <div className='card-header'>
                    <div className='h5'>
                        Nowe przesunięcie magazynowe
                    </div>
                        <table className="table table-striped">
                            <tbody>
                                <tr className='justify-content-center'>
                                    <td>Data wystawienia</td>
                                    <td>Magazyn żródłowy</td>
                                    <td>Magazyn docelowy</td>
                                    <td>Komentarz / dodatkowe informacje</td>
                                </tr>
                                <tr>
                                    <td>
                                        <input
                                        className={`mt-2 form-control form-control-sm ${!data.IssuedOn && `invalid-input`}`}
                                        type='date'
                                        value={moment(data.IssuedOn).format("YYYY-MM-DD")}
                                        onChange={evt => {setChanged(true); const value = evt.target.value ;setData(prev => ({...prev, IssuedOn: value || ""}))}}
                                        />
                                    </td>
                                    <td>
                                        <input
                                            value={data.WarehouseName}
                                            datalist='warehouses'
                                            placeholder='Wybierz...'
                                            className=  {`mt-2 form-control ${!data.WarehouseName && `invalid-input`} form-control-sm`}
                                            min={1}
                                            list='warehouses'
                                            onFocus={() => {setChanged(true); setData(prev => ({...prev, WarehouseName: "", WarehouseId: null}))}}
                                            onChange={handleChangeWarehouse}
                                            name="FromWarehouse"
                                        />
                                        <datalist id='warehouses'>
                                        {warehouses && warehouses.map((warehouse, idx) => (
                                            <option value={warehouse.Name} key={idx}/>
                                        ))}
                                        </datalist>
                                    </td>
                                    
                                    
                                    <td>
                                        <input
                                            value={data.ToWarehouseName}
                                            datalist='warehouses'
                                            placeholder='Wybierz...'
                                            className=  {`mt-2 form-control ${!data.ToWarehouseName && `invalid-input`} form-control-sm`}
                                            min={1}
                                            list='warehouses'
                                            onFocus={() => {setChanged(true); setData(prev => ({...prev, ToWarehouseName: "", ToWarehouseId: null}))}}
                                            onChange={handleChangeWarehouse}
                                            name="ToWarehouse"
                                        />
                                        <datalist id='warehouses'>
                                        {warehouses && warehouses.map((warehouse, idx) => (
                                            <option value={warehouse.Name} key={idx}/>
                                        ))}
                                        </datalist>
                                    </td>
                                    <td>
                                        <textarea
                                            className='mt-2 form-control form-control-sm'
                                            rows={3}
                                            value={data.Comment}
                                            onChange={evt => {setChanged(true);const value = evt.target.value ;setData(prev => ({...prev, Comment: value || ""}))}}
                                            // onFocus={() => {setChanged(true); setData(prev => ({...prev, Comment: ""}))}}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
            {editable && (
            <div>
                <button
                    className="d-block btn btn-link text-decoration-none ml-auto mr-1"
                    onClick={() => {setChanged(true); setItems(prev => [{...newItem, Name: ""}, ...prev]);}}
                >
                    <i className="fas fa-plus mr-2" />
                </button>
            </div>
            )}
            <div className='card' style={{marginBottom: "40px"}}>
                <table className="table table-striped ">
                <thead>
                    <tr>
                        <th>Produkt</th>
                        <th>Ilość</th>
                    </tr>
                </thead>
                    <tbody>
                        {items && items.map((item, idx) => 
                        !item.deleted && 
                        (
                            <tr key={idx}>
                            <td>
                                <input
                                disabled={!editable}
                                className={`form-control ${!item.Name && `invalid-input` } form-control-sm mx-auto mx-lg-0`}
                                value={item.Name}
                                onChange={evt => {setChanged(true); handleChangeItem(item.WarehouseOperationItemId, evt)}}
                                list='products'
                                onFocus={() => {setChanged(true); setItems(prev => prev.map(pr => pr.WarehouseOperationItemId === item.WarehouseOperationItemId ? ({...pr, Name: "", ProductId: ""}) : pr))}}
                                />
                                <datalist id='products'>
                                {products && products.map((product, idx) => (<option key={idx} value={product.Name} />))}
                                </datalist>
                            </td>
                            <td>
                                <input
                                disabled={!editable}
                                type='text'
                                className={`form-control ${(!item.Quantity || item.Quantity == "0") && `invalid-input` } form-control-sm mx-auto mx-lg-0`}
                                value={item.Quantity}
                                onChange={evt => {setChanged(true); handleChangeQuantity(item.WarehouseOperationItemId, evt.target.value)}}
                                onFocus={() => {setChanged(true); setItems(prev => prev.map(pr => pr.WarehouseOperationItemId === item.WarehouseOperationItemId ? ({...pr, Quantity: ""}) : pr))}}
                                />
                            </td>
                            {editable && (
                                <td className='d-flex justify-content-end mr-2'>
                                    <button className="btn btn-link btn-sm" onClick={() => {
                                        setChanged(true) 
                                        deleteItem(item.WarehouseOperationItemId)
                                    }}>
                                        <i className="fas fa-trash" />                                
                                    </button>
                                </td>
                            ) }
                            </tr>
                        ))}
                        
                    </tbody>
                </table>
            </div>
            <div className='position-fixed container' style={{bottom: "0px"}}>

                    <div className="card-footer text-center fixed-bottom" style={{backgroundColor:"#cbcbcb"}}>
                        <button disabled={!avaiableToSave || !changed} className={`btn btn-primary w-20  btn-sm`} onClick={handleSubmit}>
                            Zapisz
                        </button>
                    </div>
            </div>
        </div>
    )
}
