import React, { useEffect, useContext, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import { NavigationContext } from '../../../context/navigation-context';
import { LangContext } from '../../../context/lang-context';
import filterItems from '../../../util/filterItems';

import useHttp from '../../../hooks/use-http';

import MachineStatus from '../../../components/Monitoring/MachineStatus';
import MachineNotifications from '../../../components/Monitoring/MachineNotifications';
import MachineTransactions from '../../../components/Monitoring/MachineTransactions';
import MachineEvadts from '../../../components/Monitoring/MachineEvadts';
import MachineCommands from '../../../components/Monitoring/MachineCommands';

const MonitoringMachine = () => {
    const { setHeaderData } = useContext(NavigationContext);
    const { TRL_Pack } = useContext(LangContext);

    const { sendRequest } = useHttp();

    const params = useParams();
    const history = useHistory();
    const location = useLocation();

    const [currentMachine, setCurrentMachine] = useState(null);
    const [machines, setMachines] = useState([]);

    const [currentMachineSection, setCurrentMachineSection] = useState(3);
    const changeSection = id => setCurrentMachineSection(id);

    const getMonitoringResponse = monitoring => {
        const machine = monitoring.data;

        setHeaderData({
            text: `${machine.name} (${machine.type})`,
            subtext: machine.customer_name
        });

        setCurrentMachine(machine);
    };

    const getMonitoringsResponse = machines => {
        const search = new URLSearchParams(location.search).get('search') || '';

        const sortedMachines = machines.data
            .filter(({ name, type, customer_name, serialNo, terminal, ip }) =>
                filterItems(search, name, type, customer_name, serialNo, terminal, ip)
            )
            .sort((a, b) => {
                return new Date(b.last_transaction.create_date_time) - new Date(a.last_transaction.create_date_time);
            });

        setMachines(sortedMachines);
    };

    useEffect(() => {
        const id = params.id;

        sendRequest({
            url: `monitorings`, 
            method: 'POST',  data: {
                customerAbbr: localStorage.getItem('clientId')
              }, hideNotification:true
        }, getMonitoringsResponse);


        sendRequest({
            url: `monitoring/${id}`, 
            method: 'POST',  data: {
                customerAbbr: localStorage.getItem('clientId')
              }, hideNotification:true
        }, getMonitoringResponse);

    }, []);


    const returnClickHandler = () => history.goBack();

    const machineChangeHandler = (direction, id) => {
        const index = machines.findIndex(machine => machine.machineId === id); // Get current machine array index 

        let switchId;

        if (direction === 'prev') {
            // Get previous machineId. If current machine is the first element of machines array, then get the machineId of last array element
            switchId = index === 0 ? machines[machines.length - 1].machineId : machines[index - 1].machineId;
        } else if (direction === 'next') {
            // Get next machineId. If current machine is the last element of machines array, then get the machineId of first array element
            switchId = index === machines.length - 1 ? machines[0].machineId : machines[index + 1].machineId;
        }

        if (switchId)
        sendRequest({
            url: `monitoring/${switchId}`, 
            method: 'POST',  data: {
                customerAbbr: localStorage.getItem('clientId')
              }, hideNotification:true
        }, getMonitoringResponse);
    }

    return (
        currentMachine && <>
            <div className='container-xl'>
                <div className="mb-3 d-flex justify-content-between">
                    <div>
                        <button
                            onClick={returnClickHandler}
                            className="btn btn-link ml-1 text-decoration-none"
                        >
                            <i className="fas fa-arrow-left mr-2" />
                            {TRL_Pack.buttons.return}
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={() => machineChangeHandler('prev', currentMachine.machineId)}
                            className="btn btn-link text-decoration-none"
                        >
                            <i className="fas fa-chevron-left" />
                            <span className="d-none d-md-inline ml-2">{TRL_Pack.monitoring.previous}</span>
                        </button>
                        <button
                            onClick={() => machineChangeHandler('next', currentMachine.machineId)}
                            className="btn btn-link text-decoration-none"
                        >
                            <span className="d-none d-md-inline mr-2">{TRL_Pack.monitoring.next}</span>
                            <i className="fas fa-chevron-right" />
                        </button>
                    </div>
                </div>
                <ul className="nav nav-tabs machine-tabs mb-3">
                    <li className="nav-item">
                        <button
                            className={`nav-link btn ${currentMachineSection === 0 ? 'active' : ''}`}
                            onClick={() => changeSection(0)}
                            tabIndex="0"
                        >
                            {TRL_Pack.monitoring.info}
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link btn ${currentMachineSection === 1 ? 'active' : ''}`}
                            onClick={() => changeSection(1)}
                            tabIndex="0"
                        >
                            {TRL_Pack.monitoring.transactions}
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link btn ${currentMachineSection === 2 ? 'active' : ''}`}
                            onClick={() => changeSection(2)}
                            tabIndex="0"
                        >
                            {TRL_Pack.monitoring.notifications}
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link btn ${currentMachineSection === 3 ? 'active' : ''}`}
                            onClick={() => changeSection(3)}
                            tabIndex="0"
                        >
                            {TRL_Pack.monitoring.evadts}
                        </button>
                    </li>
                    <li className="nav-item">
                        <button
                            className={`nav-link btn ${currentMachineSection === 4 ? 'active' : ''}`}
                            onClick={() => changeSection(4)}
                            tabIndex="0"
                        >
                            Komendy
                        </button>
                    </li>
                </ul>
            </div>
            <div>
                {currentMachineSection === 0 && <MachineStatus machine={currentMachine} />}
                {currentMachineSection === 1 && (
                    <div className='container-xl'><MachineTransactions transactions={currentMachine.transactions} /></div>
                )}
                {currentMachineSection === 2 && (
                    <div className='container-xl'><MachineNotifications events={currentMachine.events} /></div>
                )}
                {currentMachineSection === 3 && (
                    <div className='container-fluid'><MachineEvadts machineId={parseInt(currentMachine.machineId)} customerId={currentMachine.customer_abbr} /></div>
                )}
                {/* {currentMachineSection === 4 && (
                    <div className='container-fluid'><MachineCommands machineId={parseInt(currentMachine.machineId)} /></div>
                )} */}
            </div>
        </>
    );
};

export default MonitoringMachine;