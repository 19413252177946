import React, { useState, useEffect, useContext } from 'react'
import { LangContext } from '../../context/lang-context'
import useFetch from '../../hooks/fetchMSSQL-hook'

import { NavigationContext } from '../../context/navigation-context'


const Start = () => {
  const { TRL_Pack } = useContext(LangContext)
  const { fetchMssqlApi } = useFetch()

  const { setHeaderData } = useContext(NavigationContext)

  useEffect(() => {
    setHeaderData({
      text: TRL_Pack.navbar.start
    });
  }, [])

  return (
    <div className="d-flex justify-content-left p-2 m-1">
      <div className=""><div className="badge badge-primary text-wrap pr-3 pl-3 pt-2">
        <h5>Witamy w Retail 24 Dashboard&nbsp;&nbsp;&nbsp;&nbsp;<span className="badge badge-light">3.1.3</span></h5></div>
      
      <div>
        <h6><span className="badge badge-secondary">Zmiany / nowości - bieżąca wersja</span></h6>
        <table className="table table-responsive">
          <thead>
            <tr>
              <th scope="col">Aplikacja</th>
              <th scope="col">Opis</th>
            </tr>
          </thead>
          <tbody>

            <tr>
            <td>VD 3.1.3</td>
              <td># Automaty - podgląd historii zmian na automatach - zapisywanie wizyt, pobieranei danych, modyfikacja stanów
                <br /># Zmiany związane z homologacją kasy fiskalnej
                <br /># Optymalizacje raportów dotyczących transkacji z terminali PAX / NAYAX

              </td>
            </tr>
          </tbody>
        </table>
        </div>
        {/*}
        <h6><span className="badge badge-secondary">Planowane zmiany</span></h6>

        <table className="table table-responsive">
          <thead>
            <tr>
              <th scope="col">Aplikacja</th>
              <th scope="col">Opis</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>VD 3.2</td>

              <td># Lista Transakcji gotówkowych oraz bezgotówkowych, dla urządzeń wyposażonych w: Terminal PAX IM30, płytę główną Vendim, terminale NAYAX.
                <br /># Moduł Zdarzenia / Wiadomości z Maszyn - uruchomienie w nowej wersji
                <br /># Ulepszenie systemu nawigacji
                <br /># Podgląd tagów Maszyn na wszystkich listach
                <br /># Filtrowanie raportów wg Tagów
              </td>
            </tr>
            <tr>
              <td>VD 3.3</td>
              <td># Lista Transakcji gotówkowych oraz bezgotówkowych, dla urządzeń wyposażonych w terminal NAYAX - wszystkie modele.
                <br /># Filtrowanie po Tagach Produktów na wszystkich listach dotyczących Produktów
                <br /># Wizualizacja "bieżącego stanu" dla maszyn kawowych
                <br /># Nowa platforma generowania raportów, pozwoli na duże uelastycznie formatu raportów dla każdego klienta, tworzenia podsumowań wewnątrz raportów
              </td>
            </tr>
            <tr>
              <td>VS 1.3</td>
              <td># Hierarchiczne Menu wyboru produktu
                <br /># Wybór produktów z kategorii poprzez powiększony widok zdjęcia produktu
                <br /># Komunikaty dźwiękowe (opcja)
                <br /># Płatność kodami
                <br /># Zdalne zarządzanie wszystkimi typami promocji
              </td>
            </tr>
            <tr>
              <td>VD 3.4</td>
              <td># Nowy moduł Zamówień, tworzony na potrzeby realizowania zamówień Cateringowych w szafkomatach chłodzonych
                <br /># Rozszerzenie modułu Wizyty o możliwość tworzenia wizyt z telefonu Serwisanta, zarówno w trybie online jak i offline + synchronizacja.
                <br /># Nowy moduł (nazwa robocza: "Pakowanie Serwisantów w skrzynki") pozwalający na prowadzenie gospodarki magazynowej samochodów Serwisantów w oparciu o przygotowane na magazynie paczki dedykowane dla maszyn na podstawie historycznych danych sprzedażowych lub aktualnych danych z systemów telemetrycznych
              </td>
            </tr>
            <tr>
              <td>VM 2.0</td>
              <td># Nowa aplikacja Androidowa pozwalająca na pobierania danych EVADTS z maszyn
                <br /># Po pobraniu danych EVADTS z dowolnego źródła (kabel, system telemetryczny, terminal bezgotówkowy) możliwość dodania Wizyty bezpośrednio w VD z telefonu Serwisanta (online / offline + synchronizacja)
              </td>
            </tr>
          </tbody>
        </table>
        <h6><span className="badge badge-secondary">Zgłoś uwagę lub pomysł: serwis@vendim.pl</span></h6>
        <br />
        <h6><span className="badge badge-secondary">Terminy realizacji</span></h6>
        <table className="table table-responsive">
          <thead>
            <tr>
              <th scope="col">Wersja aplikacji</th>
              <th scope="col">Planowany termin</th>

            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Vendim Dashboard 3.1</td>
              <td>20 czerwca 2022</td>
            </tr>
            <tr>
              <td>Vendim Dashboard 3.2</td>
              <td>1 wrzesień 2022</td>
            </tr>
            <tr>
              <td>Vendim Dashboard 3.3 / Vendim Shop 1.3</td>
              <td>1 październik 2022</td>
            </tr>
            <tr>
              <td>Wygaszenie Vendim Dashboard 1.9.3 / Vendim Dashboard 2.0.4 </td>
              <td>1 październik 2022</td>
            </tr>
            <tr>
              <td>Vendim Dashboard 3.4 / Vendim Mobile 2.0</td>
              <td>1 styczeń 2023</td>
            </tr>
          </tbody>
            </table></div>*/}
      <div className="m-1">

        <h6><span className="badge badge-secondary">Przydatne linki / informacje</span></h6>
        <table className="table table-responsive">
          <tbody>
            <tr>
              <td><div className="text-primary">Retail 24 Dashboard 3.x (VD)</div><div className="text-success">https://portal.retail24.pl</div></td>
            </tr>
            {/* <tr>
              <td><div className="text-primary">Vendim Dashboard 2.0.4 (VD)</div><div className="text-success">https://vf.vendim.pl/vd</div></td>

            </tr> */}
            <tr>
              <td><div className="text-primary">Vendim Mobile 1.4.6 (VM)</div><div className="text-success">https://vendim.pl/robocze/VM149400.apk</div></td>

            </tr>
            <tr>
              <td><div className="text-primary">Retail 24 Shop 1.2 (VS)</div><div className="text-success">Aplikacja do obsługi zakupów na maszynach z płytą główną Retail 24 VMC 
                <br />Kontakt w sprawie aktualizacji: serwis@retail24.pl</div></td>

            </tr>
            <tr>
              <td></td>

            </tr>
          </tbody>
        </table>
      </div>
    </div></div>
 
  )
}

export default Start;