import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { LangContext } from '../../context/lang-context'
import useFetch from '../../hooks/fetchMSSQL-hook'
import Pagination from '../../components/Pagination/Pagination'
import FilterEx from '../../components/Filter/FilterEx'
import NoResults from '../../components/NoResults/NoResults'
import moment from "moment";
import { NavigationContext } from '../../context/navigation-context'
import ReportBtn from '../../components/Buttons/ReportsBtn/ReportBtn'
import { NotificationManager } from 'react-notifications'
import { API_URL } from '../../config/config'
import axios from 'axios'


const INMS = () => {
  const { TRL_Pack } = useContext(LangContext)
  const { fetchMssqlApi } = useFetch()

  const { setHeaderData } = useContext(NavigationContext)

  const [INMS, setINMS] = useState(null)
  const [rep46, setRep46] = useState(null)

  const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
  const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))

  const defaultFilter = {
    showIndexes: false,
    page: 1,
    disableIndexes: true,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 25, 50, 100, 1000],
    visible: false,
    //sortByColumns: true,
    sortByColumns: true,
    sortBy: '3 | asc | text',
    activeTags: [],
    columns: [
      {
        id: 1,
        name: 'Pokazuj zerowe PA',
        searchable: true,
        sortable: false,
        type: 'threeStateBool',
        selectbar: null
      },
      {
        id: 2,
        name: 'Telemetria',
        searchable: true,
        sortable: false,
        type: 'threeStateBool',
        selectbar: null
      },
      {
        id: 3,
        name: 'Maszyna',
        searchable: true,
        sortable: true,
        type: 'lookup',
        MachineId: null
      },
      {
        id: 4,
        name: 'Stan maszyny',
        searchable: false,
        sortable: true,
        type: 'price',
        disabled: true
      },
      {
        id: 5,
        name: 'Serwisant',
        searchable: true,
        sortable: false,
        type: 'lookup',
        CreateUserId: null
      }, 
      // {
      //   id: 5,
      //   name: 'Zakres czasowy',
      //   searchable: false,
      //   sortable: false,
      //   type: 'datetimeRange',
      //   dateTimeRangeFrom: moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss'),
      //   dateTimeRangeTo: moment().format('YYYY-MM-DDT23:59:59')
      // }
    ]
  }

  const [filter, setFilter] = useState(() => {
    if (localStorage.getItem('inmsFilter')) {
      return JSON.parse(localStorage.getItem('inmsFilter'));
    }
    return defaultFilter;
  })
  const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
  const resetFilter = () => {
    setFilter(defaultFilter);

    //    search();
    getINMS(null, null, null);
  }

  const search = () => {
    //TODO: unhardcode column idx, make it configurable
    const columns = filter.columns

    for (let a in columns) {
      if (columns[a].searchbar == undefined) {
        columns[a].searchbar = null
      }
    }

    let isZeroTotal = null;
    if (JSON.parse(columns[0].selectbar == 'false'))
      isZeroTotal = false;
    else if (JSON.parse(columns[0].selectbar === 'true'))
      isZeroTotal = true;

    let isTelemetry = null;
    if (JSON.parse(columns[1].selectbar === 'false'))
      isTelemetry = false;
    else if (JSON.parse(columns[1].selectbar === 'true'))
      isTelemetry = true;

    let machineId = null;
    if (JSON.parse(columns[2].MachineId !== '') && JSON.parse(columns[2].MachineId != null))
      machineId = columns[2].MachineId;

    let createUserId = null;
    if (JSON.parse(columns[4].CreateUserId !== '') && JSON.parse(columns[4].CreateUserId != null))
      createUserId = columns[4].CreateUserId;

    // //set default create from / to date time
    // let fromCreateDateTime = moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss');
    // let toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59');

    // if (JSON.parse(columns[4].dateTimeRangeFrom != '') && JSON.parse(columns[4].dateTimeRangeFrom != null))
    //   //TODO: parse manually entered(?)
    //   fromCreateDateTime = columns[4].dateTimeRangeFrom.replace('T', ' ');

    // if (JSON.parse(columns[4].dateTimeRangeTo != '') && JSON.parse(columns[4].dateTimeRangeTo != null))
    //   //TODO: parse manually entered(?)
    //   toCreateDateTime = columns[4].dateTimeRangeTo.replace('T', ' ');

    let machineTags = null;
    if (JSON.parse(filter.activeTags !== '') && JSON.parse(filter.activeTags !== null))
      machineTags = filter.activeTags;
    //TODO: parse machineTags(?)

    getINMS(machineTags, machineId, createUserId);
  }

  const getINMS = (machineTags = null, machineId = null, createUserId = null) => {
    fetchMssqlApi('inm-inms', {
      method: 'POST',
      hideNotification: true,
      data: {
        clientId: localStorage.getItem("clientId"),
        // fromCreateDateTime: fromCreateDateTime,
        // toCreateDateTime: toCreateDateTime,
        machineTags: machineTags,
        machineId: machineId,
        createUserId: createUserId
      }
    }, response => {
      setINMS(response);
      // console.log(response)
    })
  }


  useEffect(() => {
    getINMS()
    setHeaderData({
      text: TRL_Pack.inm.inms
    });
  }, [])

  useEffect(() => localStorage.setItem('inmsFilter', JSON.stringify(filter)), [
    filter
  ])


  const sortRows = (a, b) => {
    if (filter.sortBy === undefined) return
    const [id, order, type] = filter.sortBy.split(' | ')
    const col = Object.keys(INMS[0])[Number(id) - 1]

    if (a[col] === b[col]) return 0
    else if (a[col] === null) return 1
    else if (b[col] === null) return -1

    let valueA, valueB
    if (type === 'lookup' || type === 'date') {
      valueA = a[col].toUpperCase()
      valueB = b[col].toUpperCase()
    } else if (type === 'price') {
      valueA = Number(a[col]) || 0
      valueB = Number(b[col]) || 0

      // Number().toLocaleString(undefined, {minimumFractionDigits: 2}) num => str '1 245 151,50'
    } else return 0

    if (order === 'asc') return valueA < valueB ? -1 : 1
    else return valueA < valueB ? 1 : -1
    
  }

  // const reportFilter = row =>
  //   filter.columns
  //     .filter(col => col.searchbar)
  //     .every(col =>
  //       row[Object.keys(row)[col.id - 1]]
  //         .toLowerCase()
  //         .includes(col.searchbar.toLowerCase()))

  // const tagFilter = salesdoc => {
  //   return (
  //     tags
  //       .filter(
  //         label =>
  //           label.others &&
  //           label.options
  //             .map(tag => tag.tagId)
  //             .some(tag => filter.activeTags.includes(tag))
  //       )
  //       .map(label =>
  //         label.options
  //           .map(opt => opt.tagId)
  //           .filter(tagId => filter.activeTags.includes(tagId))
  //       )
  //       .every(label =>
  //         label.every(opt => salesdoc.SalesdocTags.split(', ').includes(opt))
  //       ) &&
  //     tags
  //       .filter(
  //         label =>
  //           !label.others &&
  //           label.options
  //             .map(tag => tag.tagId)
  //             .some(tag => filter.activeTags.includes(tag))
  //       )
  //       .map(label =>
  //         label.options
  //           .map(opt => opt.tagId)
  //           .filter(tagId => filter.activeTags.includes(tagId))
  //       )
  //       .every(label => label.some(opt => salesdoc.SalesdocTags.split(', ').includes(opt)))
  //   )
  // }
  const getIsTelemetri = () => {
    const columns = filter.columns 
    let isTelemetry = null;
    if (JSON.parse(columns[1].selectbar === 'false'))
      isTelemetry = false;
    else if (JSON.parse(columns[1].selectbar === 'true'))
      isTelemetry = true;
    return isTelemetry
  }
  const getMachineTags = () => {
    let machineTags = null;
    if (JSON.parse(filter.activeTags !== '') && JSON.parse(filter.activeTags != null))
      machineTags = filter.activeTags;
    return machineTags
  }
  const getMachineId = () => {
    const columns = filter.columns
    let machineId = null;
    if (JSON.parse(columns[2].MachineId !== '') && JSON.parse(columns[2].MachineId != null))
      machineId = columns[2].MachineId;
    return machineId
  }
  const getMaintenance = () => {
    const columns = filter.columns
    let createUserId = null;
    if (JSON.parse(columns[4].CreateUserId !== '') && JSON.parse(columns[4].CreateUserId != null))
      createUserId = columns[4].CreateUserId;
    return createUserId
  }
  const export46 = () => {
    setRep46(true)
    axios({ 
      method: "POST",
      url: `${API_URL}/api/reports/inms`,
      data: { 
        reportId: parseInt(46),
        machineId: getMachineId(),
        maintenanceId: getMaintenance(),
        isTelemetry: getIsTelemetri(),
        machineTags: getMachineTags()
      },
      responseType:'blob'
  }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `DZ46_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`);
      document.body.appendChild(link);
      link.click();
      setRep46(null)
  }).catch(err => {
      if (err.response.data.message === "jwt malformed") window.location.reload();
      else NotificationManager.error(err.response?.data || err.toString())
      setRep46(null)
  })

  }

  const getUI = () => {
    if (INMS != null) {
      let temp = JSON.parse(JSON.stringify(INMS));

      // console.log(salesdoc)

      return (
        <div>
          <div className='container-xl'>
            <Pagination
              {...{

                totalItems: INMS.length,
                page: filter.page,
                handleSwitchPage,
                rowsPerPage: filter.rowsPerPage,
                toggleFilter,
                resetFilter,
                filterVisibility: filter.visible
              }}
            />
            {filter.visible && (
              <>
                <FilterEx
                  {...{
                    filter,
                    setFilter,
                    columns: filter.columns,
                    data: temp,
                    resetPage,
                    resetFilter,
                    search,
                    toggleFilter,
                    enableMachineTags: true
                  }}
                />
              </>
            )}
            <div className='d-flex justify-content-end w-100'>
              <ReportBtn
                state={rep46}
                setState={setRep46}
                placement='left'
                overlayText={(<div>
                  <b>Typ raportu: <br/> Maszyny - niezaładowane <br/> Zawiera informacje o produktach,<br/> ilość których poniżej 20% od pojemnośći <br/> [DZ46]</b>
                </div>)}
                clickFnc={export46}
              />
            </div>
            <div className="card">
              <h5 className="card-header">
                {TRL_Pack.inm.inms} {/* TO DO: Add translations*/}
              </h5>
              <div className="card-body overflow-auto" style={{ maxHeight: 550 }}>

                {INMS.length ? (
                  <table className="table table-striped">
                    <thead>
                      <tr className="justify-content-center">
                        <td>INm <br />Czas zapisu, Żródło</td>
                        <td>{TRL_Pack.wi.MachineFullName}</td>
                        <td>Stan maszyny</td>
                        <td>
                          Doładowanie
                        </td>
                        <td>Rozliczenie monet</td>
                      </tr>
                    </thead>
                    <tbody>
                      {INMS
                        .sort(sortRows)
                        // .filter(reportFilter)
                        .slice(
                          (filter.page - 1) * filter.rowsPerPage,
                          filter.page * filter.rowsPerPage
                        )
                        .map((entry, idx) => (
                          <tr key={idx}>
                            <td className="">
                              <div className='d-flex flex-column justify-content-center align-items-center'>
                                {entry.INm !== '' && (<Link to={`/inm/${entry.MachineInventoryId}`} className="btn text-nowrap btn-sm btn-outline-danger">{entry.INm}</Link>)}
                                <div className="btn">{entry.Created}</div>

                              </div>
                            </td>
                            <td className="d-flex flex-column justify-content-center"><Link to={`/machine/${entry.MachineId}`} className="btn btn-sm btn-outline-link">{entry.MachineFullName}</Link></td>
                            <td><div className='d-flex flex-column justify-content-center align-items-center'>
                              <div className="btn text-nowrap">{entry.StateAndCapacity}</div>
                              <div className="btn text-nowrap">{entry.StatePercent}%</div>


                            </div>
                            </td>
                            <td><div className='d-flex flex-column justify-content-center align-items-center'>

                              {entry.DO !== '' && (<Link to={`/do/${entry.LoadMachineInventoryId}`} className="btn text-nowrap btn-sm btn-outline-info">{entry.DO}</Link>)}
                              {entry.LoadQuantity !== null && (<div className="btn text-nowrap">{entry.LoadQuantity} szt.</div>)}
                              {entry.LoadQuantity === null && (<div className="btn text-nowrap">-</div>)}
                            </div></td>
                            <td><div className='d-flex flex-column justify-content-center align-items-center'>
                              {entry.KP !== '' && (<Link to={`/kp/${entry.MachineCoinInventoryId}`} className="btn text-nowrap btn-sm btn-outline-success">{entry.KP}</Link>)}

                            </div></td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <NoResults />
                )
                }
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <div>
        {getUI()}
      </div>
    </>
  )
}

export default INMS;