import React from 'react'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import {LoaderContext} from '../../context/loader-context'
import { useContext } from 'react'
import { NavigationContext } from '../../context/navigation-context'
import { useEffect } from 'react'

export default function ErrorPage() {
    const loadContext = useContext(LoaderContext)
    const {loader} = loadContext
    const { setHeaderData } = useContext(NavigationContext)
    useEffect(() => {
        setHeaderData({text: ""})
    }, [])
    return ( !loader && (

        <div className='h-100 d-flex justify-content-center align-items-center'>
        <div>

        <h1>Nie znaleziono strony</h1>
        <div>
            <Link to="/" className='d-flex justify-content-center' >
            <button className='btn btn-xl btn-info'>

                Wróć na główną
            </button>
            </Link>
        </div>
        </div>
    </div>
    )
    )
}
