import React, { useEffect, useState } from "react";




/**
 * 
 * @param {[]object} options    - array includes objects: {name: string, value: any, ?colour: string}  
 * @param {?HTMLElement} form   - TODO: make posible to use with uncontrole forms   
 * @param {?function} setState  - Could be use instead form (for controlled forms)   
 * @param {?colour} string      - set default colour for elements in array different to cian     
 * @param {?defaultValue} string - set transfered value as default      
 * @param {?multiSelection} bool - by default false. if true - value will record to array      
 * @returns                      - ui element with buttons, could bu to use instead <select>
 */


const OnceSelector = ({options, form, setState, colour, defaultValue=null, multiSelection=false}) => {

    if(defaultValue && !Array.isArray(defaultValue) && multiSelection) throw Error("provide array as a default value")
    if(defaultValue && Array.isArray(defaultValue) && !multiSelection) throw Error("provide primitive data type as default value")


    const returnDefaultValue = () => {
        if(multiSelection) {
            return defaultValue || [] 
        } return null
    }
    const [localState, setLocalState] = useState( returnDefaultValue )



    const defaultColour = colour || "info"
    useEffect(() => {
        if(setState) setState(localState)
    }, [localState])

    const handleChangeMultiplyChoose = (value) => {
        if (localState.includes(value)) {
            return setLocalState(prev => prev.filter(i => i !== value))
        } 
        return setLocalState(prev => ([...prev, value]))
    }

    const OnceSelectorWithoutForm = () => (
        <div className="d-flex flex-row">
            {Array.from(options).map((option, idx) => (
                <button 
                    key={idx}
                    value={option.value}
                    className={
                        localState === option.value 
                        ? `btn btn-${option.colour || defaultColour} rounded-0`
                        : `btn btn-outline-${option.colour || defaultColour} rounded-0`
                    }
                    onClick={(evt) => {evt.preventDefault(); setLocalState(option.value)}}
                >
                    {option.name}
                </button>
            ))}
        </div>
    )

    const MultiSelectorWithoutForm = () => (
        <div className="d-flex flex-row">
            {Array.from(options).map((option, idx) => (
                <button 
                    key={idx}
                    value={option.value}
                    className={
                        localState.includes(option.value)  
                        ? `btn btn-${option.colour || defaultColour} rounded-0`
                        : `btn btn-outline-${option.colour || defaultColour} rounded-0`
                    }
                    onClick={(evt) => {
                        evt.preventDefault(); 
                        handleChangeMultiplyChoose(option.value)
                    }}
                >
                    {option.name}
                </button>
            ))}
        </div>
    )


    return (
        (function() {
            if(!form) {
                if(!multiSelection) {
                    return OnceSelectorWithoutForm()
                } else {
                    return MultiSelectorWithoutForm()
                }
            }
        })()
    )
}

export default OnceSelector