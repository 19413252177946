import React, { useState } from "react";
import { Config, Parameters } from "./MachineTaskConstants";
import FormSkel from "../../../../components/Modals/FormSkel";
import { NotificationManager } from 'react-notifications';
import fetchMSSQLHook from "../../../../hooks/fetchMSSQL-hook";
const MachineTaskView = ({ machineType, machineId }) => {

    const { fetchMssqlApi } = fetchMSSQLHook();

    const [commandsTasks, setCommandsTasks] = useState([]);
    const [currentParameter, setCurrentParameter] = useState(0);
    const [showModal, setShowModal] = useState({
        changeParameter: false,
        getParameter: false,
        confirmRestart: false
    });

    const addTask = (task) => {
        if (commandsTasks.includes(task))
            return;

        setCommandsTasks([...commandsTasks, task]);
    }

    const handleButtonClick = (e) => {
        switch (e.target.id) {
            case "PARAM_CHANGE-0":
                setShowModal({ ...showModal, changeParameter: true });
                break;
            case "GET_PARAMETER-0":
                setShowModal({ ...showModal, getParameter: true });
                break;
            case "RESTART-0":
                setShowModal({ ...showModal, confirmRestart: true });
                break;
            default:
                addTask(e.target.id);
                break;
        }
    }

    const handleTaskExecution = () => {
        console.log(machineType)
        console.dir(machineType)
        fetchMssqlApi("machine-tasks", {
            hideNotification: true, method: "POST", data: {
                machineId: machineId,
                tasks: commandsTasks,
                slaveNo: machineType
            }
        }, res => {
            if (res.statusCode === 200) {
                NotificationManager.success("Polecenia zostały wysłane do wykonania");
                setCommandsTasks([]);
            } else {
                NotificationManager.error("Wystąpił błąd podczas wysyłania polecenia.");
            }
        })
    }


    const ParameterChangeForm = () => {
        const onSubmit = (e) => {
            e.preventDefault();

            addTask("PARAM_CHANGE" + "-" + Parameters[e.target["name"].value].address + "-" + e.target["value"].value);
            setShowModal({ ...showModal, changeParameter: false });
        };
        return (
            showModal.changeParameter && (
                <FormSkel
                    headerText={"Zmiana parametru"}
                    handleClose={() =>
                        setShowModal({ ...showModal, changeParameter: false })
                    }
                    acceptForm="true"
                >
                    <div>
                        <form
                            onSubmit={onSubmit}
                            id="modal-form"
                        >
                            <div className="form-group">
                                <label
                                    htmlFor="name"
                                    className="form-label"
                                >
                                    Zmień parametr
                                </label>

                                <select
                                    className="form-control form-control-sm"
                                    id="name"
                                    onChange={(e) => setCurrentParameter(e.target.value)}
                                >
                                    {Parameters.map((parameter, index) => (
                                        <option
                                            key={index}
                                            value={index}
                                        >
                                            {parameter.address} - [{parameter.desc}]
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="form-group">
                                <label
                                    htmlFor="parameterValue"
                                    className="form-label"
                                >
                                    Podaj wartość parametru
                                </label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="value"
                                    placeholder={
                                        "Wartość parametru: [" +
                                        Parameters[currentParameter].min +
                                        " - " +
                                        Parameters[currentParameter].max +
                                        "]"
                                    }
                                    min={Parameters[currentParameter].min}
                                    max={Parameters[currentParameter].max}
                                />
                            </div>
                        </form>
                    </div>
                </FormSkel>
            )
        );
    };

    const ParameterGetForm = () => {
        const onSubmit = (e) => {
            e.preventDefault();

            addTask("GET_PARAMETER" + "-" + Parameters[e.target["value"].value].address);
            setShowModal({ ...showModal, getParameter: false });
        };

        return (
            showModal.getParameter && (
                <FormSkel
                    headerText={"Pobierz parametr"}
                    handleClose={() =>
                        setShowModal({ ...showModal, getParameter: false })
                    }
                    acceptForm="true"
                >
                    <div>
                        <form
                            onSubmit={onSubmit}
                            id="modal-form"
                        >
                            <div className="form-group">
                                <label
                                    htmlFor="name"
                                    className="form-label"
                                >
                                    Get parametr
                                </label>

                                <select
                                    className="form-control form-control-sm"
                                    id="value"
                                >
                                    {Parameters.map((parameter, index) => (
                                        <option
                                            key={index}
                                            value={index}
                                        >
                                            {parameter.address} - [{parameter.desc}]
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </form>
                    </div>
                </FormSkel>
            )
        );
    };


    const AcceptCard = () => (
        commandsTasks.length > 0 && (
            <>

                <div className="position-absoulte card d-flex justify-content-center flex-column">
                    <div className="card-body d-flex justify-content-center flex-column">
                        <h5 className="text-center">Aktualnie zlecone polecenia</h5>
                        <ul className="list-group">
                            {commandsTasks.map((task, index) => (
                                <li key={index} className="d-flex align-items-center justify-content-center list-group-item" style={{ gap: '10px' }}>
                                    <span className="font-weight-bolder">{task}</span>
                                    <button
                                        id={task}
                                        type="button"
                                        className="btn btn-danger btn-sm"
                                        onClick={() => setCommandsTasks(commandsTasks.filter((item) => item !== task))}
                                    >
                                        Usuń
                                    </button>
                                </li>
                            ))}
                        </ul>
                        <div className="d-flex items-center justify-content-center">
                            <button className="btn mt-3 btn-primary btn-md w-50" type="button" onClick={handleTaskExecution}>Wykonaj polecenie</button>

                        </div>
                    </div>
                </div>
            </>
        )
    )

    const ConfirmRestart = () => {
        const onSubmit = () => {
            addTask("RESTART-0");
            setShowModal({ ...showModal, confirmRestart: false });
        }

        return (
            showModal.confirmRestart && (
                <FormSkel
                    headerText={"Potwierdź restart"}
                    handleClose={() =>
                        setShowModal({ ...showModal, confirmRestart: false })
                    }
                    acceptForm="true"
                    customName={"Zrestartuj"}
                    warning={true}
                >
                    <div>
                        <form
                            onSubmit={onSubmit}
                            id="modal-form"
                        >
                            <div className="form-group">
                                <label
                                    htmlFor="name"
                                    className="form-label"
                                >
                                    Jeżeli automat będzie w trakcie wydawania produktu, zdalny restart automatu może prowadzić do zablokowania automatu.
                                </label>
                                <p>Jesteś pewien?</p>
                            </div>
                        </form>
                    </div>
                </FormSkel>
            )
        )
    }


    const ControlUI = () => (
        <div>
            <div>
                {Config.reduce((acc, item, index, array) => {
                    if (index % 2 === 0) {
                        acc.push(
                            <div className="row" key={index}>
                                <div className="col-sm">
                                    <div className="form-group">
                                        <label htmlFor={`btn-${index}`} className="form-label">
                                            {item.name}
                                        </label>
                                        <div className="row">
                                            {item.options.map((option, optionIndex) => (
                                                <div key={optionIndex} className="col-sm">
                                                    <input
                                                        key={optionIndex}
                                                        type="button"
                                                        className="form-control"
                                                        onClick={handleButtonClick}
                                                        id={`${item.taskType}-${optionIndex}`}
                                                        value={option.name}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                {array[index + 1] && (
                                    <div className="col-sm">
                                        <div className="form-group">
                                            <label htmlFor={`btn-${index + 1}`} className="form-label">
                                                {array[index + 1].name}
                                            </label>
                                            <div className="row">
                                                {array[index + 1].options.map((option, optionIndex) => (
                                                    <div key={optionIndex} className="col-sm">
                                                        <input
                                                            key={optionIndex}
                                                            type="button"
                                                            className="form-control"
                                                            onClick={handleButtonClick}
                                                            id={`${array[index + 1].taskType}-${optionIndex}`}
                                                            value={option.name}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        );
                    }
                    return acc;
                }, [])}
            </div>
        </div>
    );

    return (
        <div className="row w-100">
            <div className="col-12 mb-2">
                <ControlUI />
                <AcceptCard />
                {ConfirmRestart()}
                {ParameterGetForm()}
                {ParameterChangeForm()}
            </div>
        </div>
    );
}

export default MachineTaskView;