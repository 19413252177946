import React, {useEffect, useState, useContext} from 'react';
import MachinesImages from '../../../components/Machine/MachinesImages';
import ClientPersonalisation from '../Client/client.js'
import { NavigationContext } from '../../../context/navigation-context';
import { useParams, useHistory } from 'react-router-dom/cjs/react-router-dom.min';




const Personalise = () => {

    const { setHeaderData } = useContext(NavigationContext),
    history = useHistory(),
    params = useParams()
    const changeType = (typeId) => {
        setType(typeId)
        switch(typeId) {
            case 0: 
                history.replace("/personalise/machine")
                break;
            case 1: 
                history.replace('/personalise/user')
                break;
        }
    }
    const getType = () => {
        if (params.type) {
            switch(params.type) {
                case "machine": 
                    return 0;
                case "user": 
                    return 1;
            }
        } else return 0
    } 
    const [type, setType] = useState(getType())
    useEffect(() => {
        changeType(type)
        setHeaderData({text: "Personalizacja"})
    }, [])
    return (
        <>
            <div>
                <ul className="nav nav-tabs machine-tabs mb-3">
                    <li className="nav-item">
                        <button
                            className={`nav-link btn ${type === 0 && 'active'}`}
                            onClick={() => type !== 0 && changeType(0)}
                            tabIndex="0"
                        >
                            Ustawienia R24 VMC
                        </button>
                    </li>
                    {/* <li className="nav-item">
                        <button
                        className={`nav-link btn ${type === 1 && 'active'}`}
                        onClick={() => type !== 1 && changeType(1)}
                        tabIndex="0"
                        >
                            Użytkownik
                        </button>
                    </li> */}
                </ul>
            </div>
            {
                type === 0 && <MachinesImages /> ||
                type === 1 && <ClientPersonalisation />
            }
            
            
        </>
    )

}
export default Personalise;