import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { LangContext } from '../../context/lang-context'
import useFetch from '../../hooks/fetchMSSQL-hook'
import Pagination from '../../components/Pagination/Pagination'
import FilterEx from '../../components/Filter/FilterEx'
import NoResults from '../../components/NoResults/NoResults'
import moment from "moment";
import { NavigationContext } from '../../context/navigation-context'

import axios from "axios"
import { API_URL } from '../../config/config'
import { NotificationContext } from '../../context/notification-context'
import ReportBtnForArr from '../../components/Buttons/ReportsBtn/ReportBtnForArr'

const INS = () => {
  const { TRL_Pack } = useContext(LangContext)
  const { fetchMssqlApi } = useFetch()

  const { setHeaderData } = useContext(NavigationContext)
  const { ErrorNotification, SuccessNofication } = useContext(NotificationContext)
  const [INS, setINS] = useState(null)
  const [rep31, setRep31] = useState({Report: null, Id: null})
  const [rep26, setRep26] = useState({Report: null, Id: null})
  const [rep27, setRep27] = useState({Report: null, Id: null})
  const [rep28, setRep28] = useState({Report: null, Id: null})

  const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
  const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))

  const defaultFilter = {
    showIndexes: false,
    page: 1,
    disableIndexes: true,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 25, 50, 100, 1000],
    visible: false,
    //sortByColumns: true,
    sortByColumns: false,
    //sortBy: '3 | asc | text',
    //activeTags: [],
    columns: [
      {
        id: 1,
        name: 'Serwisant',
        searchable: true,
        sortable: true,
        type: 'lookup',
        CreateUserId: null
      }, {
        id: 2,
        name: 'Zakres czasowy',
        searchable: false,
        sortable: false,
        type: 'datetimeRange',
        dateTimeRangeFrom: moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss'),
        dateTimeRangeTo: moment().format('YYYY-MM-DDT23:59:59')
      }
    ]
  }

  const [filter, setFilter] = useState(() => {
    if (localStorage.getItem('insFilter')) {
      return JSON.parse(localStorage.getItem('insFilter'));
    }
    return defaultFilter;
  })

  const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
  const resetFilter = () => {
    setFilter(defaultFilter);

    //    search();
    getINS(moment().add(-30, 'd').format('YYYY-MM-DDTHH:mm:ss'), moment().format('YYYY-MM-DDT23:59:59'), null, null);
  }

  const export26 = (warehouseInventoryId) => {
    setRep26({Report: 26, Id: warehouseInventoryId})

    axios({
        method: "POST",
        url: `${API_URL}/api/reports/ins`,
        data: { 
          reportId: parseInt(26),
          warehouseInventoryId: warehouseInventoryId
        },
        responseType:'blob'
    }).then((res) => {
        setRep26({Report: null, Id: null})
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `DZ26_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`);
        document.body.appendChild(link);
        link.click();
    }).catch(err => {
        setRep26({Report: null, Id: null})
        if (err.response.data.message === "jwt malformed") window.location.reload();
        else ErrorNotification(err.response?.data || err.toString())
    })
  }

  const export27 = (warehouseInventoryId) => {
    setRep27({Report: 27, Id: warehouseInventoryId})
    axios({
        method: "POST",
        url: `${API_URL}/api/reports/ins`,
        data: { 
          reportId: parseInt(27),
          warehouseInventoryId: warehouseInventoryId
        },
        responseType:'blob'
    }).then((res) => {
        setRep27({Report: null, Id: null})
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `DZ27_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`);
        document.body.appendChild(link);
        link.click();
    }).catch(err => {
        setRep27({Report: null, Id: null})
        if (err.response.data.message === "jwt malformed") window.location.reload();
        else ErrorNotification(err.response?.data || err.toString())
    })
  }

  const export28 = (warehouseInventoryId) => {
    setRep28({Report: 28, Id: warehouseInventoryId})
    axios({
        method: "POST",
        url: `${API_URL}/api/reports/ins`,
        data: { 
          reportId: parseInt(28),
          warehouseInventoryId: warehouseInventoryId
        },
        responseType:'blob',
        timeout: 1000 * 600 // Wait for 5 seconds
    }).then((res) => {
        setRep28({Report: null, Id: null})
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `DZ28_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`);
        document.body.appendChild(link);
        link.click();
    }).catch(err => {
        setRep28({Report: null, Id: null})
        if (err.response.data.message === "jwt malformed") window.location.reload();
        else ErrorNotification(err.response?.data || err.toString())
    })
  }

  const export31 = (warehouseInventoryId) => {
    setRep31({Report: 31, Id: warehouseInventoryId})
    // console.log(warehouseInventoryId);
   
    axios({
        method: "POST",
        url: `${API_URL}/api/reports/ins`,
        data: { 
          reportId: parseInt(31),
          warehouseInventoryId: warehouseInventoryId
        },
        responseType:'blob'
    }).then((res) => {
      setRep31({Report: null, Id: null})
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `DZ31_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`);
        document.body.appendChild(link);
        link.click();
    }).catch(err => {
        setRep31({Report: null, Id: null})
        if (err.response.data.message === "jwt malformed") window.location.reload();
        else ErrorNotification(err.response?.data || err.toString())
    })

  }

  const search = () => {
    //TODO: unhardcode column idx, make it configurable
    const columns = filter.columns

    for (let a in columns) {
      if (columns[a].searchbar === undefined) {
        columns[a].searchbar = null
      }
    }
/*
    let warehouseId = null;
    if (JSON.parse(columns[2].MachineId != '') && JSON.parse(columns[2].MachineId != null))
    warehouseId = columns[2].MachineId;
*/
    let createUserId = null;
    if (JSON.parse(columns[0].CreateUserId !== '') && JSON.parse(columns[0].CreateUserId !== null))
      createUserId = columns[0].CreateUserId;

    //set default create from / to date time
    let fromCreateDateTime = moment().add(-30, 'd').format('YYYY-MM-DDTHH:mm:ss');
    let toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59');

    if (JSON.parse(columns[1].dateTimeRangeFrom !== '') && JSON.parse(columns[1].dateTimeRangeFrom !== null))
      //TODO: parse manually entered(?)
      fromCreateDateTime = moment(columns[1].dateTimeRangeFrom).add(1, 'h').format('YYYY-MM-DDTHH:mm:ss');

    if (JSON.parse(columns[1].dateTimeRangeTo !== '') && JSON.parse(columns[1].dateTimeRangeTo !== null))
      //TODO: parse manually entered(?)
      toCreateDateTime = moment(columns[1].dateTimeRangeTo).add(1, 'h').format('YYYY-MM-DDTHH:mm:ss')
      // .replace('T', ' ');



    getINS(fromCreateDateTime, toCreateDateTime,null/* warehouseId*/, createUserId);
  }

  const getINS = (fromCreateDateTime = moment().add(-30, 'd').format('YYYY-MM-DDTHH:mm:ss'), toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59'), warehouseId = null, createUserId = null) => {
    fetchMssqlApi('in-ins', {
      method: 'POST',
      hideNotification: true,
      data: {
        clientId: localStorage.getItem("clientId"),
        fromCreateDateTime: fromCreateDateTime,
        toCreateDateTime: toCreateDateTime,
        warehouseId: warehouseId,
        createUserId: createUserId
      }
    }, response => {
      setINS(response);
      console.log(response)
    })
  }


  useEffect(() => {
    getINS()
    setHeaderData({
      text: TRL_Pack.in.ins
    });
  }, [])

  useEffect(() => localStorage.setItem('insFilter', JSON.stringify(filter)), [
    filter
  ])


  const sortRows = (a, b) => {
  }

  const getUI = () => {
    if (INS !== null) {
      let temp = JSON.parse(JSON.stringify(INS));



      return (
        <div>
          <div className='container-fluid'>
            <Pagination
              {...{

                totalItems: INS.length,
                page: filter.page,
                handleSwitchPage,
                rowsPerPage: filter.rowsPerPage,
                toggleFilter,
                resetFilter,
                filterVisibility: filter.visible
              }}
            />
            {filter.visible && (
              <>
                <FilterEx
                  {...{
                    filter,
                    setFilter,
                    columns: filter.columns,
                    data: temp,
                    resetPage,
                    resetFilter,
                    search,
                    toggleFilter,
                    enableMachineTags: false
                  }}
                />
              </>
            )}
            <div className="card">
              <h5 className="card-header">
              <div>
                        
                        <div className='d-flex justify-content-between'>
                            <div className='h5 mt-2 mb-1'>{TRL_Pack.in.ins}</div>
                            <Link to={`/in/0`} className="btn text-nowrap btn-sm btn-outline-secondary mt-1 mb-1"><i className="fas fa-plus" /></Link>
                        </div>
                </div>
                 {/* TO DO: Add translations*/}
              </h5>
              <div className="card-body overflow-auto" style={{ maxHeight: 550 }}>

                {INS.length ? (
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <td className="text-center">Nr <br />Czas zapisu, Źródło</td>
                        <td className="text-center">Magazyn<br />Komentarz</td>
                        <td className="text-center">Data / Czas inwentaryzacji</td>
                        <td className="text-center">Raporty</td>
                      </tr>
                    </thead>
                    <tbody>
                      {INS
                        .sort(sortRows)
                        // .filter(reportFilter)
                        .slice(
                          (filter.page - 1) * filter.rowsPerPage,
                          filter.page * filter.rowsPerPage
                        )
                        .map((entry, idx) => (
                          <tr key={idx}>
                            <td className="">
                              <div className='d-flex flex-column justify-content-center align-items-center'>
                                <Link to={`/in/${entry.WarehouseInventoryId}`} className="btn text-nowrap btn-sm btn-outline-secondary">{entry.IN}</Link>
                                <div className="text-nowrap">{entry.Created}</div>
                              </div>
                            </td>
                            <td className="d-flex flex-column justify-content-center">{entry.WarehouseFullName}</td>
                            <td className="d-flex flex-column justify-content-center">{entry.Comment}</td>
                            <td><div className='d-flex flex-column justify-content-center align-items-center'>
                              <div className="text-nowrap">{entry.IssuedOn}</div>
                            </div></td><td>
                            <div className='d-flex flex-row justify-content-end'>
                    <div className="text-nowrap">
                      <ReportBtnForArr
                        state={rep31}
                        setState={setRep31}
                        overlayText={
                            <b>Typ raportu:<br />Inwentaryzacja - szczegóły dok. mag. wg produktów [DZ31]</b>
                        }
                        btnColor='danger'
                        value={{Report: 31, Id: entry.WarehouseInventoryId}}
                        clickFnc={() => export31(entry.WarehouseInventoryId)}
                      />
                    {/* <OverlayTrigger
                        id="rep31"
                        placement={'left'}
                       // delay={{ show: 250, hide: 1000 }}
                        overlay={
                          <Tooltip>
                            <b>Typ raportu:<br />Inwentaryzacja - szczegóły dok. mag. wg produktów [DZ31]</b></Tooltip>
                        }
                      >
                        <button
                          className="d-inline m-1 btn btn-danger float-right fas fa-xs fa-download"
                          onClick={() => export31(entry.WarehouseInventoryId)}
                        ></button>
                      </OverlayTrigger> */}
                      <ReportBtnForArr
                        state={rep28}
                        setState={setRep28}
                        overlayText={
                            <b>Typ raportu:<br />Inwentaryzacja - stan oczekiwany i z natury [DZ28]</b>
                        }
                        value={{Report: 28, Id: entry.WarehouseInventoryId}}
                        btnColor='primary'
                        clickFnc={() => export28(entry.WarehouseInventoryId)}
                      />
                      {/* <OverlayTrigger
                        id="rep28"
                        placement={'left'}
                        //delay={{ show: 250, hide: 1000 }}
                        overlay={
                          <Tooltip>
                            <b>Typ raportu:<br />Inwentaryzacja - stan oczekiwany i z natury [DZ28]</b></Tooltip>
                        }
                      >
                        <button
                          className="d-inline m-1 btn btn-primary float-right fas fa-xs fa-download"
                          onClick={() => export28(entry.WarehouseInventoryId)}
                        ></button>
                      </OverlayTrigger> */}
                      <ReportBtnForArr
                        state={rep27}
                        setState={setRep27}
                        overlayText={
                            <b>Typ raportu:<br />Inwentaryzacja - lista dokumentów ksiegowych [DZ27]</b>
                        }
                        value={{Report: 27, Id: entry.WarehouseInventoryId}}
                        btnColor='secondary'
                        clickFnc={() => export27(entry.WarehouseInventoryId)}
                      />
                      {/* <OverlayTrigger
                        id="rep27"
                        placement={'left'}
                       // delay={{ show: 250, hide: 1000 }}
                        overlay={
                          <Tooltip>
                            <b>Typ raportu:<br />Inwentaryzacja - lista dokumentów ksiegowych [DZ27]</b></Tooltip>
                        }
                      >
                        <button
                          className="d-inline m-1 btn btn-secondary float-right fas fa-xs fa-download"
                          onClick={() => export27(entry.WarehouseInventoryId)}
                        ></button>
                      </OverlayTrigger> */}
                      <ReportBtnForArr
                        state={rep26}
                        setState={setRep26}
                        overlayText={
                            <b>Typ raportu:<br />Inwentaryzacja - lista dokumentów magazynowych [DZ26]</b>
                        }
                        value={{Report: 26, Id: entry.WarehouseInventoryId}}
                        btnColor='success'
                        clickFnc={() => export26(entry.WarehouseInventoryId)}
                      />
                      {/* <OverlayTrigger
                        id="rep26"
                        placement={'left'}
                        //delay={{ show: 250, hide: 1000 }}
                        overlay={
                          <Tooltip>
                            <b>Typ raportu:<br />Inwentaryzacja - lista dokumentów magazynowych [DZ26]</b></Tooltip>
                        }
                      >
                        <button
                          className="d-inline m-1 btn btn-success float-right fas fa-xs fa-download"
                          onClick={() => export26(entry.WarehouseInventoryId)}
                        ></button>
                      </OverlayTrigger> */}
                    </div>
                  </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                ) : (
                  <NoResults />
                )
                }
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <div>
        {getUI()}
      </div>
    </>
  )
}

export default INS;