import React, { useState } from 'react'
import FormSkel from './FormSkel'

export default function BagForm({handleClose, handleSubmit, bagData}) {
    const [data, setData] = useState(bagData || {Name: ""})

    return (
        <FormSkel
            headerText={bagData ? "Edytuj worek" : "Nowy worek"}
            onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()} 
            data={data} handleClose={handleClose} handleSubmit={evt => {handleSubmit(evt,setData)}}
            disableSubmit={!data.Name}
        >
            <form 
                autoComplete='off'
                id='modal-form'
                onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()} 
                onSubmit={evt => handleSubmit(evt,setData)}>
                <div>
                    <label className="h6">Nazwa</label>
                    <input
                        autoComplete='off'
                        minLength={1}
                        onFocus={() => setData({...data, Name: ""})}
                        name="name"
                        className={`form-control ${!data.Name && `invalid-input`}`}
                        value={data.Name}
                        onChange={evt => setData({...data, Name: evt.target.value})}
                    />
                </div>
            </form>
        </FormSkel>
    )
}
