import React, {useEffect, useContext, useState, useRef} from 'react'
import fetchHook from '../../hooks/fetchMSSQL-hook'
import { NavigationContext } from '../../context/navigation-context'
import { NotificationManager } from 'react-notifications'
import { LangContext } from '../../context/lang-context'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'


export default function Permissions() {
    const { fetchMssqlApi } = fetchHook(),
    {TRL_Pack} = useContext(LangContext),
    { setHeaderData, state, navlinks, dispatch } = useContext(NavigationContext),
    [rolePermissions, setRolePermissions] = useState([]),
    // ======
    [permissionsList, setPermissionsList] = useState([]),
    // ==========
    [permissions, setPermissions] = useState([]),
    [initialRolePermissions, setInitialRolePermissions] = useState([]),
    [changedPermissions, setChangedPermissions] = useState([]),
    [changedPermissionsOrder, setChangedPermissionsOrder] = useState([]),
    [changed, setChanged] = useState(false),
    [section, setSection] = useState("Permissions"),
    [selectedPermission, setSelectedPermission] = useState(null)
    
    
    // const permClinetAndServerSideNames = new Map(
    //     ["VD_PRODUCTS", TRL_Pack.navbar.products]
    //     ["VD_PAS", TRL_Pack.navbar.pas]
    //     ["VD_WIS", TRL_Pack.navbar.wis]
    //     ["VD_KPS", "Rozliczenia"]
    //     ["VD_DOS", TRL_Pack.navbar.dos]
    //     ["VD_ROS", TRL_Pack.navbar.ros]
    //     ["VD_INMS", "Stan bieżący"]
    //     ["VD_INS", TRL_Pack.navbar.ins]
    //     ["VD_WZS", TRL_Pack.navbar.wzs]
    //     ["VD_PZS", TRL_Pack.navbar.pzs]
    //     ["VD_STS", TRL_Pack.navbar.sts]
    //     ["VD_ZWS", TRL_Pack.navbar.zws]
    //     ["VD_FZS", TRL_Pack.navbar.fzs]
    //     ["VD_KOS", TRL_Pack.navbar.kos]
    //     ["VD_CATEGORIES", TRL_Pack.navbar.categories]
    //     ["VD_PRODUCT_GROUPS", "Grupy"]
    //     ["VD_MACHINES", TRL_Pack.navigation.machines]
    //     ["VD_PRODUCT_CATALOG", TRL_Pack.navigation.catalog]
    //     ["VD_TAGS", TRL_Pack.navigation.tags]
    //     ["VD_REPORTS", TRL_Pack.navigation.reports]
    //     ["VD_REPORT_CONDITIONS", TRL_Pack.summaries.summaries]
    //     ["VD_PAYMENT_CODES", TRL_Pack.codes.codes]
    //     ["VD_PERSONALISE", TRL_Pack.navbar.personalise]
    //     ["VD_permissions", TRL_Pack.navbar.permissions]
    //     ["VD_USERS", TRL_Pack.navbar.users]
    //     ["VD_TERMINALS", TRL_Pack.navigation.terminals]
    //     ["VD_TRXS", TRL_Pack.navigation.trxs]
    //     ["VD_MONITORING", "Monitoring"]
    //     ["VD_LASTVI", "Ostatnie wizyty"]
    //     ["VD_MM", "Przesunięcia magazynowe"]
    //     ["VD_WAREHOUSES", "Magazyny"]
    //     ["VD_MACHINE_TYPES", "Typy automatów"]
    //     ["VD_BAGS", "Worki na monety"]
    //     ["VD_CONTRACTORS", "Kontrahenci"]
    //     ["VD_BASKETS", "Zestawy promocyjne"]
    //     ["VD_EVENTS", "Typy zdarzeń"]
    //     ["VD_LOCATIONS", "Lokalizacje"]
    //     ["VD_PREPAID", "Karty prepaid"]
    //     ["VD_WKF", "Kasy fiskalne"]
    //     ["VD_CR", "Powody korekt"]
    //     ["VD_REPORT_5", "Raport Sprzedaż wg produktów"]
    //     ["VD_REPORT_6", "Raport Sprzedaż wg maszyn"]
    //     ["VD_REPORT_7", "Raport Zysk wg produktów"]
    //     ["VD_REPORT_8", "Raport Zysk wg maszyn"]
    //     ["VD_REPORT_9", "Raport Wskaźnik rentowności sprzedaży"]
    //     ["VD_REPORT_10", "Raport Wskaźnik obrotu magazynowego"]
    //     ["VD_REPORT_11", "Raport Bieżący zapas"]
    //     ["VD_REPORT_12", "Raport Wystarczalność"]
    //     ["VD_REPORT_13", "Raport Zwroty"]
    //     ["VD_REPORT_14", "Raport Straty"]
    //     ["VD_REPORT_15", "Raport Rozrachunki - nierozliczone"]
    //     ["VD_REPORT_16", "Raport Inwentaryzacja magazynu"]
    //     ["VD_REPORT_19", "Raport Najlepsza/najgorsza sprzedaż wg sprężyn"]
    //     ["VD_REPORT_22", "Raport Zakupy - wg produktów"]
    //     ["VD_REPORT_23", "Raport Zakupy - wg dostawców"]
    //     ["VD_REPORT_24", "Raport Rozrachunki - rozliczone"]
    //     ["VD_REPORT_25", "Raport Maszyny - szczegóły sprzedaży"]
    //     ["VD_REPORT_26", "Raport Inwentaryzacja - lista dokumentów magazynowych"]
    //     ["VD_REPORT_27", "Raport Inwentaryzacja - lista dokumentów ksiegowych"]
    //     ["VD_REPORT_28", "Raport Inwentaryzacja - stanu oczekiwany i z natury"]
    //     ["VD_REPORT_31", "Raport Inwentaryzacja - szczegóły dok. mag. wg produktu"]
    //     ["VD_REPORT_32", "Raport Maszyny - zużycie składników"]
    //     ["VD_REPORT_33", "Raport Faktura Zakupu"]
    //     ["VD_REPORT_34", "Raport Paragon"]
    //     ["VD_REPORT_35", "Raport Zwrot"]
    //     ["VD_REPORT_36", "Raport Strata"]
    //     ["VD_REPORT_37", "Raport Wydanie zewnętrzne"]
    //     ["VD_REPORT_38", "Raport Rozchód wewnętrzny"]
    //     ["VD_REPORT_39", "Raport Przesunięcie międzymagazynowe minus"]
    //     ["VD_REPORT_40", "Raport Przyjęcie zewnętrzne"]
    //     ["VD_REPORT_41", "Raport Przychód wewnętrzny"]
    //     ["VD_REPORT_42", "Raport Przesunięcie międzymagazynowe plus"]
    //     ["VD_REPORT_43", "Raport Korekty"]
    //     ["VD_REPORT_44", "Raport Podsumowanie pracy serwisanta"]
    //     ["VD_REPORT_45", "Raport Maszyny - puste"]
    //     ["VD_REPORT_46", "Raport Maszyny - niezaładowane"]
    //     ["VD_REPORT_47", "Raport Maszyny - średnie doładowanie wg serwisantów"]
    //     ["VD_REPORT_48", "Raport Produkty - średnie doładowanie"]
    //     ["VD_REPORT_49", "Raport Operacje magazynowe wg produktów"]
    //     ["VD_REPORT_50", "Raport Operacje księgowe wg produktów"]
    //     ["VD_REPORT_51", "Raport Wizyty - raport szczegółowy"]
    //     ["VD_REPORT_52", "Raport Utworzone dokumenty"]
    //     ["VD_REPORT_53", "Raport Zmodyfikowane dokumenty"]
    //     ["VD_REPORT_54", "Raport Zapotrzebowanie"]
    //     ["VD_REPORT_55", "Raport Zaleganie na maszynach"]
    //     ["VD_REPORT_56", "Raport Maszyny - historia modyfikacji"]
    //     ["VD_REPORT_57", "Raport Maszyny - Bieżący stan"]
    //     ["VD_REPORT_58", "Raport Maszyny - Bieżący stan wg produktów"]
    //     ["VD_REPORT_59", "Raport Płatności"]
    //     ["VD_REPORT_60", "Raport Maszyny - średnie doładowanie"]
    //     ["VD_REPORT_61", "Raport Najlepsza/najgorsza sprzedaż wg produktów"]
    //     ["VD_REPORT_62", "Raport Faktury - płatności"]
    //     ["VD_REPORT_63", "Raport Brak magazynowy"]
    //     ["VD_REPORT_64", "Raport Nadstan magazynowy"]
    //     ["VD_REPORT_65", "Raport Straty ze sprzedaży"]
    //     ["VD_REPORT_66", "Raport Niska rentowność sprzedaży"]
    //     ["VD_REPORT_67", "Raport Produkty - stan bieżący"]
    //     ["VD_REPORT_68", "Raport Operacje magazynowe wg. produktu"]
    //     ["VD_REPORT_69", "Raport Bieżąca konfiguracja na maszynach"]
    //     ["VD_REPORT_70", "Raport Szczegóły sprzedaży wg produktów/receptur"]
    //     ["VD_REPORT_71", "Raport Stan bieżący"]
    //     ["VD_REPORT_72", "Raport Konfiguracja maszyny"]
    //     ["VD_REPORT_73", "Raport Brakujace produkty na maszynie"]
    //     ["VD_REPORT_74", "Raport Produkty - doladowania"]
    //     ["VD_REPORT_75", "Raport Wizyty serwisantów wg tras"]
    //     ["VD_REPORT_76", "Raport Transakcje"]
    //     ["VD_REPORT_77", "Raport Brakujące produkty - wersja skrócona"]
    //     ["VD_REPORT_78", "Raport Produkty - doładowania wg dat"]
    //     ["VD_REPORT_79", "Raport Podsumowanie wizyt serwisantów wg tras"]
    //     ["VD_REPORT_81", "Raport Sprzedaż wg lokalizacji"]
    //     ["VD_REPORT_82", "Raport Sprzedaż wg tras"]
    // )

    // const listener =  evt => {
    //     console.log("EventListener('keydown')")
    //     if (evt.keyCode === 38 && selectedPermission) {
    //         let permission = permissions.find(i => i.PermissionId === selectedPermission)
    //         if (!permission) return
    //         upPermission(permission.MenuOrder)
    //         permission = null
    //         // document.removeEventListener(listener)
    //     }
    //     if (evt.keyCode === 40 && selectedPermission) {
    //         let permission = permissions.find(i => i.PermissionId === selectedPermission)
    //         if (!permission) return
    //         downPermission(permission.MenuOrder)
    //         permission = null
    //         // document.removeEventListener(listener)
    //     }
    // }
    // useEffect(() => selectedPermission ? document.addEventListener('keydown', listener) : document.removeEventListener('keydown', listener), [selectedPermission])
    const getRolePermissions = () => {
        fetchMssqlApi('role-permissions', {}, res => { 
            setInitialRolePermissions(res)
        })
    }

    const getPermissionsList = () => {
        fetchMssqlApi('permissions-list', {}, res => { 
            setPermissionsList(res)
        })
    }
    const getPermissions = () => {
        setRolePermissions(state.permissions)
    }
    const upPermission = (order) => {
        setChanged(true)
        let item = permissions[+order - 1]
        let item2 = permissions[+order -2]
        if(!item2) return
        let arr = [...permissions]
        changedPermissionsOrder.indexOf(permOrd =>( permOrd.PermissionId === item.PermissionId && permOrd.MenuOrder === +order - 1)) !== -1 
            ? setChangedPermissionsOrder(prev => prev.filter(i => i.PermissionId !== item.PermissionId)) 
            : setChangedPermissionsOrder(prev => [...prev, {PermissionId: item.PermissionId, MenuOrder: +order - 1}])
        changedPermissionsOrder.indexOf(permOrd => (permOrd.PermissionId === item2.PermissionId && permOrd.MenuOrder === item2.MenuOrder -2)) !== -1  
            ? setChangedPermissionsOrder(prev => prev.filter(i => i.PermissionId !== item2.PermissionId)) 
            : setChangedPermissionsOrder(prev => [...prev, {PermissionId: item2.PermissionId, MenuOrder: order}])
        // setChangedPermissionsOrder(prev => [...prev, {PermissionId: item.PermissionId, MenuOrder: +order - 1}, {PermissionId: item2.PermissionId, MenuOrder: order}])
        arr.splice(+order -2, 2, ...[{...item, MenuOrder: +order - 1}, {...item2, MenuOrder: order}])

        setPermissions(arr)
    }
    const downPermission = (order) => {
        setChanged(true)
        let item = permissions[+order - 1]
        let item2 = permissions[+order]
        if(!item2) return
        let arr = [...permissions]
        changedPermissionsOrder.indexOf(permOrd => (permOrd.PermissionId === item.PermissionId && permOrd.MenuOrder === +order + 1)) !== -1 
            ? setChangedPermissionsOrder(prev => prev.filter(i => i.PermissionId !== item.PermissionId))
            : setChangedPermissionsOrder(prev => [...prev, {PermissionId: item.PermissionId, MenuOrder: +order + 1}])
        changedPermissionsOrder.indexOf(permOrd => (permOrd.PermissionId === item2.PermissionId && permOrd.MenuOrder === order)) !== -1  
            ? setChangedPermissionsOrder(prev => prev.filter(i => i.PermissionId !== item2.PermissionId)) 
            : setChangedPermissionsOrder(prev => [...prev, {PermissionId: item2.PermissionId, MenuOrder: order}])
        // setChangedPermissionsOrder(prev => [...prev, {PermissionId: item.PermissionId, MenuOrder: +order + 1}, {PermissionId: item2.PermissionId, MenuOrder: order}])
        arr.splice(+order-1, 2, ...[{...item2, MenuOrder: order}, {...item, MenuOrder: +order + 1}])
        setPermissions(arr)
    }
    const getData = (type = "Permissions") => {
        const rpArr = []
        if (!rolePermissions || !rolePermissions.length) return null

        // const headers =  rolePermissions.map(rpm => ({RoleName: rpm.Name, RoleId: rpm.RoleId}))
        const permissionsNameArr = []
        for (const [key, value] of Object.entries(rolePermissions[0])) {
            typeof value === "boolean" && permissionsNameArr.push(key)
        }
        if (!permissionsNameArr.length) {
            NotificationManager.error("Can`t get permission name")
            return
        } 
        
        permissionsNameArr.forEach(permName => {
            // console.log("========================")
            // console.log(permClinetAndServerSideNames.get(permName))
            // console.log("========================")
            let Name = ""
            switch (permName) {
                case "VD_PRODUCTS":
                    Name = TRL_Pack.navbar.products
                    break;
                case "VD_PAS":
                    Name = TRL_Pack.navbar.pas
                    break;
                case "VD_WIS":
                    Name = TRL_Pack.navbar.wis
                    break;
                case "VD_KPS":
                    Name = "Rozliczenia"
                    break;
                case "VD_DOS":
                    Name = TRL_Pack.navbar.dos
                    break;
                case "VD_ROS":
                    Name = TRL_Pack.navbar.ros
                    break;
                case "VD_INMS":
                    Name = "Stan bieżący"
                    break;
                case "VD_INS":
                    Name = TRL_Pack.navbar.ins
                    break;
                case "VD_WZS":
                    Name = TRL_Pack.navbar.wzs
                    break;
                case "VD_PZS":
                    Name = TRL_Pack.navbar.pzs
                    break;
                case "VD_STS":
                    Name = TRL_Pack.navbar.sts
                    break;
                case "VD_ZWS":
                    Name = TRL_Pack.navbar.zws
                    break;
                case "VD_FZS":
                    Name = TRL_Pack.navbar.fzs
                    break;
                case "VD_KOS":
                    Name = TRL_Pack.navbar.kos
                    break;
                case "VD_CATEGORIES":
                    Name = TRL_Pack.navbar.categories
                    break;
                case "VD_PRODUCT_GROUPS":
                    Name = "Grupy"
                    break;
                case "VD_MACHINES":
                    Name = TRL_Pack.navigation.machines
                    break;
                case "VD_PRODUCT_CATALOG":
                    Name = TRL_Pack.navigation.catalog
                    break;
                case "VD_TAGS":
                    Name = TRL_Pack.navigation.tags
                    break;
                case "VD_REPORTS":
                    Name = TRL_Pack.navigation.reports
                    break;
                case "VD_REPORT_CONDITIONS":
                    Name = TRL_Pack.summaries.summaries
                    break;
                case "VD_PAYMENT_CODES":
                    Name = TRL_Pack.codes.codes
                    break;
                case "VD_PERSONALISE":
                    Name = TRL_Pack.navbar.personalise
                    break;
                case "VD_permissions":
                    Name = TRL_Pack.navbar.permissions
                    break;
                case "VD_USERS":
                    Name = TRL_Pack.navbar.users
                    break;
                case "VD_TERMINALS":
                    Name = TRL_Pack.navigation.terminals
                    break;
                case "VD_TRXS":
                    Name = TRL_Pack.navigation.trxs
                    break;
                case "VD_MONITORING":
                    Name = "Monitoring"
                    break;
                case "VD_LASTVI":
                    Name = "Ostatnie wizyty"
                    break;
                case "VD_MM":
                    Name = "Przesunięcia magazynowe"
                    break;
                case "VD_WAREHOUSES":
                    Name = "Magazyny"
                    break;
                case "VD_MACHINE_TYPES":
                    Name = "Typy automatów"
                    break;
                case "VD_BAGS":
                    Name = "Worki na monety"  
                    break; 
                case "VD_CONTRACTORS":
                    Name = "Kontrahenci"
                    break;
                case "VD_BASKETS":
                    Name = "Zestawy promocyjne"
                    break;
                case "VD_EVENTS":
                    Name = "Typy zdarzeń"
                    break;
                case "VD_LOCATIONS":
                    Name = "Lokalizacje"
                    break;
                case "VD_PREPAID":
                    Name = "Karty prepaid"
                    break;
                case "VD_WKF":
                    Name = "Kasy fiskalne"
                    break;
                case "VD_CR":
                    Name = "Powody korekt"
                    break;
                case "VD_REPORT_5":
                    Name = "Raport Sprzedaż wg produktów (DZ5)"
                    break;
                case "VD_REPORT_6":
                    Name = "Raport Sprzedaż wg maszyn (DZ6)"
                    break;
                case "VD_REPORT_7":
                    Name = "Raport Zysk wg produktów (DZ7)"
                    break;
                case "VD_REPORT_8":
                    Name = "Raport Zysk wg maszyn (DZ8)"
                    break;
                case "VD_REPORT_9":
                    Name = "Raport Wskaźnik rentowności sprzedaży (DZ9)"
                    break;
                case "VD_REPORT_10":
                    Name = "Raport Wskaźnik obrotu magazynowego (DZ10)"
                    break;
                case "VD_REPORT_11":
                    Name = "Raport Bieżący zapas (DZ11)"
                    break;
                case "VD_REPORT_12":
                    Name = "Raport Wystarczalność (DZ12)"
                    break;
                case "VD_REPORT_13":
                    Name = "Raport Zwroty (DZ13)"
                    break;
                case "VD_REPORT_14":
                    Name = "Raport Straty (DZ14)"
                    break;
                case "VD_REPORT_15":
                    Name = "Raport Rozrachunki - nierozliczone (DZ15)"
                    break;
                case "VD_REPORT_16":
                    Name = "Raport Inwentaryzacja magazynu (DZ16)"
                    break;
                case "VD_REPORT_19":
                    Name = "Raport Najlepsza/najgorsza sprzedaż wg sprężyn (DZ19)"
                    break;
                case "VD_REPORT_22":
                    Name = "Raport Zakupy - wg produktów (DZ22)"
                    break;
                case "VD_REPORT_23":
                    Name = "Raport Zakupy - wg dostawców (DZ23)"
                    break;
                case "VD_REPORT_24":
                    Name = "Raport Rozrachunki - rozliczone (DZ24)"
                    break;
                case "VD_REPORT_25":
                    Name = "Raport Maszyny - szczegóły sprzedaży (DZ25)"
                    break;
                case "VD_REPORT_26":
                    Name = "Raport Inwentaryzacja - lista dokumentów magazynowych (DZ26)"
                    break;
                case "VD_REPORT_27":
                    Name = "Raport Inwentaryzacja - lista dokumentów ksiegowych (DZ27)"
                    break;
                case "VD_REPORT_28":
                    Name = "Raport Inwentaryzacja - stanu oczekiwany i z natury (DZ28)"
                    break;
                case "VD_REPORT_31":
                    Name = "Raport Inwentaryzacja - szczegóły dok. mag. wg produktu (DZ31)"
                    break;
                case "VD_REPORT_32":
                    Name = "Raport Maszyny - zużycie składników (DZ32)"
                    break;
                case "VD_REPORT_33":
                    Name = "Raport Faktura Zakupu (DZ33)"
                    break;
                case "VD_REPORT_34":
                    Name = "Raport Paragon (DZ34)"
                    break;
                case "VD_REPORT_35":
                    Name = "Raport Zwrot (DZ35)"
                    break;
                case "VD_REPORT_36":
                    Name = "Raport Strata (DZ36)"
                    break;
                case "VD_REPORT_37":
                    Name = "Raport Wydanie zewnętrzne (DZ37)"
                    break;
                case "VD_REPORT_38":
                    Name = "Raport Rozchód wewnętrzny (DZ38)"
                    break;
                case "VD_REPORT_39":
                    Name = "Raport Przesunięcie międzymagazynowe minus (DZ39)"
                    break;
                case "VD_REPORT_40":
                    Name = "Raport Przyjęcie zewnętrzne (DZ40)"
                    break;
                case "VD_REPORT_41":
                    Name = "Raport Przychód wewnętrzny (DZ41)"
                    break;
                case "VD_REPORT_42":
                    Name = "Raport Przesunięcie międzymagazynowe plus (DZ42)"
                    break;
                case "VD_REPORT_43":
                    Name = "Raport Korekty (DZ43)"
                    break;
                case "VD_REPORT_44":
                    Name = "Raport Podsumowanie pracy serwisanta (DZ44)"
                    break;
                case "VD_REPORT_45":
                    Name = "Raport Maszyny - puste (DZ45)"
                    break;
                case "VD_REPORT_46":
                    Name = "Raport Maszyny - niezaładowane (DZ46)"
                    break;
                case "VD_REPORT_47":
                    Name = "Raport Maszyny - średnie doładowanie wg serwisantów (DZ47)"
                    break;
                case "VD_REPORT_48":
                    Name = "Raport Produkty - średnie doładowanie (DZ48)"
                    break;
                case "VD_REPORT_49":
                    Name = "Raport Operacje magazynowe wg produktów (DZ49)"
                    break;
                case "VD_REPORT_50":
                    Name = "Raport Operacje księgowe wg produktów (DZ50)"
                    break;
                case "VD_REPORT_51":
                    Name = "Raport Wizyty - raport szczegółowy (DZ51)"
                    break;
                case "VD_REPORT_52":
                    Name = "Raport Utworzone dokumenty (DZ52)"
                    break;
                case "VD_REPORT_53":
                    Name = "Raport Zmodyfikowane dokumenty (DZ53)"
                    break;
                case "VD_REPORT_54":
                    Name = "Raport Zapotrzebowanie (DZ54)"
                    break;
                case "VD_REPORT_55":
                    Name = "Raport Zaleganie na maszynach (DZ55)"
                    break;
                case "VD_REPORT_56":
                    Name = "Raport Maszyny - historia modyfikacji (DZ56)"
                    break;
                case "VD_REPORT_57":
                    Name = "Raport Maszyny - Bieżący stan (DZ57)"
                    break;
                case "VD_REPORT_58":
                    Name = "Raport Maszyny - Bieżący stan wg produktów (DZ58)"
                    break;
                case "VD_REPORT_59":
                    Name = "Raport Płatności (DZ59)"
                    break;
                case "VD_REPORT_60":
                    Name = "Raport Maszyny - średnie doładowanie (DZ60)"
                    break;
                case "VD_REPORT_61":
                    Name = "Raport Najlepsza/najgorsza sprzedaż wg produktów (DZ61)"
                    break;
                case "VD_REPORT_62":
                    Name = "Raport Faktury - płatności (DZ62)"
                    break;
                case "VD_REPORT_63":
                    Name = "Raport Brak magazynowy (DZ63)"
                    break;
                case "VD_REPORT_64":
                    Name = "Raport Nadstan magazynowy (DZ64)"
                    break;
                case "VD_REPORT_65":
                    Name = "Raport Straty ze sprzedaży (DZ65)"
                    break;
                case "VD_REPORT_66":
                    Name = "Raport Niska rentowność sprzedaży (DZ66)"
                    break;
                case "VD_REPORT_67":
                    Name = "Raport Produkty - stan bieżący (DZ67)"
                    break;
                case "VD_REPORT_68":
                    Name = "Raport Operacje magazynowe wg. produktu (DZ68)"
                    break;
                case "VD_REPORT_69":
                    Name = "Raport Bieżąca konfiguracja na maszynach (DZ69)"
                    break;
                case "VD_REPORT_70":
                    Name = "Raport Szczegóły sprzedaży wg produktów/receptur (DZ70)"
                    break;
                case "VD_REPORT_71":
                    Name = "Raport Stan bieżący (DZ71)"
                    break;
                case "VD_REPORT_72":
                    Name = "Raport Konfiguracja maszyny (DZ72)"
                    break;
                case "VD_REPORT_73":
                    Name = "Raport Brakujace produkty na maszynie (DZ73)"
                    break;
                case "VD_REPORT_74":
                    Name = "Raport Produkty - doladowania (DZ74)"
                    break;
                case "VD_REPORT_75":
                    Name = "Raport Wizyty serwisantów wg tras (DZ75)"
                    break;
                case "VD_REPORT_76":
                    Name = "Raport Transakcje (DZ76)"
                    break;
                case "VD_REPORT_77":
                    Name = "Raport Brakujące produkty - wersja skrócona (DZ77)"
                    break;
                case "VD_REPORT_78":
                    Name = "Raport Produkty - doładowania wg dat (DZ78)"
                    break;
                case "VD_REPORT_79":
                    Name = "Raport Podsumowanie wizyt serwisantów wg tras (DZ79)"
                    break;
                case "VD_REPORT_81":
                    Name = "Raport Sprzedaż wg lokalizacji (DZ81)"
                    break;
                case "VD_REPORT_82":
                    Name = "Raport Sprzedaż wg tras (DZ82)"
                    break;
                case "VD_REPORT_83":
                    Name = "Raport transakcje - szczegóły (DZ83)"
                    break;
                case "VD_REPORT_84":
                    Name = "Raport transakcje - sprzedaż (raport rozszerzony) (DZ84)"
                    break;
                case "VD_REPORT_85":
                    Name = "Raport transakcje - sprzedaż - wg maszyn i produktów (raport rozszerzony) (DZ85)"
                    break;
                case "VD_REPORT_86":
                    Name = "Raport transakcje prepaid pogrupowane po karcie (DZ86)"
                    break;
                case "VD_REPORT_87":
                    Name = "Raport transakcje prepaid (DZ87)"
                    break;
                case "VD_REPORT_88":
                    Name = "Raport transakcje prepaid pogrupowane po karcie (DZ88)"
                    break;
                case "VD_REPORT_89":
                    Name = "Raport transakcje wg typu platnosci (DZ89)"
                    break;
                case "VD_REPORT_90":
                    Name = "Raport transakcje wg automatu i produktów (DZ90)"
                    break;
                case "VD_REPORT_91":
                    Name = "Raport export z listy transakcji (DZ91)"
                    break;
                case "VD_UPDATE_TERMINALS":
                    Name = "Edycja terminali"
                    break;
                case "VD_REPORT_92":
                    Name = "Raport ostatnie imagenwentaryzacje monet"
                    break;
                case "VD_REPORT_93":
                    Name = "Raoprt ostatnie inwentaryzacje monet (podsumowanie)"
                    break;
                case "VD_REPORT_94":
                    Name = "Raport ostatnie wizyty"
                    break;
                case "VD_REPORT_95":
                    Name = "Raport błędów automatów"
                    break;
                case "VD_REPORT_96":
                    Name = "Raport zmiany stanu maszyny"
                    break;
                case "VD_ERRORS":
                    Name = "Błędy automatów"
                    break;
                default:
                break;
            }
            if (type === "Permissions") {

                const PermRecord = {
                    DbName: permName,
                    Items: [],
                    Name
                }
                for  (let i = 0; i < rolePermissions.length; i++) {
                    PermRecord.Items.push({RoleId: rolePermissions[i].RoleId , Value: rolePermissions[i][permName], RoleName: rolePermissions[i].Name})
                }
                rpArr.push(PermRecord)
            } 
            else if (type === "MenuOrder") {
                const arr = permissionsList
                const arr2 = navlinks
                const p = arr.find(perm => perm.Name == permName)
                const p2 = arr2.find(perm => perm.text == Name )
                // if (!p) {
                //     console.error("Can`t get permission name")
                // }
                const PermRecord = {
                    DbName: permName,
                    Name,
                    PermissionId: p?.PermissionId || null,
                    MenuOrder: p?.MenuOrder || null,
                    Icon: p2?.icon || null
                }
                rpArr.push(PermRecord)
            }
        })
        if (type === "Permissions"){
            return rpArr
        }
        else if (type === "MenuOrder") {
            return rpArr.filter(item => item.PermissionId && item.MenuOrder > 0).map((item, idx) => !item.MenuOrder ? ({...item, MenuOrder: idx}) : item).sort((a, b) => {
                if (Number(a.MenuOrder) < Number(b.MenuOrder)) {
                    return -1;
                }
                if (Number(a.MenuOrder) > Number(b.MenuOrder)) {
                    return 1;
                }
                return 0;
                }).map((perm, idx) => ({...perm, MenuOrder: ++idx}))
        } 
    }
    const defautlPermission = new Map()
    permissionsList && permissionsList.map(({PermissionId, Name}) => defautlPermission.set(Name, PermissionId))
    const getHTMLFromBool = ({Value, RoleId}, DbName) => (
        <input
            type='checkbox'
            checked={Value}
            onChange={() => {
                    if (!defautlPermission ) return
                    setChanged(true)
                    // console.log({PermissionId: defautlPermission.get(DbName), RoleId: RoleId})
                    setPermissions(prev => prev.map(item => item.DbName === DbName ? ({...item, Items: item.Items.map(item => item.RoleId === RoleId ? ({...item, Value: !item.Value}) : item)}) : item))
                    changedPermissions.findIndex(item => item.PermissionId === defautlPermission.get(DbName) && item.RoleId === RoleId) === -1
                    ? setChangedPermissions(prev =>  [...prev, {Value: !Value ,PermissionId: defautlPermission.get(DbName), RoleId: RoleId}])
                    : setChangedPermissions(prev => prev.filter(item => !(item.PermissionId === defautlPermission.get(DbName) && item.RoleId === RoleId)))
                }
            }
        />
    )
    const changeSection = (data) => {setSection(data)}
    const handleSubmit = () => {
        if (section === "Permissions") {

            const payload ={JSONRolePermissions: JSON.stringify(changedPermissions.map(({PermissionId, RoleId, Value}) => ({PermissionId, RoleId, Value: Value ? 1 : 0})))}
            fetchMssqlApi('role-permissions', {method: "PUT", data: payload}, () => {
                getRolePermissions()
                getPermissions()
                setChangedPermissions([])
            })
        }
        else if (section === "MenuOrder") {
            const payload = {JSONMenuOrder: JSON.stringify(permissions.map(({PermissionId, MenuOrder}) => ({PermissionId, MenuOrder})))}
            // const payload = changedPermissionsOrder.map(({PermissionId, MenuOrder}) => ({PermissionId, MenuOrder}))
            fetchMssqlApi('menu-order', {method: "PUT", data: payload}, () => {
                setChanged(false)
                // dispatch({type: "setPermissionFunc"})
                getData(section)
            })
        }
    }
    useEffect(() => {
        setHeaderData({text: section === "Permissions" && "Uprawnienia" || section === "MenuOrder" && "Kolejność menu" })
        getRolePermissions()
        getPermissions()
        getPermissionsList()
    }, [])
    useEffect(() => {
        setRolePermissions(initialRolePermissions)
    }, [initialRolePermissions])
    useEffect(() => {
        setHeaderData({text: section === "Permissions" && "Uprawnienia" || section === "MenuOrder" && "Kolejność menu" })
        setPermissions(getData(section))
    }, [rolePermissions, section])
    useEffect(() => {
        // console.log(divRef)
        (!changedPermissions.length && !changedPermissionsOrder.length) && setChanged(false)
    }, [changedPermissions, changedPermissionsOrder])
    return (
        <div>
            <div className='m-20'>
            <ul className="nav nav-tabs machine-tabs mb-3">
                <li className="nav-item">
                    <button
                        className={`nav-link btn ${section === "Permissions" && 'active'}`}
                        onClick={() => {
                            if(section !== "Permissions") {
                                setChangedPermissionsOrder([])
                                setChangedPermissions([])
                                setPermissions([])
                                setChanged(false);
                            } 
                            changeSection("Permissions")
                        }}
                        tabIndex="0"
                    >
                        Uprawnienia
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link btn ${section === "MenuOrder" && 'active'}`}
                        onClick={() => {
                            if(section !== "MenuOrder") {
                                setChangedPermissionsOrder([]) 
                                setSelectedPermission(null)
                                setPermissions([])
                                setChanged(false);
                            }
                            changeSection("MenuOrder")
                        }}
                        tabIndex="0"
                    >
                        Kolejność menu
                    </button>
                </li>
            </ul>
            </div>
            {section === "Permissions" && (

            <div className='card'>
                <div className='card-header'>
                    {
                    !changed 
                    ? <div className='h5 mb-2 mt-2'>Uprawnienia R24 Dashboard</div>
                    : <div className='d-flex justify-content-between align-items-center pb-0' >
                        <div className='h5 mb-2 mt-2'>Uprawnienia R24 Dashboard</div>
                        <button className='btn btn-success btn-sm mr-5' onClick={handleSubmit}>
                            Zapisz
                        </button>
                    </div>
                    } 
                </div>
                <div className='card-body overflow-auto' style={{maxHeight: "65vh"}}>
                        <table className='table table-striped'>
                            {/* <div className='position-sticky'> */}
                                <thead>
                                    <tr className='text-center'>
                                    <th className='text-left'>Nazwa uprawnienia</th>
                                    {rolePermissions && rolePermissions.map((perm, idx) => (
                                        <th key={idx}>
                                            {perm.Name}
                                        </th>
                                    ))}
                                    </tr>
                                </thead>
                            {/* </div> */}
                            <tbody>
                                {permissions && permissions.map(permission => permission.Name &&  (
                                    <tr key={permission.DbName} className='mt-2 mb-2'>
                                    <td>
                                        {permission.Name || permission.DbName} 
                                    </td>
                                    {permission.Items.map(item => (
                                        <td key={item.RoleId}>
                                            <div className='d-flex justify-content-center align-content-center mb-2 mt-2'>
                                                {getHTMLFromBool(item, permission.DbName) || ""}
                                            </div>
                                        </td>
                                    ))}
                                    </tr>
                                )
                                )}
                            </tbody>
                        </table>
                    {/* </div> */}
                </div>
            </div>
            )
            ||
            section === "MenuOrder" && (
                <div className='card'>
                <div className='card-header'>
                    {
                    !changed 
                    ? <div className='h5 mb-2 mt-2'>Kolejność menu R24 Dashboard</div>
                    : <div className='d-flex justify-content-between align-items-center pb-0' >
                        <div className='h5 mb-2 mt-2'>Kolejność menu R24 Dashboard</div>
                        <button className='btn btn-success btn-sm mr-5' onClick={handleSubmit}>
                            Zapisz
                        </button>
                    </div>
                    } 
                </div>
                <div className='card-body overflow-auto' style={{maxHeight: "65vh"}}>
                        <table className='table table-striped'>
                            {/* <div className='position-sticky'> */}
                                <thead>
                                    <tr>
                                    {/* <th></th> */}
                                    <th className='text-left' style={{fontSize: "25px"}}> <div className='ml-5'>Kolejność</div></th>
                                    <th className='text-center ' style={{fontSize: "25px"}}>Nazwa</th>
                                    <th className='text-center ' style={{fontSize: "25px"}}>Ikonka</th>
                                    <th></th>
                                    </tr>
                                </thead>
                            {/* </div> */}
                            <tbody>
                                {permissions && [...permissions].filter(i => i.MenuOrder > 0).map((permission, idx) => permission.Name && (
                                    <tr key={idx} 

                                        className={`${selectedPermission === permission.PermissionId && `bg-warning`} text-center`}
                                        style={{height: "7vh"}}
                                    >
                                        <td className=' text-left' style={{fontSize: "20px"}}
                                            onClick={evt => {
                                                selectedPermission === permission.PermissionId  ? setSelectedPermission(null) : 
                                                setSelectedPermission(permission.PermissionId)}
                                            }
                                        >
                                            <div className='ml-5'>
                                                {permission.MenuOrder}
                                            </div>
                                        </td>
                                        <td style={{fontSize: "20px"}}
                                            onClick={evt => {                                                
                                                selectedPermission === permission.PermissionId  ? setSelectedPermission(null) : 
                                                setSelectedPermission(permission.PermissionId)}
                                            }
                                        >
                                            {permission.Name || permission.DbName}
                                        </td>
                                        <td
                                            onClick={evt => {
                                                selectedPermission === permission.PermissionId  ? setSelectedPermission(null) : 
                                                setSelectedPermission(permission.PermissionId)}
                                            }
                                        >
                                            <i className={`mr-3 ${permission.Icon}`} style={{ width: 25, fontSize: '1.125em' }} />
                                        </td>
                                        <td style={{width: "30vw"}} 
                                            onClick={evt => {                                    
                                                selectedPermission === permission.PermissionId  ? setSelectedPermission(null) : 
                                                setSelectedPermission(permission.PermissionId)}
                                            }
                                        >
                                            {selectedPermission === permission.PermissionId ? (
                                                <div className='d-flex justify-content-end mr-5' name='controlPanel'>
                                                    <div className='mr-2'>
                                                        <button className="btn btn-outline-primary" 
                                                            onClick={evt => {evt.stopPropagation(); upPermission(permission.MenuOrder)}}>
                                                            <i className="fas fa-sharp fa-regular fa-arrow-up"/> 
                                                        </button>
                                                    </div>
                                                        <button
                                                            className="btn btn-outline-primary" 
                                                            onClick={evt => {evt.stopPropagation(); downPermission(permission.MenuOrder)}}
                                                        >
                                                            <i className="fas fa-sharp fa-regular fa-arrow-down"/>  
                                                        </button>
                                                </div>
                                            ) : null}
                                        </td>
                                    </tr>
                                )
                                )}
                            </tbody>
                        </table>
                    {/* </div> */}
                </div>
            </div>
            )}
        </div>
    )
}
