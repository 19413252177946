import { useState, useCallback, useEffect } from 'react'
import { API_URL } from '../config/config'
import NotificationManager from 'react-notifications'
import axios from 'axios'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export default () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);

  // function setAutoLogout (timeout) {
  //   const autoLogout = setTimeout(logout, timeout);
  //   return {
  //     changeTimeout: function(newTimeout = 100000) {
  //       clearTimeout(autoLogout)
  //       this.autoLogout = setTimeout(logout, newTimeout)
  //     },
  //     showTimeot: function () {
  //       console.log(autoLogout)
  //     }
  //   }
  // }
  // = useCallback(milliseconds => {
  //   setTimeout(logout, milliseconds)
  // }, [])
  // const history = useHistory()
  // let manipulateTimeouts
  const [isAuth, setIsAuth] = useState(() => {
    const clientId = localStorage.getItem('clientId')
    const expirationDate = localStorage.getItem('expirationDate')
    if ( !clientId || !expirationDate) {
      // if (!window.location.pathname.includes("login") ) history.replace("/login")
      return false
    }
    if (new Date(expirationDate) <= new Date()) {
      localStorage.removeItem('clientId')
      localStorage.removeItem('expirationDate')
      // if (!window.location.pathname.includes("login") ) history.replace("/login")
      return false
    }

    const remainingMilliseconds = new Date(expirationDate) - new Date()

    // setAutoLogout(remainingMilliseconds)
    return true
  })
  
  const [clientId, setClientId] = useState("") 
  const logout = async () => {
      if (isLoggingOut || !isAuth) return 
      setIsLoggingOut(true)
      setIsAuth(false)
      localStorage.removeItem('clientId')
      localStorage.removeItem('expirationDate')
      await axios.get(`${API_URL}/api/auth/logout`,{method: "GET"})
      .then(() => {
        window.location.reload()
      })
      return null
    }

  const login = (token, clientId) => {
    // localStorage.setItem('token', token || "")


    localStorage.getItem('DB_TYPE') === "mysql" ?
    localStorage.setItem('clientId', "multivend") :
    localStorage.setItem('clientId', clientId)
    
    const newExpirationDate = new Date(new Date().getTime() + 1000 * 60 * 60)
    setClientId(clientId)
    localStorage.setItem('expirationDate', newExpirationDate.toISOString())
    const isProductoionMode = API_URL === "portal.retail24"
    
    const remainingMilliseconds = new Date(newExpirationDate) - new Date()
    

    // manipulateTimeouts = setAutoLogout(remainingMilliseconds)
    // manipulateTimeouts = timeoutSettings()
    setIsAuth(true)
  }

  return { isAuth, login, logout, clientId, setIsAuth }
}
