import React, { useEffect, useState } from "react";
import Thermometer from "react-thermometer-component";

import useFetch from "../../hooks/fetchMSSQL-hook";

export default () => {
  const { fetchMssqlApi } = useFetch();
  const [temperature, setTemperature] = useState({
    master: 7,
    firstSlave: 7,
    secondSlave: 7
  });

  const [machineType, setMachineType] = useState({
    master: "-",
    firstSlave: "-",
    secondSlave: "-"
  });

  const incrementTemperature = (slave) => {
    if (slave === "master") {
      setTemperature({ ...temperature, master: temperature.master + 1 });
    } else if (slave === "slave") {
      setTemperature({ ...temperature, firstSlave: temperature.firstSlave + 1 });
    } else if (slave === "slave2") {
      setTemperature({ ...temperature, secondSlave: temperature.secondSlave + 1 });
    }
  }
  const decrementTemperature = (slave) => {
    if (slave === "master") {
      setTemperature({ ...temperature, master: temperature.master - 1 });
    } else if (slave === "slave") {
      setTemperature({ ...temperature, firstSlave: temperature.firstSlave - 1 });
    } else if (slave === "slave2") {
      setTemperature({ ...temperature, secondSlave: temperature.secondSlave - 1 });
    }
  }

  const getTemperature = () => {
    fetchMssqlApi("shop/temperature", {}, (temperature) => {
      setTemperature((prev) => ({
        ...prev,
        master: temperature.master,
        firstSlave: temperature.firstSlave,
        secondSlave: temperature.secondSlave,
      }));
    });
  };

  const submitTemperature = (slave) => {
    fetchMssqlApi("shop/temperature/submit", {
      method: "POST",
      data: { Temperature: temperature.master, Type: slave },
    });
  };

  const enableTemperature = (slave) => {
    fetchMssqlApi("shop/temperature/toggle", {
      method: "POST",
      data: { Toggle: 1, Type: slave },
      withNotification: true,
    });
  };

  const disableTemperature = (slave) => {
    fetchMssqlApi("shop/temperature/toggle", {
      method: "POST",
      data: { Toggle: 0, Type: slave },
      withNotification: true,
    });
  };

  const getMachineType = () => {
    fetchMssqlApi("machines", {}, (machines) => {
      let types = machines[0].Type;
      setMachineType((prev) => ({
        ...prev,
        master: types.split("_")[0] === undefined ? "-" : types.split("_")[0],
        firstSlave: types.split("_")[1] === undefined ? "-" : types.split("_")[1],
        secondSlave: types.split("_")[2] === undefined ? "-" : types.split("_")[2],
      }));
    });
  };

  useEffect(() => {
    getMachineType();
    getTemperature();
  }, []);

  const thermometer = (slave, machineType) => {
    let typeName, temperatureValue;
    // type = machineType;
    if (slave === "master") {
      typeName = "Master";
      temperatureValue = temperature.master;
    } else if (slave === "slave") {
      typeName = "Slave";
      temperatureValue = temperature.firstSlave;
    } else if (slave === "slave2") {
      typeName = "Slave 2";
      temperatureValue = temperature.secondSlave;
    }

    return (
      <div className="col-sm">
        <div className="row mb-4">
          <div className="col text-center">
            <h4>{machineType} [{typeName}]</h4>
            <br />
            <button className="btn btn-success mr-1" onClick={() => enableTemperature(slave)}>&#10004;</button>
            <button className="btn btn-danger" onClick={() => disableTemperature(slave)}>&#10008;</button>
          </div>
        </div>
        <div className="d-flex justify-content-center mb-4">
          <div className="d-flex align-items-center mr-3">
            <button className="btn icon-button" disabled={temperatureValue <= 5} onClick={() => decrementTemperature(slave)}>
              <i className="fas fa-minus text-danger icon-large" />
            </button>
          </div>
          <Thermometer theme="light" value={temperatureValue} max="21" format="°C" size="large" height="300" />
          <div className="d-flex align-items-center ml-3">
            <button className="btn icon-button" disabled={temperatureValue >= 21} onClick={() => incrementTemperature(slave)}>
              <i className="fas fa-plus text-success icon-large" />
            </button>
          </div>
        </div>
        <div>
          <button className="mx-auto d-block btn icon-button" onClick={() => submitTemperature(slave)}>
            <i className="fas fa-save text-info icon-large" />
          </button>
        </div>
      </div>
    )
  }

  return (
    <>
      <div>
        <div className="row">
          {machineType.master != '-' ? thermometer("master", machineType.master) : null}
          {machineType.firstSlave != '-' ? thermometer("slave", machineType.master) : null}
          {machineType.secondSlave != '-' ? thermometer("slave2", machineType.master) : null}
        </div>
      </div>
    </>
  );
};
