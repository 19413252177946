import React, {useState} from 'react'
import getPaymentType from './PaymentType'
import moment from 'moment'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { NotificationManager } from 'react-notifications'
import { API_URL } from '../../../../config/config'
import ReportBtnForArr from '../../../../components/Buttons/ReportsBtn/ReportBtnForArr'
import getFiscalDoc from '../utils/getFiscalDoc'


export default function PAPR (PAPR, idx,state, setState) {
    // const defaultQuerySettings = {process: 1, timeout: 1, lastStatus: 0, readyToFile: false},
    // [querySettings, setQuerySettings] = useState(defaultQuerySettings)
    return  (
    <tr key={idx}>
            <td>
                <div className='d-flex align-items-center'> 
                    <button
                        className={
                            PAPR.FiscalDocTypeId === 1 && 
                                `btn text-nowrap btn-sm  btn-success mb-0` || 
                            PAPR.FiscalDocTypeId === 2 && 
                                `btn text-nowrap btn-sm btn-warning mb-0`}
                    >
                        {PAPR.FiscalDocType}
                    </button>
                </div>
            </td>
            <td>
                <div className='d-flex align-items-center justify-content-between flex-wrap pt-1 pb-1'>
                    <div>
                        <div><b>{PAPR.NrUnik}</b></div>
                        <div><b>{PAPR.NrEwid}</b></div>
                    </div>
                </div>            
            </td>
            <td>
                <div>{moment(PAPR.zakSprzed).format("YYYY-MM-DD HH:mm:ss")}</div>
            </td>
            <td>
                <div className='d-flex align-items-center justify-content-between flex-wrap pt-1 pb-1'>
                        <div>
                            <div>Suma brutto: <b>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(PAPR.sumaBrutto) || "0,00zł"}</b></div>
                            <div>Suma netto: <b>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(PAPR.sumNetto) || "0,00zł"}</b></div>
                            <div>Podatek: <b>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(PAPR.sumaPod) || "0,00zł"}</b></div>
                        </div>
                </div>
            </td>
            <td>
                <div className='d-flex align-items-center justify-content-between flex-wrap pt-1 pb-1'>
                        <div className='mr-3'>
                            {getPaymentType(PAPR.PAYMENT_TYPE)}
                            <div><span className='badge badge-primary'>Waluta: {PAPR.waluta}</span></div>
                        </div>
                </div>            
            </td>
            <td>
                {/* <Link
                    target={"_blank"}
                    to={`/wkf/params?nrunik=${PAPR.NrUnik}&fiscaldoc=${PAPR.Hash}&client=${localStorage.getItem('clientId')}`}
                    className={`d-inline m-1 btn btn-primary float-right fas fa-xs fa-download`}
                >
                </Link> */}
                <ReportBtnForArr
                    state={state}
                    setState={setState}
                    value={{Report: 1, Id: PAPR.FiscalDocId}}
                    btnColor='primary'
                    clickFnc={() => getFiscalDoc(PAPR, setState )}
                />
                <button
                    onClick={() => {
                        try {
                            window.navigator.clipboard.writeText(`https://portal.retail24.pl/wkf/params?nrunik=${PAPR.NrUnik}&fiscaldoc=${PAPR.Hash}&client=${localStorage.getItem('clientId')}`)
                            NotificationManager.success("Link copied to clipboard")
                        } catch (e) {
                            NotificationManager.error(e)
                        }
                    }}
                    className={`d-inline m-1 btn btn-info float-right fas fa-xs fa-copy`}
                >
                </button>
            </td>
        </tr>
    )}