
import React, {useEffect, useState, useContext} from 'react';
import fetchMSSQLHook from '../../hooks/fetchMSSQL-hook';
import Filter from "../../components/Filter/Filter";
import { LangContext } from '../../context/lang-context';
import Pagination from "../../components/Pagination/Pagination";

const MachineProductsHistory = () => {
    const {fetchMssqlApi} = fetchMSSQLHook();
    const [machineProductsHistory, setMachineProductsHistory] = useState([]);
    const {TRL_Pack} = useContext(LangContext);
    useEffect(() => {
        fetchMssqlApi('history-machine-products', {}, data => {
            if (data.map(machineProductsHistory => machineProductsHistory.MachineFeederNo).every(No => !isNaN(No))) {
                data.sort((a, b) => Number(a.MachineFeederNo) - Number(b.MachineFeederNo));

            }
            const transformedData = data.map(d => ({
                ...d,
                IsLocked: d.IsLocked === 0 ? 'NIE' : d.IsLocked === 1 ? 'TAK' : d.IsLocked,
                HistoryAction: d.HistoryAction
                    .replace('BEFORE UPDATE', 'Zmiana stanu (Przed)')
                    .replace('AFTER UPDATE', 'Zmiana stanu (Po)')
                    .replace('AFTER INSERT', 'Dodanie')
                    .replace('BEFORE DELETE', 'Usunięcie')
            }));
            setMachineProductsHistory(transformedData);
        });
    }, []);

    const defaultFilter = {
        showIndexes: true,
        page: 1,
        rowsPerPage: 25,
        rowsPerPageOptions: [10, 25, 50],
        visible: false,
        sortByColumns: true,
        sortBy: '1 | asc | text',
        activeTags: [],
        logs: false,
        columns: [
            {id: 1, name: TRL_Pack.events.machineProductHistory.selectionNumber, sortable: true, searchable: true, type: 'price', searchbar: ''},
            {id: 2, name: TRL_Pack.events.machineProductHistory.ean, sortable: true, searchable: true, type: 'text', searchbar: ''},
            {id: 3, name: TRL_Pack.events.machineProductHistory.price, sortable: true, searchable: true, type: 'number', searchbar: ''},
            {id: 4, name: TRL_Pack.events.machineProductHistory.discountedPrice, sortable: true, searchable: true, type: 'number', searchbar: ''},
            {id: 5, name: TRL_Pack.events.machineProductHistory.quantity, sortable: true, searchable: true, type: 'number', searchbar: ''},
            {id: 6, name: TRL_Pack.events.machineProductHistory.capacity, sortable: true, searchable: true, type: 'number', searchbar: ''},
            {id: 7, name: TRL_Pack.events.machineProductHistory.selectionLocked, sortable: true, searchable: true, type: 'text', searchbar: ''},
            {id: 8, name: TRL_Pack.events.machineProductHistory.event, sortable: true, searchable: true, type: 'text', searchbar: ''},
            {id: 9, name: TRL_Pack.events.machineProductHistory.eventTime, sortable: true, date: true, searchable: true, type: 'date', searchbar: ''},
        ],
    };

    const [filter, setFilter] = useState(defaultFilter);

    const resetFilter = () => setFilter(defaultFilter);
    const toggleFilter = () => setFilter(prev => ({...prev, visible: !prev.visible}));

    const handleSwitchPage = pageNo => () => setFilter(prev => ({...prev, page: pageNo}));
    const resetPage = () => setFilter(prev => ({...prev, page: 1}));

    const reportFilter = row =>
        filter.columns
            .filter(col => col.searchbar !== undefined && col.searchbar !== '')
            .every(col => {
                const value = row[Object.keys(row)[col.id - 1]];
                const searchValue = col.searchbar.toString().toLowerCase();
                return value?.toString().toLowerCase().includes(searchValue);
            });

    const sortRows = (a, b) => {
        const [id, order, type] = filter.sortBy.split(' | ');

        const col = Object.keys(a)[Number(id) - 1];

        if (a[col] === b[col]) return 0;
        else if (a[col] === null) return 1;
        else if (b[col] === null) return -1;
        let valueA, valueB;
        if (type === 'text' || type === 'date') {
            valueA = a[col].toLowerCase();
            valueB = b[col].toLowerCase();
        } else if (type === "bool") {
            valueA = Number(a[col]);
            valueB = Number(b[col]);
        } else if (type === "number") {
            valueA = a[col];
            valueB = b[col];
        } else if (type === 'price') {
            valueA = Number(a[col].replace(',', ''));
            valueB = Number(b[col].replace(',', ''));
        } else {
            return 0;
        }
        if (order === 'asc') return valueA < valueB ? -1 : 1;
        else return valueA < valueB ? 1 : -1;
    };

    const filteredSortedData = machineProductsHistory
        .filter(reportFilter)
        .sort(sortRows);

    return (
        <div>
            <Pagination
                totalItems={filteredSortedData.length}
                page={filter.page}
                rowsPerPage={filter.rowsPerPage}
                handleSwitchPage={handleSwitchPage}
                filterVisibility={filter.visible}
                toggleFilter={toggleFilter}
                resetFilter={resetFilter}
            />
            {filter.visible && (
                <Filter
                    {...{
                        filter,
                        setFilter,
                        columns: filter.columns,
                        data: machineProductsHistory,
                        resetPage,
                        resetFilter
                    }}
                />
            )}
            <table className="table table-striped border">
                <thead>
                <tr>
                    {filter.showIndexes && (
                        <th className="text-center px-4" style={{width: '1%'}}>#</th>
                    )}
                    {filter.columns.map(col => (
                        !col.disabled && <th key={col.id} className="text-center" style={{width: 100}}>{col.name}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {filteredSortedData
                    .slice(
                        (filter.page - 1) * filter.rowsPerPage,
                        filter.page * filter.rowsPerPage
                    )
                    .map((machineProduct, index) => (
                        <tr key={index}>
                            {filter.showIndexes && (
                                <td className="font-weight-bold text-center">{(filter.page - 1) * filter.rowsPerPage + index + 1}</td>
                            )}
                            {Object.keys(machineProduct).map((key, colIndex) => (
                                <td key={colIndex} className="font-weight-bold text-center">
                                    {key === 'IsLocked'
                                        ? String(machineProduct[key]).replace('0', 'NIE').replace('1', 'TAK')
                                        : key === 'HistoryAction'
                                            ? machineProduct[key]
                                                .replace('BEFORE UPDATE', 'Zmiana stanu (Przed)')
                                                .replace('AFTER UPDATE', 'Zmiana stanu (Po)')
                                                .replace('AFTER INSERT', 'Dodanie')
                                                .replace('BEFORE DELETE', 'Usunięcie')
                                            : key === 'ActionDate'
                                                ? machineProduct[key].replace('T', ' ').replace('Z', ' ')
                                                : machineProduct[key]
                                    }
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default MachineProductsHistory;