import React from 'react'
// import { LangContext } from '../../context/lang-context'

import FormSkel from './FormSkel'
import { useState } from 'react'

export default ({ clientData, handleSubmit, handleClose }) => {
  const [deleteCustomer, setDeleteCustomer] = useState(false)
  const deleteClient = dec => {
    if(dec) {
      setDeleteCustomer(false)
      return console.log("client deleted")}
    else return setDeleteCustomer(false)
    
  }
  return (
    <FormSkel
      headerText={clientData ? 'Edycja klienta' : 'Dodaj klienta'}
      handleClose={handleClose}
      // setState={setDeleteCustomer}
      // noFooter={deleteCustomer}
    >
      {!deleteCustomer ? 
      (<form onSubmit={handleSubmit} id="modal-form" autoComplete="off">
        <div className="form-group">
          <input style={{display: "none"}} onChange={()=>{}} name="oldName" value={clientData && clientData.Abbreviation} form='modal-form'/>
          <label className="h6">Nazwa</label>
          <input
            name="name"
            className="form-control"
            defaultValue={clientData && clientData.Name || ""}
            required
          />
        </div>
          <div style={clientData.CustomerId === '3' ? {display:'none'} : {display:'block'}}>
            <label className="h6">Baza danych</label>
            <input
              name="abbreviation"
              className="form-control"
              defaultValue={clientData && clientData.Abbreviation || ""}
            />
          </div>
      </form>)
      : 
      (<div>
        <div className='h6 text-align-center mb-5'>Jestesz w tym pewien? (  jeszcze nie działa)</div>
        <div className='d-flex justify-content-lg-between'>
          <button className='btn b-1 border border-danger' onClick={() => deleteClient(true)}>Tak</button>
          <button className='btn border border-primary' onClick={() => deleteClient(false)}>Nie</button>
        </div>

      </div>)
    }
      
    </FormSkel>
  )
}
