import React, {useState, useEffect, useContext} from 'react'
import fetchHook from '../../hooks/fetchMSSQL-hook';
import { NavigationContext } from '../../context/navigation-context';
import Pagination from '../../components/Pagination/Pagination';
import FilterEx from '../../components/Filter/FilterEx';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';

export default function Contractors() {
    const [contractors, setContractors] = useState([])
    const { fetchMssqlApi } = fetchHook()
    const { setHeaderData } = useContext(NavigationContext)
    //filter...
    const defaultFilter = {
        showIndexes: false,
        name: "contractors",
        page: 1,
        disableIndexes: true,
        rowsPerPage: 50,
        rowsPerPageOptions: [10, 25, 50],
        visible: false,
        //sortByColumns: true,
        sortByColumns: false,
        //sortBy: '3 | asc | text',
        activeTags: [],
        columns: [
        {
            id: 3,
            name: "Aktywny",
            sortable: true,
            selectable: true,
            disabled: false,
            type: "threeStateBoolByActivity",
            value: "true",
            selectbar: "true"
        },
        ]
    }
    const [filter, setFilter] = useState(() => {
        if (localStorage.getItem('locationRoutesFilter')) {
        return JSON.parse(localStorage.getItem('locationRoutesFilter'));
        }
        return defaultFilter;
    })
    const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
    const resetFilter = () => {
        setFilter(defaultFilter);

    }
    const reportFilter = row =>
    filter.columns
        .filter(col => col.selectbar)
        .every(col => {
        const x = String(row[Object.keys(row)[col.id - 1]])
            .toLowerCase() === col.selectbar.toLowerCase()
        return x
        }
    )
    const search = () => {
        setFilter(prev => ({
            ...prev,
            columns: prev.columns.map(col => {
                col.selectbar = col.value
                return col
            })
        }))
    }
    const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
    const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))
    //===================
    const getContractors = () => {
        fetchMssqlApi('contractors', {}, res => setContractors(res))
    }

    const getCheckboxFromBool = (bool) => (
        <div className='d-flex justify-content-center align-items-center'>
            <input
                type='checkbox'
                checked={bool}
                onChange={() => {}}
            />
        </div>
    ) 
    useEffect(() => {
        setHeaderData({text: "Kontrahenci"})
        getContractors()
    }, [])
    return (
        <div>
            <Pagination 
                totalItems={contractors.length}
                page={filter.page}
                handleSwitchPage={handleSwitchPage}
                rowsPerPage={filter.rowsPerPage}
                toggleFilter={toggleFilter}
                filterVisibility={filter.visible}
                />
            {filter.visible && (
                <FilterEx
                    filter={filter}
                    setFilter={setFilter}
                    columns={filter.columns}
                    resetPage={resetPage}
                    resetFilter={resetFilter}
                    toggleFilter={toggleFilter}
                    search={search}
                    />
            )}
            <div className='card'>
                <div className='card-header'>
                    <div>
                        
                            <div className='d-flex justify-content-between'>
                                <div className='h5 mt-2 mb-1'>Kontrahenci</div>
                                <Link to={`/contractor/0`} className="btn text-nowrap btn-sm btn-outline-secondary mt-1 mb-1"><i className="fas fa-plus" /></Link>
                            </div>
                    </div>
                </div>
                <div className='card-body overflow-auto' style={{ maxHeight: !filter.visible ? "68vh" : "35vh"}}>
                {contractors.length ? (
                    <table className='table table-striped'>
                        <thead>
                            <tr>
                                <th> <div className='d-flex justify-content-center'> Kontrahent</div></th>
                                <th> <div className='d-flex justify-content-center'> NIP </div></th>
                                <th> <div className='d-flex justify-content-center'> Adres </div></th>
                                <th> <div className='d-flex justify-content-center'> Kod pocztowy </div></th>
                                <th> <div className='d-flex justify-content-center'> Miasto </div></th>
                                <th> <div className='d-flex justify-content-center'> Telefon </div></th>
                                <th> <div className='d-flex justify-content-center'> Stworzony </div></th>
                                <th> <div className='d-flex justify-content-center'> Aktywny </div></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                        { contractors
                        .filter(reportFilter)
                        .slice(
                            (filter.page - 1) * filter.rowsPerPage,
                            filter.page * filter.rowsPerPage
                        )
                        .map((contractor, idx) => (
                            <tr key={idx} className='mt-1 mb-1'>
                                <td> 
                                    <div className='d-flex justify-content-center'>
                                        {contractor.Name}
                                    </div>
                                </td>
                                <td> 
                                    <div className='d-flex justify-content-center'>
                                        {contractor.NIP}
                                    </div>
                                </td>
                                <td> 
                                    <div className='d-flex justify-content-center'>
                                        {contractor.Address}
                                    </div>
                                </td>
                                <td> 
                                    <div className='d-flex justify-content-center'>
                                        {contractor.ZipCode}
                                    </div>
                                </td>
                                <td> 
                                    <div className='d-flex justify-content-center'> 
                                        {contractor.City} 
                                    </div>
                                </td>
                                <td>{contractor.Phone ? 
                                    <div className='d-flex justify-content-center'> 
                                        {contractor.Phone}
                                    </div>
                                    :
                                    ("")}</td>
                                <td>
                                    {/* {contractor.RelatedDoc} */}
                                    <div className='d-flex flex-column justify-content-center align-items-center mt-1'>
                                        <div className="d-flex justify-content-center">{contractor && moment(contractor.CreateDateTime).format("YYYY.MM.DD")}</div>
                                    </div>
                                </td>
                                <td>
                                    {/* {contractor.RelatedDoc} */}
                                    <div className='d-flex flex-column justify-content-center align-items-center mt-1'>
                                        <div className="d-flex justify-content-center">{getCheckboxFromBool(contractor.IsActive)}</div>
                                    </div>
                                </td>
                                <td>
                                    <Link to={`contractor/${contractor.ContractorId}`} className="btn btn-outline-primary btn-sm "
                                            
                                            >
                                        Edytuj
                                    </Link>
                                </td>
                            </tr>
                        )) }
                        </tbody>
                    </table>) : <div className='h3 d-flex justify-content-center align-items-center'>Brak danych</div>}
                </div>
            </div>
        </div>
    )
}
