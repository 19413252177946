import React, { useEffect, useState } from "react";
import FormSkel from "../../../components/Modals/FormSkel";
import fetchMSSQLHook from "../../../hooks/fetchMSSQL-hook";
import { useRef } from "react";
import InfoToast from "../../../util/InfoToast";

export default ({ handleSubmit, handleClose, miniData, warning, form}) => {

    const {fetchMssqlApi} = fetchMSSQLHook()


    const [data, setData] = useState([])
    useEffect(() => getData(), [])
    const getData = () => {fetchMssqlApi('users/users-list', {}, answer => setData(answer))}
    const [warehouses, setWarehouses] = useState([])
    const [role, setRole] = useState([])
    const [chosedLocation, setChosedLocation] = useState(null)
    const [locations, setlLocations] = useState([])
    const [initialLocations, setInitialLocations] = useState([])
    const [newLocation, setNewLocation] = useState("")
    const [locationsList, setlLocationsList] = useState([])
    const [availableToDisable, setAvailableTODisable] = useState(() => miniData && Object.values(miniData).every(value => (value !== null) && (value !== undefined)) && miniData.IsEditable)
    // useEffect(() =>{

    //     /**@setW - warehouses | @setR - roles*/
    //     const setW = new Set(),
    //     setR = new Set()
    //     data.forEach(obj => {
    //         setW.add(obj.WarehousName)
    //         setR.add(obj.Role)
    //     })
    //     setWarehouses([...setW])
    //     setRole([...setR])

    // } ,[data])  
    const infoText = "W przypadku gdy użytkownik nie ma przypisanej żadnej lokalizacji ma dostęp do wszystkich automatów. Po przypisaniu pierwszej i kolejnych lokalizacji ogranicza mu się dostęp do automatów w wybranych lokalizacjach"
    const getWarehouses = () => {
        fetchMssqlApi('warehouses', {}, res => {
            setWarehouses(res.map(wh => wh.Name))
        })
    }
    const getUserLocations = () => {
        miniData?.UserId && fetchMssqlApi(`/user/location/${miniData.UserId}`, {}, res => {
            setInitialLocations(res.userLocations)
        })
    }
    const getRoles = () => {
        fetchMssqlApi('roles', {}, res => {
            setRole(res.map(role => role.Name))
        })
    }
    const random = () => { 
        const set = new Set(locations.map(item => +item.UserLocationId))
        const a = Math.floor(Math.random()*10000)
        return set.has(a) ? random() : a
    }
    const deleteLocation = (id) => {
        const item = locations.find(location => location.UserLocationId === id)
        if (!item) return
        setlLocations(prev => prev.map(location => location.UserLocationId == id ? ({...location, IsDeleted: true}) : location))
    } 
    const addNewLocation = (evt) => {
        evt.preventDefault()
        const location = locationsList.find(location => location.Name === newLocation)
        if (!location) {
            setNewLocation("")
            return 
        }
        if (locations && locations.map(i => i.Name).indexOf(newLocation) !== -1 ) {
            setlLocations(i => i.map(i => (i.Name === newLocation && i.IsDeleted) ? ({...i, IsDeleted: false}) : (i) ))
            setNewLocation("")
            setChosedLocation(null)
            return 
        }
        setlLocations(prev => [...prev,
            { LocationId: location.LocationId,
                Name: newLocation,
                UserLocationId: String(random()),
                IsNew: true
                // RouteId: miniData.User,
            }])
        setNewLocation("")
    }
    // const getLocations = () => {
    //     if (miniData) fetchMssqlApi(`routes-locations/${miniData?.UserId}`, {}, res => setInitialLocations(res))
    //     else setInitialLocations([])
    // }
    const getLocationsList = () => {
        fetchMssqlApi('locations', {}, (locations) => setlLocationsList(locations))
    }
    useEffect(() => {
        setlLocations(initialLocations)
    }, [initialLocations])
    useEffect(() => {
        getLocationsList()
        // getLocations()
        getWarehouses()
        getRoles()
        getUserLocations()
    }, [])
    const formEl = useRef(warning)
    // console.log(warehouses)
    return (
        <FormSkel
            headerText={(data) ? "Edytuj" : "Stworz"}
            handleClose={handleClose}
            data={miniData}
            handleSubmit={evt => {handleSubmit(evt, locations)}}
            deleteDisable={!availableToDisable}
        >
            <form onSubmit={evt => {handleSubmit(evt, locations)}} id="modal-form" autoComplete="off" ref={formEl}>
                <div className="form-group" >
                    {warning?.status && <div className="p-3 mb-2 mt-2 bg-danger text-white text-center">{warning.message}</div>}
                    <input disabled defaultValue={miniData?.UserId} 
                        form="modal-form" 
                        name="userId" 
                        style={{display: "none"}}
                        required={true}
                        
                    />
                    <label className='h6'>Nazwa</label>
                        <input defaultValue={miniData && miniData.Name} 
                            className="form-control" 
                            form='modal-form' 
                            name="name"
                            onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()}
                        />
                    <label className='h6'>Email</label>
                        <input defaultValue={miniData && miniData.Email} 
                            className="form-control" 
                            form='modal-form' 
                            name="email"
                            type="email"
                            onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()}
                        />
                    <label className='h6'>Magazyn</label>
                        <input defaultValue={miniData && miniData.WarehousName} 
                            list="warehouses" 
                            className="form-control" 
                            form='modal-form' 
                            name="warehouse"
                            onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()}
                        />
                        <datalist id="warehouses">
                            {warehouses && warehouses.map((wh, idx)=> <option value={wh} key={idx}/>)}
                        </datalist>
                    <label className='h6'>Rola</label>
                        <input defaultValue={miniData && miniData.Role} 
                            list="roles" 
                            className="form-control" 
                            form='modal-form' 
                            name="role"
                            onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()}
                        />
                        <datalist id="roles">
                            {role && role.map((wh, idx)=> <option value={wh} key={idx}/>)}
                        </datalist>
                    {form !== "new" ? 
                    <>
                    <hr/>
                    {/* <label className='h6'>Wpisz stare hasło</label>
                        <input className="form-control" 
                            form='modal-form' 
                            name="password" 
                            type="text"
                            onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()}
                        /> */}
                    <label className='h6'>Wpisz nowe hasło</label>
                        <input className="form-control" 
                            form='modal-form' 
                            name="newPassword" 
                            minLength="6"
                            type="text"
                            onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()}
                        />
                    <label className='h6'>Powtórz nowe hasło</label>
                        <input className="form-control" 
                            form='modal-form' 
                            name="newPasswordRepeat" 
                            type="text"
                            minLength="6"
                            onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()}
                        />
                    <hr/>
                    <div>
                        <div className="d-flex justify-content-between mb-1 align-items-end">
                            <label className='h6'>Przypisz lokalizację</label>
                            <InfoToast text={infoText} delay={10000} btnSize={"sm"} leftDistance={"19%"}/>
                        </div>
                        <div className='d-flex mb-2'>
                            <input
                                className='form-control'
                                value={newLocation}
                                onChange={evt => setNewLocation(evt.target.value)}
                                // placeholder='Nowa lokalizacja...'
                                onFocus={() => setNewLocation("")}
                                list='locations'
                            />
                            <datalist id='locations'>
                                {locationsList && [...locationsList].filter(i => !i.IsDeleted).map((location, idx) =>(
                                    <option value={location.Name} key={idx}/>
                                ))}
                            </datalist>
                            <button className="btn btn-outline-success" onClick={addNewLocation} disabled={!newLocation}> 
                                <i className=" fa fa-plus"/> 
                            </button>
                        </div>
                        {
                            [...locations].filter(i => !i.IsDeleted).length ? (
                                <>
                                <label className='h6 mt-3 mb-1'>Lokalizacje</label>
                                <table className='table table-striped '>
                                    <tbody>
                                        {locations && [...locations].filter(i => !i.IsDeleted).map((location, idx) => (
                                            <tr key={idx} 
                                                // onClick={() => {
                                                //     chosedLocation === location.UserLocationId 
                                                //         ? setChosedLocation(null) 
                                                //         : setChosedLocation(location.UserLocationId)
                                                //     }
                                                // } 
                                                className={location.UserLocationId === chosedLocation ? `bg-warning` : ``}>
                                                <td>
                                                    {location.Name}
                                                </td>
                                                <td>
                                                    <div className="w-100 d-flex justify-content-end ">
                                                        <button className="btn btn-sm btn-outline-danger"
                                                            onClick={evt => { evt.preventDefault(); deleteLocation(location.UserLocationId)}}> 
                                                            <i className="fa fa-trash"/> 
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </>
                            ) : <></>
                        }
                        </div>
                    </> 
                    : <>
                    <label className='h6'>Hasło</label>
                        <input className="form-control" 
                            form='modal-form' 
                            name="password" 
                            type="text"
                            minLength="6"
                            onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()}
                        />
                    </>}
                </div>
            </form>
        </FormSkel>
    )
}