import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import { LangContext } from '../../context/lang-context'
import useFetch from '../../hooks/fetchMSSQL-hook'
import Pagination from '../../components/Pagination/Pagination'
import FilterEx from '../../components/Filter/FilterEx'
import NoResults from '../../components/NoResults/NoResults'
import moment from "moment";
import { NavigationContext } from '../../context/navigation-context'


const LastVI = () => {
  const { TRL_Pack } = useContext(LangContext)
  const { fetchMssqlApi } = useFetch()

  const { setHeaderData } = useContext(NavigationContext)

  const [INMS, setINMS] = useState(null)

  const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
  const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))

  const defaultFilter = {
    showIndexes: false,
    page: 1,
    disableIndexes: true,
    rowsPerPage: 100,
    rowsPerPageOptions: [10, 25, 50, 100, 1000],
    visible: false,
    //sortByColumns: true,
    sortByColumns: true,
    sortBy: '3 | asc | text',
    activeTags: [],
    columns: [
      {
        id: 1,
        name: 'Pokazuj zerowe PA',
        searchable: true,
        sortable: false,
        type: 'threeStateBool',
        selectbar: null
      },
      {
        id: 2,
        name: 'Telemetria',
        searchable: true,
        sortable: false,
        type: 'threeStateBool',
        selectbar: null
      },
      {
        id: 3,
        name: 'Maszyna',
        searchable: true,
        sortable: true,
        type: 'lookup',
        MachineId: null
      },
      {
        id: 4,
        name: 'Stan maszyny',
        searchable: false,
        sortable: true,
        type: 'price',
        disabled: true
      },
      {
        id: 5,
        name: 'Serwisant',
        searchable: true,
        sortable: false,
        type: 'lookup',
        CreateUserId: null
      }, 
      // {
      //   id: 5,
      //   name: 'Zakres czasowy',
      //   searchable: false,
      //   sortable: false,
      //   type: 'datetimeRange',
      //   dateTimeRangeFrom: moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss'),
      //   dateTimeRangeTo: moment().format('YYYY-MM-DDT23:59:59')
      // }
    ]
  }

  const [filter, setFilter] = useState(() => {
    if (localStorage.getItem('lastViFilter')) {
      return JSON.parse(localStorage.getItem('lastViFilter'));
    }
    return defaultFilter;
  })

  const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))
  const resetFilter = () => {
    setFilter(defaultFilter);

    //    search();
    getINMS(null, null, null);
  }

  const search = () => {
    //TODO: unhardcode column idx, make it configurable
    const columns = filter.columns

    for (let a in columns) {
      if (columns[a].searchbar == undefined) {
        columns[a].searchbar = null
      }
    }

    let isZeroTotal = null;
    if (JSON.parse(columns[0].selectbar == 'false'))
      isZeroTotal = false;
    else if (JSON.parse(columns[0].selectbar == 'true'))
      isZeroTotal = true;

    let isTelemetry = null;
    if (JSON.parse(columns[1].selectbar == 'false'))
      isTelemetry = false;
    else if (JSON.parse(columns[1].selectbar == 'true'))
      isTelemetry = true;

    let machineId = null;
    if (JSON.parse(columns[2].MachineId != '') && JSON.parse(columns[2].MachineId != null))
      machineId = columns[2].MachineId;

    let createUserId = null;
    if (JSON.parse(columns[4].CreateUserId != '') && JSON.parse(columns[4].CreateUserId != null))
      createUserId = columns[4].CreateUserId;

    // //set default create from / to date time
    // let fromCreateDateTime = moment().add(-7, 'd').format('YYYY-MM-DDTHH:mm:ss');
    // let toCreateDateTime = moment().format('YYYY-MM-DDT23:59:59');

    // if (JSON.parse(columns[4].dateTimeRangeFrom != '') && JSON.parse(columns[4].dateTimeRangeFrom != null))
    //   //TODO: parse manually entered(?)
    //   fromCreateDateTime = columns[4].dateTimeRangeFrom.replace('T', ' ');

    // if (JSON.parse(columns[4].dateTimeRangeTo != '') && JSON.parse(columns[4].dateTimeRangeTo != null))
    //   //TODO: parse manually entered(?)
    //   toCreateDateTime = columns[4].dateTimeRangeTo.replace('T', ' ');

    let machineTags = null;
    if (JSON.parse(filter.activeTags != '') && JSON.parse(filter.activeTags != null))
      machineTags = filter.activeTags;
    //TODO: parse machineTags(?)

    getINMS(machineTags, machineId, createUserId);
  }

  const getINMS = (machineTags = null, machineId = null, createUserId = null) => {
    fetchMssqlApi('visit-last-wis', {
      method: 'POST',
      hideNotification: true,
      data: {
        clientId: localStorage.getItem("clientId"),
        // fromCreateDateTime: fromCreateDateTime,
        // toCreateDateTime: toCreateDateTime,
        machineTags: machineTags,
        machineId: machineId,
        createUserId: createUserId
      }
    }, response => {
      setINMS(response);
      // console.log(response)
    })
  }


  useEffect(() => {
    getINMS()
    setHeaderData({
      text: "Ostatnie wizyty"
    });
  }, [])

  useEffect(() => localStorage.setItem('lastViFilter', JSON.stringify(filter)), [
    filter
  ])


  const sortRows = (a, b) => {
    if (filter.sortBy === undefined) return
    const [id, order, type] = filter.sortBy.split(' | ')
    const col = Object.keys(INMS[0])[Number(id) - 1]

    if (a[col] === b[col]) return 0
    else if (a[col] === null) return 1
    else if (b[col] === null) return -1

    let valueA, valueB
    if (type === 'lookup' || type === 'date') {
      valueA = a[col].toUpperCase()
      valueB = b[col].toUpperCase()
    } else if (type === 'price') {
      valueA = Number(a[col]) || 0
      valueB = Number(b[col]) || 0

      // Number().toLocaleString(undefined, {minimumFractionDigits: 2}) num => str '1 245 151,50'
    } else return 0

    if (order === 'asc') return valueA < valueB ? -1 : 1
    else return valueA < valueB ? 1 : -1
    
  }

  // const reportFilter = row =>
  //   filter.columns
  //     .filter(col => col.searchbar)
  //     .every(col =>
  //       row[Object.keys(row)[col.id - 1]]
  //         .toLowerCase()
  //         .includes(col.searchbar.toLowerCase()))

  // const tagFilter = salesdoc => {
  //   return (
  //     tags
  //       .filter(
  //         label =>
  //           label.others &&
  //           label.options
  //             .map(tag => tag.tagId)
  //             .some(tag => filter.activeTags.includes(tag))
  //       )
  //       .map(label =>
  //         label.options
  //           .map(opt => opt.tagId)
  //           .filter(tagId => filter.activeTags.includes(tagId))
  //       )
  //       .every(label =>
  //         label.every(opt => salesdoc.SalesdocTags.split(', ').includes(opt))
  //       ) &&
  //     tags
  //       .filter(
  //         label =>
  //           !label.others &&
  //           label.options
  //             .map(tag => tag.tagId)
  //             .some(tag => filter.activeTags.includes(tag))
  //       )
  //       .map(label =>
  //         label.options
  //           .map(opt => opt.tagId)
  //           .filter(tagId => filter.activeTags.includes(tagId))
  //       )
  //       .every(label => label.some(opt => salesdoc.SalesdocTags.split(', ').includes(opt)))
  //   )
  // }

  const getUI = () => {
    if (INMS != null) {
      let temp = JSON.parse(JSON.stringify(INMS));

      // console.log(salesdoc)

      return (
        <div>
          <div>
            <Pagination
              {...{

                totalItems: INMS.length,
                page: filter.page,
                handleSwitchPage,
                rowsPerPage: filter.rowsPerPage,
                toggleFilter,
                resetFilter,
                filterVisibility: filter.visible
              }}
            />
            {filter.visible && (
              <>
                <FilterEx
                  {...{
                    filter,
                    setFilter,
                    columns: filter.columns,
                    data: temp,
                    resetPage,
                    resetFilter,
                    search,
                    toggleFilter,
                    enableMachineTags: true
                  }}
                />
              </>
            )}

      <div className="card">

      {/* <h5 className="card-header"> */}
      {/* TO DO: Add translations */}
        {/* {TRL_Pack.wi.wis}  */}
      {/* </h5> */}
          
      </div>
      <div className="card-body overflow-auto" style={{ maxHeight: 550 }}>

        {INMS.length ? (
          <table className="table table-striped">
            <thead> 
              <tr className="justify-content-center">
                <td>Nr wizyty <br />Czas zapisu, pobrania danych</td>
                <td>{TRL_Pack.wi.MachineFullName}</td>
                <td>{TRL_Pack.wi.Sales}<br />Rozliczenie monet</td>
                <td>Stan maszyny</td>
                <td>Doładowanie <br /> Korekta stanu</td>
              </tr>
            </thead>
            <tbody>
              {INMS
                .sort(sortRows)
                // .filter(filterByDate)
                .slice(
                  (filter.page - 1) * filter.rowsPerPage,
                  filter.page * filter.rowsPerPage
                )
                .map((entry, idx) => (
                  <tr key={idx}>
                    <td className="">
                      <div className='d-flex flex-column justify-content-center align-items-start'>
                        <div className="btn text-nowrap btn-sm btn-outline-primary">{entry.WI}</div>
                        <div className="btn text-nowrap">{entry.VisitCreated}</div>
                        {/* <div className="btn text-nowrap">{entry.CreatedBy}</div> */}
                        {entry.ID5 !== '' && (<div className="btn text-nowrap">{entry.ID5}</div>)}
                      </div>
                    </td>
                    <td className="d-flex flex-column justify-content-center">{entry.MachineFullName}</td>
                    <td><div className='d-flex flex-column justify-content-center align-items-start'>
                      <Link to={`/pa/${entry.SalesWarehouseOperationId}`} className="btn text-nowrap btn-sm btn-outline-secondary">{entry.PA}</Link>
                      
                      {/* <button className="btn text-nowrap btn-sm btn-outline-success" onClick={() => addKP(entry.VisitId)}>Dodaj KP</button> */}

                      <div className="btn text-nowrap">{entry.Total !== null && String(entry.Total.toFixed(2)).replace('.', ',')} zł</div>
                      <div className="btn text-nowrap">{entry.TotalCount} szt.</div>
                    {entry.KP !== '' && (<button onClick={() => {}} className="btn text-nowrap btn-sm btn-outline-success">{entry.KP}</button>)}
                    
                    </div>
                    </td>
                    <td><div className='d-flex flex-column justify-content-center align-items-start'>
                      {entry.INm !== '' && (<Link to={`/inm/${entry.MachineInventoryId}`} className="btn text-nowrap btn-sm btn-outline-danger">{entry.INm}</Link>)}
                      <div className="btn text-nowrap">{entry.StateAndCapacity}</div>
                      <div className="btn text-nowrap">{entry.StatePercent}%</div>
                    </div></td>
                    <td><div className='d-flex flex-column justify-content-center align-items-start'>
                      {entry.DO !== '' && (<Link to={`/do/${entry.LoadMachineInventoryId}`} className="btn text-nowrap btn-sm btn-outline-info">{entry.DO}</Link>)}
                      {entry.LoadQuantity !== null && (<div className="btn text-nowrap">{entry.LoadQuantity} szt.</div>)}
                      {entry.LoadQuantity === null && (<div className="btn text-nowrap">-</div>)}
                      {entry.ConfigChange !== 0 && (<div className="btn text-nowrap">Zmiana konfiguracji</div>)}
                      {entry.KO !== null && (<div className="btn text-nowrap">Powód: {entry.KO}</div>)}
                      {entry.KOComment !== null && (<div className="btn text-nowrap">{entry.KOComment}</div>)}
                    </div></td>
                  </tr>
                ))}
            </tbody>
          </table>
        ) : (
          <NoResults />
        )
        }
      </div>
      </div>
      </div>
      )
    }
  }

  return (
    <>
      <div>
        {getUI()}
      </div>
    </>
  )
}

export default LastVI;