import React from 'react'
import { OverlayTrigger, Button, Spinner, Tooltip } from 'react-bootstrap'

export default function PaginationExSm ({rowsPerPage, page, handleSwitchPage, rowsCount, toggleFilter, filterVisibility, resetBtnVis, resetFilter}) {
    // *direction: forward | backward
    const ListItem = ({ direction, linearStyle, disabled }) => {
        switch (direction) {
            case "start":
                return (
                    <Button
                        disabled={disabled}
                        style={{width: "57px"}}
                        className={`d-inline m-1 btn-light btn-outline-primary float-right p-0`}
                        onClick={() => handleSwitchPage(1)}
                    >
                        <div className='w-100 d-flex align-items-center justify-content-center'>

                            <i className='fas fa-xs fa-chevron-left p-0 m-0'>

                            </i>
                            <i className='fas fa-xs fa-chevron-left p-0 m-0'>

                            </i>
                        </div>
                    </Button>
                    )
            case "forward":
                return (
                    <Button
                        disabled={disabled}
                        style={{width: "114px"}}
                        className={`d-inline m-1 btn-light btn-outline-primary float-right fas fa-xs fa-chevron-right`}
                        onClick={() => handleSwitchPage(page+1)}
                    ></Button>
                    // <div className={`page-item ${linearStyle}`} >
                    //     <button className="page-link mt-1 ml-1 mb-1" style={{width: "114px", maxHeight: "12px"}} onClick={() => handleSwitchPage(page+1)}>
                    //         <i className="fas fa-chevron-right fa-sm"></i>
                    //     </button>
                    // </div>
                    )
                    case "backward":
                return (
                    <Button
                        disabled={disabled}
                        style={{width: "114px"}}
                        className={`d-inline m-1 btn-light btn-outline-primary float-right fas fa-xs fa-chevron-left` }
                        onClick={() => handleSwitchPage(page-1)}
                    ></Button>
                    // <div className={`page-item ${linearStyle}`} >
                    //     <button className="page-link mt-1 ml-1 mb-1" style={{width: "114px", maxHeight: "12px"}} onClick={() => handleSwitchPage(page-1)}>
                    //         <i className="fas fa-chevron-left fa-sm"></i>
                    //     </button>
                    // </div>
                )
            default:
                return (
                    <li></li>
                )
        }
    }

    const PaginationBar = () => {
        if (+page > 1 && +page < 3 && +rowsPerPage <= +rowsCount) {
            return (
                <>
                    <ListItem direction="start" disabled={true}/>
                    <ListItem direction="backward"/>
                    <div className='h-100 d-flex justify-content-center align-items-center'>{page}</div>
                    <ListItem direction="forward"/>
                </>
            )
        }
        if (+page > 1 && +page < 3 && +rowsPerPage > +rowsCount) {
            return (
                <>
                    <ListItem direction="start" disabled={true}/>
                    <ListItem direction="backward"/>
                    <div className='h-100 d-flex justify-content-center align-items-center'>{page}</div>
                    <ListItem direction="forward" disabled={true}/>
                </>
            )
        }
        if (+page >= 3 && +rowsPerPage > +rowsCount) {
            return (
                <>
                    <ListItem direction="start"/>
                    <ListItem direction="backward"/>
                    <div className='h-100 d-flex justify-content-center align-items-center'>{page}</div>
                    <ListItem direction="forward" disabled={true}/>
                </>
            )
        }
        if (+page === 1 && +rowsPerPage <= +rowsCount) {
            return (
                <>
                    <ListItem direction="start" disabled={true}/>
                    <ListItem direction="backward" disabled={true}/>
                    <div className='h-100 d-flex justify-content-center align-items-center'>{page}</div>
                    <ListItem direction="forward"/>
                </>
            )
        }
        if (+page >= 3 && +rowsPerPage <= +rowsCount) {
            return (
                <>
                    <ListItem direction="start"/>
                    <ListItem direction="backward"/>
                    <div className='h-100 d-flex justify-content-center align-items-center'>{page}</div>
                    <ListItem direction="forward"/>
                </>
            )
        }
        return (
            <>
                    <ListItem direction="start" disabled={true}/>
                    <ListItem direction="backward" disabled={true}/>
                    <div className='h-100 d-flex justify-content-center align-items-center'>{page}</div>
                    <ListItem direction="forward" disabled={true}/>
                </>
        )
    }
    return (
        <>
                <div className='mb-0 d-flex justify-content-center w-100  justify-content-between' >
                    <ul className="pagination mb-0" >
                        <PaginationBar/>
                    </ul>
                    <div className='d-flex'>

                    {toggleFilter && (
                        <button className=" ml-2 my-auto" onClick={toggleFilter} style={{height: "3vh"}}>
                            <i
                            className={`fas fa-filter ${filterVisibility ? 'text-primary' : 'text-secondary'}`}
                            />
                        </button>
                    )}
                    {resetBtnVis && filterVisibility && (
                        <button className=" ml-2 my-auto" onClick={resetFilter} style={{height: "3vh"}}>
                            <i
                            className="fas fa-times-circle" style={{color: "#dc3545"}}
                            />
                        </button>
                    )}
                    </div>
                </div>
        </>
    )
}
