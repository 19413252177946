import React, { useContext, useEffect, useState } from 'react'
import fetchMSSQLHook from '../../hooks/fetchMSSQL-hook'
import NoResults from '../../components/NoResults/NoResults'
import moment from 'moment'
import { NavigationContext } from '../../context/navigation-context'
import FilterEx from '../../components/Filter/FilterEx'
import Pagination from '../../components/Pagination/Pagination'
import { getSheet } from '../../util/getSheets'
import { API_URL } from '../../config/config'
import ReportBtn from '../../components/Buttons/ReportsBtn/ReportBtn'


export default function Errors() {
    const [errors, setErrors] = useState(null)
    const [machines, setMachines] = useState(null)
    const [locations, setLocations] = useState(null)
    const {fetchMssqlApi} = fetchMSSQLHook()
    const {setHeaderData} = useContext(NavigationContext)


    const defaultFilter = {
        showIndexes: false,
        page: 1,
        disableIndexes: true,
        rowsPerPage: 100,
        rowsPerPageOptions: [10, 25, 50, 100, 1000],
        visible: false,
        //sortByColumns: true,
        sortByColumns: false,
        //sortBy: '3 | asc | text',
        activeTags: [],
        columns: [
        {
            id: 2,
            name: 'Automat',
            searchable: true,
            sortable: true,
            type: 'lookup',
            MachineId: null,
            searchbar: null
        },
        {
            id: 3,
            name: 'Nr seryjny',
            searchable: true,
            sortable: true,
            type: 'lookup',
            MachineId: null,
            searchbar: null
        },
        {
            id: 8,
            name: 'Lokalizacja',
            searchable: true,
            type: 'lookup',
            locationId: null
        },
        {
            id: 7,
            name: 'Zakres czasowy',
            searchable: true,
            sortable: false,
            type: 'datetimeRange',
            dateTimeRangeFrom: moment().format('YYYY-MM-DDT00:00:00'),
            dateTimeRangeTo: moment().format('YYYY-MM-DDT23:59:59')
        },
        ]
    }


    const [filter, setFilter] = useState(() => {
        if (localStorage.getItem('machineErrorsFilter')) {
            const filter = JSON.parse(localStorage.getItem('machineErrorsFilter'))
            filter.page = 1
            filter.columns[3].dateTimeRangeFrom = moment().format('YYYY-MM-DDT00:00:00')
            filter.columns[3].dateTimeRangeTo = moment().format('YYYY-MM-DDT23:59:59')
            return filter;
        }
        return defaultFilter;
    })

    const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))

    const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }));
    const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
    const getMachinesList = () => {
        fetchMssqlApi('machines-list', {}, machines => setMachines(machines))
    }
    const getLocations = () => {
        fetchMssqlApi('locations', {}, locations => setLocations(locations))
    }

    
    const getReqBody = () => {
        const searchFilter = {}
        try {
            searchFilter.CreateDateTimeFrom = filter.columns[3].dateTimeRangeFrom
        } catch {
            searchFilter.CreateDateTimeFrom = moment().format('YYYY-MM-DDT00:00:00')
        }
        try {
            searchFilter.CreateDateTimeTo = filter.columns[3].dateTimeRangeTo
        } catch {
            searchFilter.CreateDateTimeTo = moment().format('YYYY-MM-DDT23:59:59')
        }
        const machine = {
            serialNo: filter.columns[1].value,
            name: filter.columns[0].value
        }
        if(Object.values(machine).every(i => !i)) searchFilter.MachineId = null
        if(machine.name) {
            const machineInfo = machines && machines.find(i => i.Name === machine.name)
            if (!machineInfo) searchFilter.MachineId=null
            else {
                searchFilter.MachineId = machineInfo.MachineId
            }
        }   
        if(machine.serialNo) {
            const machineInfo = machines && machines.find(i => i.SerialNo === machine.serialNo)
            if (!machineInfo) searchFilter.MachineId=null
            else {
                searchFilter.MachineId = machineInfo.MachineId
            }
        }
        const locatioName = filter.columns[2].value
        if(locatioName) {
            const locationInfo = locations && locations.find(i => i.Name === locatioName)
            if(!locationInfo) searchFilter.LocationId=null
            else {
                searchFilter.LocationId = locationInfo.LocationId
            }
        }

        searchFilter.Tags = filter.activeTags.length ? filter.activeTags : null
        return searchFilter
    }
    const getErrors = () => {
        const f = getReqBody()
        fetchMssqlApi('/machine-errors', {method: "POST", data: f ,hideNotification: true}, res => setErrors(res))
    }

    const resetFilter = () => {
        setFilter(defaultFilter);
        getErrors()
    }
    // const get
    useEffect(() => localStorage.setItem('machineErrorsFilter', JSON.stringify(filter)), [filter])
    useEffect(() => {
        setHeaderData({ text: "Błędy maszyn" })
        getErrors()
        getMachinesList()
        getLocations()
    }, [])
    const [rep95, setRep95] = useState(false)
    useEffect(() => {
        console.log(errors)
    }, [errors])

    const getRep = () => {
        const f = getReqBody()
        getSheet({
            callbackToChangeState: setRep95,
            url: `${API_URL}/api/reports/machine-errors`,  
            name: `DZ95_${moment().format('YYYY_MM_DDTHH_mm_ss').replace('T', '_')}.xlsx`,
            body: {
                reportId: 95, 
                ...f
            },
            spinnerTriggerState: true
        })
    } 
    return (
        <div className='container-fluid'>
            {
                <Pagination
                    totalItems={errors && errors.length}
                    page={filter.page}
                    handleSwitchPage={handleSwitchPage}
                    rowsPerPage={filter.rowsPerPage}
                    toggleFilter={toggleFilter}
                    filterVisibility={filter.visible}
                    resetFilter={resetFilter}
                />
            }
            {filter.visible && (
                <FilterEx
                {...{ filter, setFilter,columns: filter.columns, resetPage, resetFilter, search: getErrors, toggleFilter, enableMachineTags: true}}
                />
            )}
            {errors ? (
            <div className='card'>
                <div className='card-header d-flex justify-content-between'>
                    <div>Błędy automatu</div>
                    <div>
                    <ReportBtn
                        clickFnc={getRep}
                        state={rep95}
                        setState={setRep95}
                        overlayText={(<div>
                            <b>Typ raportu:<br />Błędy utomatów [DZ95]</b><br /><br />Raport zawiera inofrmacje o błędach, które występili w wybranym okresie czasowym.
                          </div>)}
                        btnColor='secondary'
                        permissionName={"VD_REPORT_95"}
                      />
                    </div>
                </div>
                <table className="table table-striped">
                    <thead>
                        <tr className='justify-content-center'>
                            <td>Automat</td>
                            <td>Nr seryjny</td>
                            <td>Data / czas</td>
                            <td>Nr wyboru</td> 
                            <td>Błąd / Krytyczność </td>
                        </tr>
                    </thead>
                    <tbody>
                        {errors && errors.slice(
                                (filter.page - 1) * filter.rowsPerPage,
                                filter.page * filter.rowsPerPage
                            ).map(i => (
                            <tr>
                                <td>{i.MachineName}</td>
                                <td>{i.SerialNo}</td>
                                <td>{moment(i.CreateDateTime).format("YYYY-MM-DD HH:mm:ss")}</td>
                                <td>
                                    {(function(){
                                        if ((/Nr wyboru: \d/).test(i.Comment)) {
                                            return (
                                                <div className='d-flex w-100 justify-content-between align-items-center' style={{fontSize: "120%"}}>
                                                    <div><span className={`badge badge-success`}>{i.Comment}</span></div>
                                                    {/* <div><span className={`badge badge-info`}>Ukryty: {data?.Hidden ? "tak" : "nie"}</span></div> */}
                                                    <div><span className={`badge badge-${i.IsCritical ? "danger" : "warning"}`}>Kod błędu: {i?.Code}</span></div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className='d-inline w-100 justify-content-center align-items-center'>
                                                    <div>Komentarz: {i.Comment}</div>
                                                    <div style={{fontSize: "120%"}}><span className='badge badge-warning'>Kod błędu: {i?.Code}</span></div>
                                                </div>
                                            )
                                        }

                                    })()}
                                </td>
                                <td>
                                    {i.ErrorName}
                                </td>
                            </tr> 
                        ))}
                    </tbody>
                </table>
            </div>
            ) : (<NoResults/>)}
        </div>
    )
}





