import React, {useEffect, useContext, useState} from 'react'
import fetchHook from '../../hooks/fetchMSSQL-hook'
import { NavigationContext } from '../../context/navigation-context'
import { useParams, Link } from 'react-router-dom/cjs/react-router-dom.min'
import link from '../../assets/images/back_to_list_icon.svg'



export default function KO() {
    const { fetchMssqlApi } = fetchHook(),
    { setHeaderData } = useContext(NavigationContext),
    {id} = useParams(),
    [KO, setKO] = useState(),
    [KOItems, setKOItems] = useState([])

    const getKO = () => {
        fetchMssqlApi(`/correction/${id}`, {}, res => {
            setKOItems({Minus: res.Minus, Plus: res.Plus})
            delete res.Minus
            delete res.Plus
            setKO(res)
        } )
    }
    useEffect(() => {
        setHeaderData({text: "Korekt"})
        getKO()
    }, [])
    return (
        <div>
            {
                KO && (
                    <>
                    
                            <div className='container-xl'>
                    {/* <Link to='/kos'>
                        <button className="btn btn-link ml-1 text-decoration-none mb-3">
                            <i className="fas fa-arrow-left mr-2" />
                            Wróć
                        </button>
                    </Link> */}
                    <Link to='/kos'>
                        <label to='back' className="btn btn-link ml-1 text-decoration-none mb-3">
                            <img id='back' src={link}></img>
                        </label>
                    </Link>
                    <div className='card mb-4'>
                        <div className='card-header d-flex justify-content-between'>
                            <h5 className='h5 mb-0'>
                                {KO.CorrectionName}
                            </h5 >
                        </div>
                        <div className='card-body'>
                            <table className="table table-striped">
                                <tbody>
                                    <tr className='justify-content-center'>
                                        <td className='text-center'>Automat</td>
                                        <td className='text-center'>Stworzone</td>
                                        <td className='text-center'>Komentarz</td>
                                    </tr>
                                    <tr className='justify-content-center'>
                                        <td>
                                            <div className='d-flex justify-content-center mb-1 mt-1'>
                                                {KO.MachineFullName}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-center mb-1 mt-1'>
                                                {KO.Created}
                                            </div>
                                        </td>
                                        <td>
                                            <div className='d-flex justify-content-center mb-1 mt-1'>
                                                {KO.Comment}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className='justify-content-center'>
                                        <td className='text-center'>RO</td>
                                        <td className='text-center'></td>
                                        <td className='text-center'>DO</td>
                                    </tr>
                                    <tr>
                                        <td className='text-center'>
                                            <div className='d-flex flex-column justify-content-center align-items-center mt-2'>
                                                {KO.RO && (<Link to={`/ro/${KO.UnloadMachineInventoryId}`} className="btn text-nowrap btn-sm btn-outline-danger">{KO.RO}</Link>)}
                                            </div>
                                        </td>
                                        <td className='text-center'></td>
                                        <td className='text-center'>
                                            <div className='d-flex flex-column justify-content-center align-items-center mt-2'>
                                                {KO.DO !== '' && (<Link to={`/do/${KO.LoadMachineInventoryId}`} className="btn text-nowrap btn-sm btn-outline-info">{KO.DO}</Link>)}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    
                    {KOItems.Plus.length ?
                    ( <div className='card'>
                        <div className='card-header'>
                            <div className='d-flex justify-content-between align-items-center'>

                                <div>Doladowano</div>
                                <div>
                                        <h3 className='mb-0'> <span className={"ml-2 badge badge-success"}> {KO.DOCount} </span> </h3>
                                </div>
                            </div>
                        </div>
                        <table className="table table-striped ">
                        <thead>
                            <tr>
                                <th>Produkt</th>
                                <th>Nr sprężyny</th>
                                <th>Ilość</th>
                            </tr>   
                        </thead>
                        <tbody>
                            {KOItems.Plus.map((item, idx) => 
                            !item.deleted && 
                            (
                                <tr key={idx}>
                                    <td>{item.ProductName}</td>
                                    <td>{item.MachineFeederNo}</td>
                                    <td><h5> <span className={"ml-2 badge badge-success"}> {item.Quantity} </span> </h5> </td>
                                </tr>
                            ))}
                            
                        </tbody>
                        </table>
                        </div>)
                        : 
                        <>
                        </>
                    }
                    {KOItems.Minus.length ?
                    (
                        <div className='card'>
                        <div className='card-header'>
                            <div className='d-flex justify-content-between align-items-center'>
                            <div >
                                Rozladowano
                            </div>
                            <div>
                                <h3 className='mb-0'> <span className={"ml-2 badge badge-danger"}> {KO.ROCount} </span> </h3>
                            </div>
                            </div>
                        </div>
                        <table className="table table-striped ">
                        <thead>
                            <tr>
                                <th>Produkt</th>
                                <th>Nr sprężyny</th>
                                <th>Ilość</th>
                            </tr>   
                        </thead>
                        <tbody>
                            {KOItems.Minus.map((item, idx) => 
                            !item.deleted && 
                            (
                                <tr key={idx}>
                                    <td>{item.ProductName}</td>
                                    <td>{item.MachineFeederNo}</td>
                                    <td><h5> <span className={"ml-2 badge badge-danger"}> {item.Quantity} </span> </h5> </td>
                                </tr>
                            ))}
                            
                        </tbody>
                        </table>
                        </div>)
                        : 
                        <>
                        </>
                    }
                    </div>
                    </>
                )
            }
        </div>
    )
}
