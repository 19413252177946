import React, {useEffect, useState} from 'react'
import fetchHook from '../../../hooks/fetchMSSQL-hook'
import NoResults from '../../../components/NoResults/NoResults'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'


export default function CashRegistersList() {

    const [cashRegisters, setCashRegisters] = useState([]),
    { fetchMssqlApi } = fetchHook()


    const getCashRegisters = (id = null) => {
        return fetchMssqlApi(`cashRegisters/null`, {}, res => setCashRegisters(res))
    }
    useEffect(() => {
        getCashRegisters()
    }, [])

    const getTypeOfWork = (cashRegister) => {
        switch (cashRegister.TypeOfWork) {
            case "Tylko do odczytu":
                return (
                    <h5 className='mb-0'>
                        <span className={`badge badge-warning`}>
                                {cashRegister.TypeOfWork}
                        </span>
                    </h5>
                )
            case "Fiskalny (środowisko testowe)":
                return (
                    <h5 className='mb-0'>
                        <span className={`badge badge-primary`}>
                                {cashRegister.TypeOfWork}
                        </span>
                    </h5>
                )
            case "Fiskalny":
                return (
                    <h5 className='mb-0'>
                        <span className={`badge badge-success`}>
                                {cashRegister.TypeOfWork}
                        </span>
                    </h5>
                )
            case "Obsługi":
                return (
                    <h5 className='mb-0'>
                        <span className={`badge badge-secondary`}>
                                {cashRegister.TypeOfWork}
                        </span>
                    </h5>
                )
            default:
                return (
                    <h5 className='mb-0'>
                        <span className={`badge badge-danger`}>
                                Nie rozpoznano
                        </span>
                    </h5>
                )
        }
    }
    return (
    <div>
            <div>
            <div name="filter">

            </div>
            <div className='card ml-5 mr-5'>
                <h5 className='card-header'>
                    <div className='d-flex justify-content-between'>
                        Kasy fiskalne
                        {JSON.parse(localStorage.getItem("lastLogin")).email === "serwis@vendim.pl" && (
                            <Link to={`/wkf/cashRegister/0`} className="btn text-nowrap btn-sm btn-outline-secondary"><i className="fas fa-plus" /></Link>
                        )}
                    </div>
                </h5>
                <div className='card-body'>
                    {cashRegisters.length ? (
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <td>Numer fabryczny</td>
                                    <td>Przypisany automat</td>
                                    <td>Numer ewidencyjny</td>
                                    <td>Numer unikatowy</td>
                                    <td className='text-center'>Tryb pracy</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                                {cashRegisters.map((cr, idx) => (
                                    <tr key={idx}>
                                        <td>{cr.TSN}</td>
                                        <td>{cr.MachineName}</td>
                                        <td>{cr.NrEwid}</td>
                                        <td>{cr.NrUnik}</td>
                                        <td className='text-center'>
                                            {getTypeOfWork(cr)}
                                        </td>
                                        {/* <td>
                                            <div className='d-flex justify-content-end w-100'>
                                                <ReportBtnForArr/>
                                                <ReportBtnForArr btnColor='success'/>
                                                <ReportBtnForArr btnColor='danger'/>
                                            </div>
                                        </td> */}
                                        <td>
                                            <div className='d-flex justify-content-end border-0 mt-2 mb-2'>
                                                <Link className=" mr-4 btn btn-outline-primary btn-sm "
                                                    to={`/wkf/cashRegister/${cr.CashRegisterId}`}
                                                    // key={location.id+"btn"}
                                                >
                                                        Podgląd
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <NoResults/>
                    )}

                </div>
            </div>
        </div>
    </div>
    )
}
