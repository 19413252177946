import React, { useEffect } from 'react'
import { OverlayTrigger, Button, Spinner, Tooltip } from 'react-bootstrap'
import { useContext } from 'react'
import { NavigationContext } from '../../../context/navigation-context'
import { useState } from 'react'

export default function ReportBtn({
    clickFnc = (() => {}) // click handler function (in params toggle state)
    , state = false // toggle state
    , setState // toggle function
    , overlayText = (<></>)
    , placement = "left"
    , btnColor = "primary" // button color. By default is blue
    , size = "sm" 
    , permissionName = null
    , style = {}
}) {
    const navCtx = useContext(NavigationContext)
    const permissions = navCtx.state?.enableReports
    const [showBtn, setShowBtn] = useState(false)
    useEffect(() => {
            if (permissionName) {
                permissions && permissions.indexOf(String(permissionName).toUpperCase()) !== -1 ? setShowBtn(true) : setShowBtn(false)
            } else if (!permissionName) {
                setShowBtn(true)
            }
    }, [] )
    return (
        showBtn ?
            !state ? (
                <OverlayTrigger
                    // id='DZ87'
                    placement={placement}
                    overlay={<Tooltip>{overlayText}</Tooltip>}
                >
                    <Button
                        variant='primary'
                        style={style}
                        className={`d-inline m-1 btn btn-${btnColor} float-right fas fa-xs fa-download`}
                        onClick={() => clickFnc(setState)}
                    ></Button>
                </OverlayTrigger>
            ) : (
                <Button
                style={style}
                className={`d-flex justify-content-center align-items-center m-1 btn btn-${btnColor} float-right btn `}>
                <Spinner
                    as="span"
                    animation="border"
                    size={size}
                    role="status"
                    aria-hidden="true"
                    variant='light'
                    className='b-0 pr-0 pl-0 '
                    style={{maxHeight: "12px", maxWidth: "12px"}}
                />
            </Button>
            ) 
        : (<></>)
    )
}
