import React, { useContext, useEffect, useState, useRef } from 'react';

import useHttp from '../../hooks/use-http';
import { LangContext } from '../../context/lang-context';
import { API_URL } from '../../config/config';

import classes from './MachinesImages.module.css';
import { Button, ButtonGroup, Form, InputGroup, ModalBody, Toast, ToggleButton } from 'react-bootstrap';
import fetchHook from '../../hooks/fetchMSSQL-hook';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { NotificationManager } from 'react-notifications';
import FormSkel from '../Modals/FormSkel';

const MachinesImages = ({machineData}) => {
    const { TRL_Pack } = useContext(LangContext);
    const [images, setImages] = useState({});
    const [initialImages, setInitialImages] = useState({});
    const [initialServicemanInfo, setInitialServicemanInfo] = useState({});
    const [servicemanInfo, setServicemanInfo] = useState({});
    const [initialMenuOptinalText, setInitialMenuOptinalText] = useState("");
    const [menuOptinalText, setMenuOptinalText] = useState("");
    const [initialAutoAddToCart, setInitialAutoAddToCart] = useState(false);
    const [autoAddToCart, setAutoAddToCart] = useState(false);
    const [initialCoinCreditEnabled, setInitialCoinCreditEnabled] = useState(false);
    const [coinCreditEnabled, setCoinCreditEnabled] = useState(false);
    const [isImageChanged, setIsImageChanged] = useState(false);
    const {fetchMssqlApi} = fetchHook()
    const formRef = useRef();
    const [screensaverImgs, setScreensaverImgs] = useState([])
    const params = useParams()
    const [isDownloading, setIsLoading] = useState(false)
    const [showToast, setShowToast] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [isDefaultSettings, setIsDefaultSettings] = useState(null)
    const [initialScreensaversDurationBeforeTurnOn, setInitialScreensaversDurationBeforeTurnOn] = useState("")
    const [screensaverTimout, setScreensaverTimout] = useState(null)
    const [maxCartPrice, setMaxCartPrice] = useState(0.00)
    const [initialMaxCartPrice, setInitialMaxCartPrice] = useState(0.00)
    const [maxCartCapacity, setMaxCartCapacity] = useState(0)
    const [initialMaxCartCapacity, setInitialMaxCartCapacity] = useState(0)

    const { sendRequest: sendImageRequest } = useHttp(); // Use http hook
    useEffect(() => {
        const getImagesResponse = images => { // Callback to set received data from axios HTTP request executed in useHttp hook
            setIsLoading(true)
            const _images = {
                Header: images.data.Header && images.data.Header.Image ? `${API_URL}/${images.data.Header.Image}` : null,
                Footer: images.data.Footer && images.data.Footer.Image ?`${API_URL}/${images.data.Footer.Image}` : null,
                Cart: images.data.Cart && images.data.Cart.Image ? `${API_URL}/${images.data.Cart.Image}` : null,
                Screensavers: images.data.Screensavers ? images.data.Screensavers.map(image => ({...image, Image:`${API_URL}/${image.Image}`})) : null
            }
            setIsLoading(false)
            setInitialImages(_images);
        };

        sendImageRequest({ url: params.machineId ? `machine-images/${machineData.SerialNo}` : 'machine-images' }, getImagesResponse); // execute sendRequest renamed to getImages from useHttp hook
    }, [sendImageRequest]);

    useEffect(() => {
        let isImageChanged = false;

        if(
            images.Header === initialImages.Header && 
            images.Footer === initialImages.Footer && 
            images.Cart === initialImages.Cart && 
            JSON.stringify(images.Screensavers) === JSON.stringify(initialImages.Screensavers) &&
            JSON.stringify(initialServicemanInfo) === JSON.stringify(servicemanInfo) &&
            menuOptinalText === initialMenuOptinalText &&
            initialAutoAddToCart === autoAddToCart && 
            coinCreditEnabled === initialCoinCreditEnabled
        ) {
            isImageChanged = false;
        } else {
            isImageChanged = true;
        }

        setIsImageChanged(isImageChanged);
    }, [images, initialImages, servicemanInfo, menuOptinalText, coinCreditEnabled, autoAddToCart]);
    const focusOnElement = (evt) => {
        document.querySelector('#screensaver').click()
    } 
    const random = () => { 
        const set = new Set(images.Screensavers.map(item => +item.Id))
        const a = Math.floor(Math.random()*10000)
        return set.has(a) ? random() : a
    }
    const getServicemanInfo = () => {
        fetchMssqlApi(params.machineId ? `setting/serviceman/${machineData.SerialNo}` : 'setting/serviceman/null', {}, res => {
            Object(res).hasOwnProperty('Value') && setInitialServicemanInfo({...res, ...JSON.parse(res.Value)})
        } )
    }
    const getMenuOptionalText = () => {
        fetchMssqlApi(params.machineId ? `setting/menu_optional_text/${machineData.SerialNo}` : 'setting/menu_optional_text/null', {}, res => {
            Object(res).hasOwnProperty('Value') ? setInitialMenuOptinalText(res.Value) : setInitialMenuOptinalText("")
        } )
    }
    const getAutoAddToCart = () => {
        fetchMssqlApi(params.machineId ? `setting/auto_add_to_cart/${machineData.SerialNo}` : 'setting/auto_add_to_cart/null', {}, res => {
            Object(res).hasOwnProperty('Value') && setInitialAutoAddToCart(+res.Value === 0 ? false : true)
        } )
    }
    const getCoinCreditEnabled = () => {
        fetchMssqlApi(params.machineId ? `setting/coin_credit_enabled/${machineData.SerialNo}` : 'setting/coin_credit_enabled/null', {}, res => {
            Object(res).hasOwnProperty('Value') && setInitialCoinCreditEnabled(+res.Value === 0 ? false : true)
        } )
    }
    const getDurationBeforeTurnOn = () => {
        fetchMssqlApi(params.machineId ? `setting/screensaver/${machineData.SerialNo}` : 'setting/screensaver/null', {}, res => {
            Object(res).hasOwnProperty('Value') && setInitialScreensaversDurationBeforeTurnOn(JSON.parse(res.Value).ScreensaverTimout)
        } )
    }
    const getMaxCartPrice = () => {
        fetchMssqlApi(params.machineId ? `setting/max_cart_price/${machineData.SerialNo}` : 'setting/max_cart_price/null', {}, res => {
            Object(res).hasOwnProperty('Value') && setInitialMaxCartPrice(res.Value)
        } )
    }
    const getMaxCartCapacity = () => {
        fetchMssqlApi(params.machineId ? `setting/max_cart_capacity/${machineData.SerialNo}` : 'setting/max_cart_capacity/null', {}, res => {
            Object(res).hasOwnProperty('Value') && setInitialMaxCartCapacity(res.Value)
        } )
    }
    const getIsDefault = () => {
        if(params.machineId) {
            fetchMssqlApi(`setting/is-default/${params.machineId}`, {}, res => {
                setIsDefaultSettings(res.isDefault)
            })
        } else {
            setIsDefaultSettings(false)
        }
    }
    useEffect(() => {
        getIsDefault()
        getServicemanInfo()
        getMenuOptionalText()
        getAutoAddToCart()
        getCoinCreditEnabled()
        getDurationBeforeTurnOn()
        getMaxCartPrice()
        getMaxCartCapacity()
        // getScreensavers()
    }, [])
    useEffect(() => {
        setAutoAddToCart(initialAutoAddToCart)
    }, [initialAutoAddToCart])
    useEffect(() => {
        setCoinCreditEnabled(initialCoinCreditEnabled)
    }, [initialCoinCreditEnabled])
    useEffect(() => {
        setMenuOptinalText(initialMenuOptinalText)
    }, [initialMenuOptinalText])
    useEffect(() => {
        setServicemanInfo(initialServicemanInfo)
    }, [initialServicemanInfo])
    useEffect(() => {
        setImages(initialImages)
    }, [initialImages])
    useEffect(() => {
        setScreensaverTimout(initialScreensaversDurationBeforeTurnOn)
    }, [initialScreensaversDurationBeforeTurnOn])
    useEffect(() => {
        setMaxCartPrice(initialMaxCartPrice)
    }, [initialMaxCartPrice])
    useEffect(() => {
        setMaxCartCapacity(initialMaxCartCapacity)
    }, [initialMaxCartCapacity])
    const deleteImg = (id) => {
        // console.log(id)
        setImages(prev => ({
            ...prev,
            Screensavers: (function(){
                const scr = prev.Screensavers.find(img => String(img.Id) === String(id))
                if (scr.new) {
                    return prev.Screensavers.filter(img => String(img.Id) !== String(id))
                } else {
                    return prev.Screensavers.map(img => String(img.Id) === String(id) ? ({...img, deleted: true}) : img)
                }
            } )()
        }))
    }
    const getDeletedItems = () => {
        const deleted = [];
        (initialImages.Footer && !images.Footer || initialImages.Footer && (String(initialImages.Footer) !== String(images.Footer))) &&  deleted.push(new URL(initialImages.Footer).pathname); 
        (initialImages.Header && !images.Header || initialImages.Header && (String(initialImages.Header) !== String(images.Header))) &&  deleted.push(new URL(initialImages.Header).pathname); 
        (initialImages.Cart && !images.Cart || initialImages.Cart && (String(initialImages.Cart) !== String(images.Cart))) &&  deleted.push(new URL(initialImages.Cart).pathname); 
        return deleted
    } 
    const submitHandler = e => {
        e.preventDefault();
        setIsLoading(true)
        // console.log("========================")
        // console.log(e.target);
        // console.log("========================")
        const header = e.target.elements.Header.files[0];
        const footer = e.target.elements.Footer.files[0];
        const cart = e.target.elements.Cart.files[0];
        const screensavers = e.target.elements.Screensavers.files 
        const screensaversArr = new Map()
        const formData = new FormData()
        // console.log("screensavers", ...screensavers)
        ;
        //'Screensaver'
        // const reader =new  URL()
        formData.append('Header', header);
        formData.append('Footer', footer);
        formData.append('Cart', cart);
        formData.append('ScreensaverOrder', images.Screensavers ? 
            JSON.stringify(
                images.Screensavers
                    .filter(img => img.new)
                    .map((img) => (
                        {
                            Image: img.Image
                            , Index: img.Index
                            , Duration: img.Duration
                            , ImageName: img.ImageName
                            , Imagemimetype: img.Imagemimetype
                            , new: img.new
                            , isDefault: img.isDefault
                        }
                    ))
                ) : []);
        formData.append('ScreensaverDuration', images.Screensavers ? JSON.stringify(images.Screensavers.filter(img => !(img.new) && !(img.deleted)).map((img) => ({Index: img.Index, Duration: img.Duration}))) : []);
        formData.append('ServicemanInfo', JSON.stringify({
            Phone: servicemanInfo.Phone ? servicemanInfo.Phone : "",
            Email: servicemanInfo.Email ? servicemanInfo.Email : "",
            Name: servicemanInfo.Name ? servicemanInfo.Name : "",
        }));
        formData.append('ScreensaversToCopy', images.Screensavers 
            ? JSON.stringify(images.Screensavers
                .filter(img => img.isDefault && !img.deleted )
                .map(img => ({ImageName: img.ImageName, Duration: img.Duration, Index: img.Index}))
            ) 
            : []);
        // console.log(images.Screensavers.map(({Image, Index, Duration, ImageName, Imagemimetype}) => ({Image, Index, Duration, ImageName, Imagemimetype})));
        // formData.append('Screensavers', screensavers)
        // screensavers.map(img => {
        //     return console.log(img, '             ' ,typeof(img))
        // })
        // console.log(screensavers.map(img => console.log(img)))
        // images.Screensavers.filter(image => image.new).map((image, idx) =>{formData.append(`Screensaver`, image.Image)})
        [...screensaverImgs].map(img => formData.append("Screensaver", img));
        // [...initialImages.Screensavers].map(img =>  console.log());
        initialImages.Screensavers && [...initialImages.Screensavers].map(img => formData.append("Screensaver", img.Image))
        formData.append("ScreensaversToDelete", images.Screensavers ? JSON.stringify(Array.from(images.Screensavers.filter(img => img.deleted).map(img => img.ImageName))) : [])
        formData.append('DeletedItems', JSON.stringify(getDeletedItems()))
        formData.append("CoinCreditEnabled", coinCreditEnabled === true ? 1 : 0)
        formData.append("AutoAddToCart", autoAddToCart === true ? 1 : 0)
        formData.append("MenuOptinalText", menuOptinalText)
        formData.append("ScreensaverTimout", screensaverTimout)
        formData.append("MaxCartCapacity", maxCartCapacity)
        formData.append("MaxCartPrice", maxCartPrice && Number.parseFloat(maxCartPrice).toFixed(2) || '')
        if(!header && !footer && !cart && !screensavers)
            return;

        const postImagesResponse = response => {
            if(response.status === 201) {
                const getImagesResponse = images => { // Callback to set received data from axios HTTP request executed in useHttp hook
                    const _images = {
                        Header: images.data.Header && images.data.Header.Image ? `${API_URL}/${images.data.Header.Image}` : null,
                        Footer: images.data.Footer && images.data.Footer.Image ?`${API_URL}/${images.data.Footer.Image}` : null,
                        Cart: images.data.Cart && images.data.Cart.Image ? `${API_URL}/${images.data.Cart.Image}` : null,
                        Screensavers: images.data.Screensavers ? images.data.Screensavers.map(image => ({...image, Image:`${API_URL}/${image.Image}`})) : null
                    }
                    
                    setInitialImages(_images);
                    getServicemanInfo()                        
                    setScreensaverImgs([])
                    setInitialMenuOptinalText(menuOptinalText)
                    getMenuOptionalText()
                    getAutoAddToCart()
                    getCoinCreditEnabled()
                    setIsLoading(false)
                    getDurationBeforeTurnOn()
                    getMaxCartPrice()
                    getMaxCartCapacity()
                };
                // setImages({})
                
                formRef.current.reset(); // Reset form inputs
                sendImageRequest({ url: params.machineId ? `machine-images/${machineData.SerialNo}` : 'machine-images' }, getImagesResponse); // execute sendRequest renamed to getImages from useHttp hook

            }
        }
        sendImageRequest({
            method: 'POST',
            url: params.machineId ? `machine-images/${machineData.SerialNo}` : 'machine-images',
            data: formData
        }, postImagesResponse);
        setIsLoading(false)
    }
    const togglePopup = () => setShowModal(false)
    const recoverSettings = evt => {
        if(!params.machineId) return
        if(!showModal) {
            setShowModal(true)
            return
        }
        evt.preventDefault()
        fetchMssqlApi(`setting/recover/${machineData.SerialNo}`, {method: 'DELETE'}, res => {
            const getImagesResponse = images => { // Callback to set received data from axios HTTP request executed in useHttp hook
                const _images = {
                    Header: images.data.Header && images.data.Header.Image ? `${API_URL}/${images.data.Header.Image}` : null,
                    Footer: images.data.Footer && images.data.Footer.Image ?`${API_URL}/${images.data.Footer.Image}` : null,
                    Cart: images.data.Cart && images.data.Cart.Image ? `${API_URL}/${images.data.Cart.Image}` : null,
                    Screensavers: images.data.Screensavers ? images.data.Screensavers.map(image => ({...image, Image:`${API_URL}/${image.Image}`})) : null
                }
                setInitialImages(_images);
                getServicemanInfo()                        
                setScreensaverImgs([])
                setInitialMenuOptinalText("")
                setMenuOptinalText('')
                setCoinCreditEnabled(0)
                setAutoAddToCart(0)
                setInitialServicemanInfo({})
                setServicemanInfo({})
                setIsLoading(false)
                setShowModal(false)
                getIsDefault()
                getDurationBeforeTurnOn()
                getMaxCartPrice()
                getMaxCartCapacity()
            };
            // setImages({})
            formRef.current.reset(); // Reset form inputs
            sendImageRequest({ url: params.machineId ? `machine-images/${machineData.SerialNo}` : 'machine-images' }, getImagesResponse); // execute sendRequest renamed to getImages from useHttp hook
        })
    }
    const turnOnPersonalSettings = evt => {
        if(!params.machineId) return
        if(!showModal) {
            setShowModal(true)
            return
        }
        evt.preventDefault()
        fetchMssqlApi(`setting/activate-personal-setting/${machineData.SerialNo}`, {}, res => {
            const getImagesResponse = images => { // Callback to set received data from axios HTTP request executed in useHttp hook
                setIsLoading(true)
                const _images = {
                    Header: images.data.Header && images.data.Header.Image ? `${API_URL}/${images.data.Header.Image}` : null,
                    Footer: images.data.Footer && images.data.Footer.Image ?`${API_URL}/${images.data.Footer.Image}` : null,
                    Cart: images.data.Cart && images.data.Cart.Image ? `${API_URL}/${images.data.Cart.Image}` : null,
                    Screensavers: images.data.Screensavers ? images.data.Screensavers.map(image => ({...image, Image:`${API_URL}/${image.Image}`})) : null
                }

                setInitialImages(_images);
                setIsLoading(false)
            };
            
            getIsDefault()
            getServicemanInfo()
            getMenuOptionalText()
            getAutoAddToCart()
            getCoinCreditEnabled()
            getMenuOptionalText()
            getAutoAddToCart()
            getCoinCreditEnabled()
            setShowModal(false) 
            getDurationBeforeTurnOn()
            getMaxCartPrice()
            getMaxCartCapacity()
            sendImageRequest({ url: params.machineId ? `machine-images/${machineData.SerialNo}` : 'machine-images' }, getImagesResponse); // execute sendRequest renamed to getImages from useHttp hook
        })
        setShowModal(false) 
    }
    const handleChangeServiceman = (evt) => {
        const {name, value} = evt.target
        setServicemanInfo(prev => ({...prev, [name]: value}))
    }
    const handleChangeFocusServiceman = (evt) => {
        const {name} = evt.target
        setServicemanInfo(prev => ({...prev, [name]: ""}))
    }
    const handleFocusInputs = (evt) => {
        const {id} = evt.target
        setIsImageChanged(true)
        switch(id) {
            case "maxCartPrice":
                setMaxCartPrice("")
                break;
            case "maxCartCapacity":
                setMaxCartCapacity("")
                break;
            case "screensaverTimout":
                setScreensaverTimout("")
                break;
            
        }
        // setServicemanInfo(prev => ({...prev, [name]: ""}))
    }
    const changeHandler = e => {
        let image;
        const screensaverImgArr = []
        const name = e.target.name;
        if (name === "Screensavers") {
            const minNewIndex = images.Screensavers.length ? images.Screensavers.sort().at(-1).Index : 0 
            if(images.Screensavers.length + screensaverImgArr.length + e.target.files.length > 20) {
                NotificationManager.error("You can`t added more than 20 images")
                return
            }
            // console.log(Object.values(e.target.files))
            for (let i of Array.from(e.target.files)) {
                if( i) {
                    image = URL.createObjectURL(i)
                } else {
                    image = initialImages[name];
                }
                screensaverImgArr.push({Image: image, Imagemimetype: i.type ,ImageName: i.name, Index: (images.Screensavers.length ? minNewIndex + screensaverImgArr.length + 1 : screensaverImgArr.length + 1), new: true, Id: random(), Duration: "10"})
                setScreensaverImgs(prev => ([...prev, i]))

                image = null
            }
            setImages(prevState => ({
                ...prevState,
                'Screensavers': [...prevState.Screensavers, ...screensaverImgArr] 
            }))
        } else {
            if(e.target.files && e.target.files[0]) {
                image = URL.createObjectURL(e.target.files[0]);
                
            } else if(e.target.files && !e.target.files[0]) {
                image = initialImages[name];
            }
            setImages(prevState => ({
                ...prevState,
                [name]:  image  
            }))
        }

    }
    // useEffect(() => {
    //     screensaverImgs.map(img => console.log("scr: ", img), [screensaverImgs])
    // })
    const undoSubmitHandler = () => {
        setImages({ // Set current images to initial images
            ...initialImages
        });
        setServicemanInfo({
            ...initialServicemanInfo
        })
        setScreensaverImgs([])
        formRef.current.reset(); // Reset form inputs
    }
    const clickHandler = (evt, name) => {
        if (images[name]) {
            setImages(prev => ({...prev, [name]: undefined})) 
            evt.preventDefault()
            return
        }
        
    } 

    const toggleToast = () => setShowToast(!showToast)
    // console.log(images)
    return (
        // <div>
        //     <div onBlur={() => setShowToast(false)} className=' mb-0 ' style={{position: 'absolute', zIndex: 2}}>
        //         <Button  onClick={toggleToast}>
        //             Dodatkowe informacje
        //         </Button>
        //         <Toast delay={10000} autohide show={showToast} onClose={() => setShowToast(false)} className='position-relative'>
        //             <Toast.Header className='d-flex justify-content-between'>
        //                 {/* <strong>Domyślne ustawienia</strong> */}
        //                 <strong>Osobne ustawienia automatu</strong>
        //             </Toast.Header>
        //             <Toast.Body>
        //                 {/* Domyślne ustawienia znajdziesz na zakładce <Link to='/personalise/machine'>Personalizacja</Link> */}
        //                 Na tej zakładce możesz ustawić osobne ustawienia dla automatu.
        //                 <br/>
        //                 Żeby przewróczyć ustawienia domyślne, zaznaczone w zakładce <span><Link to='/personalise/machine'>"Personalizacja" </Link></span> 
        //                 wczisni przycisk "Przewrócz domyślne ustawienia"
        //             </Toast.Body>
        //         </Toast>
        //     </div>
        // </div>
        isDefaultSettings !== null && isDefaultSettings === true && (
                <div  className='d-flex justify-content-center flex-wrap w-100 h-100 align-items-center'>
                    <div style={{maxHeight: "38px"}}>
                        <Button variant='success' onClick={() => turnOnPersonalSettings()}>
                            Włącz ustawienia dedykowane dla tego Automatu
                        </Button>
                    </div>
                    {showModal && (
                        <FormSkel
                            handleClose={() => togglePopup()}
                        >
                            <form onSubmit={turnOnPersonalSettings} id="modal-form">
                                <div>
                                    Włącz ustawienia dedykowane dla tego Automatu. <br/> Ustawienia dla wszystkich Automatów można modyfikować w zakładce <span><Link to='/personalise/machine'>"Personalizacja" </Link></span> 
                                </div>
                            </form>
                        </FormSkel>
                    )}
                </div>
            )
        ||
        // <div>
        //     <div onBlur={() => setShowToast(false)} className=' mb-0 ' style={{position: 'absolute', zIndex: 2}}>
        //         <Button  onClick={toggleToast}>
        //             Dodatkowe informacje
        //         </Button>
        //         <Toast delay={10000} autohide show={showToast} onClose={() => setShowToast(false)} className='position-relative'>
        //             <Toast.Header className='d-flex justify-content-between'>
        //                 {/* <strong>Domyślne ustawienia</strong> */}
        //                 <strong>Osobne ustawienia automatu</strong>
        //             </Toast.Header>
        //             <Toast.Body>
        //                 {/* Domyślne ustawienia znajdziesz na zakładce <Link to='/personalise/machine'>Personalizacja</Link> */}
        //                 Na tej zakładce możesz ustawić osobne ustawienia dla automatu.
        //                 <br/>
        //                 Żeby przewróczyć ustawienia domyślne, zaznaczone w zakładce <span><Link to='/personalise/machine'>"Personalizacja" </Link></span> 
        //                 wczisni przycisk "Przewrócz domyślne ustawienia"
        //             </Toast.Body>
        //         </Toast>
        //     </div>
        // </div>
        isDefaultSettings !== null && isDefaultSettings === false && (
            <div className='container-xl'>
                <div  className='d-flex justify-content-between flex-wrap'>
                    <div>
                        <div onBlur={() => setShowToast(false)} className=' mb-0 ' style={{position: 'absolute', zIndex: 2}}>
                            <Button  onClick={toggleToast}>
                            <i className="fas fa-info"></i>
                            </Button>
                            <Toast delay={10000} autohide show={showToast} onClose={() => setShowToast(false)} className='position-relative'>
                                <Toast.Body>
                                    Ustawienia R24 VMC dotyczą tylko automatów wyposażonych w obsługującą je płytę główną
                                </Toast.Body>
                            </Toast>
                        </div>
                    </div>
                {params.machineId ? (
                    <div style={{maxHeight: "38px"}}>
                        <Button variant='danger' onClick={() => recoverSettings()}>
                            Przewrócz...
                        </Button>

                    </div>
                ) : (
                    <div style={{height: "38px"}}></div>
                )}
                </div>
            <div className={`card ${params.machineId ? `mt-1` : `mt-2`}`}>
                <h5 className='card-header'>
                    {/* {TRL_Pack.machinesImages.header} */}
                    Personalizacja
                </h5>
                <div className='card-body overflow-auto'>
                    <form ref={formRef} encType="multipart/form-data" onSubmit={submitHandler} id='machine-images-form'>
                        <div className='row'>
                            <div className='col-md-4'>
                                <label htmlFor={!images.Header ? 'header' : undefined} className={`form-label ${classes.label}`} onClick={evt => clickHandler(evt, "Header")}>
                                    <h5>{TRL_Pack.machinesImages.properties.header}</h5>
                                    <div className={`mt-2 ${classes.labelContainer}`}>
                                        {!isDownloading ? images.Header ? <img src={images.Header} alt='header' className={classes.labelImg}/> : '+' : <div className={classes.Skeleton}></div>}
                                    </div>
                                </label>
                                <input
                                    type='file'
                                    className={`form-control ${classes.fileInput}`}
                                    id='header'
                                    name='Header'
                                    accept='image/png, image/jpg, image/jpeg'
                                    onChange={changeHandler}
                                />
                            </div>
                            <div className='col-md-4'>
                                <label htmlFor={!images.Footer ? 'footer' : undefined} className={`form-label ${classes.label}`} onClick={evt => clickHandler(evt, "Footer")}>
                                    <h5>{TRL_Pack.machinesImages.properties.footer}</h5>
                                    <div className={`mt-2 ${classes.labelContainer}`}>
                                        {!isDownloading ? images.Footer ? <img src={images.Footer} alt='footer' className={classes.labelImg}/> : '+' : <div className={classes.Skeleton}></div>}
                                    </div>
                                </label>
                                <input
                                    type='file'
                                    className={`form-control ${classes.fileInput}`}
                                    id='footer'
                                    name='Footer'
                                    accept='image/png, image/jpg, image/jpeg'
                                    onChange={changeHandler}
                                    
                                />
                            </div>
                            <div className='col-md-4'>
                                <label htmlFor={!images.Cart ? 'cart' : undefined} className={`form-label ${classes.label}`} onClick={evt => clickHandler(evt, "Cart")}>
                                    <h5>{TRL_Pack.machinesImages.properties.cart}</h5>
                                    <div className={`mt-2 ${classes.labelContainer}`}>
                                        {!isDownloading ? images.Cart ? <img src={images.Cart} alt='cart' className={classes.labelImg}/> : '+' : <div className={classes.Skeleton}></div>}
                                    </div>
                                </label>
                                <input
                                    type='file' 
                                    className={`form-control ${classes.fileInput}`}
                                    id='cart'
                                    name='Cart'
                                    accept='image/png, image/jpg, image/jpeg'
                                    onChange={changeHandler} 
                                    />
                            </div>
                        </div>
                        <hr/>

                        <div className='row mt-2 mb-3'>
                        {/* <div className='col-md-4'> */}
                                <label className={`form-label ${classes.label}`}>
                                    <h5>Wygaszacze</h5>
                                </label>
                                    <div className='overflow-auto'>
                                        <div className='d-flex '  >
                                            {images && images.Screensavers && images.Screensavers.filter(img => !(img.deleted)).map((img, idx) => (
                                                <div key={idx} className=' mr-3 ml-3'>
                                                    {/* <input
                                                    style={{display: "none"}}
                                                    type='file' 
                                                    className={`custom-file-input form-control-sm mx-auto mx-lg-0 d-none`}
                                                    name='Screensavers'
                                                    accept='image/png, image/jpg, image/jpeg'
                                                    files={img.Image}
                                                    disabled/> */}
                                                    <label className={`mt-2 ${classes.scerensaverContainer}`}>

                                                        {!isDownloading ? images.Screensavers.length ? <img src={img.Image || img } alt={`Screensaver ${img.Index}`} className={classes.labelImg} onClick={() => deleteImg(img.Id)}/> : 'error' : <div className={classes.Skeleton}></div>}
                                                    </label>
                                                    <InputGroup className='w-80 mt-2 mb-2'>
                                                        <Form.Control
                                                            placeholder='Wybierz czas wyśwetlenia'
                                                            value={img.Duration || ""}
                                                            onChange={evt => {const value = evt.target.value; setImages(prev => ({...prev, Screensavers: prev.Screensavers.map(i => String(i.Id) === String(img.Id) ? ({...i, Duration: value.replace(/[^0-9]/g, '')}) : i)}))}}
                                                            onFocus={()=> {setImages(prev => ({...prev, Screensavers: prev.Screensavers.map(i => String(i.Id) === String(img.Id) ? ({...i, Duration: ""}) : i)}))}}
                                                        />
                                                        <InputGroup.Text>Sec</InputGroup.Text>
                                                    </InputGroup>
                                                </div>
                                            ))}
                                            <div className={`mt-2 ${classes.scerensaverContainer} mr-3 ml-3 mb-3`} onClick={focusOnElement}>
                                                +
                                            </div>
                                        </div>
                                    </div>
                                <input
                                    style={{display: "none"}}
                                    multiple='multiple'
                                    type='file' 
                                    className={`custom-file-input form-control-sm mx-auto mx-lg-0 d-none`}
                                    id='screensaver'
                                    name='Screensavers'
                                    accept='image/png, image/jpg, image/jpeg'
                                    onChange={changeHandler} />
                            {/* </div> */}
                        </div>
                        <div className='row d-flex justify-content-around mb-5'>
                        <label className={`form-label ${classes.label}`}>
                            <h5>Informacja o serwisie</h5>
                        </label>
                            <div >
                                <div>
                                    Imię
                                </div>
                                <div>

                                    <input
                                        className={`form-control form-control mx-auto mx-lg-0`}
                                        name="Name"
                                        value={servicemanInfo.Name || ""}
                                        onChange={handleChangeServiceman}
                                        minLength={1}
                                        onFocus={handleChangeFocusServiceman}
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    Nr telefonu (wyświetlany na ekranie)
                                </div>
                                <div>

                                    <input
                                        className={`form-control form-control mx-auto mx-lg-0`}
                                        name="Phone"
                                        value={servicemanInfo.Phone || ""}
                                        onChange={handleChangeServiceman}
                                        minLength={1}
                                        onFocus={handleChangeFocusServiceman}
                                        // type='phone'
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    Email (powiadomienia z automatów)
                                </div>
                                <div>

                                    <input
                                        className={`form-control form-control mx-auto mx-lg-0`}
                                        name="Email"
                                        value={servicemanInfo.Email || ""}
                                        onChange={handleChangeServiceman}
                                        minLength={1}
                                        onFocus={handleChangeFocusServiceman}
                                        // type='email'
                                    />
                                </div>
                            </div>
                        </div>
                        <hr/>
                        <div className='row d-flex justify-content-around mb-5'>
                        <label className={`form-label ${classes.label}`}>
                            <h4 className='mb-4'>Pozostałe ustawienia</h4>
                            <div className='pl-3 pr-3 mt-2 mb-3'>
                                <div className='pl-2 w-100 d-flex justify-content-start'>
                                    <label htmlFor='menuOptinalText'>
                                        <h6>Opcjonalny  tekst menu</h6>
                                    </label> 
                                </div>
                                <textarea
                                    value={menuOptinalText}
                                    onChange={evt => setMenuOptinalText(evt.target.value)}
                                    className='form-control '
                                    name='menuOptinalText'
                                    id='menuOptinalText'
                                />
                            </div>
                            <div className='d-flex justify-content-around'>
                                <div>
                                    <div>
                                        <label htmlFor='autoAddToCart'>
                                            <h6>Automatyczne dodawanie do koszyka</h6>
                                        </label> 
                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <div style={{height: "30px", width: "30px"}}>
                                            <input
                                                id='autoAddToCart'
                                                type='checkbox'
                                                checked={autoAddToCart}
                                                onChange={() => setAutoAddToCart(prev => !prev)}
                                                className='w-100 h-100'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label htmlFor='coinCreditEnabled'>
                                            <h6>Kredyt gotówkowy</h6>
                                        </label> 
                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <div style={{height: "30px", width: "30px"}}>
                                            <input
                                                id='coinCreditEnabled'
                                                type='checkbox'
                                                checked={coinCreditEnabled}
                                                onChange={() => setCoinCreditEnabled(prev => !prev)}
                                                className='w-100 h-100'
                                            />
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                            <div className='d-flex justify-content-around mt-3'>
                                <div>
                                    <div>
                                        <label htmlFor='screensaverTimout'>
                                            <h6>Okres bezczynności przed włączeniem wygaszacza ekranu (w sekundach)</h6>
                                        </label> 
                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <div>
                                            <input
                                                id='screensaverTimout'
                                                type='text'
                                                value={screensaverTimout || ""}
                                                onChange={evt => setScreensaverTimout(evt.target.value.replace(/[^0-9]/g, ""))}
                                                onFocus={handleFocusInputs}
                                                className='form-control'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label htmlFor='maxCartCapacity'>
                                            <h6>Maksymalna ilość produktów w koszyku</h6>
                                        </label> 
                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <div>
                                            <input
                                                id='maxCartCapacity'
                                                type='text'
                                                value={maxCartCapacity || ""}
                                                onChange={evt => setMaxCartCapacity(evt.target.value.replace(/[^0-9]/g, ""))}
                                                onFocus={handleFocusInputs}
                                                className='form-control'
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div>
                                        <label htmlFor='maxCartPrice'>
                                            <h6>Maksymalna wartość koszyka</h6>
                                        </label> 
                                    </div>
                                    <div className='w-100 d-flex justify-content-center'>
                                        <div>
                                            <input
                                                id='maxCartPrice'
                                                type='text'
                                                value={maxCartPrice || ""}
                                                onChange={evt => setMaxCartPrice(evt.target.value.replace(/[^0-9.]/g, ""))}
                                                onFocus={handleFocusInputs}
                                                className='form-control'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </label>
                        </div>
                    </form>
                </div>
                <div className='position-fixed container' style={{bottom: "0px"}}>
                    <div className="card-footer text-center fixed-bottom" style={{backgroundColor:"#cbcbcb"}}>
                        <button className="btn btn-secondary btn-sm mr-3" onClick={undoSubmitHandler} disabled={!isImageChanged}>{TRL_Pack.buttons.cancel}</button>
                        <button type='submit' className='btn btn-success btn-sm' form='machine-images-form' disabled={!isImageChanged}>
                            {TRL_Pack.buttons.save}
                        </button>
                    </div>
                </div>
            </div>
            {showModal && (
                <FormSkel
                    handleClose={() => togglePopup()}
                >
                    <form onSubmit={recoverSettings} id="modal-form">
                        <div>
                            Czy chcesz przewrócić domyślne ustawienia?
                            <br/>
                            Wszystkie dedykowane ustawienia automatu zostaną nadpisane
                        </div>
                    </form>
                </FormSkel>
            )}
            </div>
            
        ) 
    );
};

export default MachinesImages;