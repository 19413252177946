import React, { useState, useEffect, useContext } from "react";
import { NavigationContext } from "../../context/navigation-context";
import { LangContext } from "../../context/lang-context";

import MachineConfiguration from "./Advanced/MachineConfiguration";
import Other from "./Advanced/Other";
import Hardware from "./Advanced/Hardware";
import Control from "./Advanced/Control";
import Tasks from "./Advanced/Tasks";

const AdvancedConfig = () => {
  const { setHeaderData } = useContext(NavigationContext);
  const { TRL_Pack } = useContext(LangContext)

  const [currentMachineSection, setCurrentMachineSection] = useState(0);
  const changeSection = (id) => () => setCurrentMachineSection(id);

  return (
    <div className="container-xl">
      <h5>Ustawienia zaawansowane</h5>
      <ul className="nav nav-tabs machine-tabs mb-3">
        <li className="nav-item">
          <button
            className={`nav-link btn ${currentMachineSection === 0 && "active"
              }`}
            onClick={changeSection(0)}
            tabIndex="0"
          >
            {TRL_Pack.advancedConfig.machineConfiguration}
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link btn ${currentMachineSection === 1 && "active"
              }`}
            onClick={changeSection(1)}
            tabIndex="0"
          >
            {TRL_Pack.advancedConfig.control}
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link btn ${currentMachineSection === 2 ? "active" : ""
              }`}
            onClick={changeSection(2)}
            tabIndex="0"
          >
            {TRL_Pack.advancedConfig.hardware}
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link btn ${currentMachineSection === 3 ? "active" : ""
              }`}
            onClick={changeSection(3)}
            tabIndex="0"
          >
            {TRL_Pack.advancedConfig.tasks}
          </button>
        </li>
        <li className="nav-item">
          <button
            className={`nav-link btn ${currentMachineSection === 4 && "active"
              }`}
            onClick={changeSection(4)}
            tabIndex="0"
          >
            {TRL_Pack.advancedConfig.other}
          </button>
        </li>
      </ul>
      {currentMachineSection === 0 && <MachineConfiguration />}
      {currentMachineSection === 1 && <Control />}
      {currentMachineSection === 2 && <Hardware />}
      {currentMachineSection === 3 && <Tasks />}
      {currentMachineSection === 4 && <Other />}
    </div>
  );
};

export default AdvancedConfig;
