import React, { useContext, useEffect, useState } from 'react'
import fetchHook from '../../hooks/fetchMSSQL-hook'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { NavigationContext } from '../../context/navigation-context'
import { Link, Prompt } from 'react-router-dom/cjs/react-router-dom.min'
import link from '../../assets/images/back_to_list_icon.svg'
export default function INM() {
    const { fetchMssqlApi } = fetchHook(),
    {id} = useParams(),
    // history = useHistory(),
    { setHeaderData } = useContext(NavigationContext),
    [initiaINM, setInitiaINM] = useState(),
    [INM, setINM] = useState(),
    [initialItems, setInitialItems] = useState([]),
    [changed, setChanged] = useState(false),
    [items, seItems] = useState([])
    const getINM = () => {
        fetchMssqlApi(`inm-items/${id}`, {}, res => {
            setInitialItems(res.Items)
            delete res.Items
            setInitiaINM(res)
        })
    }
    useEffect(() => {
        setHeaderData({text: "Inwentaryzacja automatu"})
        getINM()
    }, [])
    useEffect(() => {
        setINM(initiaINM)
    }, [initiaINM])
    useEffect(() => {
        seItems(initialItems)
    }, [initialItems])
    return (
        <div>

            <div className='container'>
            <Prompt when={changed} message="Istnieją niezapisane zmiany, czy na pewno chcesz kontynuować?"/>
            {/* <button
                onClick={() => {
                    window.history.back()
                }}
                className="btn btn-link mt-0 mb-1 ml-1 text-decoration-none mb-3"
            >
                <i className="fas fa-arrow-left mr-2" />
                Wróć
            </button> */}
            <Link to='/inms'>
                    <label to='back' className="btn btn-link ml-1 text-decoration-none mb-3">
                        <img id='back' src={link}></img>
                    </label>
            </Link>
            {INM && (
                <>
                    <div className='card mb-4'>
                        <div className='card-header'>
                            <div className='h5 mt-2'>{INM.MachineInventoryFullName}</div>
                        </div>
                        <div className='card-body'>
                            <table className='table table-striped'>
                                <thead>
                                    <tr className='text-center'>
                                        <td>Stworzona</td>
                                        <td>Automat</td>
                                        {INM.WarehouseInventoryId && <td>Zamknięty</td>}
                                        <td>Dodatkowa informacja</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className='text-center'>
                                        <td>{INM.Created}</td>
                                        <td>
                                            <Link 
                                                to={`/machine/${INM.MachineId}`}
                                                className="btn btn-outline-secondary"
                                            >
                                                {INM.MachineFullName}
                                            </Link>
                                        </td>
                                        {INM.WarehouseInventoryId && <td>
                                                <Link
                                                    className="btn btn-outline-primary"
                                                    to={`/in/${INM.WarehouseInventoryId}`}
                                                >
                                                    {INM.WarehouseInventoryFullName}
                                                </Link>
                                            </td>}
                                        <td>{INM.Comment}</td>
                                    </tr>
                                    {/* <tr >
                                        <td>
                                            <div className='text-center mt-3'>
                                                <Link
                                                to={`/na/${INM.NAId}`}
                                                    className="btn btn-outline-success"
                                                    // disabled
                                                >
                                                    {INM.NA}
                                                </Link>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td>
                                            <div className='text-center mt-3'>
                                                <Link
                                                to={`/br/${INM.RWId}`}
                                                    className="btn btn-outline-danger"
                                                    // disabled
                                                >
                                                    {INM.RW}
                                                </Link>
                                            </div>
                                        </td>
                                    </tr> */}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className='card'>
                        {/* <div className='card-header'>
                            <div className='h6 mt-2'>Produkty</div>
                        </div> */}
                        <div className='card-body overflow-auto' style={{maxHeight: "41vh"}}>
                            <table className='table table-striped'>
                                <thead>
                                    <tr>
                                        <td>Produkt</td>
                                        <td className='text-center'>Ilość oczekiwana</td>
                                        <td className='text-center'>Nr wyboru</td>
                                    </tr>
                                </thead>
                                <tbody>
                                        {items.length ? items.map((item, idx) => (
                                            <tr key={idx}>
                                                <td >
                                                    {item.ProductName}
                                                </td>
                                                <td className='text-center'>
                                                    {item.Quantity}
                                                </td>
                                                <td className='text-center'>
                                                    {item.MachineFeederNo}
                                                </td>
                                            </tr>
                                        )) : <></>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </>
            )}
                </div>
        </div>
    )
}
