import React, { useContext } from 'react';
import ReactTimeAgo from 'react-time-ago';
import ReactTooltip from 'react-tooltip';
import PropTypes from 'prop-types';

import { LangContext } from '../../context/lang-context';
import { useHistory } from 'react-router-dom';

import getColorByStatus from '../../util/getColorByStatus';

const MachinesTable = ({ machines, searchedText }) => {
  const { TRL_Pack } = useContext(LangContext);
  const history = useHistory();

  const machineDetailsHandler = (id) => {
    let url = `/monitoring/${id}`;

    if (searchedText)
      url = `/monitoring/${id}?search=${searchedText}`;

    history.push(url);
  }

  return (
    <>
      <div className="overflow-auto">
        <table className="table table-striped border text-center table-monitoring">
          <thead>
            <tr>
              <th>Ost. sygnał</th>
              <th>{TRL_Pack.monitoring.machine}</th>

              <th className="d-none d-md-table-cell">
                {TRL_Pack.monitoring.technicalData}
              </th>
              <th>{TRL_Pack.monitoring.transactions}</th>
              <th style={{ width: '1%' }} colSpan="2" />
            </tr>
          </thead>
          <tbody>
            {machines.length &&
              machines.map((machine, idx) => (
                <tr key={idx} onClick={() => machineDetailsHandler(machine.machineId)} style={{ cursor: 'pointer' }}>
                  <td>
                    <ReactTimeAgo date={new Date(machine.lastHeartbeat)} locale="pl" />
                  </td>
                  <td>
                    <span className="d-block">
                      {machine.name}
                      <span className="ml-1 font-italic">({machine.type})</span>
                    </span>
                    <span className="d-block small mt-1 font-weight-bolder">
                      {machine.customer_name}
                    </span>
                  </td>
                  <td className="d-none d-md-table-cell">
                    <span className="small">{machine.serialNo}</span>
                    <br />
                    <span className="small">{machine.terminal}</span>
                  </td>
                  <td>{machine.trx_count}</td>
                  <td className="align-middle">
                    <div className="d-flex">
                      <i
                        style={{
                          textShadow: '#007bff 0px 0px 3px',
                          color: getColorByStatus(machine.trx_status)
                        }}
                        className="fas fa-money-bill-wave"
                        data-type="info"
                        data-tip={
                          'Ost transakcja:' +
                          '<br />' +
                          machine.last_transaction.create_date_time +
                          '<br /><br />' +
                          machine.last_transaction.status
                        }
                      />
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div >
      <ReactTooltip multiline border />
    </>
  )
};

MachinesTable.propTypes = {
  machines: PropTypes.array,
  searchedText: PropTypes.string
};

export default MachinesTable;