import React, { useState } from "react";
import { useEffect } from "react";
import useFetch from '../../../hooks/fetchMSSQL-hook'
import useForm from "../../../hooks/form-hook";
import FormSkel from "../../../components/Modals/FormSkel";
import { Fragment } from "react";




export default ({filter,setFilter , resetPage, search, toggleFilter, customers, machines, resetFilter, terminalsList, columns, handleChange}) => {

    
    const {fetchMssqlApi} = useFetch()
    const [tags, setTags] = useState([])
    const setInformation = evt => {
        const {value, name} = evt.target
        resetPage()
        if(name === "Automat") {
            setFilter(prev => ({
                ...prev,
                columns: prev.columns.map(col => {if(col.name === "Automat") col.value = value
            return col})
            }))
        }
        if(name === "Terminal") {
            setFilter(prev => ({
                ...prev,
                columns: prev.columns.map(col => {if(col.name === "Terminal") col.value = value
            return col})
            }))
        }
        if(name === "Klient") {
            setFilter(prev => ({
                ...prev,
                columns: prev.columns.map(col => {if(col.name === "Klient") col.value = value
            return col})
            }))
        }
        
    } 
    useEffect(() => {
        terminalsList.map(terminal => {
            terminal.Name.split(" ")[0] === "Vendotek" && console.log(terminal.Name)
        })
    }, [])

    const handleFocus = evt => {
        const { name} = evt.target
        evt.target.value = ''
        setFilter(prev => ({
            ...prev,
            columns: prev.columns.map(col => {
                if(col.name === name) {
                    col.value = null
                    col.save = null
                }
                return col
            })
        }))
    }

    const handleChangeSelectbar = evt => {
        const { name, value } = evt.target
        const v = value === "null" ? null : value
        resetPage()
        setFilter(prev => ({
            ...prev,
            columns: prev.columns.map(col => {
                if (col.name === name) col.save = v
                return col
            })
            }))
    }
    const getSort = (params) => {
        return params.map((item, idx) => {
            // console.log(item.name ,(item.selected && item.selected === "desc") === true)
            if (!item.hideArray) {
                if (!item.preferDirection) return (
                        <Fragment key={idx}>
                            <option value={`${item.id} | asc | ${item.type}`} selected={item.selected && item.selected === "asc"}>
                                {item.name} &nbsp; &#8681;
                            </option>
                            <option value={`${item.id} | desc | ${item.type}`} selected={item.selected && item.selected === "desc"}>
                                {item.name} &nbsp; &#8679;
                            </option>
                        </Fragment>
                    )
                if (item.preferDirection == "asc") return (
                        <Fragment key={idx}>
                            <option value={`${item.id} | asc | ${item.type}`} selected={item.selected && item.selected === "asc"}>
                                {item.name} &nbsp; &#8681;
                            </option>
                        </Fragment>
                    )
                if (item.preferDirection == "desc") return (
                        <Fragment key={idx}>
                            <option value={`${item.id} | desc | ${item.type}`} selected={item.selected && item.selected === "desc"}>
                                {item.name} &nbsp; &#8679;
                            </option>
                        </Fragment>
                    )
            } else {
                    
                    if (!item.preferDirection) return (
                            <Fragment key={idx}>
                                <option value={`${item.id} | asc | ${item.type}`} selected={item.selected && item.selected === "asc"}>
                                    {item.name} 
                                </option>
                                <option value={`${item.id} | desc | ${item.type}`} selected={item.selected && item.selected === "desc"}>
                                    {item.name} 
                                </option>
                            </Fragment>
                        )
                    if (item.preferDirection == "asc") return (
                            <Fragment key={idx}>
                                <option value={`${item.id} | asc | ${item.type}`} selected={item.selected && item.selected === "asc"}>
                                    {item.name} 
                                </option>
                            </Fragment>
                        )
                    if (item.preferDirection == "desc") return (
                            <Fragment key={idx}>
                                <option value={`${item.id} | desc | ${item.type}`} selected={item.selected && item.selected === "desc"}>
                                    {item.name}
                                </option>
                            </Fragment>
                        )
            }
            return (<></>)
        })
    }
    return (
        <div className="mb-4 rounded border bg-fade p-3 user-select-none " >
            <div className="row px-3 d-flex justify-content-around ">
        
        {filter.columns
        .filter(col => !col?.disabled )
        .map((col, idx) => (
            <div
            className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
            key={idx}
            >                
            {col.type === 'lookup' && (
                <>
                <h6 className="text-center mb-0">{col.name}</h6>
                    <input 
                        type="text" 
                        list={col.name}
                        name={col.name}
                        placeholder="Szukaj..."
                        autoComplete="off"
                        onChange={setInformation}
                        onFocus={handleFocus}
                        value={col.value || ""}
                        className="mt-2 form-control form-control-sm"
                    />
                    <datalist id={col.name}>
                    {col.name === "Automat" && 
                        (machines.map((machine, idx) => (
                            <option value={machine.Name} key={idx}/>
                        )))
                    }
                    {col.name === "Terminal" && 
                        (terminalsList && terminalsList.map((terminal, idx) => 
                        // terminal.Name.split(" ")[0] !== "Vendotek" && 
                        (
                            <option value={terminal.Name} key={idx}/>
                        )))
                    }
                    {col.name === "Klient" &&
                        (customers && customers.sort((a, b) => {
                            if (String(a.Abbreviation).toLowerCase() < String(b.Abbreviation).toLowerCase()) {
                                return -1;
                            }
                            if (String(a.Abbreviation).toLowerCase() > String(b.Abbreviation).toLowerCase()) {
                                return 1;
                            }
                            return 0;
                            })
                        .map((customer, idx) => (
                            <option value={`${customer.Name} (${customer.Abbreviation})`} key={idx}/>
                        )))
                    }
                    </datalist>
                
                </>
            )}
            {col.type === "threeStateBool" && (
                <>
                    <h6 className="text-center mb-0">{col.name}</h6>
                    <select
                        className="mt-2 form-control form-control-sm"
                        defaultValue={col.selectbar}
                        name={col.name}
                        onChange={handleChangeSelectbar}
                    >
                        < option value={"null"} >Wybierz</ option>
                        < option value={"2"} >Pax</ option>
                        < option value={"3"} >Nayax</ option>
                    </select>
                </>
            )}
            </div>

        ))}
        {filter && filter.sortParams && filter.sortParams.length  && (
            <div className="col-12 col-md-6 col-lg-4 mb-1 mt-1 d-flex align-items-center justify-content-center ">
                <h6 className="mb-0">Sortowanie</h6>
                <select
                // value={}
                className="form-control form-control-sm ml-2"
                style={{ maxWidth: 175 }}
                name="sortBy"
                defaultValue="none"
                // value={filter.sortBy}
                onChange={handleChange}
                >
                    {getSort(filter.sortParams)}
                </select>
            </div>
        )}
                        {/* {tags && (
                    <div className='d-flex flex-column justify-content-center' >
                    <h6 className="text-center mb-0">Tagi</h6>
                    <div className="">                                     
                        <div className="mb-n1 mt-1 d-flex flex-column justify-content-center flex-row">
                        {tags.map(tag =>
                            tag.options
                            .filter(opt => filter.activeTags.includes(opt.tagId))
                            .map(opt => (
                                <button className="btn btn-info badge badge-pill px-2 py-1 mx-2 mb-1">
                                {tag.label ? `${tag.label} - ${opt.name}` : opt.name}
                                </button>
                            ))
                        )}
                        <button
                            className="btn btn-info badge badge-pill px-2 py-1 mx-2 mb-1"
                            onClick={openForm()}
                        >
                            <i className="fas fa-bars" />
                        </button>
                        </div>
                        {form && (
                        <FormSkel
                            headerText="Tagi"
                            noFooter
                            handleClose={closeForm}
                            classes="d-flex p-0"
                        >
                            <div className="w-50 overflow-auto" style={{ maxHeight: 250 }}>
                            {tags.map((tag, idx) => (
                                <div
                                key={idx}
                                className={`font-weight-bolder list-group-item cursor-pointer ${idx === activeLabel ? 'active' : ''
                                    }`}
                                onClick={handleChangeLabel(idx)}
                                >
                                {tag.others ? 'Inne' : tag.label}
                                </div>
                            ))}
                            </div>
                            {activeLabel !== null && (
                            <div className="w-50 overflow-auto" style={{ maxHeight: 250 }}>
                                {tags[activeLabel].options.map((opt, idx) => (
                                <div
                                    key={idx}
                                    className={`font-weight-bolder list-group-item cursor-pointer ${filter.activeTags.includes(opt.tagId)
                                    ? 'list-group-item-success'
                                    : ''
                                    }`}
                                    onClick={toggleTag(opt.tagId)}
                                >
                                    {opt.name}
                                </div>
                                ))}
                            </div>
                            )}
                        </FormSkel>
                        )}
                        </div>
                    </div>
                    )} */}
            </div>

        <div className="row align-items-center d-flex justify-content-center">
        <button onClick={search} className="d-inline m-2 btn btn-primary float-right">Szukaj</button>
        <button
            className="d-inline m-2 btn btn-primary float-right"
            onClick={resetFilter}
        >
            Przywróć domyślne
        </button>
        <button
            className="d-inline m-2 btn btn-primary float-right"
            onClick={toggleFilter}
        >Zamknij</button>
        </div>
        
        </div>
        
    )
}

