import React, {Fragment} from 'react'

export default function MachinesFilter({filter, setFilter, terminals, machines, customers, toggleFilter, resetFilter, search}) {
    const handleChange = evt => {
        const { name, value, type, checked } = evt.target
    
        // resetPage()
    
        if (type === 'checkbox') setFilter(prev => ({ ...prev, [name]: checked }))
    
        else setFilter(prev => ({ ...prev, [name]: value }))
        // if (resetPage) resetPage()
    }
    const setInformation = evt => {
        const {name, value} = evt.target
        
        // filter.columns.map(col => {
        if(name === "Automat") setFilter(prev => ({
            ...prev,
            columns: prev.columns.map(col => {
                if (col.name === "Automat") col.value = value
                return col
            })
        }))
        if(name === "Terminal")  setFilter(prev => ({
            ...prev,
            columns: prev.columns.map(col => {
                if (col.name === "Terminal") col.value = value
                return col
            })
        }))
        if(name === "Klient")  setFilter(prev => ({
            ...prev,
            columns: prev.columns.map(col => {
                if (col.name === "Klient") col.value = value
                return col
            })
        }))
        // })
    }
    const handleFocus = evt => {
        const { name} = evt.target
        evt.target.value = ''
        setFilter(prev => ({
            ...prev,
            columns: prev.columns.map(col => {
                if(col.name === name) {
                    col.value = null
                    col.save = null
                }
                return col
            })
        }))
    }
    return terminals && machines && customers && (
        <div className="mb-4 rounded border bg-fade p-3 user-select-none " >
            <div className="row px-3 d-flex justify-content-around ">
            {filter.columns
                .filter(col => !col?.disabled )
                .map((col, idx) => (
                    <div
                        className="col-12 col-sm-6 col-md-4 col-lg-3 border px-2 py-3 position-relative d-flex flex-column justify-content-center"
                        key={idx}
                    >                
                    <h6 className="text-center mb-0">{col.name}</h6>
                        <input 
                            type="text" 
                            list={col.name}
                            name={col.name}
                            placeholder="Szukaj..."
                            autoComplete="off"
                            onChange={setInformation}
                            onFocus={handleFocus}
                            // value={col.value || ""}
                            className="mt-2 form-control form-control-sm"
                        />
                        <datalist id={col.name}>
                            {(col.name === "Automat") && machines.map((machine, idx) => (
                                <option value={machine.Name || `(${machine.SerialNo})`} key={idx}/>
                            ))}
                            {(col.name === "Terminal") && terminals.map((terminal, idx) => (
                                <option value={terminal.Name.toUpperCase()} key={idx}/>
                            ))}
                            {(col.name === "Klient") && customers
                            .sort((a, b) => {
                                if (String(a.Abbreviation).toLowerCase() < String(b.Abbreviation).toLowerCase()) {
                                    return -1;
                                }
                                if (String(a.Abbreviation).toLowerCase() > String(b.Abbreviation).toLowerCase()) {
                                    return 1;
                                }
                                return 0;
                                })
                            .map((customer, idx) => (
                                <option value={`${customer?.Name} (${customer?.Abbreviation.toLowerCase()})`} key={idx}/>
                            ))}
                        </datalist>
                    </div>
                ))}
            </div>


            {filter.sortByColumns && (
                <div className="col-12 col-md-6 col-lg-4 mb-1 mt-1 d-flex align-items-center justify-content-center ">
                    <h6 className="mb-0">Sortuj po</h6>
                    <select
                    className="form-control form-control-sm ml-2"
                    style={{ maxWidth: 175 }}
                    name="sortBy"
                    defaultValue="none"
                    // value={filter.sortBy}
                    onChange={handleChange}
                    >
                    <option defaultValue value={""}>Nie</option>
                    {filter.columns
                        .filter(col => col.sortable)
                        .map((col, idx) => (
                        <Fragment key={idx}>
                            <option value={`${col.id} | asc | ${col.type}`}>
                            {col.name} &nbsp; &#8681;
                            </option>
                            <option value={`${col.id} | desc | ${col.type}`}>
                            {col.name} &nbsp; &#8679;
                            </option>
                        </Fragment>
                        ))}
                    </select>
                </div>
            )}

                <div className="row align-items-center d-flex justify-content-center">
                    <button onClick={search} className="d-inline m-2 btn btn-primary float-right">Szukaj</button>
                    <button
                        className="d-inline m-2 btn btn-primary float-right"
                        onClick={resetFilter}
                    >
                        Przywróć domyślne
                    </button>
                    <button
                        className="d-inline m-2 btn btn-primary float-right"
                        onClick={toggleFilter}
                    >Zamknij</button>
                </div>
            </div>
    )
}
