import React, { useEffect, useState } from 'react'
import FormSkel from './FormSkel'
import fetchHook from '../../hooks/fetchMSSQL-hook'

export default function EventForm({handleSubmit, handleClose, eventData}) {
    const [initialData, setInitialData] = useState(eventData || {Code: "", Name: "", NotificationType: "Nie wyświetlaj", Description: "", DefaultNotificationLevelId: 8, IsEditable: true})
    const [data, setData] = useState([])
    const [notificationTypes, setNotificationType] = useState([])
    const [changed, setChanged] = useState(false)
    const [isAvailableToSave, setIsAvailableToSave] = useState(false)
    const { fetchMssqlApi } = fetchHook()
    useEffect(() => {
        setData(initialData)
    }, [initialData])
    const getNotificationTypes = () => {
        fetchMssqlApi('notification-types', {}, res => {
            setNotificationType(res)
        })
    }
    const handleChangeNT = name => {
        const NT = notificationTypes.find(nt => nt.Name === name) 
        if (NT) {
            setData(prev => ({...prev,NotificationType: name, DefaultNotificationLevelId: NT.NotificationLevelId}))
        } else {
            setData(prev => ({...prev,NotificationType: name}))
        }
    }
    useEffect(() => {
        data.Code
        && data.Name
        && data.Description
        ? setIsAvailableToSave(true)
        : setIsAvailableToSave(false)
    },[data])
    useEffect(() => {
        getNotificationTypes()
    }, [])
    return (
        <FormSkel disableSubmit={!isAvailableToSave || !changed} noFooter={data && !data.IsEditable} handleClose={handleClose}  data={data} headerText={eventData ? (data.Editable ? "Edytuj zdarzenie" : "Zdarzenie") : "Nowe zdarzenie"}>
            <form id='modal-form' onSubmit={evt => handleSubmit(evt, data, setData)} onKeyDown={evt => evt.key === "Enter" && evt.preventDefault()}>
                <div>
                    <label className='h6'>Kod zdarzenia</label>
                    <input
                        disabled={data && !data.IsEditable}
                        className={`form-control ${!data.Code && `invalid-input`}`}
                        value={data.Code}
                        onChange={evt => {setChanged(true);const value = evt.target.value; setData(prev => ({...prev, Code: value || ""}))}}
                        onFocus={() => {setData(prev => ({...prev, Code: ""}))}}
                    />
                </div>
                <div className='mt-3'>
                    <label className='h6'>Nazwa</label>
                    <input 
                        disabled={data && !data.IsEditable}
                        className={`form-control ${!data.Name && `invalid-input`}`}
                        value={data.Name}
                        onChange={evt => {setChanged(true); const value = evt.target.value; setData(prev => ({...prev, Name: value || ""}))}}
                        onFocus={() => {setData(prev => ({...prev, Name: ""}))}}
                    />
                </div>
                <div className='mt-3'>
                    <label className='h6'>Rodzaj powiadomienia</label>
                    <input 
                        disabled={data && !data.IsEditable}
                        className={`form-control`}
                        value={data.NotificationType}
                        onChange={evt =>{setChanged(true); handleChangeNT(evt.target.value)}}
                        onFocus={() => {setChanged(true); setData(prev => ({...prev, NotificationType: "", DefaultNotificationLevelId: null}))}}
                        list='notification-type'
                    />
                    <datalist id='notification-type'>
                        {notificationTypes && notificationTypes.map((type, idx) => (
                            <option value={type.Name} key={idx}/>
                        ))}
                    </datalist>
                </div>
                <div className='mt-3'>
                    <label className='h6'>Opis</label>
                    <textarea
                        disabled={data && !data.IsEditable}
                        className={`form-control ${!data.Description && `invalid-input`}`}
                        value={data.Description}
                        onChange={evt => {setChanged(true); const value = evt.target.value; setData(prev => ({...prev, Description: value || ""}))}}
                        rows={5}
                    />
                </div>
            </form>
        </FormSkel>
    )
}
