import React, {useState, useContext, useEffect} from 'react'
import { Link, Prompt, useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import link from '../../../assets/images/back_to_list_icon.svg'
import fetchHook from '../../../hooks/fetchMSSQL-hook'
import { NavigationContext } from '../../../context/navigation-context'
import moment from 'moment'
import { NotificationManager } from 'react-notifications'
import { useMemo } from 'react'



export default function PrepaidGroup() {
    const params = useParams(), 
    { fetchMssqlApi } = fetchHook(),
    { setHeaderData } = useContext(NavigationContext),
    [initialPrepaidGroup, setInitialPrepaidGroup] = useState({}),
    [prepaidGroup, setPrepaidGroup] = useState({}),
    [initialTerminals, setInitialTerminals] = useState([]),
    [terminals, setTerminals] = useState([]),
    [timespans, setTimespans] = useState([]),
    [users, setUsers] = useState([]),
    [changed, setChanged] = useState(false),
    [avaiableToSave, setAvaiableToSave] = useState(true),
    [initialTerminalList, setInitialTerminalList] = useState([]),
    [terminalList, setTerminalList] = useState([]),
    [terminalName, setTerminalName] = useState(""),
    [prepaidName, setPrepaidName] = useState(""),
    [prepaidUsers ,setPrepaidUsers] = useState([]),
    [customersList ,setCustomerList] = useState([]),
    [showCalendar , setShowCalendar ] = useState(false),
    history = useHistory(),
    [param, setParam] = useState(params.id)
    const getPrepaidGroup = (id = params.id) => {
        if (params.id !== "0") fetchMssqlApi(`prepaid-group/${id}`, {}, res => {
            if(!res.PrepaidGroupId) {
                NotificationManager.error("Takiej grupy nie istnieje")
                history.push("/prepaids/groups")
            }
            setInitialTerminals(res.Terminals)
            res.terminals && delete res.terminals
            setUsers(res.Users)
            res.Users && delete res.Users
            setInitialPrepaidGroup(res)})
    }
    const getTerminalsList = (customerAbbr = localStorage.getItem('clientId') === 'console' ? (initialPrepaidGroup.Abbreviation ? initialPrepaidGroup.Abbreviation : localStorage.getItem('clientId')) : localStorage.getItem('clientId')) => {
        fetchMssqlApi('terminal-list2', {method: "POST", data: {clientId: customerAbbr}, hideNotification: true}, terminals => {terminals.length ? setInitialTerminalList(terminals) :setInitialTerminalList([])} )
        // fetchMssqlApi(`terminal-list/${customerAbbr}`, {},  terminals => setInitialTerminalList(terminals))
    }
    const getPrepaidUsers = (id = null) => {
        fetchMssqlApi(`prepaids/${id ? id : ""}`, {}, res => {
            // prepaidGroup.PrepaidGroupId
            // ? setPrepaidUsers(res.filter(user => !user.PrepaidGroupId || user.PrepaidGroupId === prepaidGroup.prepaidGroupId))
            // : setPrepaidUsers(res.filter(user => !user.PrepaidGroupId))
            res.length && setPrepaidUsers(res)
        })
    }
    const getPrepaidTimespans = () => fetchMssqlApi('prepaid-timespans', {}, res => setTimespans(res.filter(item => item.PrepaidTimespanId !== "5")))
    const getCustomersList = () => fetchMssqlApi('clients', {}, res => setCustomerList(res))
    const handleSwitchObj = (name,  keyToSearch, value ,arrToFind, stateCallback) => {

        const item = arrToFind.find(item => item.Name === value)
        if (!item) return stateCallback(prev => ({...prev, [name]: value}))
        return stateCallback(prev => ({...prev, [name]: item.Name, [keyToSearch]: item[keyToSearch]}))
    } 
    const handleSwitchObj2 = ( value ) => {
        const item = customersList.find(item => `${item.Name} (${item.Abbreviation})` === value)
        if (!item) { setPrepaidGroup(prev => ({...prev, CustomerName: value}))
            return
        } 
        else {
            getTerminalsList(item.Abbreviation)
            getPrepaidUsers(item.Abbreviation)
            return setPrepaidGroup(prev => ({...prev, CustomerName: `${item.Name} (${item.Abbreviation})`, CustomerId: item.CustomerId, Abbreviation: item.Abbreviation}))
        }
    } 
    const deleteTerminal = (name) => {
        setChanged(true)
        setTerminals(prev => prev.map(terminal => terminal.Name === name ? ({...terminal, deleted: true}) : (terminal)))
    } 
    const deletePrepaid = (prepaidId) => {
        // setUsers(prev => prev.map(user => user.PrepaidId === prepaidId ? ({...user, deleted: true}) : user))
        setUsers(prev => prev.filter(user => !(user.new && user.PrepaidId === prepaidId)))
    } 
    const handleChangeIsActive = (value) => {
        if (value === false) {
            setPrepaidGroup(prev => ({...prev, Enabled: false, IsActive: false}))
        } else if (value === true) {
            setPrepaidGroup(prev => ({...prev, Enabled: true, IsActive: true}))
        }
    }
    const handleChangeEnabled = () => {
        if (prepaidGroup.IsActive === false) {
            NotificationManager.warning("You can`t enable inactive group")
            return
        } else {
            setPrepaidGroup(prev => ({...prev, Enabled: !prev.Enabled}))
        }
    }
    const handleChangePrepaidGroup = (evt) => {
        const {name, value, checked} = evt.target
        setChanged(true)
        switch (name) {
            case "PrepaidTimespanName": 
                handleSwitchObj("PrepaidTimespanName", "PrepaidTimespanId", value, timespans, setPrepaidGroup)
                break;
            case "CustomerName": 
                handleSwitchObj2(value)
                break;
            case "Enabled":
                handleChangeEnabled()
                break;
            case "IsActive":
                handleChangeIsActive(checked)
                break;
            case "OverwriteCredit":
                setPrepaidGroup(prev => ({...prev, [name]: !(prev[name])}))
                break;
            case "CreditValue":
                setPrepaidGroup(prev => ({...prev, [name]: Number.parseFloat(value)}))
                break;
            case "LastStart":
                // console.log(moment(value).format('YYYY-MM-DDTHH:MM'))
                setPrepaidGroup(prev => ({...prev, LastStart: value}))
                break;
            default: setPrepaidGroup(prev => ({...prev, [name]: value}))
        }
    }

    const restartLastStart = (evt) => {
        evt.preventDefault()
        const {elements} = evt.target
        console.log(elements)
    }
    const handleSubmit = () => {
        if (showCalendar) {
            NotificationManager.warning("Zapisz albo anuluj zmiany ostatniego startu żeby kontynuować")
            return
        }
        const payloadPrepaids = users.filter(user => user.new).map(({PrepaidId}) => ({PrepaidId: PrepaidId}))
        const payloadTerminals = terminals.filter(terminal => !(terminal.deleted && terminal.new) && !(!terminal.deleted && !terminal.new)).map(terminal => ({TermminalId: terminal.TerminalId, new: terminal.new ? 1 : 0, deleted: terminal.deleted ? 1 : 0}))
        const payload = localStorage.getItem('clientId') !== 'console' ? {
            creditValue: Number.parseFloat(+prepaidGroup.CreditValue),
            enabled: prepaidGroup.Enabled || false,
            overwriteCredit: prepaidGroup.OverwriteCredit || false,
            name: prepaidGroup.PrepaidGroupName,
            timespanId: prepaidGroup.PrepaidTimespanId,
            terminals: JSON.stringify(payloadTerminals),
            creditCount: null,
            prepaids: JSON.stringify(payloadPrepaids),
            isActive: prepaidGroup.IsActive,
            lastStart: moment(prepaidGroup.LastStart).format('YYYY-MM-DD HH:00:00.000')
        } : {
            creditValue: Number.parseFloat(+prepaidGroup.CreditValue),
            enabled: prepaidGroup.Enabled || false,
            overwriteCredit: prepaidGroup.OverwriteCredit || false,
            name: prepaidGroup.PrepaidGroupName,
            timespanId: prepaidGroup.PrepaidTimespanId,
            terminals: JSON.stringify(payloadTerminals),
            creditCount: null,
            prepaids: JSON.stringify(payloadPrepaids),
            isActive: prepaidGroup.IsActive,
            lastStart: moment(prepaidGroup.LastStart).format('YYYY-MM-DD HH:00:00.000'),
            customerId: prepaidGroup.Abbreviation 
        }
        setInitialTerminals([])
        setTerminals([])
        setUsers([])
        try {if (params.id === "0") {
            fetchMssqlApi(`add-prepaid-group`, {method: "POST", data: payload}, res => {
                if (res.id) {
                    setChanged(false)
                    history.replace(res.id)     
                    setParam(res.id)
                } else {
                    NotificationManager.error(res.message)
                }
            })
        } else {
            fetchMssqlApi(`prepaid-group/${params.id}`, {method: "PUT", data: payload}, res => {
                setChanged(false)
                getPrepaidGroup()
            })
        }} catch (e) {
            NotificationManager.error(e)
        }
    }
    const handleChangeTerminal = (terminalName) => {
        const terminal = terminalList.find(item => item.Name === terminalName)
        if (!terminal) {
            setTerminalName("")
            return
        } else {
            setChanged(true)
            const t1 = terminals && terminals.find(item => item.TerminalId === terminal.TerminalId )
            if (t1 && t1.deleted) {
                setTerminals(prev => prev.map(terminal1 => terminal1.TerminalId === terminal.TerminalId ? ({...terminal1, deleted: false}) : terminal1))
                setTerminalName("")
                return
            } else if (t1 && !t1.deleted) {
                setTerminalName("")
                NotificationManager.error("Terminal already added to list")
                return
            } else {
                setTerminals(prev => [{Name: terminal.Name, TerminalId: terminal.TerminalId, new: true} ,...prev])
                setTerminalName("")
            }
        }
    }

    const handleChangePrepaid = () => {
        const user = prepaidUsers.find(item => `${item.PrintedCode} (${item.OwnerData})` === prepaidName)
        if (!user) {
            setPrepaidName("")
            return
        } else {
            setChanged(true)
            const u1 = users && users.find(item => item.PrepaidId === user.PrepaidId )
            if (u1 && u1.deleted) {
                setUsers(prev => prev.map(item => item.PrepaidId === u1.PrepaidId ? ({...item, deleted: false}) : item))
                setPrepaidName("")
                return
            } else if (u1 && !u1.deleted) {
                setPrepaidName("")
                NotificationManager.error("User already added to list")
                return
            } else {
                setUsers(prev => [{PrepaidId: user.PrepaidId
                    , AdditionalData: user.AdditionalData
                    , OwnerFullData: `${user.OwnerData}(${user.AdditionalData})`
                    , Code: user.Code
                    , PrintedCode: user.PrintedCode
                    , CreditValue: user.CreditValue
                    , PrepaidStatusName: user.PrepaidStatus
                    , PrepaidTypeName: user.PrepaidType
                    , new: true
                } ,...prev])
                setPrepaidName("")
            }
        }
    }
    const handleCheckFocus = (evt) => {
        setChanged(true)
        const {name} = evt.target

        switch (name) {
            case "PrepaidTimespanName":
                setPrepaidGroup(prev => ({...prev, PrepaidTimespanName: "", PrepaidTimespanId: null}))
                break;
            case "CustomerName":
                setTerminals([])
                setTerminalList([])
                setUsers([])
                setPrepaidUsers([])
                setPrepaidGroup(prev => ({...prev, [name]: "", CustomerId: null, Abbreviation: null}))
                break
            default: setPrepaidGroup(prev => ({...prev, [name]: ""}))
        }
    }
    useEffect(() => {
        setHeaderData({text: "Szczegóły Grupy Prepaid"})
        getPrepaidGroup()
        getPrepaidTimespans()
        localStorage.getItem('clientId') === 'console' && params.id === "0" && getCustomersList()
    }, [])
    useEffect(() => {
        setTerminalList(initialTerminalList)
    },[initialTerminalList])
    useEffect(() => {
        setPrepaidGroup({...initialPrepaidGroup, CreditValue: Number(initialPrepaidGroup.CreditValue || null).toFixed(2) })
        getPrepaidUsers(initialPrepaidGroup.Abbreviation)
        getTerminalsList()
    }, [initialPrepaidGroup])
    useEffect(() => {
        setTerminals(initialTerminals)
    }, [initialTerminals])
    useEffect(() => {
        getPrepaidGroup()
    }, [param])
    useEffect(() => {
        (
            !prepaidGroup.PrepaidGroupName || 
            !prepaidGroup.PrepaidTimespanId ||
            showCalendar === true
            // !prepaidGroup.PrepaidTimespanName ||
        ) ? setAvaiableToSave(false) : setAvaiableToSave(true)
    }, [prepaidGroup, showCalendar])

    return (
        <div>
            <Prompt when={changed} message="Istnieją niezapisane zmiany, czy na pewno chcesz kontynuować?"/>
            <Link to='/prepaids/groups' >
                <label htmlFor='back' className="btn btn-link ml-1 text-decoration-none ml-2 mb-3">
                    <img id='back' src={link}></img>
                </label>
            </Link>
            <div className='container-fluid'>
            <div className='row col-12'>
            <div className={`col ${window.innerWidth < 500 ? `col-12 mb-3` : `col-7`}`}>   
                <div className='card '>
                    <div className=' card-header'>
                        Ustawienia
                    </div>
                    <div className='card-body'>
                        <div className='mb-3'>
                            <h6 className='mb-2'>Nazwa</h6>
                            <input
                                className={`form-control ${!prepaidGroup.PrepaidGroupName && `invalid-input`}`}
                                value={prepaidGroup.PrepaidGroupName}
                                name='PrepaidGroupName'
                                onChange={handleChangePrepaidGroup}
                                onFocus={handleCheckFocus}
                                placeholder='Wpisz nazwę grupy...'
                            />
                        </div>
                        {localStorage.getItem('clientId') === "console" && (
                            <>
                                <div className='mb-3'>
                                    <h6 className='mb-2'>Klient</h6>
                                    <input
                                        className={`form-control ${(!prepaidGroup.CustomerName || !prepaidGroup.CustomerId) && `invalid-input`}`}
                                        value={prepaidGroup.CustomerName}
                                        name='CustomerName'
                                        onChange={handleChangePrepaidGroup}
                                        onFocus={handleCheckFocus}
                                        placeholder='Wpisz nazwę użytkownika...'
                                        disabled={params.id !== "0"}
                                        list='customersList'
                                />
                                <datalist id='customersList'>
                                    {customersList && customersList.map((customer, idx) => (
                                        <option key={idx} value={`${customer.Name} (${customer.Abbreviation})`}/>
                                    ))}
                                </datalist>
                                </div>
                            </>
                        )}
                        <div className='mb-3'>
                            <h6 className='mb-2'>Doladowania</h6>
                            <input
                                className={`form-control ${(!prepaidGroup.PrepaidTimespanName || !prepaidGroup.PrepaidTimespanId) && `invalid-input`}`}
                                value={prepaidGroup.PrepaidTimespanName}
                                name='PrepaidTimespanName'
                                onChange={handleChangePrepaidGroup}
                                onFocus={handleCheckFocus}
                                list='PrepaidTimespanName'
                                placeholder='Wybierz częstotliwość zasilania...'
                            />
                            <datalist id='PrepaidTimespanName'>
                                {timespans && timespans.map((timespan, idx) => (
                                    <option key={idx} value={timespan.Name}/>
                                ))}
                            </datalist>
                        </div>
                        {/* {params.id !== "0" && (

                            <div className='mb-3 d-flex flex-wrap justify-content-between'>
                                <div>
                                    <h6 className='mb-2'>Ostatni start</h6>
                                    <input
                                        type='datetime-local'
                                        name='LastStart'
                                        className={`form-control `}
                                        value={moment(prepaidGroup.LastStart).format('YYYY-MM-DDTHH:00') }
                                        disabled={!showCalendar}
                                        onChange={handleChangePrepaidGroup}
                                        step={60}
                                    />  
                                </div>
                                {
                                    !showCalendar ? (

                                        <div className='d-flex justify-content-end align-items-center'>
                                            <button onClick={() => setShowCalendar(true)} className=" btn btn-outline-primary btn-sm ">
                                                Restartuj
                                            </button>
                                        </div>
                                    ) : (
                                        <div className='d-flex justify-content-end align-items-center'>
                                            <button onClick={() => {
                                                setPrepaidGroup(prev => ({...prev, LastStart: initialPrepaidGroup.LastStart}))
                                                setShowCalendar(false)}} className=" btn btn-outline-danger btn-sm ">
                                                Anuluj zmiany
                                            </button>
                                            <button onClick={() => setShowCalendar(false)} className=" btn btn-outline-success btn-sm ml-3">
                                                Zapisz
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                        )} */}
                        {/* { showCalendar && (
                            <form id='setDate'>
                                <input
                                    type="datetime-local"
                                    id="datetime"
                                    defaultValue={moment(prepaidGroup.LastStart).format('YYYY-MM-DDTHH:MM')}
                                />
                                <div className='d-flex justify-content-center'>
                                    <div>
                                        <button onClick={() => setShowCalendar(prev => !prev)} className=" btn btn-outline-success btn-sm ">
                                            Restartuj
                                        </button>
                                    </div>
                                    <div>
                                        <button onClick={evt => restartLastStart(evt)} className=" btn btn-outline-success btn-sm ">
                                            Zapisz
                                        </button>
                                    </div>
                                </div>
                            </form>
                        )} */}
                        {/* {params.id === "0" && ( */}

                            <div className='mb-3 d-flex flex-wrap justify-content-between'>
                                <h6 className='mb-2'>Kredyt</h6>
                                <input
                                    type="number"
                                    className={`form-control ${!prepaidGroup.CreditValue ? `invalid-input` : ``}`}
                                    value={prepaidGroup.CreditValue}
                                    name='CreditValue'
                                    onChange={handleChangePrepaidGroup}
                                    onFocus={handleCheckFocus}
                                    list='CreditValue'
                                    placeholder='Wpisz kredyt...'
                                />
                            </div>
                        {/* )} */}
                        <div className='d-flex flex-wrap justify-content-around mb-3'>
                            {params.id !== "0" && (
                                <div>
                                    <label htmlFor="Enabled">
                                        <h6>Włączona</h6>
                                        <div className='d-flex w-100 justify-content-center'>
                                            <input
                                                style={{width: "60%", height: "60%"}}
                                                type='checkbox'
                                                checked={prepaidGroup.Enabled}
                                                onClick={handleChangePrepaidGroup}
                                                name='Enabled'
                                                id='Enabled'
                                            />
                                        </div>
                                    </label>
                                </div>
                            )}
                            {params.id !== "0" && (
                                <div>
                                    <label htmlFor='IsActive'>
                                        <h6>Aktywna</h6>
                                        <div className='d-flex w-100 justify-content-center'>
                                            <input
                                                style={{width: "60%", height: "60%"}}
                                                type='checkbox'
                                                checked={prepaidGroup.IsActive}
                                                onClick={handleChangePrepaidGroup}

                                                name='IsActive'
                                                id='IsActive'
                                            />
                                        </div>
                                    </label>
                                </div>
                            )}
                            <div>

                                <label htmlFor='OverwriteCredit'>
                                    <h6>Nadpisywanie środków</h6>
                                    <div className='d-flex w-100 justify-content-center'>
                                        <input
                                            style={{width: "60%", height: "60%"}}
                                            type='checkbox'
                                            checked={prepaidGroup.OverwriteCredit}
                                            onClick={handleChangePrepaidGroup}
                                            name='OverwriteCredit'
                                            id='OverwriteCredit'
                                        />
                                    </div>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div className={`col col-5 ${window.innerWidth < 500 ? `col-12` : `col-5`} overflow-auto pr-0`} style={{maxHeight: "500px"}}>
                    <div className='card'>

                        <div className=' card-header'>
                            Terminale
                        </div>
                        <div className='card-body overflow-auto'>
                        <div className='d-flex ml-1 mb-1'>

                            <input
                                type='text'
                                autoComplete='off'
                                onChange={evt => setTerminalName(evt.target.value)}
                                onFocus={() => setTerminalName("")}
                                value={terminalName}
                                list='terminalList'
                                className='form-control'
                                placeholder='Dodaj terminal do grupy...'
                            />
                            <datalist id='terminalList'>
                                {terminalList && terminalList.map((terminal, idx) => <option key={idx} value={terminal.Name}/>)}
                            </datalist>
                            <button
                                className="btn text-nowrap btn-sm btn-outline-primary pr-3 pl-3 pb-2 pt-2 mb-0 mt-0 ml-2 mr-1"
                                onClick={() => handleChangeTerminal(terminalName)}
                            >
                                <i className="fas fa-plus" />
                            </button>
                        </div>
                            <table className='table table-striped '>
                                <thead>
                                    <tr>
                                        <td>Terminal</td>
                                        <td></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {terminals.length ? terminals.filter(terminal => !terminal.deleted).map((terminal, idx) => (
                                        <tr key={idx}>
                                            <td>{terminal.Name}</td>
                                            <td>
                                                <div className='d-flex justify-content-end border-0'>
                                                    <button className=" mr-4 btn btn-outline-danger btn-sm "
                                                        onClick={() => deleteTerminal(terminal.Name)}
                                                        // key={location.id+"btn"}
                                                    >
                                                            Usuń
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    )) : <></>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className='container-fluid'>

                <div className='row mt-5 mb-5'>
                    <div className='col'>
                        <div className='card'>
                            <div className='card-header'>
                                Karty prepaid
                            </div>
                            <div className='card-body overflow-auto' >
                            {/* handleChangePrepaid */}
                                <div className='d-flex mb-1'>

                                    <input
                                        type='text'
                                        autoComplete='off'
                                        onChange={evt => setPrepaidName(evt.target.value)}
                                        onFocus={() => setPrepaidName("")}
                                        value={prepaidName}
                                        list='prepaidList'
                                        className='form-control'
                                        placeholder='Dodaj kartę do grupy...'
                                    />
                                    <datalist id='prepaidList'>
                                        {prepaidUsers && prepaidUsers.map((user, idx) => <option key={idx} value={`${user.PrintedCode} (${user.OwnerData})`}/>)}
                                    </datalist>
                                    <button
                                        className="btn text-nowrap btn-sm btn-outline-primary pr-3 pl-3 pb-2 pt-2 mb-0 mt-0 ml-2"
                                        onClick={handleChangePrepaid}
                                    >
                                        <i className="fas fa-plus" />
                                    </button>
                                </div>
                                <table className='table table-striped'>
                                    <thead>
                                        <tr>
                                            <td>Numer karty</td>
                                            <td>Dodatkowe informacje</td>
                                            <td>Wewnętrzny numer</td>
                                            <td>Typ</td>
                                            <td>Status</td>
                                            <td>Kredyt</td>
                                            {users && users.some(user => user.new) && <td></td>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users && users.filter(prev => !prev.deleted).map((user, idx) => (
                                            <tr key={idx}>
                                                <td className='pt-3 pb-3'>
                                                    <Link
                                                        to={`/prepaids/card/${user.PrepaidId}`}
                                                        className=" mr-4 btn btn-outline-secondary btn-sm "
                                                    >
                                                        {user.PrintedCode}
                                                    </Link>
                                                </td>
                                                <td>{user.OwnerFullData}</td>
                                                <td>{user.Code}</td>
                                                <td>{user.PrepaidTypeName}</td>
                                                <td>{user.PrepaidStatusName}</td>
                                                <td>{Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format((user.CreditValue).toFixed(2)) || "0,00zł"}</td>
                                                {user.new ? <td>
                                                    <div className='d-flex justify-content-end border-0'>
                                                        <button className=" mr-2 btn btn-outline-danger btn-sm "
                                                            onClick={() => deletePrepaid(user.PrepaidId)}
                                                            // key={location.id+"btn"}
                                                        >
                                                                Usuń
                                                        </button>
                                                    </div>
                                                </td> : <td></td>}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='position-fixed container'  style={{bottom: "0px"}}>

                    <div className="card-footer text-center fixed-bottom" style={{backgroundColor:"#cbcbcb"}}>
                        <button disabled={!avaiableToSave || !changed } className={`btn btn-primary w-20  btn-sm`} onClick={handleSubmit}>
                            Zapisz
                        </button>
                    </div>
            </div>
        </div>
    )
}
