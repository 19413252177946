import React from "react";
import { useState, useEffect, useContext } from "react";
import fetchMSSQLHook from "../../hooks/fetchMSSQL-hook";
import Filter from "../../components/Filter/Filter";
import Pagination from "../../components/Pagination/Pagination";
import { LangContext } from '../../context/lang-context';

const EventLog = () => {
    const [logs, setLogs] = useState([])
    const { fetchMssqlApi } = fetchMSSQLHook()
    const { TRL_Pack } = useContext(LangContext);

    const getLogs = () => {
        fetchMssqlApi('logs', {}, logs => setLogs(logs))
    }

    useEffect(() => getLogs(), [])

    let defaultFilter = {
        showIndexes: true,
        page: 1,
        rowsPerPage: 25,
        rowsPerPageOptions: [10, 25, 50],
        visible: false,
        sortByColumns: true,
        sortBy: '3 | asc | text',
        activeTags: [],
        logs: true,
        // disableIndexes: false,
        columns: [
            {
                id: 1,
                name: "#",
                disabled: true,
            },
            {
                id: 2,
                name: TRL_Pack.events.eventLog.event,
                sortable: true,
                searchable: true,
                type: 'text',
            },
            {
                id: 3,
                name: TRL_Pack.events.eventLog.level,
                sortable: true,
                searchable: true,
                type: 'text',
            },
            {
                id: 4,
                name: TRL_Pack.events.eventLog.date,
                sortable: true,
                date: true,
                searchable: true,
                type: 'date',
            },
        ],
    }


    const [filter, setFilter] = useState(() => {
        if (localStorage.getItem('eventlogFilter')) {
            return JSON.parse(localStorage.getItem('eventlogFilter'))
        } else return defaultFilter
    })


    const resetFilter = () => setFilter(defaultFilter)
    const toggleFilter = () => setFilter(prev => ({ ...prev, visible: !prev.visible }))

    const handleSwitchPage = pageNo => () => setFilter(prev => ({ ...prev, page: pageNo }))
    const resetPage = () => setFilter(prev => ({ ...prev, page: 1 }))

    // const filteredLogs = logs.filter(({ Messaeg }) => filterItems(searchedText, Name))

    const reportFilter = row =>
        filter.columns
            .filter(col => col.searchbar ? col.searchbar : col.selectbar)
            .every(col => {
                const x = String(row[Object.keys(row)[col.id - 1]])
                    .toLowerCase()
                    .includes(col.searchbar ? col.searchbar.toLowerCase() : col.selectbar.toLowerCase())
                return x
            })

    const sortRows = (a, b) => {
        const [id, order, type] = filter.sortBy.split(' | ')

        const col = Object.keys(logs[0])[Number(id) - 1]

        if (a[col] === b[col]) return 0
        else if (a[col] === null) return 1
        else if (b[col] === null) return -1

        let valueA, valueB
        if (type === 'text' || type === 'date') {

            valueA = a[col]?.toLowerCase()
            valueB = b[col]?.toLowerCase()
        }
        else if (type === "bool") {
            valueA = Number(a[col])
            valueB = Number(b[col])
        }
        else if (type === 'price') {
            valueA = Number(a[col].replace(',', ''))
            valueB = Number(b[col].replace(',', ''))

            //   // Number().toLocaleString(undefined, {minimumFractionDigits: 2}) num => str '1 245 151,50'
        } else return 0
        if (order === 'asc') return valueA < valueB ? -1 : 1
        else return valueA < valueB ? 1 : -1
    }


    return (
        logs && (
            <div>
                <Pagination
                    totalItems={logs.filter(reportFilter).sort(sortRows).length}
                    page={filter.page}
                    rowsPerPage={filter.rowsPerPage}
                    handleSwitchPage={handleSwitchPage}
                    filterVisibility={filter.visible}
                    toggleFilter={toggleFilter}
                    resetFilter={resetFilter}

                />
                {filter.visible && (
                    <Filter
                        {...{
                            filter,
                            setFilter,
                            columns: filter.columns,
                            data: logs,
                            resetPage,
                            resetFilter
                        }}
                    />
                )}

                <table className="table table-striped border">
                    <thead>
                        <tr>
                            {filter.showIndexes && (
                                <th className="text-center px-4" style={{ width: '1%' }}>#</th>
                            )}
                            {filter.columns.map(col => (
                                !col.disabled && <th key={col.id} className="text-center" style={{ width: 100 }}>{col.name}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {logs
                            .filter(reportFilter)
                            .sort(sortRows)
                            .slice(
                                (filter.page - 1) * filter.rowsPerPage,
                                filter.page * filter.rowsPerPage
                            )
                            .map((log, index) => (
                                <tr key={index}>
                                    {filter.showIndexes && (
                                        <td className="font-weight-bold text-center">{log.LogId}</td>
                                    )}
                                    <td className="font-weight-bold text-center">{log.Message.substring(log.Message.indexOf("Event: ") + 7, log.Message.indexOf(", Raw:"))}</td>
                                    <td className="font-weight-bold text-center">{log.Level}</td>
                                    <td className="font-weight-bold text-center">{log.CreateDateTime.replace('T', ' ').slice(0, 19)}</td>
                                </tr>
                            ))}
                    </tbody>

                </table>
            </div>
        )
    );
}

export default EventLog;