import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { NotificationManager } from 'react-notifications'
import fetchHook from '../../../../hooks/fetchMSSQL-hook'
import renderPAPR from './PAPR'
import renderRD from './RD'
import renderRF from './RF'
import renderZE from './ZE'


export default function CashRegisterCurrentState({CashRegisterId, setFilter, filter, setFiscalDocsLength}) {
    const defaultQuerySettings = {process: 1, timeout: 1, lastStatus: 0, readyToFile: false},
    [querySettings, setQuerySettings] = useState(defaultQuerySettings)

    const {fetchMssqlApi} = fetchHook()
    const [fiscalDocs, setFiscalDocs] = useState(null)
    const [fiscalDocTypes, setFiscalDocTypes] = useState(null)
    const [fiscalDocBtn, setFiscalDocBtn] = useState({Report: null, Id: null})
    const handleChangeDate = ({target}) => {

    const {name, value, type, innerText} = target
        if (type === "date") {
            setFilter(prev => ({...prev, [name]: value}))
        } else {
            switch (innerText) {
                case "Przywróć domyślne":
                    setFilter(prev => ({
                            ...prev
                            , fromDate: moment().add(-1, 'd').format('YYYY-MM-DD')
                            , toDate: moment().format('YYYY-MM-DD')
                        }))
                    break;
                case "Bieżący miesiąc":
                    setFilter(prev => ({
                            ...prev
                            , fromDate: moment().startOf("month").format('YYYY-MM-DD')
                            , toDate: moment().format('YYYY-MM-DD')
                        }))
                    break;
                case "Poprzedni miesiąc":
                    setFilter(prev => ({
                            ...prev
                            , fromDate: moment().add(-31, 'd').format('YYYY-MM-DD')
                            , toDate: moment().format('YYYY-MM-DD')
                        }))
                    break;
            }
        }
    }
    const getFiscalDocTypes = () => {
        fetchMssqlApi('cashRegisters/reports/types', {}, types => setFiscalDocTypes(types)) 
    }
    const getReceipts = () => {
        if (CashRegisterId == "0") {
            return
        }

        let typeObj = fiscalDocTypes ? fiscalDocTypes.find(type => filter.docType === type.Name) : {FiscalDocTypeId: 1} 
        const FiscalDocTypeId = typeObj ? typeObj.FiscalDocTypeId : null

        fetchMssqlApi('fiscalisationDocs', 
            {
                method: "POST",
                data: {
                    CashRegisterId,
                    FromCreateDateTime: moment(filter.fromDate).format('YYYY-MM-DD 00:00:00:000'),
                    ToCreateDateTime: moment(filter.toDate).format('YYYY-MM-DD 23:59:59:000'),
                    FiscalDocTypeId,
                    PageNo: filter.page,
                    RecordsPerPage: 100,
                    IsTotalRowCountIgnoring: false
                },
                hideNotification: true
            },
            res => {
                if(res.Docs) {

                    setFiscalDocs(res.Docs)
                    setFiscalDocsLength(res.Docs.length)
                }
            }
        )
    }
    const renderElement = (obj, idx) => {
        switch (obj.FiscalDocTypeId) {
            case 1:
            case 2:
                return renderPAPR(obj, idx, fiscalDocBtn, setFiscalDocBtn)
            case 3:
                return renderRD(obj, idx, fiscalDocBtn, setFiscalDocBtn)
            case 4:
                return renderZE(obj, idx, fiscalDocBtn, setFiscalDocBtn)
            case 5:
                return renderRF(obj, idx, fiscalDocBtn, setFiscalDocBtn)
            default:
                return (<tr></tr>)
        } 
    }
    useEffect(() => {
        getReceipts()
        getFiscalDocTypes()
    }, [])
    useEffect(() => {
        const positionInsideParentDiv = document.querySelector("section").scrollTop
        const viewHeightOfParentDiv = document.querySelector("filter").getBoundingClientRect().height + document.querySelector("section").getBoundingClientRect().height
        if(positionInsideParentDiv > viewHeightOfParentDiv) {
            document.querySelector("filter").scrollIntoView()
        }
        getReceipts()
    }, [filter.page])
    return (
        <div>
            <filter className={`w-100 justify-content-between d-flex ${window.innerWidth < 500 && `flex-wrap`} mb-3 flex-wrap`}>
                <div>
                    <div className='d-flex h-100 align-items-between' >
                        <div className='mr-2'>
                            <label htmlFor='fromDate' className='m-0'>
                                Od
                            </label>
                            <input
                                type='date'
                                name='fromDate'
                                id='fromDate'
                                value={filter.fromDate}
                                onChange={handleChangeDate}
                                className='form-control '
                            />
                        </div>
                        <div>
                            <label htmlFor='toDate' className='m-0'>
                                Do
                            </label>
                            <input
                                type='date'
                                name='toDate'
                                id='toDate'
                                value={filter.toDate}
                                onChange={handleChangeDate}
                                className='form-control'
                            />
                        </div>
                    </div>
                </div>

                <div className='d-flex h-100 align-items-between' >
                    <div>
                        <label htmlFor='docTypes' className='m-0'>
                            Typ dokumentu
                        </label>
                        <input
                            
                            id='docTypes'
                            list='docTypesList'
                            className='form-control'
                            value={filter.docType || ""}
                            onChange={evt => {const value = evt.target.value; setFilter(prev => ({...prev, docType: value}))}}
                            onFocus={() => setFilter(prev => ({...prev, docType: ""}))}
                        />
                        <datalist id='docTypesList'>
                            {fiscalDocTypes && fiscalDocTypes.map((type, idx) => (
                                <option key={idx} value={type.Name}/>
                            ))}
                        </datalist>
                    </div>
                </div>

                <div className='d-flex align-items-end'>
                    <button
                        className= { `btn btn-${
                        !(moment(filter.fromDate).format('YYYY-MM-DD') === moment(filter.toDate).format('YYYY-MM-DD')) && (filter.fromDate > filter.toDate) 
                        || !filter.fromDate 
                        || !filter.toDate
                        || moment(filter.fromDate).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD')
                        ? `danger` 
                        : `success`} 
                        ml-2 mr-2`}
                        onClick={() => {
                            if (!filter.fromDate || !filter.toDate) {
                                NotificationManager.error("Choose date")
                                return
                            }
                            if(!(moment(filter.fromDate).format('YYYY-MM-DD') === moment(filter.toDate).format('YYYY-MM-DD')) && (filter.fromDate > filter.toDate)) {
                                NotificationManager.error("Start date can`t be bigger than end date", "Choose correct data")
                                return
                            }
                            if (moment(filter.fromDate).format('YYYY-MM-DD') > moment().format('YYYY-MM-DD') ) {
                                NotificationManager.error("Choose past date, not future", "Choose correct data")
                                return
                            }
                            setFilter(prev => ({...prev, page: 1}))
                            getReceipts()
                        }}
                    >Szukaj</button>
                    <button
                        className= { `btn btn-primary mr-2`}
                        onClick={handleChangeDate}
                    >Przywróć domyślne</button>
                    <button
                        className= { "btn btn-primary mr-2"}
                        onClick={handleChangeDate}
                        >Bieżący miesiąc</button>
                    <button
                        onClick={handleChangeDate}
                        className= { "btn btn-primary mr-2"}
                    >Poprzedni miesiąc</button>
                </div>
            </filter>
            <div>
            <table className="table table-striped">
                        <thead>
                            <tr>
                                <th>Typ dokumentu</th>
                                <th>Nr unikatowy / Nr ewidencyjny</th>
                                <th>Data / czas</th>
                                <th colSpan={2} className='text-center'>Dodatkowe informacje</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {fiscalDocs && fiscalDocs.map((fd, idx) => renderElement(fd, idx)) }
                        </tbody>
                    </table>
            </div>
        </div>
    )
}
