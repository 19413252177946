import React, { useState } from 'react'

import useFetch from '../../hooks/fetchMSSQL-hook'
import FormSkel from './FormSkel'
import { NotificationManager } from 'react-notifications'

export default ({ data, handleClose, search}) => {
  const { fetchMssqlApi } = useFetch()

  console.log("data: ", data)
  
  const handleSubmit = evt => {
    evt.preventDefault()
    
    let machineCoinInventoryId = data && data.MachineCoinInventoryId;
    if(!machineCoinInventoryId) {
      NotificationManager.error("Something went wrong");
      handleClose()
      return
    }
    const postData = {
      machineCoinInventoryId, 
      coinAlgoritmId: evt.target.id
    }

    fetchMssqlApi(
      `kp-update-coin-algorithm`,
      { method: 'POST', data: postData },
      () => {
        handleClose()
        search()
      }
    )
  }

  return (
    <FormSkel
      headerText={<div>
        Aktualny sposób wyliczania: {data.ExpectedCoinsValue}zł<br></br>
        {data.DefaultCoinAlgoritmId === '1' && (<button className="text-nowrap btn-sm btn-outline-primary">Wg sumy sprzedaży</button>)}
        {data.DefaultCoinAlgoritmId === '2' && (<button className="text-nowrap btn-sm btn-outline-secondary">Wg liczników całkowitych</button>)}
        {data.DefaultCoinAlgoritmId === '3' && (<button className="text-nowrap btn-sm btn-outline-danger">Wg monet wrzuconych do skrzynki</button>)}
        {data.DefaultCoinAlgoritmId === '4' && (<button className="text-nowrap btn-sm btn-outline-warning">Wg sprzedaży gotówkowej</button>)}
        {data.DefaultCoinAlgoritmId === '5' && (<button className="btn-sm btn-outline-info">Wg notowanych liczników<br /> (ilość * średnia(!) cena)</button>)}
        {data.DefaultCoinAlgoritmId === '6' && (<button  className="text-nowrap btn-sm btn-outline-dark">Wg notowanych liczników (wartość)</button>)}
        {data.DefaultCoinAlgoritmId === null && (<button className="text-nowrap btn-sm btn-outline-danger">BRAK</button>)}
        </div>
      }
      handleClose={handleClose}
    >
      <form id="modal-form" autoComplete="off">
        <div className='d-flex flex-column justify-content-center align-items-start'>
        <div className="text-nowrap">{data.KP}, EVADTS: {data.HasEVADTS}</div>
        <div className="">{data.MachineFullName}</div>
        
        <div className="text-nowrap">Stan wrzutnika: {data.I}zł, zmiana stanu wrzutnika: {data.H}zł </div>
        <div className="text-nowrap">Automatyczny komentarz: {data.Comment}</div>
        <br></br>
        <div className="text-nowrap">Dostępne algorytmy wyliczania:</div>
        <div className="text-nowrap">
          <button
            onClick={handleSubmit}
            id='2'
            className="m-1 text-nowrap btn-sm btn-outline-secondary"
          >
            Wg liczników całkowitych
          </button>
        &nbsp;&nbsp;Wartość oczekiwana: {data.ExpectedCoinsValue1}zł</div>
        <div className="text-nowrap">
          <button
            onClick={handleSubmit}
            id='1'
            className="m-1 text-nowrap btn-sm btn-outline-primary"
          >
            Wg sumy sprzedaży
          </button>
        &nbsp;&nbsp;Wartość oczekiwana: {data.ExpectedCoinsValue2}zł</div>
        <div className="text-nowrap">
          <button
            onClick={handleSubmit}
            id='3'
            className="m-1 text-nowrap btn-sm btn-outline-danger"
          >
            Wg monet wrzuconych do skrzynki
          </button>
        &nbsp;&nbsp;Wartość oczekiwana: {data.ExpectedCoinsValue3}zł</div>
        <div className="text-nowrap">
          <button
            onClick={handleSubmit}
            id='4'
            className="m-1 text-nowrap btn-sm btn-outline-warning"
          >
            Wg sprzedaży gotówkowej
          </button>
        &nbsp;&nbsp;Wartość oczekiwana: {data.ExpectedCoinsValue4}zł</div>
        <div className="text-nowrap">
          <button
            onClick={handleSubmit}
            id='5'
            className="m-1 btn-sm btn-outline-info">
              Wg notowanych liczników<br /> (ilość * średnia(!) cena)
          </button>
        &nbsp;&nbsp;Wartość oczekiwana: {data.ExpectedCoinsValue5}zł</div>
        <div className="text-nowrap">
          <button
            onClick={handleSubmit}
            id='6'
            className="m-1 text-nowrap btn-sm btn-outline-dark"
          >
            Wg notowanych liczników (wartość)
          </button>
        &nbsp;&nbsp;Wartość oczekiwana: {data.ExpectedCoinsValue6}zł</div>
        <div className="text-nowrap">
          <button
            onClick={handleSubmit}
            className="m-1 text-nowrap btn-sm btn-outline-danger"
          >
            BRAK
          </button></div>

          {/* tutaj dane {data.MachineCoinInventoryId}

          {data.DefaultCoinAlgoritmId}
{data.ExpectedCoinsValue1}
{data.ExpectedCoinsValue2}
{data.ExpectedCoinsValue3}
{data.ExpectedCoinsValue4}
{data.ExpectedCoinsValue5}
{data.ExpectedCoinsValue6}
{data.A}
{data.C}
{data.D}
{data.E}
{data.F}
{data.G}
{data.H}
{data.I}
{data.J}
{data.K}
{data.L}
{data.M}
{data.N}
{data.O}
{data.Comment}
{data.MoneyTakeOutDetails} */}
        </div>
      </form>
    </FormSkel>
  )
}
