import React, {useContext, useEffect, useState} from "react";
import {NavigationContext} from "../../context/navigation-context";
import {LangContext} from "../../context/lang-context";
import EventLog from "./EventLog";
import ErrorTable from "./ErrorTable";
import MachineProductsHistory from "./MachineProductsHistory";

const Events = () => {
    const {setHeaderData} = useContext(NavigationContext);
    const {
        TRL_Pack
    } = useContext(LangContext);

    const [currentMachineSection, setCurrentMachineSection] = useState(0);
    const changeSection = (id) => () => setCurrentMachineSection(id);

    useEffect(() => {
        setHeaderData({text: TRL_Pack.events.events});
    }, []);

    return (
        <div className="container-xl">
            <ul className="nav nav-tabs machine-tabs mb-3">
                <li className="nav-item">
                    <button
                        className={`nav-link btn ${
                            currentMachineSection === 0 && "active"
                        }`}
                        onClick={changeSection(0)}
                        tabIndex="0"
                    >
                        {TRL_Pack.events.eventLog.name}
                    </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link btn ${
                            currentMachineSection === 1 && "active"
                        }`}
                        onClick={changeSection(1)}
                        tabIndex="1"
                    >
                        {TRL_Pack.events.machineProductHistory.name}
                        </button>
                </li>
                <li className="nav-item">
                    <button
                        className={`nav-link btn ${
                            currentMachineSection === 2 && "active"
                        }`}
                        onClick={changeSection(2)}
                        tabIndex="2"
                    >
                        {TRL_Pack.events.errors.name}
                        </button>
                </li>
            </ul>
            {currentMachineSection === 0 && <EventLog/>}
            {currentMachineSection === 1 && <MachineProductsHistory/>}
            {currentMachineSection === 2 && <ErrorTable/>}

        </div>
    );
};

export default Events;
