import React from "react"
import FormSkel from "./FormSkel"
import PropTypes from 'prop-types'

const ConfirmationForm = ({ text, handleClose, handleSubmit }) => {
    return (
        <FormSkel headerText={"Potwierdzenie operacji"} handleClose={handleClose} acceptForm={true}>
            <form onSubmit={handleSubmit} id="modal-form" autoComplete="off">
                <div className="form-group">
                    <label>Czy jesteś pewien, ze chcesz wykonać te operacje?</label>
                    <label style={{fontWeight: "bold"}}>({text})</label>
                </div>
            </form>
        </FormSkel>
    )
}

ConfirmationForm.propTypes = {
    text: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired
}

export default ConfirmationForm;