import React, { useContext, useEffect, useRef, useState } from 'react'
import fetchHook from '../../../hooks/fetchMSSQL-hook'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import axios from 'axios'
import { API_URL } from '../../../config/config'
import moment from 'moment'
import { NavigationContext } from '../../../context/navigation-context'



export default function PR() {
    const [pr, setPr] = useState(null),
    {fetchMssqlApi} = fetchHook(),
    params = useParams(),
    {id, type} = params,
    paRef = useRef(null),
    {setHeaderData} = useContext(NavigationContext) 

    const getPR = () => typeof(+id) === "number" && fetchMssqlApi(`cashRegisters/report/${id}`, {}, data => {
        setPr(data)
    })
    const getData = (receip) => {
        let binary = ''
        let bytes = new Uint8Array(receip)
        console.log(bytes)
        let length = bytes.byteLength
        for (let i = 0; i < length; i++) {
            console.log(bytes[i])
            binary += String.fromCharCode(bytes[i])
        }
        return window.btoa(binary)
    }
    const blobFormatterBase64 = base64b => {
        const byteArray = Uint8Array.from(
                atob(base64b)
                .split('')
                .map(char => char.charCodeAt(0))
            );
            return new Blob([byteArray], { type: 'application/pdf' })
    }
    const getPdf = () => {
        axios({
            method: "GET",
            url: `${API_URL}/api/wkf/fsfsdfs`,
            responseType:'blob',
            headers:{"Content-Type":"application/pdf"}
        })
        .then( arrBuffer => {
            const fileURL = URL.createObjectURL(arrBuffer.data)
            paRef.current.src = fileURL
        })
    }
    useEffect(() => {
        getPR()
        type === "pr" && setHeaderData({text: "Paragon"})  
    }, [])
    useEffect(() => {
        
    }, [pr])
    const getPaymentType = (paymentTypeId) => {
        switch (paymentTypeId) {
            case 0:
                return (<div><h6>Typ platności: Gotówka, gotówkowa</h6></div>)
            case 1:
                return (<div><h6>Typ platności: Karta, bezgotówkowa</h6></div>)
            case 3:
                return (<div><h6>Typ platności: BLIK, bezgotówkowa</h6></div>)
            default:
                return <div className='content'></div>
        }
    }
    const renderSum = ({Doc}) => {
        console.log(Doc)
        const sumTaxes = Doc.dokument.paragon.podsum.sumaNetto.map(ptu => {
            const taxDisc = Doc.dokument.paragon.stPTU.map(v => v.id === ptu.idStPTU && `PTU ${ptu.idStPTU} ${v.wart / 100}%`)[0]
            const taxValue = Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(ptu.vat / 100 || 0)
            const disc = `Sprzedaż opodatkowana PTU ${ptu.idStPTU}`
            const value = Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(ptu.brutto / 100 || 0)
            return {taxDisc, taxValue, disc, value}
        })
        return (
            <div className='mr-4 ml-4'>
                {sumTaxes.map(i => (
                    <div>
                        <div className='d-flex justify-content-between'>
                            <h6>
                                {i.disc}
                            </h6>
                            <h6>
                                {i.value}
                            </h6>
                        </div>
                        <div className='d-flex justify-content-between'>
                            <h6>
                                {i.taxDisc}
                            </h6>
                            <h6>
                                {i.taxValue}
                            </h6>
                        </div>
                    </div>
                ))}
                <div className='d-flex justify-content-between'>
                    <h6>
                        Suma PTU
                    </h6>
                    <h6>
                        {Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(Doc.dokument.paragon.podsum.sumaPod / 100 || 0)}
                    </h6>
                </div>
                <div className='d-flex justify-content-between'>
                    <h6><b>
                        Suma PLN
                    </b></h6>
                    <h6><b>
                        {Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format(Doc.dokument.paragon.podsum.sumaBrutto / 100 || 0)}
                    </b></h6>
                </div>
            </div>
        )
    }
    return (
        pr && (

        <div className='container-xl'>
            <div className='card'>
                <div className='card-header'>
                    <div className='d-flex justify-content-between'>
                        <h5 className='mb-0'>
                            {pr.NrUnik}
                        </h5>
                        <h5 className='mb-0'>
                            Status dokumentu: {pr.DocStatus}
                        </h5>
                    </div>
                    {/* <hr/> */}
                    {/* <div className='d-flex justify-content-around w-100'>
                        <h5>
                            {pr.Doc.dokument.podmiot1.nazwaPod}
                        </h5>
                    </div>
                    <div className='d-flex justify-content-around w-100'>
                        <h5>
                            {`${pr.Doc.dokument.podmiot1.adresPod.ulica} ${pr.Doc.dokument.podmiot1.adresPod.nrDomu}`}
                        </h5>
                    </div>
                    <div className='d-flex justify-content-around w-100'>
                        <h5>
                            {`${pr.Doc.dokument.podmiot1.adresPod.kodPoczt} ${pr.Doc.dokument.podmiot1.adresPod.miejsc}`}
                        </h5>
                    </div>
                    <hr/> */}
                    {/* <div className='d-flex justify-content-between'>
                        <div>
                            {moment(pr.Doc.dokument.paragon.zakSprzed).format('DD.MM.YYYY, HH:mm:ss')}
                        </div>
                        <div>
                            Nr dokumentu: {pr.Doc.dokument.paragon.JPKID}
                        </div>
                    </div> */}
                </div>
                <div className='card-body'>
                    {/* <div className='w-100 text-center'>
                        <h3>{pr.FiscalDocTypeId == 1 && "PARAGON FISKALNY" || pr.FiscalDocTypeId == 2 && "PARAGON ANULOWANY"}</h3> 
                    </div> */}
                    <div className='w-100 text-center mb-4'>
                        <h5>Produkty</h5>
                    </div>
                    <div className='mb-4'>
                        <table className='table table-striped'>
                            <thead>
                                <tr>
                                    <th>Produkt</th>
                                    <th className='text-center'>Numer wyboru</th>
                                    <th className='text-center'>Ilość</th>
                                    <th className='text-center'>Cena brutto</th>
                                    <th className='text-center'>Suma brutoo</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pr.Doc.dokument.paragon.pozycja && pr.Doc.dokument.paragon.pozycja.map(({towar}, idx) => (
                                    <tr key={idx} >
                                        <td>
                                            {towar.nazwa}
                                        </td>
                                        <td className='text-center'>
                                            {String(towar.opis[0].tekst).replace(/[^0-9]/g,'')}
                                        </td>
                                        <td className='text-center'>
                                            {towar.ilosc}
                                        </td>
                                        <td className='text-center'>
                                            {Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format((towar.cena / 100)?.toFixed(2) || 0) }
                                        </td>
                                        <td className='text-center'>
                                            {Intl.NumberFormat('pl-PL', {style: "currency", currency: "PLN"}).format((towar.brutto / 100)?.toFixed(2) || 0) }
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <hr/>
                    <div className='w-100 text-center mb-4'>
                        <h5>Podsumowanie</h5>
                    </div>
                    <div>
                        {renderSum(pr)}
                    </div>
                    <hr/>
                    <div className='w-100 text-center mb-4'>
                        <h5>
                            Rozliczenie płatności
                        </h5>
                    </div>
                    <div className='d-flex justify-content-between mr-4 ml-4'>
                        <div>
                            <h6>{moment(pr.Doc.dokument.paragon.zakSprzed).format('YYYY-MM-DD HH:mm:ss')}</h6>
                        </div>
                        <div>
                            {getPaymentType(pr.Doc.dokument.paragon.PAYMENT_TYPE)}
                        </div>
                        <div>
                            <h6>Nr dokumentu: {pr.Doc.dokument.paragon.nrDok}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    )
}
